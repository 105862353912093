import { Arr } from './Array.lib';
import { NOT } from './Boolean.lib';

/**
 * 
 * @param {function} presumedFunction 
 * @param {any[]} params
 * @returns 
 */
export function tryExecute(presumedFunction, params) {
  try { return Fun(presumedFunction)(...Arr(params)); } catch (error) {
    console.warn(
      'tryExecute: Something goes wrong...',
      '\n', error
    );
  }
  return null;
}

/**
 * Always returns a function
 * 
 * @param {function} presumedFunction
 * @returns 
 */
export function Fun(presumedFunction, debug = false) {
  if (NOT(presumedFunction instanceof Function)) {
    debug && console.debug(
      'Fun: presumedFunction is not a function...',
      '\n', { presumedFunction },
      '\n', new Warning(),
    );
    presumedFunction = () => null;
  }
  return presumedFunction;
}
