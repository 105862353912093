import moment from 'moment';
import React, {
  Component,
  Fragment
} from 'react';
import { join } from '../../../lib/Array.lib';
import Core from '../../../lib/Core';
import {
  DATE_FORMAT__DATETIME__ISO,
  evalDiffTimeZoneToPT,
  formatDateTime24hPT,
  getLocalTimeZone,
  isValidDate
} from '../../../lib/Date.lib';
import Engagement from '../../../lib/Engagement';
import {
  NOT
} from '../../../lib/GenericTools.lib';
import Google from '../../../lib/Google';
import Job from '../../../lib/Job';
import Streak from '../../../lib/Streak';
import {
  getLocation
} from '../../../lib/URL.lib';
import {
  STAGE_CONFIRMATION,
  STAGE_END,
  STAGE_GUARANTEE,
  STAGE_HIRE,
  STAGE_OFFER,
  STAGE_ONSITE,
  STAGE_PENDING,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STAGE_SUBMISSION
} from '../../../dictionaries/Engagement.dic';
import EngagementModel, {
  mapEngagementInterviewDates
} from '../../../lib/models/engagement';
import {
  openCandidateRepresentEmailPreview
} from '../../../lib/services/Email/EmailCandidate.lib';
import MoreOptionsMenu from '../../Home/3DotsMenu/MoreOptionsMenu';
import {
  moreMenuOptionsEngagements
} from '../../Home/3DotsMenu/moreMenuOptionsEngagements';
import Button from '../../Layout/Wrappers/Button';
import Card from '../../Layout/Wrappers/Card';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import Col from '../../Layout/Wrappers/Col';
import Divider from '../../Layout/Wrappers/Divider';
import IconButton from '../../Layout/Wrappers/IconButton';
import Row from '../../Layout/Wrappers/Row';
import Table from '../../Layout/Wrappers/Table';
import TextField from '../../Layout/Wrappers/TextField';
import StyledTooltip, {
  PLACEMENT__RIGHT
} from '../../Layout/Wrappers/StyledTooltip';
import Loader from '../../Shared/Loader';
import {
  Row1Col1
} from './Row1Col1';
import {
  Row2Col1
} from './Row2Col1';
import {
  Row2ColN
} from './Row2ColN';
import StageColumn from './StageColumn';

export default class EngagementCard extends Component {
  timeout;
  pickers = {};
  dropdowns = {};

  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = {
      id: 0,
      engagement: {},
      candidate: {},
      recruiter: {},
      job: {},
      engagementNotes: '',
      candidateNotes: '',
      jobNotes: '',
      engagementNotesModel: {},
      candidateNotesModel: {},
      jobNotesModel: {},
      ...this.props.model,
      expanded: false,
      history: [],
      openedColor: {},
      CardStyle: {},
      newComment: '',
      box: {}
    };
    this.reloadData = (ev) => this.parent.parent.reloadData();
    if (/represent/i.test(getLocation())) {
      const engagement = this.state;
      const { candidateId } = engagement;
      openCandidateRepresentEmailPreview({
        candidateId,
        engagement
      });
    }
  }

  unexpandCard = () => {
    this.setState({
      expanded: false,
      CardStyle: null,
      openedColor: null,
      rightArrow: { transform: 'rotate(0deg)' }
    });
  };

  expandCard = () => {
    this.setState(
      {
        expanded: true,
        CardStyle: { margin: '10px auto' },
        openedColor: { color: '#715EFF' },
        rightArrow: { transform: 'rotate(180deg)' }
      },
      (then) => Core.log(this.state)
    );
  };

  updateField = (update, eventStatus, callback = (engagement) => null) => {
    this.parent.parent.updateField(this.state, update, async (engagement) => {
      engagement = await this.state.__refetch();
      if (!eventStatus) {
        engagement.filters = EngagementModel.mapFilters(engagement);
      }
      mapEngagementInterviewDates({ engagement: engagement });
      this.parent.updateItem(engagement);
      this.unexpandCard();
      this.setState(engagement, (then) => callback(engagement));
    });
  };

  onCheckStar = (ev, checked) => {
    const updateLocalList = (response) => {
      const state = {
        id: this.state.id,
        starredId: response.id,
        starred: response.starred,
        filters: {
          ...this.state.filters,
          Starred: ['Non Starred', 'Starred'][~~Boolean(response.starred)]
        }
      };
      this.setState(state);
      this.parent.updateItem(state);
    };
    Engagement.updateStarred(
      this.state.id,
      this.state.starredId,
      checked,
      updateLocalList
    );
    this.unexpandCard();
  };

  openDatesDialog = (ev) => {
    Core.dialog.open({
      title: <>Update Dates</>,
      message: (
        <DatesForm
          ref={(self) => (this.DatesForm = self)}
          engagement={this.state}
        />
      ),
      actions: [
        <Button flat
          label="Cancel"
          className="button-flat-darker"
          onClick={(ev) => {
            Core.dialog.close();
          }}
        />,
        <Button flat
          label="Done"
          className="button-white-cyan"
          onClick={(ev) => {
            Core.dialog.close();
            this.updateField({
              ...this.DatesForm.getDates()
            });
          }}
        />
      ],
      paperStyle: { width: 480 },
    });
  };

  openEmailThreads = (ev) => {
    Streak.getThreads(this.state.boxKey, (threads) => {
      if (!!threads.length) {
        Core.dialog.open({
          title: <>Email Threads</>,
          message: (
            <EmailThreads
              ref={(self) => (this.EmailThreads = self)}
              threads={threads}
              engagement={this.state}
            />
          ),
          style: { minWidth: '60vw' },
          actions: [
            <Button flat
              label="Done"
              className="button-white-cyan"
              onClick={(ev) => Core.dialog.close()}
            />
          ]
        });
      } else {
        Core.showWarning(
          "This Streak Box, doesn't have email threads attached yet"
        );
      }
    });
  };

  getPreview = (job) => Job.getPreview(job);

  render() {

    const { state: engagement } = this;

    engagement.___engagementCardUpdater___ = (update) => this.setState(update);

    console.debug(
      this,
      '\n', this.state,
      '\n', this.state.job
    );

    return (
      <Card
        className="row-card engagement"
        style={this.state.CardStyle}
      >
        {/** COLLAPSED VIEW */}
        <Table className="collapsed-view" style={{ tableLayout: 'initial' }}>
          <Table.Body>
            <Table.Row className="v-align-top padding-tops">
              {/** COL#01: CANDIDATE EMAIL & NAME & PLATFORM RATING */}
              <Table.Cell
                title="Check to Select for Matching"
                style={{
                  width: 36,
                  textAlign: 'right',
                  paddingLeft: 0,
                  paddingRight: 0
                }}
                rowSpan="2"
              >
                <Checkbox small
                  checked={this.state.checked}
                  onCheck={(event, checked) => {
                    this.setState({ checked }, (then) => {
                      this.props.onCheck(this.state.id, checked, this);
                    });
                  }}
                  disabled={false}
                />
              </Table.Cell>
              <Row1Col1 engagement={this.state} />
              <StageColumn
                parent={this}
                args={{
                  ref: STAGE_CONFIRMATION,
                  streak: 'Confirmed',
                  label: STAGE_CONFIRMATION,
                  stage: new RegExp(
                    join([
                      STAGE_PENDING,
                      STAGE_CONFIRMATION
                    ], '|'), 'i'
                  ),
                  value: 'introduced',
                  edit: 'confirmed',
                  next: STAGE_SUBMISSION
                }}
              />
              <StageColumn
                parent={this}
                args={{
                  ref: STAGE_SUBMISSION,
                  streak: 'Submitted',
                  label: 'Submitted',
                  stage: new RegExp(STAGE_SUBMISSION),
                  value: 'submitted',
                  next: STAGE_REVIEW,
                  width: 120
                }}
              />
              <StageColumn
                parent={this}
                args={{
                  ref: STAGE_REVIEW,
                  streak: 'Reviewed',
                  label: 'CV Reviewed',
                  stage: new RegExp(STAGE_REVIEW),
                  value: 'reviewed',
                  next: STAGE_SCREEN,
                  width: 120
                }}
              />
              <StageColumn
                parent={this}
                args={{
                  ref: STAGE_SCREEN,
                  streak: 'Screened',
                  label: 'Screen Dates',
                  stage: new RegExp(STAGE_SCREEN),
                  value: 'screened',
                  next: STAGE_ONSITE
                }}
              />
              <StageColumn
                parent={this}
                args={{
                  ref: STAGE_ONSITE,
                  streak: 'Onsite',
                  label: 'Onsite Dates',
                  stage: new RegExp(STAGE_ONSITE),
                  value: 'onsite',
                  next: STAGE_OFFER
                }}
              />
              <StageColumn
                parent={this}
                args={{
                  ref: STAGE_OFFER,
                  streak: 'Onsite',
                  label: STAGE_OFFER,
                  stage: new RegExp(
                    join([
                      STAGE_OFFER,
                      STAGE_GUARANTEE,
                      STAGE_HIRE
                    ], '|')
                  ),
                  value: 'onsite',
                  next: STAGE_HIRE,
                  next2: STAGE_GUARANTEE,
                  next3: STAGE_END,
                  colSpan: 2
                }}
              />
              <Table.Cell
                title="Options"
                className="last-col inline-blocks v-align-mid"
                rowSpan={Core.isAdmin() ? 2 : 3}
              >
                <div className="d-flex flex-align-right-center">
                  <Checkbox
                    title="Click to Starred"
                    className="star"
                    checked={this.state.starred}
                    onCheck={this.onCheckStar}
                    checkedIcon={<i className="material-icons">star</i>}
                    uncheckedIcon={
                      <i className="material-icons">star_border</i>
                    }
                    iconStyle={this.state.openedColor}
                  />

                  <MoreOptionsMenu
                    row={{ original: { ...this.state } }}
                    context={{
                      config: { moreMenuOptions: moreMenuOptionsEngagements }
                    }}
                  />

                  <i
                    className="material-icons"
                    style={{
                      width: 24,
                      height: 24,
                      margin: 0,
                      cursor: 'pointer',
                      fontWeight: 200
                    }}
                    onClick={engagement.openDetails}
                  >
                    chevron_right
                  </i>
                </div>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Row2Col1 engagement={this.state} />
              <Row2ColN engagement={this.state} />
            </Table.Row>
          </Table.Body>
        </Table>
        <Divider />
      </Card>
    );
  }
}

export class StreakComments extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      boxKey: this.props.boxKey,
      newComment: '',
      sign: '',
      comments: this.props.comments || []
    };
  }
  saveToCloud = (ev) => {
    !!this.state.newComment.trim().length &&
      Streak.createComment(
        {
          boxKey: this.state.boxKey,
          message: [this.state.newComment, this.state.sign].join('\n\n')
        },
        (response) => {
          Core.showSuccess('Added new comment to the Streak box');
        }
      );
  };
  setComment = (ev, newComment) => {
    const sign =
      ' - ' +
      Core.getUserName() +
      ' - ' +
      moment().tz(moment.tz.guess()).format('lll z');
    this.setState({ newComment, sign });
  };
  render() {
    return (
      <div>
        <TextField
          name="newComment"
          placeholder="Type something"
          value={this.state.newComment}
          onChange={this.setComment}
          rows={1}
          rowsMax={6}
          multiLine
          fullWidth
        />
        <span>{this.state.sign}</span>
        {!!this.state.comments && !!this.state.comments.length && (
          <div>
            <h3>Previous comments</h3>
            <div className="scroll-y" style={{ maxHeight: 480 }}>
              {this.state.comments
                .sort((a, b) => b.timestamp - a.timestamp)
                .map((item, index) => (
                  <Fragment key={`engagement-${this.state.id}-card-comments-${index}`}>
                    <div>
                      <pre>{item.message}</pre>
                    </div>
                    <Divider />
                  </Fragment>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export class StreakNotes extends Component {
  constructor() {
    super(...arguments);
    const split = String(this.props.notes || '')
      .replace('\r', '')
      .split('\n\n');
    let sign = '';
    if (split.length > 1) {
      sign = split.pop();
    }
    const notes = split.join('\n\n');
    this.state = {
      boxKey: this.props.boxKey,
      notes,
      sign
    };
  }
  saveToCloud = (ev) => {
    Streak.updateNotes(
      {
        boxKey: this.state.boxKey,
        notes: [this.state.notes, this.state.sign].join('\n\n')
      },
      (response) => { }
    );
  };
  setNotes = (ev, notes) => {
    const sign =
      ' - ' +
      Core.getUserName() +
      ' - ' +
      moment().tz(moment.tz.guess()).format('lll z');
    this.setState({ notes, sign }, (then) => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.saveToCloud, 2000);
    });
  };
  render() {
    return (
      <div>
        <TextField
          name="newComment"
          placeholder="Type something"
          value={this.state.notes}
          onChange={this.setNotes}
          rows={1}
          rowsMax={20}
          multiLine
          fullWidth
        />
        <span>{this.state.sign}</span>
      </div>
    );
  }
}

export class DatesForm extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      ...this.props.engagement
    };
  }
  getDates = (ev) => {
    const dates = {
      reviewed: '',
      screened: '',
      onsite: '',
      hired: '',
      homeworkAssigned: '',
      homeworkCompleted: '',
      overdueDate: '',
      lastAction: '',
      statusUpdatedDate: '',
      closed: ''
    };
    Object.keys(dates).forEach((key) => (dates[key] = this.state[key]));
    return dates;
  };
  render() {
    const _getValue = (key) =>
      isValidDate(this.state[key])
        ? moment(this.state[key]).format(DATE_FORMAT__DATETIME__ISO)
        : '';
    const _onChange = (key) => ({ target: { value } }) => {
      this.setState({
        [key]: isValidDate(value)
          ? moment(value).toISOString()
          : ''
      });
    };
    const _onDelete = (key) => (event) => this.setState({ [key]: '' });
    console.debug('DatesForm', this.state);
    return (
      <div className="pb-2 min-w480px">
        <div className='d-flex flex-align-right-center'>
          <span className='fw-500 f-sm c-gray-common px-2 pb-1'>
            The dates are edited on your local time ({getLocalTimeZone()})
          </span>
        </div>
        <Row>
          <Col>
            <label>Reviewed</label>
          </Col>
          <Col>
            <DateTimePicker
              name="reviewed"
              value={_getValue('reviewed')}
              onChange={_onChange('reviewed')}
              onDelete={_onDelete('reviewed')}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <label>Screened</label>
          </Col>
          <Col>
            <DateTimePicker
              name="screened"
              value={_getValue('screened')}
              onChange={_onChange('screened')}
              onDelete={_onDelete('screened')}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <label>Homework assigned</label>
          </Col>
          <Col>
            <DateTimePicker
              name="homeworkAssigned"
              value={_getValue('homeworkAssigned')}
              onChange={_onChange('homeworkAssigned')}
              onDelete={_onDelete('homeworkAssigned')}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <label>Homework completed</label>
          </Col>
          <Col>
            <DateTimePicker
              name="homeworkCompleted"
              value={_getValue('homeworkCompleted')}
              onChange={_onChange('homeworkCompleted')}
              onDelete={_onDelete('homeworkCompleted')}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <label>Onsite</label>
          </Col>
          <Col>
            <DateTimePicker
              name="onsite"
              value={_getValue('onsite')}
              onChange={_onChange('onsite')}
              onDelete={_onDelete('onsite')}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <label>Hired</label>
          </Col>
          <Col>
            <DateTimePicker
              name="hired"
              value={_getValue('hired')}
              onChange={_onChange('hired')}
              onDelete={_onDelete('hired')}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <label>Overdue</label>
          </Col>
          <Col>
            <DateTimePicker
              name="overdueDate"
              value={_getValue('overdueDate')}
              onChange={_onChange('overdueDate')}
              onDelete={_onDelete('overdueDate')}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <label>Last Action</label>
          </Col>
          <Col>
            <DateTimePicker
              name="lastAction"
              value={_getValue('lastAction')}
              onChange={_onChange('lastAction')}
              onDelete={_onDelete('lastAction')}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <label>Status Updated Date</label>
          </Col>
          <Col>
            <DateTimePicker
              name="statusUpdatedDate"
              value={_getValue('statusUpdatedDate')}
              onChange={_onChange('statusUpdatedDate')}
              onDelete={_onDelete('statusUpdatedDate')}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <label>Closed</label>
          </Col>
          <Col>
            <DateTimePicker
              name="closed"
              value={_getValue('closed')}
              onChange={_onChange('closed')}
              onDelete={_onDelete('closed')}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export class EmailThreads extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      threads: this.props.threads,
      engagement: this.props.engagement,
      data: [],
      fetching: true
    };
    this.onLoad(this.state);
  }
  onLoad = (state) => {
    const threads = [...state.threads].slice(0, 5);
    const next = (em) => {
      if (!!threads.length) {
        const thread = threads.pop();
        Google.getThreadEmails(
          thread.threadGmailId,
          (response) => {
            this.setState(
              (state) => {
                if (response.messages && response.messages.length) {
                  state.data.push(response);
                }
                return state;
              },
              (then) => {
                Core.log({ data: this.state.data });
                next();
              }
            );
          },
          (error) => {
            Core.showFailure(error);
            this.setState({ fetching: false });
          }
        );
      } else {
        this.setState({ fetching: false });
      }
    };
    next();
  };
  render() {
    return (
      <div>
        {this.state.data
          .sort((a, b) => {
            const a_firstMessage = a.messages[0];
            const a_headers = {};
            a_firstMessage.payload.headers.forEach((header) => {
              a_headers[header.name] = header.value;
            });
            const b_firstMessage = b.messages[0];
            const b_headers = {};
            b_firstMessage.payload.headers.forEach((header) => {
              b_headers[header.name] = header.value;
            });
            return new Date(b_headers['Date'])
              .toISOString()
              .localeCompare(new Date(a_headers['Date']).toISOString());
          })
          .map((item, index) => {
            const firstMessage = item.messages[0];
            const headers = {};
            firstMessage.payload.headers.forEach((header) => {
              headers[header.name] = header.value;
            });
            return (
              <p key={`engagement-${this.state.id}-card-email-thread-${index}`}>
                <span
                  style={{ float: 'right' }}
                  title={moment(headers['Date']).format('YYYY/MM/DD hh:mm Z')}
                >
                  &nbsp;&nbsp;&nbsp;
                  {moment(headers['Date']).fromNow()}
                </span>
                <Divider />
                <span>{headers['Subject']}</span>
                <br />
                <span>
                  From {headers['From']} to {headers['To']}
                </span>
                <br />
                <a
                  href={`https://mail.google.com/mail/u/1/#box/${this.state.engagement.boxKey}`}
                  target="_blank"
                  rel="noreferrer"
                  className="pointer"
                >
                  {item.messages.length}
                  &nbsp;messages &nbsp;❯&nbsp;&nbsp;&nbsp;
                </a>
                <br />
              </p>
            );
          })}
        <Loader loading={this.state.fetching} />
      </div>
    );
  }
}

function DateTimePicker({
  name = '',
  value = '',
  onChange = (event) => null,
  onDelete = (event) => null
}) {
  const _fixDate = (warning) => (event) => {
    /**
     * [2024-02-22][story_10170]
     * 
     * @issue
     * The onChange event is only triggered if the complete date and time is entered, 
     * then once the operation is done, if the user only types the date, 
     * the exporting out of the value never occurs.
     * @hack
     * Trying to prevent the user enter an incomplete date-time...
     * When focus or blur the text input, if the param value is an invalid date, 
     * it will be preset to the current date-time by code.
     */
    if (NOT(isValidDate(value))) {
      event.target.value = moment().format('YYYY-MM-DD HH:mm');
      onChange(event);
      warning && Core.showWarning(warning)
    }
  }
  return (
    <StyledTooltip
      title={(evalDiffTimeZoneToPT() && isValidDate(value)) ? (
        <span className='f-xs c-gray-common'>
          {formatDateTime24hPT(value)}
        </span>
      ) : ''}
      placement={PLACEMENT__RIGHT}
      className='d-flex flex-align-left-center mt-1'
    >
      <TextField
        name={name}
        type="datetime-local"
        value={value}
        onChange={onChange}
        onFocus={_fixDate()}
        onBlur={_fixDate(`Be careful, ${name} was auto-fixed to the current date-time`)}
        variant="standard"
        fullWidth
        className="mr-1"
      />
      <IconButton
        className="icon16"
        onClick={onDelete}
      >
        <i className="material-icons">remove_circle_outline</i>
      </IconButton>
    </StyledTooltip>
  );
}
