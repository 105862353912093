import Handlebars, {
  compile
} from "handlebars";
import moment from "moment";
import {
  showConfirm
} from '../../../components/Dialogs/AppConfirmationDialog';
import EmailPreview from "../../../components/Dialogs/EmailPreview";
import RejectionEmail from '../../../components/Engagements/Card/RejectionEmail';
import {
  JOB_SECTION__INSIDER_SCOOP,
  JOB_SECTION__INTERVIEW_FEEDBACK,
  JOB_SECTION__RESUME_FEEDBACK
} from '../../../components/Jobs/Card/JobDetails';
import {
  JobUI
} from '../../../components/Jobs/JobUI.dic';
import Button from '../../../components/Layout/Wrappers/Button';
import {
  generateRejectionHavesHTMLString
} from '../../../components/Match/Haves/v2/Generators.lib';
import Account from "../../Account";
import {
  join
} from '../../Array.lib';
import Candidate from '../../Candidate';
import {
  CSS_PURPLE_RGB,
  TIMEZONE__LA
} from '../../Constants';
import Core from "../../Core";
import Definition, {
  EMP_MSG_TYPE__ALL_ID,
  EMP_MSG_TYPE__SUBMISSION_ID,
  REC_MSG_TYPE__ALL_ID,
  REC_MSG_TYPE__REJECTION_ID,
  REC_MSG_TYPE__STAGE_TRANSITION_ID
} from "../../Definition";
import Employer from '../../Employer';
import Engagement from "../../Engagement";
import Job from "../../Job";
import {
  Obj
} from '../../Object.lib';
import Streak from '../../Streak';
import {
  convertHtmlToPlainText,
  trim
} from '../../String.lib';
import TemplateLib from "../../Template.lib";
import {
  REJECTION_REASON__RECRUITER__BAD_MATCH,
  STAGE_CONFIRMATION,
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STAGE_SUBMISSION,
  STATUS_E_10X10
} from "../../../dictionaries/Engagement.dic";
import {
  mapAccount
} from "../../models/account";
import {
  mapEngagement
} from "../../models/engagement";
import {
  TEMPLATE__REJECTION_EMAIL__BODY,
  TEMPLATE__REJECTION_EMAIL__SUBJECT
} from '../../templates/RejectionEmail.templates';
import cleanHtml from "../../tools/cleanHtml";
import {
  combineEmailsLists,
  mapEmailsListToString, sendSafeEmail,
  TEN_BY_TEN_RECRUITER_EMAIL_OBJECT,
  updateReminderSentDates
} from "./Email.lib";

/**
 * Maps Recruiter emailsList
 *
 * into Delivery Arrays according
 *
 * recruiterMessageType - tagId
 *
 * @param {object} props_
 * @param {number} props_.messageType
 * @param {string} props_.recruiterId
 * @param {{name,email}[]} props_.emails
 * @param {{name,email}[]} props_.to
 * @param {{name,email}[]} props_.cc
 * @param {{name,email}[]} props_.bcc
 * @returns
 */
async function mapEmailDeliveries(props) {
  const {
    messageType: recMsgTypeTagId = REC_MSG_TYPE__ALL_ID,
    recruiterId,
    emails: emailsList = [],
    to: deliveryTo = [],
    cc: deliveryCc = [],
    bcc: deliveryBcc = [],
    add10by10EmailToCcList = false,
    useMainEmailWhenToIsEmpty = true,
    useMainEmailWhenCcIsEmpty = false,
  } = props;

  const hashEntries = {};

  /**
   * This is adding an email entry to the list of all available emails,
   * using a hash list to avoid duplicates.
   *
   * @param {object} emailEntry
   */
  function setEmailOnCommonList(emailEntry) {
    const { name, email } = emailEntry;
    const hashKey = `${name} <${email}>`;
    if (!hashEntries[hashKey]) {
      emailsList.push(emailEntry);
      hashEntries[hashKey] = true;
    }
  }

  const recruiter = mapAccount(await Account.get(recruiterId));
  const {
    _name: recruiterFullname,
    email: recruiterEmail,
    emailsList: recruiterEmailsList = []
  } = recruiter;

  /** Main Account Email */
  const recruiterMainEmail = {
    accountType: "Recruiter",
    name: recruiterFullname,
    email: recruiterEmail
  };
  setEmailOnCommonList(recruiterMainEmail);

  recruiterEmailsList.forEach(({
    name,
    email,
    to = [],
    cc = [],
    bcc = []
  }) => {
    const emailEntry = {
      accountType: "Recruiter",
      name,
      email
    };
    if (
      to.includes(REC_MSG_TYPE__ALL_ID) ||
      to.includes(recMsgTypeTagId)
    ) {
      deliveryTo.push(emailEntry);
      setEmailOnCommonList(emailEntry);
    }
    if (
      cc.includes(REC_MSG_TYPE__ALL_ID) ||
      cc.includes(recMsgTypeTagId)
    ) {
      deliveryCc.push(emailEntry);
      setEmailOnCommonList(emailEntry);
    }

    if (
      bcc.includes(REC_MSG_TYPE__ALL_ID) ||
      bcc.includes(recMsgTypeTagId)
    ) {
      deliveryBcc.push(emailEntry);
      setEmailOnCommonList(emailEntry);
    }

  });

  if (!deliveryTo.length && useMainEmailWhenToIsEmpty) {
    deliveryTo.push(recruiterMainEmail);
  }

  if (!deliveryCc.length && useMainEmailWhenCcIsEmpty) {
    deliveryCc.push(recruiterMainEmail);
  }

  if (add10by10EmailToCcList) {
    deliveryCc.push(TEN_BY_TEN_RECRUITER_EMAIL_OBJECT);
  }

  setEmailOnCommonList(TEN_BY_TEN_RECRUITER_EMAIL_OBJECT);

  /** µ FOR DEBUG PURPOSES */
  console.debug('µ:mapEmailDeliveries', {
    REC_MSG_TYPE__ALL_ID,
    recMsgTypeTagId,
    recruiter,
    recruiterMainEmail,
    emailsList,
    deliveryTo,
    deliveryCc,
  });
  /** */

  return {

    /** Main emails for this recruiter */
    recruiterMainEmail,

    /** All emails related to this recruiter */
    recruiterEmails: emailsList,

    /**
     * All emails related to this recruiter,
     * and accepted for delivery "To",
     * on this type of message.
     */
    recruiterToList: deliveryTo,

    /**
     * All emails related to this recruiter,
     * and accepted for delivery "Cc",
     * on this type of message.
     * If no recruiter.emailList doesn't exists,
     * this will return the main email.
     */
    recruiterCcList: deliveryCc,

    /**
     * All emails related to this recruiter,
     * and accepted for delivery "Bcc",
     * on this type of message.
     */
    recruiterBccList: deliveryBcc,

  };
}

export async function openRecruiterReminderDialog({ recruiterId }) {
  const _key = `openRecruiterReminderDialog__${recruiterId}`;
  const _template = await TemplateLib.getBy({
    type: 'email',
    name: 'recruiter-todo-reminder'
  });
  const _processedEmailData = await TemplateLib.getRender({
    templateId: _template.id,
    recruiterId
  });
  const { errors = [], rendered = {}, mixins = {} } = _processedEmailData;
  console.debug({ errors, rendered, mixins, recruiterId });
  if (!errors.length) {
    let {
      subject = '',
      bodyHtml: body = ''
    } = rendered;
    let postProcessingTemplate = (String(body || '').match(/<!-- POST_PROCESSING >>> -->.*<!-- <<< POST_PROCESSING -->/i) || [])[0];
    body = String(body || '').replace(postProcessingTemplate, '');
    const {
      from,
      emails = [],
      to = [],
      cc = [],
      bcc = [],
      engagements
    } = mixins;
    showConfirm({
      title: <>Recruiter Reminder</>,
      content: (
        <EmailPreview
          ref={(self) => (self && Core.setKeyValue(_key, self))}
          emails={emails}
          from={from}
          to={to}
          cc={cc}
          bcc={bcc}
          subject={subject}
          body={body}
        />
      ),
      onAcceptLabel: <>Send</>,
      onAccept: async (event) => {
        try {
          const emailParameters = Core.getKeyValue(_key).getParams();
          emailParameters.html = `${postProcessingTemplate || ''}${emailParameters.html || ''}`;
          await sendSafeEmail({ ...emailParameters, source: _key }).then((response) => {
            Core.showSuccess('Email sent');
            if (!!response?.threadId) {
              const next = em => {
                if (!!engagements.length) {
                  const engagement = engagements.pop();
                  Streak.putEmailInBox(
                    {
                      boxKey: engagement.boxKey,
                      threadGmailId: response.threadId
                    },
                    response => {
                      updateReminderSentDates({
                        engagement,
                        next: () => { },
                        reminder: {
                          email: to, // email address reminder was sent to
                          date: new Date().toISOString(), // date - time reminder was set
                          source: 'recruiter', // source of email, e.g. job, employer, recruiter, agency, null (for candidate)
                          type: 'primary' // primary or secondary
                        }
                      });
                    }
                  );
                }
              };
              next();
            }
          });
        }
        catch (error) {
          Core.showError(error);
        }
      },
      paperStyle: {
        width: 1024,
        maxWidth: 'calc(100vh - 2rem)'
      }
    });
  }
  else {
    Core.showWarning("There are no Engagements for sending a reminder");
  }
};

export async function generateRejectionEmailContent({
  EngagementCardController,
  engagement
}) {

  const { jobId, candidateId, stage, status, rejectionReason } = engagement;

  const feedbackStage = Obj(
    ({
      [STAGE_REVIEW]: {
        title: JobUI.feedbackResume.title,
        hash: JOB_SECTION__RESUME_FEEDBACK
      },
      [STAGE_SCREEN]: {
        title: JobUI.feedbackInterview.title,
        hash: JOB_SECTION__INTERVIEW_FEEDBACK
      },
      [STAGE_ONSITE]: {
        title: JobUI.feedbackInterview.title,
        hash: JOB_SECTION__INTERVIEW_FEEDBACK
      }
    })[stage]
  );

  const job = (
    engagement.job.id ? engagement.job : jobId
      ? await Job.get(jobId) || {}
      : {}
  );
  const candidate = (
    engagement.candidate.id ? engagement.candidate : candidateId
      ? await Candidate.get(candidateId) || {}
      : {}
  );
  const { employerId } = job;
  const employer = (
    engagement.employer.id ? engagement.employer : employerId
      ? await Employer.get(employerId) || {}
      : {}
  );

  const IS_ON_CONF_OR_SUBMISSION = [
    STAGE_CONFIRMATION,
    STAGE_SUBMISSION
  ].includes(stage);

  const IS_ON_REVIEW_OR_INTERVIEW = [
    STAGE_REVIEW,
    STAGE_SCREEN,
    STAGE_ONSITE
  ].includes(stage);

  const jobTitle = job.jobTitle ? ` (${job.jobTitle})` : ``;

  const subject = convertHtmlToPlainText(
    compile(TEMPLATE__REJECTION_EMAIL__SUBJECT)({
      CANDIDATE_NAME: candidate._name,
      EMPLOYER_NAME: employer.name,
      JOB_TITLE: jobTitle,
      IS_ON_CONF_OR_SUBMISSION,
      IS_ON_REVIEW_OR_INTERVIEW,
      STAGE: stage,
      USER: Core.getUserName()
    })
  );

  const body = compile(TEMPLATE__REJECTION_EMAIL__BODY)({
    STAGE: stage,
    CANDIDATE_NAME: candidate._name,
    EMPLOYER_NAME: employer.name,
    JOB_TITLE: jobTitle,
    IS_ON_CONF_OR_SUBMISSION,
    IS_ON_REVIEW_OR_INTERVIEW,
    RECRUITER__BAD_MATCH: (
      [STAGE_CONFIRMATION, STAGE_SUBMISSION].includes(stage) &&
      (status === STATUS_E_10X10) &&
      (rejectionReason === REJECTION_REASON__RECRUITER__BAD_MATCH) &&
      trim(await generateRejectionHavesHTMLString({
        job,
        candidate,
        EngagementCardController
      }))
    ),
    /** 
     * @note [2024-03-27][MS]
     * We must access to the live state of the engagement using the EngagementCardController.
     */
    ADDITIONAL_INFO: EngagementCardController && (
      EngagementCardController.state.__includeRejectionEmailAdditionalInfo &&
      trim(EngagementCardController.state.rejectionReasonAdditionalInfo)
    ),
    INSIDER_SCOOP_URL: Core.getPath(
      `job/view/${jobId}?${JOB_SECTION__INSIDER_SCOOP}`
    ),
    FEEDBACK_URL: Core.getPath(
      `job/view/${jobId}?${feedbackStage.hash}`
    ),
    FEEDBACK_TITLE: feedbackStage.title
  });

  if (EngagementCardController) {
    EngagementCardController.setState({
      __rejectionEmail: {
        subject,
        body
      }
    });
  }

  return {
    subject,
    body
  };

}

export async function setExampleOfRejected({ engagement, rejectionReason, rejectionReasonAdditionalInfo }) {
  const { jobId, candidateId } = engagement;
  let job = await Job.getWhere({ id: jobId }).then(response => response[0]);
  let candidate = await Candidate.getWhere({ id: candidateId }).then(response => response[0]);
  const line = cleanHtml(
    "<p>" +
    [
      engagement.stage,
      moment().format("MM/DD/YY"),
      `<a href="/#/candidate/edit/${candidate.id}" target="_blank">${candidate._name}</a>`,
      Definition.getLabel(
        "platformRating",
        candidate.platformRating
      ),
      rejectionReason,
      rejectionReasonAdditionalInfo !== rejectionReason && rejectionReasonAdditionalInfo,
    ]
      .filter((e) => !!e && !!String(e).trim().length)
      .join(", ") +
    ".</p>"
  );
  const examplesOfRejected =
    typeof job.examplesOfRejected === "string"
      ? line + job.examplesOfRejected
      : line;
  Job.update(job.id, { examplesOfRejected });
};

export async function openRejectionEmailPreview({ EngagementCardController }) {
  await generateRejectionEmailContent({
    EngagementCardController,
    engagement: EngagementCardController.state
  });
  let {
    from = '',
    to = [],
    cc = [],
    bcc = []
  } = {};
  try {
    const renderCandidate = await TemplateLib.getRender({
      templateName: 'candidate-message',
      candidateId: EngagementCardController.state.candidateId,
      messageTypes: [REC_MSG_TYPE__REJECTION_ID]
    });
    from = renderCandidate.mixins.from;
    to = combineEmailsLists(to, renderCandidate.mixins.to);
    cc = combineEmailsLists(cc, renderCandidate.mixins.cc);
    bcc = combineEmailsLists(bcc, renderCandidate.mixins.bcc);
  }
  catch { }
  const deliveryTo = mapEmailsListToString(to);
  const _onCloseDialog = async () => {
    const engagement = EngagementCardController.state;
    const {
      rejectionReason,
      rejectionReasonAdditionalInfo
    } = engagement;
    await Engagement.update(engagement, { rejectionReasonAdditionalInfo });
    setExampleOfRejected({ engagement, rejectionReason, rejectionReasonAdditionalInfo });
  }
  showConfirm({
    paperStyle: {
      width: 1024,
      maxWidth: 'calc(100vh - 2rem)'
    },
    title: join([
      EngagementCardController.state.rejectionReason,
      'Email Preview'
    ], ' - '),
    content: (
      <RejectionEmail
        {...({
          EngagementCardController,
          deliveryTo
        })}
      />
    ),
    onCancel: (event) => {
      sendSafeEmail({
        from: mapEmailsListToString([from]),
        to: deliveryTo,
        cc: mapEmailsListToString(cc),
        bcc: mapEmailsListToString(bcc),
        subject: EngagementCardController.state.__rejectionEmail.subject,
        html: EngagementCardController.state.__rejectionEmail.body,
        boxKey: EngagementCardController.state.boxKey,
      }).then((response) => Core.showSuccess('Email sent'));
      _onCloseDialog();
    },
    onAccept: _onCloseDialog,
    onCancelLabel: `Send Email`,
    onAcceptLabel: `Don't Send`
  });
}

/**
 * Generates the inputs for stage transition email preview.
 * 
 * Eventually this method will be passed to BE, 
 * for that this require the engagementId instead the engagement object.
 * 
 * @param {object} options
 * @param {string} options.engagementId
 * @param {string} options.eventType screen|onsite|offer|interview|firstScreenInterview|status
 * @param {string} options.previousStage
 * @param {string} options.previousStatus
 * @param {string} options.interviewDate Date ISO string
 * @param {boolean} options.isFullday
 * @returns 
 */
export async function generateStageTransitionEmailInputs({
  engagementId,
  eventType,
  previousStage,
  previousStatus,
  interviewDate,
  isFullday,
}) {

  // Following must be updated on BE to get the engagement and containing its related entities.
  const engagement = mapEngagement(await Engagement.get(engagementId))
  console.debug({ engagement });
  if (!engagement.id) { return {}; }

  async function generateDeliveriesList({ engagement }) {

    const emails = [];

    engagement.actionOwner.email &&
      emails.push({
        name: engagement.actionOwner._name || "Action Owner",
        email: engagement.actionOwner.email
      });

    // note: this is for stage transition email
    const {
      // recruiterEmailEntry = {},
      recruiterEmails = [],
      recruiterToList = [],
      recruiterCcList = []
    } = await mapEmailDeliveries({
      messageType: REC_MSG_TYPE__STAGE_TRANSITION_ID,
      recruiterId: engagement.recruiter.id,
      add10by10EmailToCcList: false,
      useMainEmailWhenToIsEmpty: true, // TO
      useMainEmailWhenCcIsEmpty: false  // CC
    });

    recruiterEmails.forEach(m => emails.push(m));

    engagement.employer.primaryContactEmail &&
      emails.push({
        accountType: "Employer",
        name: engagement.employer.primaryContactName || "Primary Contact",
        email: engagement.employer.primaryContactEmail
      });
    engagement.job.emailsList
      .filter(({ to }) => (
        to.includes(EMP_MSG_TYPE__ALL_ID) ||
        to.includes(EMP_MSG_TYPE__SUBMISSION_ID)
      )).forEach(contact => emails.push({
        accountType: `${engagement.job.jobTitle}(job)`,
        ...contact
      }));
    engagement.candidate.email &&
      emails.push({
        accountType: "Candidate",
        name: engagement.candidate._name || "Candidate",
        email: engagement.candidate.email
      });

    return {
      emails,
      to: recruiterToList,
      cc: recruiterCcList,
    };

  }

  function generateSubject({ engagement, eventType }) {
    const { job, candidate } = engagement;
    const { employer } = job;
    // const { recruiter } = candidate;
    const types = {

      // this is triggered when stage transition to screen
      screen: 'Candidate Stage Update',

      // this is triggered when stage transition to onsite
      onsite: 'Candidate Stage Update',

      // this is triggered when set new eventDate OLD
      interview: 'Candidate Interview Alert',

      // this is triggered when stage transition to offer
      offer: 'Potential Offer Alert',

      /*
      this is triggered when stage is screen or onsite NEW
      and status transition someone of following statuses
      W-Employer Availability
      W-Employer Details
      W-Cando-Emp Availability
      W-Candidate Availability
      W-Candidate Details
      W-Candidate Homework
      W-Recruiter Details
      */
      status: 'Candidate Status Update',

      // this is triggered when set new first eventDate NEW
      firstScreenInterview: 'Candidate First Interview Alert',

    };
    return cleanHtml(`
      [ 10x10 ${types[eventType]} ] ${candidate.firstName} ${candidate.lastName} - ${employer.name}, moved to ${engagement.stage} stage - ${engagement.status}
    `);
  }

  function generateBody({
    engagement,
    previousStage,
    previousStatus,
    interviewDate,
    isFullday,
    eventType,
  }) {

    const { job, candidate } = engagement;
    const { employer } = job;
    const { recruiter } = candidate;

    const template = compile(`
      <style>strong{color:purple;}</style>
      <p>
        Hi {{recruiter.firstName}}, Please see the new updates:
      </p>
      <p>
        Candidate: {{candidate.firstName}} {{candidate.lastName}}<br/>
        Employer: {{employer.name}}<br/>
        Job: {{job.jobTitle}}<br/>
        Role: {{rol}} - {{job.jobTitle}}<br/>
        Submitted: {{submitted}}
      </p>
      <p>
        Stage: <strong style="color: ${CSS_PURPLE_RGB};">{{engagement.stage}}{{stageExtraWord}}</strong>{{previousStage}}
      </p>
      <p>
        Status: <strong style="color: ${CSS_PURPLE_RGB};">{{engagement.status}}</strong><!--{{previousStatus}}-->
      </p>
      {{#if interviewDate}}
        <h2>
          Interview Date: <strong style="color: ${CSS_PURPLE_RGB};">{{moment interviewDate isFullday}}</strong>
        </h2>
      {{/if}}
      <br/>
      <br/>
      <br/>
      <p>
        ---------------------------------
      </p>
      {{#if firstScreenInterview}}
        <p style="color: rgb(136, 136, 136);">
          <span style="color: rgb(136, 136, 136);">
            Please make sure your candidate shows up to the interview well prepared and can explain why they are interested in this role.  If you have any concerns that the candidate may not be well prepared for the interview, please alert us right away if we need to give the interviewer a heads up.
          </span>
        </p>
      {{/if}}
      {{#if offer}}
        <p>
          <span style="color: rgb(136, 136, 136);">
            This is a heads up on a potential offer that we are now tracking very closely. We don't have details on what steps are left for their offer decision yet. We will let you know when we have more details. Please do the same if you have any information from the candidate side.
          </span>
        </p>
      {{/if}}
      <p>
        <span style="color: rgb(136, 136, 136);">
          <a href="{{jobLink}}" target="_blank">See job details</a> - to copy JD for your candidate, click on the copy icon near the top right corner of the job details card.
        </span>
      </p>
      <p>
        <span style="color: rgb(136, 136, 136);">
          {{summary job}}
        </span>
      </p>
      <p>
        ---------------------------------
      </p>
      <p>
        Team 10x10
      </p>
      <p>
        PS. This email is automatically generated by the 10x10 platform to provide you real time update
      </p>
    `.replace(/ {2,}/g, ' ').trim());

    Handlebars.registerHelper('moment', function (interviewDate, isFullday) {
      let interviewDateWithFullday;
      if (interviewDate) {
        interviewDateWithFullday = (
          isFullday
            ? moment(interviewDate).format("MM/DD")
            : moment(interviewDate).tz(TIMEZONE__LA).format("MM/DD, h:mm a z")
        );
        if (isFullday) {
          interviewDateWithFullday = `${interviewDateWithFullday} (interview time unknown)`
        }
      }
      // console.debug({ interviewDate, isFullday });
      return new Handlebars.SafeString(interviewDateWithFullday);
    });

    Handlebars.registerHelper('summary', function (job) {
      // console.debug('preview', Job.getPreview(job));
      return new Handlebars.SafeString(Job.getPreview(job));
    });

    return template(
      {
        engagement,
        recruiter,
        employer,
        candidate,
        job,
        interviewDate,
        isFullday,
        rol: Definition.getLabel('rol', job.roles[0]),
        submitted: moment(engagement.submitted).format("MM/DD"),
        previousStage: previousStage ? ` (was ${previousStage})` : '',
        previousStatus: previousStatus ? ` (was ${previousStatus})` : '',
        stageExtraWord: /screen|onsite/i.test(engagement.stage) ? ' Interview' : '',
        firstScreenInterview: (eventType === 'firstScreenInterview'),
        offer: (eventType === 'offer'),
        jobLink: Core.getPath(`job/view/${job.id}`)
      }
    );

  }

  const { emails, to, cc } = await generateDeliveriesList({ engagement });

  const subject = generateSubject({ engagement, eventType });

  const body = generateBody({
    engagement,
    previousStage,
    previousStatus,
    interviewDate,
    isFullday,
    eventType,
  });

  return {
    emails,
    to,
    cc,
    subject,
    body
  }
}

/**
 * Shows a dialog with email preview
 * 
 * On click send button, it will
 * - close dialog
 * - call to dropdown.open
 * - call sendSafeEmail
 *   - onSuccess: call to Streak.putEmailInBox
 * 
 * @param {object} options
 * @param {object} options.engagement
 * @param {string} options.eventType screen|onsite|offer|interview|firstScreenInterview|status
 * @param {string} options.previousStage
 * @param {string} options.previousStatus
 * @param {string} options.interviewDate
 * @param {boolean} options.isFullday
 * @param {JSX.Element} options.dropdown
 */
async function openStageTransitionEmailPreview({
  engagement,
  eventType,
  previousStage,
  previousStatus,
  interviewDate,
  isFullday,
  dropdown
}) {

  let {
    subject,
    body
  } = await generateStageTransitionEmailInputs({
    engagementId: engagement.id,
    eventType,
    previousStage,
    previousStatus,
    interviewDate,
    isFullday
  });

  let {
    from = '',
    emails = [],
    to = [],
    cc = [],
    bcc = []
  } = {};
  try {
    const renderCandidate = await TemplateLib.getRender({
      templateName: 'candidate-message', // candidate-message is an empty template 2022-02-01 Tue
      candidateId: engagement.candidateId,
      messageTypes: [REC_MSG_TYPE__STAGE_TRANSITION_ID]
    });
    from = renderCandidate.mixins.from;
    emails = combineEmailsLists(emails, renderCandidate.mixins.emails);
    to = combineEmailsLists(to, renderCandidate.mixins.to);
    cc = combineEmailsLists(cc, renderCandidate.mixins.cc);
    bcc = combineEmailsLists(bcc, renderCandidate.mixins.bcc);
  }
  catch { }

  console.debug({
    emails,
    to,
    cc,
    bcc,
    subject,
    body
  });
  Core.dialog.open({
    title: <>Stage Transition Message</>,
    content: (
      <EmailPreview
        ref={self =>
          Core.setKeyValue(
            'RecruiterStageTransitionEmailPreview', self
          )
        }
        {...{
          emails,
          to,
          cc,
          bcc,
          subject,
          body
        }}
        loadSnippets
      />
    ),
    actions: [
      <Button outlined minW120
        onClick={ev => {
          Core.dialog.close();
        }}
      >
        Cancel
      </Button>,
      <Button primary minW120
        onClick={ev => {
          Core.dialog.close();
          dropdown && dropdown.open();
          sendSafeEmail({
            ...Core.getKeyValue('RecruiterStageTransitionEmailPreview').getParams(),
            from,
            source: 'EmailRecruiter.lib.js',
            boxKey: engagement.boxKey,
          }).then(response => Core.showSuccess('Email sent'));
        }}
      >
        Send
      </Button>
    ],
    paperStyle: { width: 1200 },
  });
};

export async function getRecruiterEmailRecipients({ recruiterId }) {

  let {
    emails = [],
    to = [],
    cc = [],
    bcc = [],
    names = []
  } = {};

  try {
    const renderRecruiter = await TemplateLib.getRender({
      templateName: 'account-message',
      accountId: recruiterId
    });
    emails = combineEmailsLists(emails, renderRecruiter.mixins.emails);
    to = combineEmailsLists(to, renderRecruiter.mixins.to);
    cc = combineEmailsLists(cc, renderRecruiter.mixins.cc);
    bcc = combineEmailsLists(bcc, renderRecruiter.mixins.bcc);
  }
  catch { }

  names = emails.map(input => input.name).filter(v => !!v && !v.match(/10x10|10by10/i));

  return {
    emails,
    to,
    cc,
    bcc,
    names
  }

}

/* DICTIONARY ================================= */

const RecruiterEmailLib = {
  mapEmailDeliveries,
  openRejectionEmailPreview,
  openStageTransitionEmailPreview,
};

/* EXPORTS ==================================== */

export {
  RecruiterEmailLib as default,
  mapEmailDeliveries as mapRecruiterEmailDeliveries,
  openStageTransitionEmailPreview as openRecruiterStageTransitionEmailPreview, RecruiterEmailLib
};

/* ============================================ */
