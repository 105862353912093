import { compile } from 'handlebars';
import dig from "object-dig";
import { Arr, join } from '../../../lib/Array.lib';
import Col from '../../Layout/Wrappers/Col';
import { WarningMessage } from '../../Layout/Wrappers/Message';
import Row from '../../Layout/Wrappers/Row';
import TextField from '../../Layout/Wrappers/TextField';
import { CANDIDATE_UI } from '../CandidateUI.dic';

export function CandidateNameFieldsets(props) {
  let { CandidateEditController = {} } = props;
  let { state: candidate, state } = CandidateEditController;
  (async () => {
    if (!CandidateNameFieldsets.busy && !!Arr(state.rawSovren).length && !state.errorFullName) {
      CandidateNameFieldsets.busy = true;
      const sovrenRecord = state.rawSovren[0];
      const CANDIDATE_NAME = join([candidate.firstName, candidate.lastName], ' ');
      const PARSED_NAME = join([
        dig(sovrenRecord, 'contactInfo', 'PersonName', 'GivenName'),
        dig(sovrenRecord, 'contactInfo', 'PersonName', 'FamilyName'),
      ], ' ');
      if (PARSED_NAME && (PARSED_NAME.toLowerCase() !== CANDIDATE_NAME.toLowerCase())) {
        CandidateEditController.setState({
          errorFullName: compile(CANDIDATE_UI.firstName.candidateEditDiffParsedNameErrorMessage)({
            CANDIDATE_NAME,
            PARSED_NAME
          })
        });
      }
      CandidateNameFieldsets.busy = false;
    }
  })();
  return (
    <>
      <Row className='mt-2'>
        <Col>
          <label>
            First name <b className='cred'>*</b>
          </label>
          <TextField required
            name='firstName'
            value={candidate.firstName}
            onChange={(ev, firstName) =>
              CandidateEditController.setStateStore({
                firstName: firstName.replace(/\s{2,}/, ' '),
                key: 'firstName',
                errorFirstName: '',
                errorLastName: '',
                errorFullName: ''
              })
            }
            onBlur={(ev) => {
              !!candidate.firstName &&
                CandidateEditController.setState({ duplicateTriggeredBy: 'name' }, () => {
                  CandidateEditController.checkPotentialDuplicatedCandidates((em) => { });
                });
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                CandidateEditController.setState({ duplicateTriggeredBy: 'name' }, () => { });
              }
            }}
            errorText={state.errorFirstName}
          />
        </Col>
        <Col>
          <label>
            Last name <b className='cred'>*</b>
          </label>
          <TextField
            name='lastName'
            value={candidate.lastName}
            onChange={(ev, lastName) =>
              CandidateEditController.setStateStore({
                lastName: lastName.replace(/\s{2,}/, ' '),
                key: 'lastName',
                errorFirstName: '',
                errorLastName: '',
                errorFullName: ''
              })
            }
            onBlur={(ev) => {
              !!candidate.lastName &&
                CandidateEditController.setState({ duplicateTriggeredBy: 'name' }, () => {
                  CandidateEditController.checkPotentialDuplicatedCandidates((em) => { });
                });
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                CandidateEditController.setState({ duplicateTriggeredBy: 'name' }, () => { });
              }
            }}
            required
            fullWidth
            errorText={state.errorLastName}
          />
        </Col>
      </Row>
      <WarningMessage show={!!state.errorFullName}>
        {state.errorFullName}
      </WarningMessage>
    </>
  )
}
