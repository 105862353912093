
import moment from "moment";
import React from "react";
import DatePicker from '../Layout/Wrappers/DatePicker';
import {
  REACT_TABLE__INPUT_RANGE_DATE
} from "./useEnhancedReactTable.hook";

export const DATE_FORMAT = `MM-DD-YYYY`;

export function FilterDateRangeColumn({
  column: {
    id: columnId,
    preFilteredRows
  },
  filters = {},
  setFilter = () => { },
}) {

  let values = [];
  let columnFilters = filters[columnId] || {};
  values[0] = columnFilters['$gte'] ? moment(columnFilters['$gte']) : values[0];
  values[1] = columnFilters['$lte'] ? moment(columnFilters['$lte']) : values[1];

  const _setFilters = values => {

    values = values.map(value => ((value === undefined) ? null : value));

    let mongoRange = {};

    if (values[0]) { mongoRange['$gte'] = values[0]; }
    if (values[1]) { mongoRange['$lte'] = values[1]; }

    console.debug('values', values);

    setFilter({
      columnId,
      value: (values[0] || values[1]) ? mongoRange : undefined
    });

  }

  return (
    <div className="d-flex flex-wrap">
      <DatePicker
        format={DATE_FORMAT}
        className="mr-1"
        style={{ width: REACT_TABLE__INPUT_RANGE_DATE }}
        placeholder={`${moment().subtract(180, 'days').format(DATE_FORMAT)}`}
        value={values[0]}
        onChange={(date, dateString) => {
          let value = date;
          _setFilters([value, values[1]]);
        }}
      />
      <span className="mr-1">to</span>
      <DatePicker
        format={DATE_FORMAT}
        className="mr-1"
        style={{ width: REACT_TABLE__INPUT_RANGE_DATE }}
        placeholder={`${moment().add(1, 'days').format(DATE_FORMAT)}`}
        value={values[1]}
        onChange={(date, dateString) => {
          let value = date;
          _setFilters([values[0], value]);
        }}
      />
    </div>
  );
}
