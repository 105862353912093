import {
  Icon as MuiIcon
} from '@mui/material';
import {
  joinClassName,
  mapWrapper
} from '../Libraries/Theme.lib';
import Wrapper from './Wrapper';

/**
 * @see https://mui.com/material-ui/api/icon/
 */
export default function Icon({
  component = MuiIcon,
  // ---- //
  icon = 'settings',
  // ---- //
  children = icon,
  className = '',
  // ---- //
  debug = false,
  small = false,
  medium = false,
  large = false,
  xl = false,
  mr = 0,
  // ---- //
  wrapperProps = {},
  ...props
}) {
  if (mr === true) { mr = 1; }
  className = [
    small && 'f-sm',
    medium && 'f-md',
    large && 'f-lg',
    xl && 'f-xl',
    mr && `mr-${mr}`,
    className
  ];
  mapWrapper(
    {
      role: 'Icon',
      props: wrapperProps,
      assign: {
        ...props,
        component,
        children,
        className: joinClassName(className),
      },
      flags: { debug }
    }
  );
  return (
    <Wrapper {...wrapperProps} />
  );
}
