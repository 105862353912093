import { Arr } from '../../lib/Array.lib';
import Definition, {
  DEFINITION_CATEGORY__LOCATION,
  LOC_TYPE__ALIAS_ID,
  LOC_TYPE__CITY_ID,
  LOC_TYPE__COUNTRY_ID,
  LOC_TYPE__METRO_AREA_ID,
  LOC_TYPE__METRO_AREA_SEC_ID,
  LOC_TYPE__MULTI_CONT_ID,
  LOC_TYPE__STATE_ID,
  LOCATION__AMERICAS,
  LOCATION__CALIFORNIA,
  LOCATION__SAN_FRANCISCO_BAY_AREA,
  LOCATION__UNITED_STATES
} from '../../lib/Definition';
import FilterControlLib from '../../lib/FilterControl';
import {
  Obj
} from '../../lib/Object.lib';
import Box from '../Layout/Wrappers/Box';
import Menu from '../Layout/Wrappers/Menu';
import MoreFiltersDialog from './MoreFiltersDialog';

export default function MenuBar({
  FilterControlController,
  menus: propsMenus,
  children,
  ...props
}) {
  const menus = propsMenus || Arr(
    FilterControlController.state.menus
  );
  const more = Arr(FilterControlController.state.more);
  const _getMenuTreeContext = (menu) => {
    menu = Obj(menu);
    const items = Obj(menu.items);
    const options = Definition.get(DEFINITION_CATEGORY__LOCATION);
    const value = Object.keys(items).filter(
      (key) => Boolean(items[key])
    ).map(
      (label) => Definition.getId(
        DEFINITION_CATEGORY__LOCATION,
        FilterControlLib.getItemLabel({ menu, itemValue: label })
      )
    );
    const layers = [
      LOC_TYPE__MULTI_CONT_ID,
      LOC_TYPE__COUNTRY_ID,
      LOC_TYPE__STATE_ID,
      LOC_TYPE__METRO_AREA_ID,
      LOC_TYPE__METRO_AREA_SEC_ID,
      LOC_TYPE__CITY_ID
    ];
    const treeDefaultExpandedIds = [
      LOCATION__AMERICAS,
      LOCATION__UNITED_STATES,
      LOCATION__CALIFORNIA,
      LOCATION__SAN_FRANCISCO_BAY_AREA
    ];
    if (menu.key === 'candidateLocations') {
      layers.push(LOC_TYPE__ALIAS_ID);
    }
    return {
      layers,
      treeDefaultExpandedIds,
      items,
      options,
      value
    }
  }
  const _getMenuContext = (menu) => {
    menu = Obj(menu);
    const items = Obj(menu.items);
    const options = Object.keys(items).sort();
    const value = options.filter((key) => Boolean(items[key]));
    return ({
      items,
      options,
      value
    });
  };
  const _onChangeOptions = (menu) => (values) => {
    menu = Obj(menu);
    const { options } = _getMenuContext(menu);
    options.forEach((label) => {
      const _checked = values.includes(label);
      Object.assign(menu.items, { [label]: _checked });
      setTimeout(() => {
        FilterControlController.checkLocations({
          label,
          checked: _checked,
          cb: then => {
            FilterControlController.jiraVER20({
              label,
              menuKey: menu.key,
              checked: _checked,
              cb: FilterControlController.onChange(),
            })
          }
        });
      });
    });
  };
  const _onChangeLocationOptions = (menu) => (locations) => {
    const locationLabels = Definition.getLabels(
      'location',
      locations,
      menu.items
    );
    Object.keys(menu.items).forEach(key => menu.items[key] = false);
    locationLabels.forEach(locationLabel => {
      const itemValue = FilterControlLib.getItemValue({
        menu,
        itemLabel: locationLabel
      });
      menu.items[itemValue] = true;
    });
    menu.value = locations;
    FilterControlController.setState({ menu },
      then => {
        setTimeout(() => {
          FilterControlController.checkLocations({
            label: '',
            checked: true,
            cb: then =>
              FilterControlController.jiraVER20({
                label: '',
                menuKey: menu.key,
                checked: true,
                cb: FilterControlController.onChange(),
              })
          });
        });
      }
    );
  }
  return (
    <Box row wAuto noWrap className="mr-auto">

      {/** MENUS */}
      {
        menus.map(
          (menu, index) => (
            (menu.definitionKey === DEFINITION_CATEGORY__LOCATION)
              ?
              <Menu filter tree small
                key={`menu_bar__tree__${menu.key}`}
                name={`filter_control__menu__${menu.key}`}
                label={menu.label}
                {..._getMenuTreeContext(menu)}
                onChange={_onChangeLocationOptions(menu)}
                className='pointer mr-1 c-purple-hover w-auto'
              />
              :
              <Menu filter multiple small
                key={`menu_bar__multiple__${menu.key}`}
                name={`filter_control__menu__${menu.key}`}
                label={menu.label}
                {..._getMenuContext(menu)}
                onChange={_onChangeOptions(menu)}
                className='pointer mr-1 c-purple-hover w-auto'
              />
          )
        )
      }

      {/** MORE FILTERS */}
      {children ||
        <>

          <Menu.Item
            label='More Filters'
            onClick={(event) => FilterControlController.AllFilters.open(more)}
            endIcon='arrow_drop_down'
            className="f-sm c-purple-hover"
          />

          <MoreFiltersDialog
            ref={self => (FilterControlController.AllFilters = self)}
            parent={FilterControlController}
          />

          <Box row w100>
            {
              FilterControlController.props.toolBarLeft
            }
          </Box>

        </>
      }

    </Box>
  )
}
