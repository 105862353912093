import {
  useState
} from 'react';
import Core from '../../lib/Core';
import Box from '../Layout/Wrappers/Box';
import IconButton from '../Layout/Wrappers/IconButton';
import Menu from '../Layout/Wrappers/Menu';
import Loader from '../Shared/Loader';
import Bookmark from './Bookmark';
import {
  useAccountBookmarks
} from './useAccountBookmarks.hook';

function Bookmarks({
  debug = false,
  enhancedReactTableContext,
  ...props
}) {
  const [showNewBookmark, setShowNewBookmark] = useState(false);
  const {
    loading,
    bookmarks,
    fetchBookmarks,
    upsertBookmark,
    deleteBookmark
  } = useAccountBookmarks({
    accountId: Core.getUserId()
  });
  return (
    <Box {...props}>
      <Menu
        title='Bookmarks'
        icon='bookmark'
        onClick={(event) => {
          event.stopPropagation();
          setShowNewBookmark(false);
          fetchBookmarks();
        }}
      >
        <Box style={{ minWidth: 360 }}>
          <div className="d-flex flex-align-left-center px-1 col">
            <label className="m-0">Bookmarks</label>
            <Loader loading={loading} text="" />
            {!showNewBookmark && (
              <IconButton
                title="Add new bookmark"
                onClick={(event) => setShowNewBookmark(true)}
                className="ml-auto"
                icon='add_circle_outline'
              />
            )}
          </div>
          <Bookmark
            enhancedReactTableContext={enhancedReactTableContext}
            showNewBookmark={showNewBookmark}
            upsertBookmark={upsertBookmark}
            setShowNewBookmark={setShowNewBookmark}
          />
          <Box
            className="scroll-y"
            style={{ maxHeight: 'calc(100vh - 12rem)' }}
          >
            {bookmarks
              .sort((a, b) => String(a.pathname).localeCompare(b.pathname))
              .map((bookmark, index) => {
                return (
                  <>
                    {bookmark.pathname !== bookmarks[index - 1]?.pathname && (
                      <Box className="p-1 bg-main">
                        {bookmark.pathname}
                      </Box>
                    )}
                    <Bookmark
                      key={`bookmark-${bookmark.createdAt}`}
                      enhancedReactTableContext={enhancedReactTableContext}
                      bookmark={bookmark}
                      upsertBookmark={upsertBookmark}
                      deleteBookmark={deleteBookmark}
                      setShowNewBookmark={setShowNewBookmark}
                    />
                  </>
                )
              })}
          </Box>
        </Box>
      </Menu>
    </Box>
  );
}

export { Bookmarks, Bookmarks as default };

