import Core from '../../lib/Core';
import useState from '../../lib/hooks/useState.hook';
import Store from '../../lib/Store';
import Button from '../Layout/Wrappers/Button';
import NavLink from '../Layout/Wrappers/NavLink';
import TextField from '../Layout/Wrappers/TextField';
import {
  LoginLayout,
  loginUI
} from './Login';

export default function ForgotPassword(props) {
  const [_state, _updateState] = useState({
    email: '',
    password: '',
    confirm: '',
    error: {
      email: false,
      password: false,
      confirm: false,
    }
  });
  const _doForgot = async (event) => {
    try {
      const inputs = {
        email: String(_state.email).toLowerCase(),
      };
      if (!String(inputs.email).trim().length) {
        _state.error.email = loginUI.errorText;
        await _updateState({ error: _state.error });
        throw Error(_state.error.email);
      }
      Store.set('email', inputs.email);
      await Core.forgot(inputs);
      Core.showWarning(
        'If an account exists, a reset password link will be sent to the email address.'
      )
    }
    catch (error) {
      Core.showError(error);
    }
  };
  return (
    <LoginLayout>
      <TextField required autoFocus
        placeholder='Email'
        name="email"
        autoComplete="email"
        value={_state.email}
        onChange={async (event, email) => {
          _state.error.email = false;
          await _updateState({ email, error: _state.error });
        }}
        type="email"
        errorText={_state.error.email}
      />
      <Button
        label="SEND RECOVERY EMAIL"
        className="mt-3"
        onClick={_doForgot}
        fullWidth
      />
      <NavLink decoration to='login' className='flex-align-center mt-2'>
        Log in
      </NavLink>
    </LoginLayout>
  );

}
