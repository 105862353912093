import React from 'react';
import {
  Arr
} from '../../../lib/Array.lib';
import {
  MDASH
} from '../../../lib/Constants';
import {
  Obj
} from '../../../lib/Object.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  joinClassName
} from '../Libraries/Theme.lib';
import Box from './Box';

export default function Toggle(props) {
  const {
    className,
    data,
    value,
    onChange = (selected) => null,
    children,
    renderSelected = (selected) => null,
    ..._props
  } = props;
  const [_state, _updateState] = useState();
  const { _selectedId = value } = _state;
  const _data = Arr(data);
  const _getIndex = (selectedId) => _data.findIndex((item) => (item.id === selectedId));
  const _getSelected = (selectedId) => Obj(_data.find((item) => (item.id === selectedId)));
  const _onClick = async (event) => {
    let index = _getIndex(_selectedId);
    index = (index >= _data.length - 1) ? 0 : index + 1;
    const newSelectedId = Obj(_data[index]).id;
    onChange(_getSelected(newSelectedId));
    await _updateState({ _selectedId: newSelectedId });
  }
  const _selected = _getSelected(_selectedId);
  setTimeout(async () => {
    if (value !== _selectedId) {
      await _updateState({ _selectedId: value });
    }
  });
  console.debug('Toggle :_state :_selected', _state, _selected);
  return (
    <Box row noWrap wAuto
      {..._props}
      className={joinClassName(['pointer', className])}
      onClick={_onClick}
    >
      {(
        renderSelected(_selected) ||
        React.Children.map(children,
          (child) => (
            React.isValidElement(child)
              ? React.cloneElement(child, {
                context: props,
                selected: _selected
              })
              : children
          )
        ) ||
        (_selected.label || MDASH)
      )}
    </Box>
  );
}
