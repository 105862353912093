import {
  Component
} from "react";
import Card from '../../Layout/Wrappers/Card';
import Divider from '../../Layout/Wrappers/Divider';
import Typography from '../../Layout/Wrappers/Typography';
import WordChips from '../Components/WordChips';

class RequirementsSection2 extends Component {
  render() {

    const { job = {} } = this.props;

    const requiredFields = [
      'idealMinExperience',
      'idealMaxExperience',
      '_experience',
      '_positiveSignals',
      '_negativeSignals'
    ];

    return !!requiredFields.find((field) => !!(job[field])) && (
      <Card
        role='RequirementsSection2'
        className='mb-05'
      >

        <Typography strong large alignCenter blackDark
          className='m-1'
        >
          EXPERIENCE AND SIGNALS
        </Typography>
        <Divider />

        <WordChips inline
          acl={!!job.idealMinExperience}
          label='Ideal years of experience'
          words={job.idealMinExperience}
        />

        <WordChips inline
          acl={!!job.idealMaxExperience}
          label='Max years'
          words={job.idealMaxExperience}
        />

        <WordChips
          acl={!!job._experience}
          label='Experience'
          words={job._experience}
        />

        <WordChips
          acl={!!job._positiveSignals}
          label='Positive Signals'
          words={job._positiveSignals}
        />

        <WordChips
          acl={!!job._negativeSignals}
          label='Negative Signals'
          words={job._negativeSignals}
        />

      </Card>
    );
  }
}

export default RequirementsSection2;