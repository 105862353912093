import {
  Input
} from 'antd';
import {
  isUndefined
} from 'lodash';
import {
  useState
} from 'react';
import EmployerPendingsUI from '../../../dictionaries/EmployerPendingsUI.dic';
import {
  join
} from '../../../lib/Array.lib';
import Core from '../../../lib/Core';
import {
  getFulldayDateISOString
} from '../../../lib/Date.lib';
import Engagement from '../../../lib/Engagement';
import {
  Str
} from '../../../lib/String.lib';
import { getLocation, setLocation } from '../../../lib/URL.lib';
import {
  setURLQueryString
} from '../../../lib/URLQuery.lib';
import {
  ENGAGEMENT__STATE_OPEN,
  STAGE_ONSITE,
  STAGE_SCREEN,
  STATUS_W_EMPLOYER_FEEDBACK
} from '../../../dictionaries/Engagement.dic';
import Button from '../../Layout/Wrappers/Button';
import Chip from '../../Layout/Wrappers/Chip';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import IconButton from '../../Layout/Wrappers/IconButton';
import StyledTooltip from '../../Layout/Wrappers/StyledTooltip';
import {
  LoadingImage
} from '../../Shared/Loader';
import {
  EmployerPendings_updateState
} from './EmployerPendings';
import {
  ACTION_TYPE__ACCEPT,
  ACTION_TYPE__ACCEPT_STRONG,
  ACTION_TYPE__ACCEPT_WEAK,
  ACTION_TYPE__FEEDBACK,
  ACTION_TYPE__NO_ACTION,
  ACTION_TYPE__REJECT,
  ACTION_TYPE__REJECT_STRONG,
  ACTION_TYPE__REJECT_WEAK,
  EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK,
  EMPLOYER__PROCESSING_MODEL_KEY__HOMEWORK_TO_REVIEW,
  sendFeedback
} from './EmployerPendings.lib';
import {
  getAcceptAdditionalPresets,
  getAcceptPresets
} from './NextSteps.lib';
import {
  getRejectionReasonPresets,
  mapRejectionReasonTooltip
} from './RejectionReasons.lib';

const { TextArea } = Input;

export function FeedbackInput(props) {
  let { actionType } = props.state;
  let context = { ...props, actionType };
  if (!!String(actionType || '').match(ACTION_TYPE__ACCEPT)) {
    return <FeedbackInputAccept {...context} />;
  }
  if (!!String(actionType || '').match(ACTION_TYPE__REJECT)) {
    return <FeedbackInputReject {...context} />;
  }
  if (!!String(actionType || '').match(ACTION_TYPE__FEEDBACK)) {
    return <FeedbackOnlyPanel {...context} />;
  }
  else {
    return <FeedbackMainPanel {...context} />;
  }
}

export function FeedbackMainPanel(props) {
  let { selected = {} } = props;
  let actionType = String(selected.thread?.actionType || '');
  let isAction = !!actionType;
  let isAccepted = actionType.match(ACTION_TYPE__ACCEPT);
  let isRejected = actionType.match(ACTION_TYPE__REJECT);
  let isFeedback = actionType.match(ACTION_TYPE__FEEDBACK);
  const isNotCalibration = (!selected.engagementKey || (selected.engagementKey !== EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK));
  return (
    <div className='ml-7' style={{ maxWidth: 576 }}>
      {(!!Str(selected.engagementKey).match(
        new RegExp(
          join([
            'feedback',
            EMPLOYER__PROCESSING_MODEL_KEY__HOMEWORK_TO_REVIEW
          ], '|'), 'i')
      )
        ? (
          <div>
            <Button
              color={
                isRejected
                  ? 'inherit'
                  : !isAction
                    ? 'error'
                    : 'gray'
              }
              variant={
                isRejected
                  ? 'contained'
                  : 'outlined'
              }
              endIcon={<i className='material-icons c-inherit'>thumb_down</i>}
              className='flex-1 nowrap m-05 tt-unset min-w-120'
              size='small'
              onClick={event => EmployerPendings_updateState({ actionType: ACTION_TYPE__REJECT })}
            >
              Reject
            </Button>
            <Button
              color={
                isAccepted
                  ? 'inherit'
                  : !actionType
                    ? 'secondary'
                    : 'gray'
              }
              variant={
                (!actionType || isAccepted)
                  ? 'contained'
                  : 'outlined'
              }
              endIcon={<i className='material-icons c-inherit'>thumb_up</i>}
              className='flex-1 min-w-120 nowrap m-05 tt-unset min-w-120'
              size='small'
              onClick={event => EmployerPendings_updateState({ actionType: ACTION_TYPE__ACCEPT })}
            >
              {isNotCalibration
                ? EmployerPendingsUI.middlePanelActionYesInterview
                : EmployerPendingsUI.middlePanelActionYes}
            </Button>
            <Button
              color={
                isFeedback
                  ? 'inherit'
                  : !actionType
                    ? 'inherit'
                    : 'gray'
              }
              variant={
                isFeedback
                  ? 'contained'
                  : 'outlined'
              }
              className='flex-1 min-w-120 nowrap m-05 tt-unset min-w-120'
              size='small'
              onClick={event => EmployerPendings_updateState({ actionType: ACTION_TYPE__FEEDBACK })}
            >
              Other
            </Button>
          </div>
        ) : (
          <FeedbackOnly {...props} />
        )
      )}
      <FeedbackInputFooter selected={selected} context={props} main />
    </div>
  );
}

export function FeedbackOnlyPanel(props) {
  return (
    <div className='ml-7 outlined rounded px-1 pb-1' style={{ maxWidth: 576 }}>
      <div className='d-flex flex-align-left-center'>
        <div className='f-md fw-600 c-cyan-darker'>
          Inform 10 by 10
        </div>
        <IconButton onClick={event => EmployerPendings_updateState({ actionType: ACTION_TYPE__NO_ACTION })} className='ml-auto'>
          <i className='material-icons'>highlight_off</i>
        </IconButton>
      </div>
      <FeedbackOnly {...props} />
    </div>
  )
}

export function FeedbackOnly(props) {
  let {
    textAreaRef,
    selected,
    feedback,
    feedbacks,
    includeInterviewHappenAction = false
  } = props;
  let { loading } = feedback;
  let [value, setValue] = useState();
  value = Str(!isUndefined(value) ? value : feedback.text);
  const isNotCalibration = (!selected.engagementKey || (selected.engagementKey !== EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK));
  const isEngagementOpen = (selected.state === ENGAGEMENT__STATE_OPEN);
  return (
    <>
      <TextArea
        id='feedbackInput'
        ref={textAreaRef}
        placeholder={EmployerPendingsUI.middlePanelInputPlaceholder}
        value={value}
        onChange={(event) => {
          if (value !== event.target.value) {
            delete selected.thread;
          }
          setValue(event.target.value);
        }}
        onBlur={(event) => {
          if (selected.id) {
            feedbacks[selected.id] = {
              ...(feedbacks[selected.id] || {}),
              text: value,
              pendingsCounter: selected.pendingsCounter
            };
            EmployerPendings_updateState({ feedbacks });
          }
        }}
        autoSize={{ minRows: 6, maxRows: 12 }}
        autoFocus
        className='mt-05'
      />
      <div className='d-flex flex-wrap flex-align-right-center mt-05'>
        {includeInterviewHappenAction && isNotCalibration && isEngagementOpen && (
          <Button
            color={selected.thread?.actionType === ACTION_TYPE__FEEDBACK ? 'inherit' : !!selected.thread ? 'gray' : 'secondary'}
            variant={'outlined'}
            className='flex-1 nowrap m-05 tt-unset min-w-120'
            size='small'
            onClick={async (event) => {
              try {
                const update = {};
                const date = getFulldayDateISOString();
                if (selected.stage === STAGE_SCREEN) {
                  update.screen3 = date;
                }
                else if (selected.stage === STAGE_ONSITE) {
                  update.onsite2 = date;
                }
                update.status = STATUS_W_EMPLOYER_FEEDBACK;
                await Engagement.update(selected, update);
                Object.assign(selected, update);
                setLocation(
                  setURLQueryString({
                    url: getLocation(),
                    update: { engagementId: selected.id }
                  })
                );
                await EmployerPendings_updateState({
                  selected,
                  actionType: ACTION_TYPE__NO_ACTION
                });
              }
              catch (error) {
                Core.showError(error);
              }
            }}
            disabled={loading}
          >
            Interview already happened?
          </Button>
        )}
        <Button
          color={selected.thread?.actionType === ACTION_TYPE__FEEDBACK ? 'inherit' : !!selected.thread ? 'gray' : 'secondary'}
          variant={!selected.thread || selected.thread.actionType === ACTION_TYPE__FEEDBACK ? 'contained' : 'outlined'}
          className='flex-1 nowrap m-05 tt-unset min-w-120'
          size='small'
          onClick={sendFeedback({ ...props, actionType: ACTION_TYPE__FEEDBACK })}
          endIcon={loading ? LoadingImage : !selected.thread && <i className='material-icons c-inherit'>send</i>}
          disabled={loading || !!selected.thread}
        >
          {!!selected.thread
            ? EmployerPendingsUI.middlePanelInputActionFeedbackOnly.actionCompleted
            : EmployerPendingsUI.middlePanelInputActionFeedbackOnly.actionLabel
          }
        </Button>
      </div>
    </>
  );
}

export function FeedbackInputAccept(props) {
  let { ref: textAreaRef, selected, feedback, feedbacks, state } = props;
  let { actionType = ACTION_TYPE__NO_ACTION } = state;
  let { loading } = feedback;
  let [value, setValue] = useState();
  value = Str(!isUndefined(value) ? value : feedback.text);
  let loadingAction = (loading && actionType);
  let activePresets = (feedback.presets || []);
  let presets = getAcceptPresets({ activePresets });
  let additionalPresets = getAcceptAdditionalPresets({ preset: activePresets[0] });
  let isStrong = selected.thread?.actionType === ACTION_TYPE__ACCEPT_STRONG;
  let isWeak = selected.thread?.actionType === ACTION_TYPE__ACCEPT_WEAK;
  let isAccepted = selected.thread?.actionType === ACTION_TYPE__ACCEPT;
  const isNotCalibration = (!selected.engagementKey || (selected.engagementKey !== EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK));

  /** @todo [ 2022-10-26 ][ MS: to review logic with AC ] */
  /** * /
  let { stage } = selected;
  const INTERVIEW_DATE_IS = `Interview date is:`;
  const INTERVIEW_REQUEST_WAS = `The interview request was sent around what date:`;
  function _setFeedbackText({ text } = {}) {
    text = String(text || '').trim();
    feedbacks[selected.id].text = String(feedback.text || '').trim();
    feedback = feedbacks[selected.id];
    if (text) {
      if (!feedback.text.includes(text)) {
        feedbacks[selected.id].text = `${!!feedback.text ? `${feedback.text}\n\n` : ``}${text}`;
      }
    }
    else {
      feedbacks[selected.id].text = feedbacks[selected.id].text.replace(
        new RegExp(`\n*(${[INTERVIEW_DATE_IS, INTERVIEW_REQUEST_WAS].join('|')})`, 'i'),
        ''
      );
    }
  }
  function _updateBoxText({ feedbacks }) {
    let activeAdditionalPresets = (feedbacks[selected.id].additionalPresets || []);
    if (!activeAdditionalPresets.length) {
      _setFeedbackText(); // this remove additional text in the feedback box
    }
    else if (stage === STAGE_REVIEW) {
      if (activeAdditionalPresets.includes(NEXT_STEP_ADDITIONAL__NEED_TIME_FROM_CANDO)) {
        _setFeedbackText({ text: INTERVIEW_REQUEST_WAS });
      }
      else if (activeAdditionalPresets.includes(NEXT_STEP_ADDITIONAL__INTERVIEW_BOOKED)) {
        _setFeedbackText({ text: INTERVIEW_DATE_IS });
      }
    }
    else if ([STAGE_SCREEN, STAGE_ONSITE].includes(stage) && activeAdditionalPresets.includes(NEXT_STEP_ADDITIONAL__INTERVIEWED_DECISION_TBD)) {
      _setFeedbackText({ text: INTERVIEW_DATE_IS });
    }
  }
  /** */

  return (
    <div className='ml-7 outlined rounded px-1 pb-1' style={{ maxWidth: 576 }}>
      <Fieldset
        wrapperProps={{ className: 'p-0' }} fullWidth
        title={
          <span className='f-md fw-600 c-cyan-darker'>
            {EmployerPendingsUI.middlePanelCardTitleAcceptance}
          </span>
        }
        actionBar={
          <IconButton onClick={event => EmployerPendings_updateState({ actionType: ACTION_TYPE__NO_ACTION })} className='ml-auto'>
            <i className='material-icons'>highlight_off</i>
          </IconButton>
        }
      >
        {isNotCalibration && (
          <>
            <div>
              {presets.map(
                (line, row) => (
                  <div key={`accept-feedback-preset-row-${row}`} className='mb-1'>
                    {line.map((preset, col) => {
                      feedbacks[selected.id] = (feedbacks[selected.id] || {});
                      feedbacks[selected.id].presets = (feedbacks[selected.id].presets || []);
                      feedbacks[selected.id].additionalPresets = (feedbacks[selected.id].additionalPresets || []);
                      let checked = feedbacks[selected.id].presets.includes(preset);
                      function _toggle(event) {
                        if (checked) {
                          feedbacks[selected.id].presets = [];
                          feedbacks[selected.id].additionalPresets = [];
                        }
                        else {
                          feedbacks[selected.id].presets = [preset];
                        }
                        /** @todo [ 2022-10-26 ][ MS: to review logic with AC ] */
                        // _updateBoxText({ feedbacks });
                        EmployerPendings_updateState({ feedbacks });
                      }
                      return (
                        <Chip
                          key={`accept-feedback-preset-row-${row}-col-${col}`}
                          label={preset}
                          size='small'
                          className={`mr-1 mb-05 c-black-medium ${checked ? 'bg-green-lighter' : 'bg-white dashed-darker'}`}
                          onClick={_toggle}
                          onDelete={checked ? _toggle : undefined}
                        />
                      );
                    })}
                  </div>
                )
              )}
            </div>
            {!!additionalPresets.length && (
              <Fieldset
                wrapperProps={{ className: 'p-0' }} fullWidth
                title={
                  <div className='f-sm fw-600 c-cyan-darker'>
                    {EmployerPendingsUI.middlePanelCardHeaderOneAcceptance}
                  </div>
                }
              >
                {additionalPresets.map((additionalPreset, index) => {
                  feedbacks[selected.id] = (feedbacks[selected.id] || {});
                  feedbacks[selected.id].additionalPresets = (feedbacks[selected.id].additionalPresets || []);
                  let checked = feedbacks[selected.id].additionalPresets.includes(additionalPreset);
                  function _toggle(event) {
                    if (checked) {
                      feedbacks[selected.id].additionalPresets = [];
                    }
                    else {
                      feedbacks[selected.id].additionalPresets = [additionalPreset];
                    }
                    /** @todo [ 2022-10-26 ][ MS: to review logic with AC ] */
                    // _updateBoxText({ feedbacks });
                    EmployerPendings_updateState({ feedbacks });
                  }
                  return (
                    <Chip
                      key={`accept-feedback-additional-preset-${index}`}
                      label={additionalPreset}
                      size='small'
                      className={`mr-1 mb-05 c-black-medium ${checked ? 'bg-green-lighter' : 'bg-white dashed-darker'}`}
                      onClick={_toggle}
                      onDelete={checked ? _toggle : undefined}
                    />
                  );
                })}
              </Fieldset>
            )}
          </>
        )}
      </Fieldset>
      <Fieldset
        wrapperProps={{ className: 'p-0' }} fullWidth
        title={
          <div className='f-sm fw-600 c-cyan-darker'>
            {EmployerPendingsUI.middlePanelCardHeaderTwoAcceptance}
          </div>
        }
      >
        <TextArea
          id='feedbackInput'
          ref={textAreaRef}
          placeholder={EmployerPendingsUI.middlePanelInputPlaceholderYes}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          onBlur={(event) => {
            if (selected.id) {
              feedbacks[selected.id] = {
                ...(feedbacks[selected.id] || {}),
                text: value,
                pendingsCounter: selected.pendingsCounter
              };
              EmployerPendings_updateState({ feedbacks });
            }
          }}
          autoSize={{ minRows: 6, maxRows: 12 }}
          autoFocus
        />
      </Fieldset>
      <div className='d-flex flex-wrap flex-align-right-center mt-05'>
        <Button
          color={loading || selected.thread?.actionType === ACTION_TYPE__ACCEPT_WEAK
            ? 'inherit'
            : (
              !selected.thread ||
              !String(selected.thread?.actionType).match(`${ACTION_TYPE__ACCEPT}|${ACTION_TYPE__REJECT}`)
            )
              ? 'secondary'
              : 'gray'
          }
          variant={!loading && selected.thread?.actionType === ACTION_TYPE__ACCEPT_WEAK ? 'contained' : 'outlined'}
          className='flex-1 nowrap m-05 tt-unset min-w-120'
          size='small'
          onClick={sendFeedback({ ...props, actionType: ACTION_TYPE__ACCEPT_WEAK })}
          endIcon={(loadingAction === ACTION_TYPE__ACCEPT_WEAK)
            ? LoadingImage
            : isWeak
              ? <i className='material-icons c-inherit'>thumb_up</i>
              : null
          }
          disabled={loading}
        >
          {ACTION_TYPE__ACCEPT_WEAK}
        </Button>
        <Button
          color={loading || selected.thread?.actionType === ACTION_TYPE__ACCEPT
            ? 'inherit'
            : (
              !selected.thread ||
              !String(selected.thread?.actionType).match(`${ACTION_TYPE__ACCEPT}|${ACTION_TYPE__REJECT}`)
            )
              ? 'secondary'
              : 'gray'
          }
          variant={
            !loading && (
              !selected.thread ||
              (selected.thread?.actionType === ACTION_TYPE__ACCEPT) ||
              !String(selected.thread?.actionType).match(`${ACTION_TYPE__ACCEPT}|${ACTION_TYPE__REJECT}`)
            )
              ? 'contained'
              : 'outlined'
          }
          className='flex-1 nowrap m-05 tt-unset min-w-120'
          size='small'
          onClick={sendFeedback({ ...props, actionType: ACTION_TYPE__ACCEPT })}
          endIcon={
            (loadingAction === ACTION_TYPE__ACCEPT)
              ? LoadingImage
              : isAccepted
                ? <i className='material-icons c-inherit'>thumb_up</i>
                : null
          }
          disabled={loading}
        >
          {ACTION_TYPE__ACCEPT}
        </Button>
        <Button
          color={loading || selected.thread?.actionType === ACTION_TYPE__ACCEPT_STRONG
            ? 'inherit'
            : (
              !selected.thread ||
              !String(selected.thread?.actionType).match(`${ACTION_TYPE__ACCEPT}|${ACTION_TYPE__REJECT}`)
            )
              ? 'secondary'
              : 'gray'
          }
          variant={
            (!loading && selected.thread?.actionType === ACTION_TYPE__ACCEPT_STRONG)
              ? 'contained'
              : 'outlined'
          }
          className='flex-1 nowrap m-05 tt-unset min-w-120'
          size='small'
          onClick={sendFeedback({ ...props, actionType: ACTION_TYPE__ACCEPT_STRONG })}
          endIcon={
            (loadingAction === ACTION_TYPE__ACCEPT_STRONG)
              ? LoadingImage
              : isStrong
                ? <i className='material-icons c-inherit'>thumb_up</i>
                : null
          }
          disabled={loading}
        >
          {ACTION_TYPE__ACCEPT_STRONG}
        </Button>
      </div>
      <FeedbackInputFooter selected={selected} context={props} />
    </div >
  );
}

export function FeedbackInputReject(props) {
  let { ref: textAreaRef, selected, feedback, feedbacks, state } = props;
  let { actionType = ACTION_TYPE__NO_ACTION } = state;
  let { loading } = feedback;
  let [value, setValue] = useState();
  value = Str(!isUndefined(value) ? value : feedback.text);
  let loadingAction = (loading && actionType);
  let presets = getRejectionReasonPresets();
  feedbacks[selected.id] = (feedbacks[selected.id] || {});
  feedbacks[selected.id].presets = (feedbacks[selected.id].presets || []);
  let isStrong = selected.thread?.actionType === ACTION_TYPE__REJECT_STRONG;
  let isWeak = selected.thread?.actionType === ACTION_TYPE__REJECT_WEAK;
  let isRejected = selected.thread?.actionType === ACTION_TYPE__REJECT;
  const isNotCalibration = (!selected.engagementKey || (selected.engagementKey !== EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK));
  return (
    <div className='ml-7 outlined rounded px-1 pb-1' style={{ maxWidth: 576 }}>
      <div className='d-flex flex-align-left-center'>
        <div className='f-md fw-600 c-cyan-darker'>
          {isNotCalibration ? 'Select all the reasons' : 'Rejection'}
        </div>
        <IconButton onClick={event => EmployerPendings_updateState({ actionType: ACTION_TYPE__NO_ACTION })} className='ml-auto'>
          <i className='material-icons'>highlight_off</i>
        </IconButton>
      </div>
      <div>
        {presets.map(
          (line, row) => (
            <div key={`accept-feedback-preset-row-${row}`} className='d-flex flex-align-left-center wrap my-1'>
              {line.map((value, col) => {
                let checked = feedbacks[selected.id].presets.includes(value);
                function _toggle(event) {
                  if (checked) {
                    feedbacks[selected.id].presets = feedbacks[selected.id].presets.filter(v => v !== value);
                  }
                  else {
                    feedbacks[selected.id].presets.push(value);
                  }
                  EmployerPendings_updateState({ feedbacks });
                }
                return (
                  <StyledTooltip
                    title={mapRejectionReasonTooltip({ value })}
                    placement='bottom-start'
                  >
                    <Chip
                      key={`accept-feedback-preset-row-${row}-col-${col}`}
                      label={value}
                      size='small'
                      className={`mr-1 mb-05 c-black-medium ${checked ? 'bg-red-lighter' : 'bg-white dashed-darker'}`}
                      onClick={_toggle}
                      onDelete={checked ? _toggle : undefined}
                    />
                  </StyledTooltip>
                );
              })}
            </div>
          )
        )}
      </div>
      <TextArea
        id='feedbackInput'
        ref={textAreaRef}
        placeholder={EmployerPendingsUI.middlePanelInputPlaceholderNo}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        onBlur={(event) => {
          if (selected.id) {
            feedbacks[selected.id] = {
              ...(feedbacks[selected.id] || {}),
              text: value,
              pendingsCounter: selected.pendingsCounter
            };
            EmployerPendings_updateState({ feedbacks });
          }
        }}
        autoSize={{ minRows: 6, maxRows: 12 }}
        autoFocus
        className='mt-1'
      />
      <div className='d-flex flex-wrap flex-align-right-center mt-05'>
        <Button
          color={loading || selected.thread?.actionType === ACTION_TYPE__REJECT_STRONG
            ? 'inherit'
            : (
              !selected.thread ||
              !String(selected.thread?.actionType).match(`${ACTION_TYPE__ACCEPT}|${ACTION_TYPE__REJECT}`)
            )
              ? 'error'
              : 'gray'
          }
          variant={!loading && selected.thread?.actionType === ACTION_TYPE__REJECT_STRONG ? 'contained' : 'outlined'}
          className='flex-1 nowrap m-05 tt-unset min-w-120'
          size='small'
          onClick={sendFeedback({ ...props, actionType: ACTION_TYPE__REJECT_STRONG })}
          endIcon={
            (loadingAction === ACTION_TYPE__REJECT_STRONG)
              ? LoadingImage
              : isStrong
                ? <i className='material-icons c-inherit'>thumb_down</i>
                : null
          }
          disabled={loading}
        >
          {ACTION_TYPE__REJECT_STRONG}
        </Button>
        <Button
          color={loading || selected.thread?.actionType === ACTION_TYPE__REJECT
            ? 'inherit'
            : (
              !selected.thread ||
              !String(selected.thread?.actionType).match(`${ACTION_TYPE__ACCEPT}|${ACTION_TYPE__REJECT}`)
            )
              ? 'error'
              : 'gray'
          }
          variant={
            !loading && (
              !selected.thread ||
              (selected.thread?.actionType === ACTION_TYPE__REJECT) ||
              !String(selected.thread?.actionType).match(`${ACTION_TYPE__ACCEPT}|${ACTION_TYPE__REJECT}`)
            )
              ? 'contained'
              : 'outlined'
          }
          className='flex-1 nowrap m-05 tt-unset min-w-120'
          size='small'
          onClick={sendFeedback({ ...props, actionType: ACTION_TYPE__REJECT })}
          endIcon={
            (loadingAction === ACTION_TYPE__REJECT)
              ? LoadingImage
              : isRejected
                ? <i className='material-icons c-inherit'>thumb_down</i>
                : null
          }
          disabled={loading}
        >
          {ACTION_TYPE__REJECT}
        </Button>
        <Button
          color={loading || selected.thread?.actionType === ACTION_TYPE__REJECT_WEAK
            ? 'inherit'
            : (
              !selected.thread ||
              !String(selected.thread?.actionType).match(`${ACTION_TYPE__ACCEPT}|${ACTION_TYPE__REJECT}`)
            )
              ? 'error'
              : 'gray'
          }
          variant={!loading && (selected.thread?.actionType === ACTION_TYPE__REJECT_WEAK)
            ? 'contained'
            : 'outlined'
          }
          className='flex-1 nowrap m-05 tt-unset min-w-120'
          size='small'
          onClick={sendFeedback({ ...props, actionType: ACTION_TYPE__REJECT_WEAK })}
          endIcon={
            (loadingAction === ACTION_TYPE__REJECT_WEAK)
              ? LoadingImage
              : isWeak
                ? <i className='material-icons c-inherit'>thumb_down</i>
                : null
          }
          disabled={loading}
        >
          {ACTION_TYPE__REJECT_WEAK}
        </Button>
      </div>
      <FeedbackInputFooter selected={selected} context={props} />
    </div >
  );
}

export function FeedbackInputFooter({ selected, context, main }) {
  if (main) {
    if (selected.thread?.actionType) {
      return (
        <div className='px-1 c-red f-sm mt-05'>
          Prior feedback: {selected.thread.actionType/* === ACTION_TYPE__FEEDBACK ? 'Other' : selected.thread.actionType // leave comment just in case */}
        </div>
      );
    }
  }
  else if (
    selected.thread?.actionType &&
    (selected.thread?.actionType !== context.actionType) &&
    !!String(selected.thread?.actionType).match(`${ACTION_TYPE__ACCEPT}|${ACTION_TYPE__REJECT}`)
  ) {
    return (
      <div className='px-1 c-red f-sm mt-05'>
        Prior feedback: {selected.thread.actionType}
      </div>
    );
  }
  return null;
}
