import React from "react";
import Core from "../../../lib/Core";
import Definition, {
  ACCOUNT_ACTION__LIST_JOBS,
  DEFINITION_CATEGORY__EMPLOYER__STAGE,
  DEFINITION_CATEGORY__STATE
} from "../../../lib/Definition";
import Employer from "../../../lib/Employer";
import {
  trim
} from '../../../lib/String.lib';
import CardContent from "../../Details/CardContent";
import Col from "../../Layout/Wrappers/Col";
import Row from "../../Layout/Wrappers/Row";
import AppUI from '../../../dictionaries/AppUI.dic';
import Divider from '../../Layout/Wrappers/Divider';
import Link from '../../Layout/Wrappers/Link';
import TextField from '../../Layout/Wrappers/TextField';
import EmployerUI from '../EmployerUI.dic';

export default function EmployerDetails(props) {
  const { employer } = props;
  const [publicNotes, setPublicNotes] = React.useState(employer.publicNotes);
  const _onChangeEmployerNotes = async (event) => {
    const publicNotes = event.target.value;
    setPublicNotes(publicNotes);
    clearTimeout(EmployerDetails.timeout);
    EmployerDetails.timeout = setTimeout(() => {
      Employer.update(employer.id, { publicNotes }).then(() => {
        employer.publicNotes = publicNotes;
      });
    }, 1000);
  };
  return (
    <>
      <div className="sticky-top bg-white">
        <Row className="pb-1">
          <Col fullWidth>
            <label>{employer.name}</label>
          </Col>
        </Row>
        <Divider />
      </div>

      {[
        { title: EmployerUI.url.title, value: employer.url, Component: Link },
        { title: EmployerUI.primaryContactLinkedin.title, value: employer.primaryContactLinkedin, Component: Link },
        { title: EmployerUI.crunchbaseUrl.title, value: employer.crunchbaseUrl, Component: Link },
        { title: EmployerUI.notables.title, value: employer.notables },
        { title: 'State', show: true, value: Definition.getLabel(DEFINITION_CATEGORY__STATE, employer.state) },
        { title: 'Stage', value: Definition.getLabel(DEFINITION_CATEGORY__EMPLOYER__STAGE, employer.stage) },
        {
          title: 'Hiring',
          show: !!employer.jobs.length,
          value: (
            <ul>
              {Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS })
                ? employer.jobs.map(job => (
                  <span
                    key={`employer-${employer.id}-details-hiring-${job.id}`}
                    className="anchor"
                    onClick={ev => Core.go({
                      ...props,
                      to: `/job/view/${job.id}`
                    })}
                  >
                    <li style={{ marginBottom: 16 }}>
                      {Definition.getLabel("roles", job.role)}
                      &nbsp;&mdash;&nbsp;
                      {Definition.getLabel("state", job.state)}
                    </li>
                  </span>
                ))
                : employer.jobs.map(job => (
                  <li key={
                    `employer-${employer.id}-details-hiring-${job.id}-role`
                  }>
                    {Definition.getLabel("roles", job.role)}
                  </li>
                ))}
            </ul>
          )
        },
        { title: 'Technology stack', show: !!employer._technicalSkills.length, value: employer._technicalSkills },
        {
          title: 'Notes',
          value: (
            <TextField
              name="employerNotes"
              multiline
              minRows={3}
              maxRows={5}
              value={publicNotes}
              onChange={_onChangeEmployerNotes}
              fullWidth
              size='small'
              placeholder={AppUI.placeholder.multiline}
            />
          )
        },
      ].map(({ show, title, value, Component }) => ((show ? show : trim(value)) && (
        <CardContent title={title} key={`employer_details__${title}`}>
          {Component ? <Component>{value}</Component> : value}
        </CardContent>
      )))}


      <div className="d-flex">
        {Core.isAdminOrCoordinator() && !!employer.employerBlackList.length && (
          <CardContent
            icon={<i className="material-icons">block</i>}
            title="BLACK LIST"
            className="w-50"
          >
            <ul>
              {employer.employerBlackList.map(item => (
                <li key={`employer-${employer.id}-blacklist-item-${item.id}`}>
                  {item.firstName} {item.lastName}
                </li>
              ))}
            </ul>
          </CardContent>
        )}
        {Core.isAdminOrCoordinator() && !!employer.employerWhiteList.length && (
          <CardContent
            icon={<i className="material-icons">done</i>}
            title="WHITE LIST"
            className="w-50"
          >
            <ul>
              {employer.employerWhiteList.map(item => (
                <li key={`employer-${employer.id}-whitelist-item-${item.id}`}>
                  {item.firstName} {item.lastName}
                </li>
              ))}
            </ul>
          </CardContent>
        )}
      </div>
    </>
  );
}
