import {
  Alert
} from '@mui/material';
import React from 'react';
import {
  join
} from '../../../lib/Array.lib';
import Core from '../../../lib/Core';
import Chip from './Chip';
import Dialog from './Dialog';
import StyledTooltip from './StyledTooltip';
import {
  LoadingImage
} from '../../Shared/Loader';

const DEBUG = Core.debug('Message');

export const MESSAGE_TYPE__INLINE = 'Inline';
export const MESSAGE_TYPE__CHIP = 'Chip';
export const MESSAGE_TYPE__POPUP = 'Popup';

export default function Message({
  title = '',
  children = '<specify some message>',
  severity = 'info',
  variant = 'standard',
  icon,
  color,
  show = true,
  showClose = false,
  className,
  style,
  type = MESSAGE_TYPE__INLINE
} = {}) {
  const isValidChildren = React.isValidElement(children) || (
    React.Children.toArray(children).every(child => React.isValidElement(child))
  );
  DEBUG && console.debug('Message:children', children, isValidChildren);
  children = (isValidChildren
    ? children
    : Array.isArray(children)
      ? join(children, ' ')
      : children?.toString()
  );
  const [open, setOpen] = React.useState();
  const MsAlert = (props) => {
    let { className, style } = props;
    let cssMargin = className?.includes('m-') ? '' : ' m-1';
    return (
      <Alert
        severity={severity}
        variant={variant}
        icon={!children ? LoadingImage : icon}
        onClose={showClose ? (event => setOpen(false)) : undefined}
        className={`rounded${cssMargin} f-md ${className || ''}`.trim()}
        style={{ fontSize: '0.75rem', ...style }}
      >
        {children || 'Loading...'}
      </Alert >
    );
  };
  return open !== undefined ? open : !!show && (
    (type === MESSAGE_TYPE__INLINE)
      ? (
        <MsAlert className={className} style={style} />
      )
      : (type === MESSAGE_TYPE__POPUP)
        ? (
          <Dialog
            open={show}
            style={{ ...style, zIndex: 9999, padding: 0 }}
            fixedContent={
              <MsAlert
                className='p-2 f-lg m-0'
                style={{ maxWidth: sizes.m7 }}
              />
            }
          />
        )
        : (
          <StyledTooltip
            title={title || ''}
            placement="top"
          >
            <Chip
              color={color || severity}
              size='small'
              variant='outlined'
              label={children}
              icon={(icon !== false)
                ? (icon || <i className='material-icons ml-1'>{severity}</i>)
                : undefined
              }
              className={className || 'p-05'}
              style={style}
            />
          </StyledTooltip>
        )
  )
}

export function LoadingMessage(props = {}) {
  return (
    <Message {...props} severity='info' icon={LoadingImage}>{props.children}</Message>
  );
}

export function ErrorMessage(props = {}) {
  return (
    <Message {...props} className={`bg-a-red ${props.className || ''}`.trim()} severity='error'>{props.children || 'Error'}</Message>
  );
}

export function SuccessMessage(props = {}) {
  return (
    <Message {...props} severity='success'>{props.children || 'Success'}</Message>
  );
}

export function WarningMessage(props = {}) {
  return (
    <Message {...props} severity='warning'>{props.children || 'Warning'}</Message>
  );
}

export function InfoChipMessage(props = {}) {
  return (
    <Message {...props} severity='info' type={MESSAGE_TYPE__CHIP}>{props.children || 'Info'}</Message>
  );
}

export function SuccessChipMessage(props = {}) {
  return (
    <Message {...props}
      severity='success' type={MESSAGE_TYPE__CHIP}
      icon={<i className='material-icons'>task_alt</i>}
    >
      {props.children || 'Success'}
    </Message>
  );
}

export function WarningChipMessage(props = {}) {
  return (
    <Message {...props} severity='warning' type={MESSAGE_TYPE__CHIP}>{props.children || 'Warning'}</Message>
  );
}

export function ErrorChipMessage(props = {}) {
  return (
    <Message {...props} severity='error' type={MESSAGE_TYPE__CHIP}>{props.children || 'Error'}</Message>
  );
}
