import Card from '@mui/material/Card';
import moment from "moment";
import {
  Component
} from "react";
import Definition from "../../../lib/Definition";
import Engagement from "../../../lib/Engagement";
import {
  NOT
} from '../../../lib/GenericTools.lib';
import {
  REJECTION_REASON__10X10__NO_MATCH,
  STAGE_CONFIRMATION,
  STAGE_END,
  STAGE_GUARANTEE,
  STAGE_HIRE,
  STAGE_OFFER,
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STAGE_SUBMISSION,
  STATUS_W_EMPLOYER_FEEDBACK
} from '../../../dictionaries/Engagement.dic';
import cleanHtml from "../../../lib/tools/cleanHtml";
import Divider from '../../Layout/Wrappers/Divider';
import { Html } from '../../Layout/Wrappers/Html';
import Typography from '../../Layout/Wrappers/Typography';
import Fieldset from '../../Layout/Wrappers/Fieldset';

class SubmissionHistory extends Component {

  constructor() {
    super(...arguments);
    this.state = {
      jobEngagements: []
    }
  }

  fetchEngagements = () => {
    Engagement.getWhere(
      {
        jobId: this.props.job.id,
        rejectionReason: {
          nin: [
            REJECTION_REASON__10X10__NO_MATCH,
            'bad_match'
          ]
        }
      },
      (engagements) => this.setState({ jobEngagements: engagements }),
      {},
      { source: `candidates__match_new__job_pipe__submission_history` }
    )
  }

  componentDidMount() {
    this.fetchEngagements();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!!prevProps.job.id && prevProps.job.id !== this.props.job.id) {
      this.fetchEngagements();
    }
  }

  profiles = () => {
    let ret = {}
    let engagements = this.state.jobEngagements;

    if (!Array.isArray(engagements)) {
      return;
    }

    ret.no = () => engagements.filter((engagement) =>
      (engagement.stage === STAGE_REVIEW) &&
      /e -/i.test(engagement.status)
    );

    ret.yes = () => {
      let filtered = engagements.filter((engagement) => NOT(
        [
          STAGE_CONFIRMATION,
          STAGE_SUBMISSION,
          STAGE_REVIEW
        ].includes(engagement.stage)
      ));
      let hash = {
        Screen: 1,
        Onsite: 2,
        Offer: 3,
        Guarantee: 4,
        Hire: 5,
        End: 6
      };

      filtered.sort((a, b) => {
        return ((new Date(b.submitted)).getTime() * hash[b.stage]) - ((new Date(a.submitted)).getTime() * hash[a.stage]);
      });

      return filtered;
    }

    ret.mayBe = () => engagements.filter((engagement) =>
      (engagement.stage === STAGE_REVIEW) &&
      (engagement.status === STATUS_W_EMPLOYER_FEEDBACK)
    );

    ret.summary = () => {
      let onsite = engagements.filter(
        (engagement) => [
          STAGE_ONSITE,
          STAGE_OFFER,
          STAGE_GUARANTEE,
          STAGE_HIRE,
          STAGE_END
        ].includes(engagement.stage)
      );
      let screens = engagements.filter((engagement) =>
        (engagement.stage === STAGE_SCREEN)
      );
      let reviewed = [...ret.yes(), ...ret.no()];
      let submitted = [...reviewed, ...ret.mayBe()];
      let screenPercent, onSitesPercent = "0%";

      if (reviewed.length > 0) {
        screenPercent = `${parseInt(((screens.length + onsite.length) * 100) / reviewed.length)}%`;
        onSitesPercent = `${parseInt((onsite.length * 100) / reviewed.length)}%`;
      }

      return (
        <div className='m-1'>{submitted.length} submitted
          | {reviewed.length} reviewed
          | {screens.length} screens & after ({screenPercent})
          | {onsite.length} onSites & after ({onSitesPercent})
        </div>
      );
    }

    const printSingle = (eng) => {
      let update = eng;
      let candidate = eng.candidate;
      let isRejected = eng.state === 'Closed';
      let rejectedLabel = isRejected ? 'REJECTED' : '';
      let isAliveLabel = isRejected ? '' : '*';
      let rejectionReasonDetails = [eng.rejectionReason, eng.rejectionReasonAdditionalInfo].filter(el => !!el).join(' - ');
      return cleanHtml(
        '<div>' + isAliveLabel + String(eng.stage) + ' | ' + String(eng.status) + ' | ' +
        [
          [
            moment(update.submitted).format("MM/DD/YY"),
            `<a href="/#/candidate/edit/${candidate.id
            }" target="_blank">${candidate._name}</a>`,
            Definition.getLabel(
              "platformRating",
              candidate.platformRating
            )
          ]
            .filter(e => !!e && !!String(e).trim().length)
            .join(", "),
          Definition.getLabels(
            "positiveSignals",
            candidate.positiveSignals
          ).join(", "),
          Definition.getLabels(
            "negativeSignals",
            candidate.negativeSignals
          ).join(", "),
          candidate.tagLine
        ]
          .filter(e => !!e && !!String(e).trim().length)
          .join("; ") +
        "<strong> - " + rejectedLabel + "</strong>.</div>"
        + '<div style="font-size:11px;">' + rejectionReasonDetails + '</div>'
      );
    };

    ret.print = (array) => {
      return array.map(el => printSingle(el)).join('')
    }

    return ret;
  }

  render() {

    return (
      <Card className='mb-05'>

        <Typography strong large alignCenter blackDark
          className='m-1'
        >
          SUBMISSION HISTORY
        </Typography>
        <Divider />

        <Fieldset
          title='Summary'
        >
          {this.profiles().summary()}
        </Fieldset>

        <Fieldset
          title={
            <>YES - Client accepted profile ({this.profiles().yes().length})</>
          }
        >
          <Html
            value={this.profiles().print(this.profiles().yes())}
            className='w-unset m-1 f-md fw-400 c-black-dark'
          />
        </Fieldset>

        <Fieldset
          title={
            <>MAYBE - Profiles under Client Review ({this.profiles().mayBe().length})</>
          }
        >
          <Html
            value={this.profiles().print(this.profiles().mayBe())}
            className='w-unset m-1 f-md fw-400 c-black-dark'
          />
        </Fieldset>

        <Fieldset
          title={
            <>NO - Client rejected profile ({this.profiles().no().length})</>
          }
        >
          <Html
            value={this.profiles().print(this.profiles().no())}
            className='w-unset m-1 f-md fw-400 c-black-dark'
          />
        </Fieldset>

      </Card>
    );
  }
}

export default SubmissionHistory;