import axios from 'axios';
import {
  NOT
} from '../Boolean.lib';

const cache = {};
export async function getPdfLocalUrl({ id, url }) {
  if (NOT(cache[id])) {
    const response = await axios.get(url, {
      responseType: 'blob'
    });
    const blob = new Blob([response.data], {
      type: 'application/pdf'
    });
    const localUrl = URL.createObjectURL(blob);
    cache[id] = (
      (response.status === 200)
        ? localUrl
        : null
    );
  }
  return cache[id];
}

export function getPdfLocalUrlCache() {
  return cache;
}
