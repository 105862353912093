import {
  useState
} from "react";
import Core from '../../lib/Core';
import Employer from "../../lib/Employer";
import Job from "../../lib/Job";
import JobFeedbacks from '../Jobs/Edit/Forms/JobFeedbacks';
import Col from "../Layout/Wrappers/Col";
import Fieldset from '../Layout/Wrappers/Fieldset';
import RichTextBox from "../Layout/Wrappers/RichTextBox";
import Row from "../Layout/Wrappers/Row";

export default function InterviewDetails({
  engagement
}) {
  const {
    candidate,
    job
  } = engagement;
  const {
    employer
  } = job;
  console.debug({
    candidate,
    job,
    employer,
  });
  const [interviewProcess, setInterviewProcess] = useState(employer.interviewProcess);
  const [jobInterviewProcess, setJobInterviewProcess] = useState(job.jobInterviewProcess);
  const [qualificationNotes, setQualificationNotes] = useState(job.qualificationNotes);
  const [, reRender] = useState();
  console.debug(
    'InterviewDetails...',
    '\n', engagement,
    '\n', job,
  );
  return (
    <>

      <Row>
        <Col fullWidth className="p-0">
          <label className='m-0'>Employer Interview Process</label>
          <RichTextBox
            value={interviewProcess}
            onChange={(interviewProcess) => {
              setInterviewProcess(interviewProcess);
              clearTimeout(InterviewDetails.timeout);
              InterviewDetails.timeout = setTimeout(() => Employer.update(employer.id, { interviewProcess }), 3000);
            }}
          />
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col fullWidth className="p-0">
          <label>Job Interview Process + Interview Rejection Reason</label>
          <RichTextBox
            value={jobInterviewProcess}
            onChange={(jobInterviewProcess) => {
              setJobInterviewProcess(jobInterviewProcess);
              clearTimeout(InterviewDetails.timeout);
              InterviewDetails.timeout = setTimeout(() => Job.update(job.id, { jobInterviewProcess }), 3000);
            }}
          />
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col fullWidth className="p-0">
          <label>Job Insider Scoop + Resume Rejection Reason</label>
          <RichTextBox
            value={qualificationNotes}
            onChange={(qualificationNotes) => {
              setQualificationNotes(qualificationNotes);
              clearTimeout(InterviewDetails.timeout);
              InterviewDetails.timeout = setTimeout(() => Job.update(job.id, { qualificationNotes }), 3000);
            }}
          />
        </Col>
      </Row>

      <Fieldset
        title={<>Job feedback</>}
        wrapperProps={{ className: 'p-0' }}
        fullWidth
      >
        <JobFeedbacks
          job={job}
          onChange={async (update) => new Promise((resolve) => {
            Object.assign(engagement.job, update);
            reRender(Date.now());
            resolve();
            clearTimeout(InterviewDetails.timeout);
            InterviewDetails.timeout = setTimeout(async () => {
              await Job.update(job.id, update).catch(Core.showError);;
            }, 3000);
          })}
        />
      </Fieldset>

    </>
  );
}
