import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import moment from 'moment';
import {
  Component
} from "react";
import AppUI from '../../../dictionaries/AppUI.dic';
import {
  NOT
} from '../../../lib/Boolean.lib';
import Core from "../../../lib/Core";
import Definition from "../../../lib/Definition";
import MenuBar from '../../FilterControl/MenuBar';
import { THEME__VARIANT__FLAT } from '../../Layout/Libraries/Theme.lib';
import Autocomplete from '../../Layout/Wrappers/Autocomplete';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import Chip from '../../Layout/Wrappers/Chip';
import Col from "../../Layout/Wrappers/Col";
import DatePicker from '../../Layout/Wrappers/DatePicker';
import Dialog from '../../Layout/Wrappers/Dialog';
import Icon from '../../Layout/Wrappers/Icon';
import IconButton from '../../Layout/Wrappers/IconButton';
import Menu from '../../Layout/Wrappers/Menu';
import Row from "../../Layout/Wrappers/Row";
import Switch from '../../Layout/Wrappers/Switch';
import TextField from '../../Layout/Wrappers/TextField';
import Typography from '../../Layout/Wrappers/Typography';
import { MatchPageController } from '../MatchPage';
import CompanySize from "./CompanySize";

class NewFilterControl extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      chips: [],
      keywords: [],
      minimumSalary: this.props.minimumSalary,
      minimumExperience: this.props.minimumExperience,
      minimumCompanySize: this.props.minimumCompanySize || 0,
      sliderCandidateDuration: [0, 6],
      shouldShowBlacklisted: this.props.shouldShowBlacklisted,
      applyLooseMatch: true,
      toggleShouldShowBlacklisted: false,
      toggleApplyLooseMatch: false,
      stateFilter: [1],
    };
  }

  componentDidMount() {
    const { updateSliderValues } = this.props;
    const { applyLooseMatch } = this.state;

    if (applyLooseMatch) {
      updateSliderValues("applyLooseMatch", applyLooseMatch);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.minimumSalary !== prevProps.minimumSalary) {
      this.setState({ minimumSalary: this.props.minimumSalary });
    }
    if (this.props.minimumExperience !== prevProps.minimumExperience) {
      this.setState({ minimumExperience: this.props.minimumExperience });
    }
    if (this.props.minimumCompanySize !== prevProps.minimumCompanySize) {
      this.setState({ minimumCompanySize: this.props.minimumCompanySize });
    }
  }

  checkLocations = ({ label, checked, cb }) => {
    cb();
  };

  jiraVER20 = ({
    label,
    menuKey,
    checked,
    fields = [],
    cb,
    shouldUpdateFilteredObjects = true,
  }) => {
    const { onChangeMenusHandler } = this.props;
    !!onChangeMenusHandler &&
      onChangeMenusHandler(
        label,
        menuKey,
        checked,
        [],
        cb,
        shouldUpdateFilteredObjects
      );
  };

  onChange = (e) => {
    //console.log("onchange");
  };

  cleanSearch = (ev) => {
    //console.log("clean search");
  };

  updateChip = (update) => {
    const { updateKeywordChip } = this.props;
    !!updateKeywordChip && updateKeywordChip(update);
  };
  ///////////////////////
  closeEventSalaryPopup = () => {
    this.setState({ showSalaryPopup: false });
  };

  applyEventSalaryPopup = (salary) => {
    const { updateSliderValues } = this.props;

    this.closeEventSalaryPopup();
    this.setState({ minimumSalary: salary }, () => {
      updateSliderValues("tempMinimumSalary", salary);
    });
  };

  onChangeEventSalaryPopup = (salary) => {
    this.setState({ minimumSalary: salary });
  };
  ///////////////////////
  closeEventExperiencePopup = () => {
    this.setState({ showExperiencePopup: false });
  };

  applyEventExperiencePopup = (experience) => {
    const { updateSliderValues } = this.props;
    this.closeEventExperiencePopup();
    this.setState({ minimumExperience: experience }, () => {
      updateSliderValues("tempMinimumExperience", experience);
    });
  };

  onChangeEventExperiencePopup = (experience) => {
    this.setState({ minimumExperience: experience });
  };

  showSalaryPopup = () => {
    const { salaryPopup } = this.props;
    const { showSalaryPopup, minimumSalary } = this.state;

    if (!!showSalaryPopup && !!salaryPopup) {
      return salaryPopup(
        this.closeEventSalaryPopup,
        this.applyEventSalaryPopup,
        this.onChangeEventSalaryPopup,
        minimumSalary
      );
    }
    return null;
  };

  showExperiencePopup = () => {
    const { experiencePopup } = this.props;
    const { showExperiencePopup, minimumExperience } = this.state;

    if (!!showExperiencePopup && !!experiencePopup) {
      return experiencePopup(
        this.closeEventExperiencePopup,
        this.applyEventExperiencePopup,
        this.onChangeEventExperiencePopup,
        minimumExperience
      );
    }
    return null;
  };

  handlerMoreFiltersApply() {
    const {
      updateFilteredObjects,
      fetchMatches,
      handlerToggleShouldShowBlacklisted,
      updateSliderValues,
    } = this.props;
    const {
      toggleShouldShowBlacklisted,
      shouldShowBlacklisted,
      toggleApplyLooseMatch,
      applyLooseMatch,
      stateFilterChanged,
      stateFilter,
      stateFilterChangedCb,
      dateRange
    } = this.state;

    updateFilteredObjects();
    if (this.state.durationChanged) {
      fetchMatches({ dateRange });
    }

    if (toggleShouldShowBlacklisted) {
      handlerToggleShouldShowBlacklisted(shouldShowBlacklisted);
    }

    if (toggleApplyLooseMatch) {
      updateSliderValues("applyLooseMatch", applyLooseMatch);
    }

    if (stateFilterChanged) {
      fetchMatches({ state: stateFilter });
      stateFilterChangedCb && stateFilterChangedCb();
    }

    this.setState({
      showMoreFilter: false,
      durationChanged: false,
      toggleShouldShowBlacklisted: false,
      toggleApplyLooseMatch: false,
    });
  }

  render() {

    const {
      menus,
      more,
      chips,
      sources,
      fetchMatches
    } = this.props;

    const {
      showMoreFilter,
      shouldShowBlacklisted,
      applyLooseMatch,
      stateFilter,
      dateRange = [moment().subtract(180, 'days'), moment()]
    } = this.state;

    this.matchingPage = true;

    Core.setKeyValue('NewFilterControlController', this);

    const _setSelected = (name) => {
      if (
        !MatchPageController().state.keywords.find(
          (keyword) => (keyword.name === name)
        )
      ) {
        this.updateChip({
          name,
          keyword: true
        });
      }
    };

    return (
      <Box column w100 className='px-3 border-bottom'>

        <Box row w100 noWrap scrollY spaceBetween
          className="bg-white outlined rounded-sm"
        >

          {/** CHIPS BAR */}
          <Box row noWrap scrollX className='py-05'>
            <Icon icon='filter_list' className='f-lg mx-1' />
            <Typography acl={NOT(chips.length)} className="placeholder">
              Select Filters
            </Typography>
            {chips.map((item, index) => (
              <Chip styled small key={`new_filter_control__chip__${index}`}
                className='mr-05'
                onDelete={(event) => {
                  setTimeout(() => {
                    if (item.key === "state") {
                      const stateId = +Definition.getId(item.key, item.name);
                      const stateFilterNew = stateFilter.filter(
                        (stateIdF) => +stateIdF !== +stateId
                      );
                      this.setState({ stateFilter: stateFilterNew }, () => {
                        fetchMatches({ state: stateFilterNew });
                      });
                    }
                  });
                  this.props.deleteChip(item, index);
                }}
              >
                {item.name}
              </Chip>
            ))}
          </Box>

          <Box row noWrap scrollX style={{ width: 360 }}>

            <Autocomplete
              name="search"
              placeholder={AppUI.placeholder.filterBar}
              options={sources}
              onSelect={_setSelected}
              variant={THEME__VARIANT__FLAT}
              clearOnSelect
            />

            {/** SAVE AN CLEAN BUTTONS */}
            {!!this.state.chips.length && (
              <Box row wAuto noWrap className='p-05'>
                {Core.isAdminOrCoordinator() && (
                  <Button outlined minW120 mr1 small
                    label="Save Search"
                    onClick={this.storeState}
                  />
                )}
                <Button outlined minW120 small warning
                  label="Clear All"
                  onClick={this.cleanSearch}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box row w100 noWrap scrollX className='py-05'>

          <MenuBar FilterControlController={this} menus={menus}>
            <Menu.Item
              key={`menu1`}
              className="f-sm"
              onClick={(ev) =>
                this.setState({ showSalaryPopup: true })
              }
            >
              Salary
              <Icon icon='arrow_drop_down' />
            </Menu.Item>
            {this.showSalaryPopup()}
            <Menu.Item
              key={`menu2`}
              className="f-sm"
              onClick={(ev) =>
                this.setState({ showExperiencePopup: true })
              }
            >
              Experience
              <Icon icon='arrow_drop_down' />
            </Menu.Item>

            {this.props.matchKey === "job" && (
              <CompanySize
                minimumCompanySize={this.state.minimumCompanySize}
                updateSliderValues={this.props.updateSliderValues}
                companySizePopup={this.props.companySizePopup}
              />
            )}
            {
              /** MORE FILTERS */
              !!more.length && (
                <Menu.Item
                  className="f-sm"
                  onClick={(event) => this.setState({ showMoreFilter: true })}
                >
                  More Filters
                  <Icon icon='arrow_drop_down' />
                </Menu.Item>
              )
            }
            {this.showExperiencePopup()}
          </MenuBar>

          <Dialog
            open={showMoreFilter}
            title={"Filter by"}
            actionBar={
              <TextField required
                name="searchFilter"
                className="my-05 min-w-240 mr-2"
                floatingLabelText="Search keywords"
                value={this.state.search}
                onChange={(event, search) => this.setState({ search })}
                autoFocus
                fullWidth
              />
            }
            content={
              <Box column h100 w100 scrollY>

                {(this.props.matchKey === "job") &&
                  <Row key={`menu-duration`} className='mb-3'>
                    <Col fullWidth>
                      <label>State</label>
                      {
                        more.filter((el) => (el.key === "state")).map(
                          (menu) => {
                            return (
                              <Row key={`new_filter_control__menu__${menu.key}`}>
                                <Col fullWidth>
                                  <List key={"list"} style={{ maxHeight: "60vh" }}>
                                    {Object.keys(menu.items || {})
                                      .sort()
                                      .filter((label) =>
                                        new RegExp(this.state.search, "i").test(label)
                                      )
                                      .map((label) => (
                                        <ListItem
                                          key={`listItem${label}`}
                                          role={undefined}
                                          dense
                                          button
                                          onClick={(ev) => {
                                            menu.items[label] = !menu.items[label];
                                            const labelId = +Definition.getId(
                                              "state",
                                              label
                                            );
                                            this.setState((state) => state);

                                            let stateFilter = [
                                              ...this.state.stateFilter,
                                              labelId,
                                            ];

                                            if (!menu.items[label]) {
                                              stateFilter = stateFilter.filter(
                                                (id) => id !== labelId
                                              );
                                            }

                                            this.setState({
                                              stateFilter,
                                              stateFilterChanged: true,
                                              stateFilterChangedCb: () => {
                                                this.checkLocations({
                                                  label,
                                                  checked: menu.items[label],
                                                  cb: (then) =>
                                                    this.jiraVER20({
                                                      label,
                                                      menuKey: menu.key,
                                                      checked: menu.items[label],
                                                      cb: this.onChange(),
                                                      shouldUpdateFilteredObjects: false,
                                                    }),
                                                });
                                              },
                                            });
                                          }}
                                        >
                                          <ListItemIcon
                                            style={{ minWidth: "inherit" }}
                                          >
                                            <Checkbox
                                              edge="start"
                                              checked={Object(menu.items)[label]}
                                              tabIndex={-1}
                                              color="primary"
                                              disableRipple
                                              inputProps={{
                                                "aria-labelledby": label,
                                              }}
                                            />
                                          </ListItemIcon>
                                          <ListItemText id={label} primary={label} />
                                        </ListItem>
                                      ))}
                                  </List>
                                </Col>
                              </Row>
                            );
                          }
                        )
                      }
                    </Col>
                  </Row>
                }

                {
                  more.filter((el) => (el.key !== "state")).map((menu) => {
                    return menu.visible === false ? '' : (
                      <Row key={`new_filter_control__menu__${menu.key}`} className='mb-3'>
                        <Col fullWidth>
                          <label>{menu.label}</label>
                          <List key={"list"} style={{ maxHeight: "60vh" }}>
                            {Object.keys(menu.items || {})
                              .sort()
                              .filter((label) =>
                                new RegExp(this.state.search, "i").test(label)
                              )
                              .map((label) => (
                                <ListItem
                                  key={`listItem${label}`}
                                  role={undefined}
                                  dense
                                  button
                                  onClick={(ev) => {
                                    menu.items[label] = !menu.items[label];
                                    this.setState((state) => state);

                                    this.checkLocations({
                                      label,
                                      checked: menu.items[label],
                                      cb: (then) =>
                                        this.jiraVER20({
                                          label,
                                          menuKey: menu.key,
                                          checked: menu.items[label],
                                          cb: this.onChange(),
                                          shouldUpdateFilteredObjects: false,
                                        }),
                                    });
                                  }}
                                >
                                  <ListItemIcon style={{ minWidth: "inherit" }}>
                                    <Checkbox
                                      edge="start"
                                      checked={Object(menu.items)[label]}
                                      tabIndex={-1}
                                      color="primary"
                                      disableRipple
                                      inputProps={{ "aria-labelledby": label }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={label}
                                    primary={
                                      label === "State: Lead" ? "Lead" : label
                                    }
                                  />
                                </ListItem>
                              ))}
                          </List>
                        </Col>
                      </Row>
                    );
                  })
                }

                {(this.props.matchKey === "candidate") &&
                  <Row
                    key={`menu-duration`}
                    className='mb-3'
                  >
                    <Col fullWidth>
                      <label>Duration</label>
                      <div className='d-flex flex-align-left-center mt-1' style={{ height: 24 }}>
                        <DatePicker.RangePicker
                          popupStyle={{ borderRadius: 4, zIndex: 10000 }}
                          value={dateRange}
                          onChange={value => {
                            this.setState({
                              dateRange: value,
                              durationChanged: true
                            });
                          }}
                        />
                        {!dateRange?.length && (
                          <IconButton small icon='restart_alt'
                            onClick={event => {
                              this.setState({
                                dateRange: [
                                  moment().subtract(180, 'days'),
                                  moment()
                                ],
                                durationChanged: true
                              });
                            }}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                }

                <Row className='mb-3'>
                  <Col fullWidth>
                    <Switch
                      checked={shouldShowBlacklisted}
                      onChange={(event) => {
                        this.setState({
                          shouldShowBlacklisted: event.target.checked,
                          toggleShouldShowBlacklisted: true,
                        });
                      }}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      label='Show Blacklisted'
                    />
                  </Col>
                </Row>

                <Row>
                  <Col fullWidth>
                    <Switch
                      name="checkedA"
                      title='secondary checkbox'
                      checked={applyLooseMatch}
                      onChange={(event) => {
                        this.setState({
                          applyLooseMatch: event.target.checked,
                          toggleApplyLooseMatch: true,
                        });
                      }}
                    >
                      Loose Match Expanded Search
                    </Switch>
                  </Col>
                </Row>

              </Box>
            }
            actions={[
              <Button outlined minW120
                label="Cancel"
                onClick={() => this.setState({ showMoreFilter: false })}
              />,
              <Button primary minW120
                label="Apply"
                onClick={(ev) => {
                  this.handlerMoreFiltersApply();
                }}
              />
            ]}
          />

        </Box>
      </Box>
    );
  }
}

export default NewFilterControl;

export function NewFilterControl__state(update = {}, callback = () => null) {
  let state = Core.getKeyValue('NewFilterControlController')?.state;
  return state;
}

export function NewFilterControl__updateState(update = {}, callback = () => null) {
  let method = Core.getKeyValue('NewFilterControlController')?.setState;
  return (method instanceof Function ? method : (() => null))(update, callback);
}
