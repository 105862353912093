import {
  Component
} from "react";
import JobRequirement from "../../Jobs/Edit/Forms/MustHave/JobRequirement";
import Card from '../../Layout/Wrappers/Card';
import Divider from '../../Layout/Wrappers/Divider';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import { Html } from '../../Layout/Wrappers/Html';
import Typography from '../../Layout/Wrappers/Typography';
import WordChips from '../Components/WordChips';
import InputFieldContent from "./InputFieldContent";
import JobTags from "./JobTags";
import { Arr } from '../../../lib/Array.lib';

class Requirements extends Component {
  render() {
    let {
      job = {},
      candidate = {},
      saveJob,
      candidateResume,
      source
    } = this.props;
    return (
      <Card
        role='Requirements'
        className='my-05'
      >

        <Typography strong large alignCenter blackDark
          className='m-1'
        >
          TECHNICAL SKILLS
        </Typography>
        <Divider />

        {!!job.id && !!candidate.id &&
          <JobRequirement onlyView
            candidate={candidate}
            job={job}
            mustHave={job.mustHave}
            niceToHave={job.niceToHave}
            yearsOfExperience={job.minYearsOfExperience || null}
            candidateResume={candidateResume}
            source={source}
          />
        }

        {!!candidate.id && !!job.id && !!Arr(job.jobTags).length &&
          <Fieldset
            title='Job Tags'
          >
            <JobTags
              labels={job.jobTags.filter(tags => !!tags.value).map(tags => tags.value)}
              resume={candidate.resumeTxtUrl}
              source={source}
            />
          </Fieldset>
        }

        {!!job._technicalSkills &&
          <WordChips
            label='Job Skills'
            words={job._technicalSkills}
          />
        }

        <InputFieldContent
          label='New Technical Skills'
          contentKey="newTechSkill"
          selectedMatch={job}
          content={job.newTechSkill}
          saveJob={saveJob}
        />

        {[
          {
            key: 'candidateMust',
            label: 'Must Have'
          },
          {
            key: 'candidatePositives',
            label: 'Nice Have'
          },
          {
            key: 'candidateMustNot',
            label: 'Must Not'
          },
          {
            key: 'candidateNegatives',
            label: 'Nice Not'
          }
        ].map((el, index) => {
          return !!job[el.key] && (
            <Fieldset
              key={index}
              title={el.label}
            >
              <Html
                className='w-unset m-1 f-md fw-400 c-black-dark'
                value={job[el.key]}
              />
            </Fieldset>
          );
        })}

      </Card>
    );
  }
}

export default Requirements;