import {
  compile
} from 'handlebars';
import { Fragment, useState } from 'react';
import {
  PRESCREEN_QUESTION__ANSWER_APPLY_TO__GLOBAL_ANSWER,
  PRESCREEN_QUESTION__ANSWER_APPLY_TO__JOB_SPECIFIC_ANSWER,
  PRESCREEN_QUESTION__QUESTION_APPLY_TO__ALL_JOBS
} from '../../dictionaries/PrescreenQuestionsSectionUI.dic';
import Core from '../../lib/Core';
import {
  HTML__IN_BLANK_LINE
} from '../../lib/HTML.lib';
import {
  isEmptyString,
  isNullOrUndefined,
  trim
} from '../../lib/String.lib';
import {
  createLoopbackRecord,
  deleteLoopbackRecord,
  readLoopbackRecord,
  updateLoopbackRecord
} from '../../lib/services/BE/loopback.api';
import {
  showConfirm
} from '../Dialogs/AppConfirmationDialog';
import Button from '../Layout/Wrappers/Button';
import Divider from '../Layout/Wrappers/Divider';
import Dropdown from '../Layout/Wrappers/Dropdown';
import IconButton from '../Layout/Wrappers/IconButton';
import Message, {
  ErrorMessage,
  WarningChipMessage,
  WarningMessage
} from '../Layout/Wrappers/Message';
import StyledTableCell from '../Layout/Wrappers/StyledTabCell';
import Table from '../Layout/Wrappers/Table';
import TextField from '../Layout/Wrappers/TextField';



const CONFIG = {
  collection: 'PrescreenQuestionTemplates',
  answerApplyTo: {
    data: [
      PRESCREEN_QUESTION__ANSWER_APPLY_TO__GLOBAL_ANSWER,
      PRESCREEN_QUESTION__ANSWER_APPLY_TO__JOB_SPECIFIC_ANSWER,
    ]
  },
  columnHeaders: {
    question: 'Question',
    answerMandatory: 'Answer mandatory',
    questionApplyTo: 'Question apply to',
    answerApplyTo: 'Answer apply to',
    autoIncludeInWriteup: 'Include in writeup',
    instruction: 'Instruction',
    author: 'Author',
    actions: 'Action',
  },
  columnWidth: {
    question: 320,
    answerMandatory: 80,
    questionApplyTo: 160,
    answerApplyTo: 160,
    autoIncludeInWriteup: 100,
    instruction: 416,
    author: 200,
    actions: 80,
  },
  fieldSet: {
    add: 'Add question',
    noData: 'No prescreen questions yet. Click on the "Add" button to add a question.'
  },
  questionApplyTo: {
    data: [
      PRESCREEN_QUESTION__QUESTION_APPLY_TO__ALL_JOBS,
    ]
  },
  requiredFieldLabel: 'This field is required',
  saveConfirm: {

    // [TBD1]
    title: 'Confirm Question Bank Changes',

    // [TBD2]
    message: (`
      {{{BREAK_LINE}}}
      <div>
        {{#if TO_CREATE}}{{TO_CREATE}} questions will be added.<br/>{{/if}}
        {{#if TO_UPDATE}}{{TO_UPDATE}} questions will be updated.<br/>{{/if}}
        {{#if TO_DELETE}}{{TO_DELETE}} questions will be deleted.<br/>{{/if}}
      </div>
      <div>
        Are you sure you want to continue?
      </div>
      {{{BREAK_LINE}}}
    `),
    // [TBD3]
    onAcceptLabel: 'Confirm'

  }

};

export function ManagePrescreenQuestionsContent(props) {
  let [state, setState] = useState({});
  async function _updateState(update, delay) {
    update = Object(update) === update ? update : {};
    state = { ...state, ...update };
    delay ? setTimeout(() => setState(state), delay) : setState(state);
    return await new Promise((resolve) => setTimeout(() => resolve(state), delay));
  }
  let { data = [], toUpdate = {}, toDelete = {}, toCreate = {}, errors = {}, busy } = state;
  const _onAddRow = () => {
    let id = Date.now();
    let record = {
      id,
      question: null,
      answerMandatory: false,
      questionApplyTo: null,
      answerApplyTo: PRESCREEN_QUESTION__ANSWER_APPLY_TO__GLOBAL_ANSWER.id,
      autoIncludeInWriteup: true,
      instruction: null,
      author: Core.getUser()?.email || Core.getUserName(),
    };
    toCreate[id] = record;
    _updateState({
      data: [...data, record],
      toCreate
    });
  };
  const _onChange = (update) => {
    data = data.map((row) => {
      if (row.id === update.id) {
        Object.assign(row, update);
        if (typeof update.id === 'string') {
          toUpdate[update.id] = row;
        }
        else {
          toCreate[update.id] = row;
        }
      }
      return row;
    });
    _updateState({
      data,
      toCreate,
      toUpdate,
    });
  };
  const _onDelete = (id) => {
    if (typeof id === 'string') {
      toDelete[id] = true;
    }
    delete toCreate[id];
    delete toUpdate[id];
    _updateState({
      data: data.filter((row) => row.id !== id),
      toCreate,
      toUpdate,
      toDelete,
    });
  };
  let _toCreateCount = Object.keys(toCreate).length;
  let _toUpdateCount = Object.keys(toUpdate).length;
  let _toDeleteCount = Object.keys(toDelete).length;
  const _onSave = () => {
    showConfirm({
      title: CONFIG.saveConfirm.title,
      message: (
        <div dangerouslySetInnerHTML={{
          __html: compile(CONFIG.saveConfirm.message)({
            BREAK_LINE: HTML__IN_BLANK_LINE,
            TO_CREATE: !!_toCreateCount ? _toCreateCount : '',
            TO_UPDATE: !!_toUpdateCount ? _toUpdateCount : '',
            TO_DELETE: !!_toDeleteCount ? _toDeleteCount : ''
          })
        }} />
      ),
      async onAccept() {
        await _updateState({ errors: [], busy: true });
        for (let id in toCreate) {
          let record = { ...toCreate[id] };
          delete record.id;
          await createLoopbackRecord({ collection: CONFIG.collection, record })
            .then(async response => {
              console.debug('r', response);
              delete toCreate[id];
              delete errors[id];
              await _updateState({ toCreate, errors });
            })
            .catch(async error => {
              errors[id] = error;
              await _updateState({ errors });
            });
        }
        for (let id in toUpdate) {
          let record = { ...toUpdate[id] };
          delete record.id;
          await updateLoopbackRecord({ collection: CONFIG.collection, id, record })
            .then(async response => {
              console.debug('r', response);
              delete toUpdate[id];
              delete errors[id];
              await _updateState({ toUpdate, errors });
            })
            .catch(async error => {
              errors[id] = error;
              await _updateState({ errors });
            });
        }
        for (let id in toDelete) {
          await deleteLoopbackRecord({ collection: CONFIG.collection, id })
            .then(async response => {
              console.debug('r', response);
              delete toDelete[id];
              delete errors[id];
              await _updateState({ toDelete, errors });
            })
            .catch(async error => {
              errors[id] = error;
              await _updateState({ errors });
            });
        }
        if (!Object.keys(errors).length) {
          await _fetchData();
        }
        await _updateState({ busy: false });
      },
      onAcceptLabel: CONFIG.saveConfirm.onAcceptLabel,
    });
  };
  const _fetchData = async () => {
    await _updateState({ fetchingData: true });
    await readLoopbackRecord({
      collection: CONFIG.collection,
    })
      .then(async (data) => {
        await _updateState({ data: Array.isArray(data) ? data : [], fetchedData: true });
      })
      .catch(async (error) => {
        await _updateState({ fetchingError: error });
      });
    await _updateState({ fetchingData: false });
  };
  const _stringToBoolean = (value) => (value === 'Yes' ? true : value === 'No' ? false : null);
  const _booleanToString = (value) => (value === true ? 'Yes' : value === false ? 'No' : null);

  (async () => {
    if (!state.fetchingData && !state.fetchingError && !state.fetchedData) {
      _fetchData();
    }
  })();

  console.debug('state', state);

  return (
    <div className='flex-column h-100'>
      <div className='scroll w-100 flex-1'>
        <Table
          style={{ minWidth: '100%' }}
        >
          <Table.Head className='sticky-top' style={{ zIndex: 2 }}>
            <Table.Row>
              <StyledTableCell
                className='sticky-start'
                style={{ minWidth: CONFIG.columnWidth.question }}
              >
                {CONFIG.columnHeaders.question}
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: CONFIG.columnWidth.answerMandatory }}>
                {CONFIG.columnHeaders.answerMandatory}
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: CONFIG.columnWidth.questionApplyTo }}>
                {CONFIG.columnHeaders.questionApplyTo}
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: CONFIG.columnWidth.answerApplyTo }}>
                {CONFIG.columnHeaders.answerApplyTo}
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: CONFIG.columnWidth.autoIncludeInWriteup }}>
                {CONFIG.columnHeaders.autoIncludeInWriteup}
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: CONFIG.columnWidth.instruction }}>
                {CONFIG.columnHeaders.instruction}
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: CONFIG.columnWidth.author }}>
                {CONFIG.columnHeaders.author}
              </StyledTableCell>
              <StyledTableCell
                className='sticky-end align-right'
                style={{ minWidth: CONFIG.columnWidth.actions }}
              >
                {CONFIG.columnHeaders.actions}
              </StyledTableCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.map(
              ({
                id,
                question,
                answerMandatory,
                questionApplyTo,
                answerApplyTo,
                autoIncludeInWriteup,
                instruction,
                author,
              }) => (
                <Fragment key={`mng_pre_scr_qsn_${id}`}>
                  <Table.Row>
                    <StyledTableCell className='sticky-start' style={{ borderBottom: errors[id] ? 'none' : '1px solid #c6c7c9' }}>
                      <TextField multiline
                        value={question}
                        onChange={(event) => _onChange({ id, question: event.target.value || null })}
                        onBlur={event => _onChange({ id, question: trim(event.target.value) || null })}
                        autoSize={{ minRows: 1, maxRows: 5 }}
                      />
                      <WarningChipMessage show={isEmptyString(question)} className='mt-1'>
                        {CONFIG.requiredFieldLabel}
                      </WarningChipMessage>
                    </StyledTableCell>

                    <StyledTableCell style={{ borderBottom: errors[id] ? 'none' : '1px solid #c6c7c9' }}>
                      <Dropdown
                        name='answerMandatory'
                        data={['Yes', 'No']}
                        value={_booleanToString(answerMandatory)}
                        onChange={(value) => _onChange({ id, answerMandatory: _stringToBoolean(value) })}
                      />
                      <WarningChipMessage show={isNullOrUndefined(answerMandatory)} className='mt-1'>
                        {CONFIG.requiredFieldLabel}
                      </WarningChipMessage>
                    </StyledTableCell>

                    <StyledTableCell style={{ borderBottom: errors[id] ? 'none' : '1px solid #c6c7c9' }}>
                      <Dropdown
                        name='questionApplyTo'
                        data={CONFIG.questionApplyTo.data}
                        value={questionApplyTo}
                        onChange={(value) => _onChange({ id, questionApplyTo: value })}
                      />
                    </StyledTableCell>

                    <StyledTableCell style={{ borderBottom: errors[id] ? 'none' : '1px solid #c6c7c9' }}>
                      <Dropdown
                        name='answerApplyTo'
                        data={CONFIG.answerApplyTo.data}
                        value={answerApplyTo}
                        onChange={(value) => _onChange({ id, answerApplyTo: value })}
                      />
                      <WarningChipMessage show={isNullOrUndefined(answerApplyTo)} className='mt-1'>
                        {CONFIG.requiredFieldLabel}
                      </WarningChipMessage>
                    </StyledTableCell>

                    <StyledTableCell style={{ borderBottom: errors[id] ? 'none' : '1px solid #c6c7c9' }}>
                      <Dropdown
                        name='autoIncludeInWriteup'
                        data={['Yes', 'No']}
                        value={_booleanToString(autoIncludeInWriteup)}
                        onChange={(value) => _onChange({ id, autoIncludeInWriteup: _stringToBoolean(value) })}
                      />
                      <WarningChipMessage show={isNullOrUndefined(autoIncludeInWriteup)} className='mt-1'>
                        {CONFIG.requiredFieldLabel}
                      </WarningChipMessage>
                    </StyledTableCell>

                    <StyledTableCell style={{ borderBottom: errors[id] ? 'none' : '1px solid #c6c7c9' }}>
                      <TextField multiline
                        value={instruction}
                        onChange={(event) => _onChange({ id, instruction: event.target.value || null })}
                        onBlur={event => _onChange({ id, instruction: trim(event.target.value) || null })}
                        autoSize={{ minRows: 1, maxRows: 5 }}
                      />
                    </StyledTableCell>

                    <StyledTableCell style={{ borderBottom: errors[id] ? 'none' : '1px solid #c6c7c9' }}>
                      {author}
                    </StyledTableCell>

                    <StyledTableCell className='sticky-end align-right' style={{ borderBottom: errors[id] ? 'none' : '1px solid #c6c7c9' }}>
                      <IconButton small onClick={(event) => _onDelete(id)}>
                        <i className='material-icons'>delete</i>
                      </IconButton>
                    </StyledTableCell>

                  </Table.Row>

                  {!!errors[id] && (
                    <Table.Row>
                      <StyledTableCell colSpan={8} className='p-0'>
                        <ErrorMessage className='mt-0'>
                          {errors[id]}
                        </ErrorMessage>
                      </StyledTableCell>
                    </Table.Row>
                  )}

                </Fragment>
              )
            )}
          </Table.Body>
        </Table>
        <Message show={!!state.fetchingData}>Fetching data</Message>
        <WarningMessage show={!state.fetchingData && !state.fetchingError && !data.length}>
          {CONFIG.fieldSet.noData}
        </WarningMessage>
        <ErrorMessage show={!!state.fetchingError}>{state.fetchingError}</ErrorMessage>
      </div>
      <div className='bg-white' style={{ height: 48 }}>
        <Divider className='m-0' />
        <div className='d-flex p-1 space-between'>
          <Button outlined secondary minW120 onClick={_onAddRow}>
            {CONFIG.fieldSet.add}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={_onSave}
            className='min-w-120'
            debug={console.debug({ _toCreateCount, _toUpdateCount, _toDeleteCount })}
            disabled={!!busy || !(_toCreateCount || _toUpdateCount || _toDeleteCount)}
          >
            Save
            {/*!!_toCreateCount ? ` C:${_toCreateCount}` : ''*/}
            {/*!!_toUpdateCount ? ` U:${_toUpdateCount}` : ''*/}
            {/*!!_toDeleteCount ? ` D:${_toDeleteCount}` : ''*/}
          </Button>
        </div>
      </div>
    </div>
  );
}
