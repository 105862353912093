import Col from '../../Layout/Wrappers/Col';
import RichTextBox from '../../Layout/Wrappers/RichTextBox';

export default function RejectionEmail(props) {
  const {
    EngagementCardController,
    deliveryTo
  } = props;
  return (
    <Col className='p-0' fullWidth>
      <div className="d-flex flex-align-left-top" fullWidth>
        <label className='m-0 col1'>To:</label>&nbsp;
        <span className='c-black-medium mt-05'>{deliveryTo}</span>
      </div>
      <div className="d-flex flex-align-left-top" fullWidth>
        <label className='m-0 col1'>Subject:</label>&nbsp;
        <span className='c-black-medium mt-05'>
          {EngagementCardController.state.__rejectionEmail.profile}
        </span>
      </div>
      <RichTextBox
        className='mt-1'
        value={EngagementCardController.state.__rejectionEmail.body}
        onChange={(value) => {
          const update = {
            __rejectionEmail: {
              ...EngagementCardController.state.__rejectionEmail,
              body: value
            }
          };
          EngagementCardController.setState(update);
        }}
        taller
      />
    </Col>
  );
}
