import {
  DatePicker as AntDDatePicker
} from 'antd';
import moment from 'moment';
import {
  DATE_FORMAT__DATE__ISO,
  DATE_FORMAT__DATETIME__ISO,
  isValidDate
} from '../../../lib/Date.lib';
import TextField from './TextField';
import useState from '../../../lib/hooks/useState.hook';

export const DATE_PICKER__TYPE_DATE = 'date';
export const DATE_PICKER__TYPE_DATETIME = 'datetime-local';
export const DATE_PICKER__FORMATS = {
  [DATE_PICKER__TYPE_DATE]: DATE_FORMAT__DATE__ISO,
  [DATE_PICKER__TYPE_DATETIME]: DATE_FORMAT__DATETIME__ISO,
};
export const DATE_PICKER__COMPONENT__MUI = 'mui';
export const DATE_PICKER__COMPONENT__ANTD = 'antd';

export default function DatePicker({
  name = `date_picker__${Date.now()}`,
  value: propsValue,
  type = DATE_PICKER__TYPE_DATE,
  fullWidth = true,
  onChange,
  component = DATE_PICKER__COMPONENT__ANTD,
  ...props
}) {
  const [{ value, prev }, _updateState] = useState({ value: propsValue, prev: propsValue });
  setTimeout(async () => {
    if (prev !== propsValue) {
      await _updateState({
        prev: propsValue,
        value: propsValue
      });
    }
  });
  const isMui = (component === DATE_PICKER__COMPONENT__MUI);
  const _onChange = async (event, ...more) => {
    const value = isMui ? event.target.value : event;
    await _updateState({ value });
    if (isValidDate(value)) {
      const _value = moment(value);
      isMui ? onChange(event, _value) : onChange(_value, ...more);
    }
  }
  const _props = {
    name,
    value: (
      isMui
        ? moment(value).format(DATE_PICKER__FORMATS[type])
        : value ? moment(value, DATE_FORMAT__DATE__ISO) : null
    ),
    fullWidth,
    onChange: _onChange,
    ...props
  }
  return (
    isMui
      ? <TextField type={type} {..._props} />
      : <AntDDatePicker {..._props} style={{ width: fullWidth ? '100%' : 'auto', ..._props.style }} />
  );
}

DatePicker.RangePicker = AntDDatePicker.RangePicker;
