import moment from 'moment';
import {
  getCaller
} from './Debug.lib';
import {
  NOT
} from './GenericTools.lib';
import {
  Obj
} from './Object.lib';
import {
  Str,
  compileText
} from './String.lib';

export const DATE_TYPE__DATE = 'date';
export const DATE_TYPE__DATE_TIME = 'datetime-local';

export const DATE_FORMAT__DATE__US_SHORT = 'MM/DD';
export const DATE_FORMAT__DATE__US_LONG = 'YYYY/MM/DD';
export const DATE_FORMAT__DATETIME__US_SHORT = 'MM/DD, HH:mm';
export const DATE_FORMAT__DATETIME__US_LONG = 'YYYY/MM/DD, HH:mm';
export const DATE_FORMAT__DATETIME__US_12H = 'YYYY/MM/DD, hh:mm a';
export const DATE_FORMAT__DATETIME__US_24H = 'YYYY/MM/DD, HH:mm';
export const DATE_FORMAT__DATETIME__US_12H_SECS = 'YYYY/MM/DD, hh:mm:ss a';
export const DATE_FORMAT__DATETIME__US_24H_SECS = 'YYYY/MM/DD, HH:mm:ss';
export const DATE_FORMAT__DATE__ISO = 'YYYY-MM-DD';
export const DATE_FORMAT__TIME__ISO = 'HH:mm:ssZ';
export const DATE_FORMAT__DATETIME__ISO = 'YYYY-MM-DDTHH:mm';

// DATE_FORMAT aliases
export const TIME_FORMAT = DATE_FORMAT__DATETIME__ISO;
export const US_DATE_FORMAT = DATE_FORMAT__DATE__US_LONG;
export const US_TIME_FORMAT = DATE_FORMAT__DATETIME__US_LONG;

export const FULLDAY_SECOND_REFERENCE = 1;
export const FULLDAY_MILL_SECOND_REFERENCE = 300;

export const TIMEZONE__LA = 'America/Los_Angeles';

export function isValidDate(value) {
  const hasValue = !!value;
  if (hasValue && moment(value).isValid()) {
    return true;
  }
  else {
    if (hasValue) { console.warn('isValidDate', `${value}`, getCaller()); }
    return false;
  }
}

export function getPacificTimeIsoString(date) {
  return moment(date).tz(TIMEZONE__LA).toISOString();
}

export function getPacificTimeUSDateFormat(value) {
  return moment(value).tz(TIMEZONE__LA).format(US_DATE_FORMAT);
}

export function getPacificTimeUSTimeFormat(value) {
  return moment(value).tz(TIMEZONE__LA).format(US_TIME_FORMAT);
}

export function formatDateTime12hPT(value, options) {
  const {
    seconds = false,
    postFix = true
  } = Obj(options);
  return compileText('{{DATE}}{{POST_FIX}}', {
    DATE: moment(value).tz(TIMEZONE__LA).format(seconds
      ? DATE_FORMAT__DATETIME__US_12H_SECS
      : DATE_FORMAT__DATETIME__US_12H
    ),
    POST_FIX: postFix && ' (Pacific Time)'
  });
}

export function formatDateTime24hPT(value, options) {
  const {
    seconds = false,
    postFix = true
  } = Obj(options);
  return compileText('{{DATE}}{{POST_FIX}}', {
    DATE: moment(value).tz(TIMEZONE__LA).format(seconds
      ? DATE_FORMAT__DATETIME__US_24H_SECS
      : DATE_FORMAT__DATETIME__US_24H
    ),
    POST_FIX: postFix ? ' (Pacific Time)' : ''
  });
}

export function formatDateTime24h(value, options) {
  const {
    pacificTime = true,
    postFix = true,
    seconds = false
  } = Obj(options);
  return compileText('{{DATE}}{{POST_FIX}}', {
    DATE: (
      pacificTime
        ? moment(value).tz(TIMEZONE__LA)
        : moment(value)
    ).format(seconds
      ? DATE_FORMAT__DATETIME__US_24H_SECS
      : DATE_FORMAT__DATETIME__US_24H
    ),
    POST_FIX: (pacificTime && postFix) ? ' (Pacific Time)' : ''
  });
}

/**
 * 
 * @param {any} value  A valid moment date.
 * @param {string} prefix  [plaintext] ex. ' '; // a space character.
 * @returns {string}  A text displaying the difference in days between now and the value. "{{PREFIX}}{{DAYS}}d ago"
 * 
 * @issue
 * We found that sometimes there could be a time gap that produces a display of the wrong amount.
 * @hack
 * This function matches the server behavior that is cutting the time part for diff calculation.
 * @see https://github.com/10By10/10by10-web-app/blob/9922c6bc7dc95e09878e8318a74e68c5eb53f8a7/common/Helpers/emails/employerTodoReminder.helper.js#L109
 */
export function getAgoText(value, prefix) {
  if (NOT(isValidDate(value))) { return ''; }
  const _now = moment().format(DATE_FORMAT__DATE__ISO);
  const _date = moment(value).format(DATE_FORMAT__DATE__ISO);
  return compileText('{{PREFIX}}{{DAYS}}d ago', {
    DAYS: moment(_now).diff(_date, 'days'),
    PREFIX: Str(prefix)
  });
}

export function getFulldayDateISOString(date = Date.now()) {
  date = moment.tz(date, TIMEZONE__LA).toDate();
  date.setSeconds(
    FULLDAY_SECOND_REFERENCE,
    FULLDAY_MILL_SECOND_REFERENCE
  );
  return moment(date).toISOString();
}

export function getISOString() {
  return moment().toISOString();
}

export function getLocalTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function evalDiffTimeZoneToPT() {
  return (TIMEZONE__LA !== getLocalTimeZone());
}
