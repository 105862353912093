import {
  joinClassName
} from '../Libraries/Theme.lib';

export function Html({
  children = '',
  value = children,
  className = '',
  ...props
}) {
  return (
    <div
      className={joinClassName(['w-100', className])}
      dangerouslySetInnerHTML={{ __html: value }}
      {...props}
    />
  );
}
