import { YES } from '../../lib/GenericTools.lib';
import { REACT_TABLE__COLUMN_MD, REACT_TABLE__COLUMN_XXS } from '../Home/useEnhancedReactTable.hook';

export function TaggedValue({ tag, value }) {
  return YES(value) && (
    <div className='d-flex flex-align-left-center'>
      <span className='truncate mr-1' style={{ minWidth: REACT_TABLE__COLUMN_XXS, maxWidth: REACT_TABLE__COLUMN_MD }}>
        {tag}:
      </span>
      {value}
    </div>
  )
}
