import React, {
  Component
} from "react";
import Definition from "../../lib/Definition";
import Menu from '../Layout/Wrappers/Menu';

class ResumeMatch extends Component {

  allTechnicalSkills = Object.values(Definition.getAllLabels("technicalSkills")).join(',');

  processSkills = (skills, text, attributes = {}, specialCharacters = []) => {
    let regex = "";
    const specialSkills = ["c++", "C++", "c#", "C#", ".net", ".Net"];
    if (skills.length > 0 && text) {
      skills.filter(fval => !!fval.length).forEach(val => {
        if (specialSkills.includes(val)) {
          text = text.replace(val, "<span style='background-color: " + attributes.color + ";'>" + val + "</span>");
        } else if (val.length === 1) {
          val = this.escapeRegExp(val);
          regex = new RegExp(`^${val}(?!\\+#)$`, 'gi');
          text = text.replace(regex, "<span style='background-color: " + attributes.color + ";'>" + val + "</span>");
        } else {
          val = this.escapeRegExp(val);
          regex = new RegExp(`\\b${val}\\b|^${val}\\b|^${val},\\w`, 'gi');
          text = text.replace(regex, "<span style='background-color: " + attributes.color + ";'>" + val + "</span>");
        }
      })
    }

    return text;
  }

  escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  addToNewWindow = (resumeMatchedText) => {
    const oNewWin = window.open("about:blank", "newwindow", "height=550,width=900,top=10,left=10,resizable=yes");

    if (!oNewWin) {
      alert('browser blocking popup, please allow and then retry, thanks');
      return;
    }

    oNewWin.open();
    oNewWin.document.write("<html><head><title>New Window</title></head>");

    oNewWin.document.write("<body>" + resumeMatchedText.replace(/\n/g, "<br />") + "</body></html>");
    oNewWin.document.close();
  }


  matchResume = () => {
    const { showOnDrawer, renderTextCV } = this.props;

    const { candidateResume, jobSkills, employerSkills } = this.props;

    const jobSkillsValue = typeof jobSkills === "string"
      ? jobSkills
        .split(',')
        .map(el => el.trim())
      : [];

    const employerSkillsValue = typeof employerSkills === "string"
      ? employerSkills
        .split(',')
        .map(el => el.trim())
        .filter(v => !jobSkillsValue.includes(v))
      : [];

    const allTechnicalSkills = typeof this.allTechnicalSkills === "string"
      ? this.allTechnicalSkills
        .split(',')
        .map(el => el.trim())
        .filter(v => ![...jobSkillsValue, ...employerSkillsValue].includes(v))
      : [];

    const specialCharacters = ['+', '#'];

    const green = '#008896aa';
    const yellow = '#ffaa00aa';
    const red = '#ff000077';

    fetch(candidateResume).then(responseText => {
      responseText.text().then(text => {
        text = this.processSkills(allTechnicalSkills, text, { color: green }, specialCharacters);
        text = this.processSkills(employerSkillsValue, text, { color: yellow }, specialCharacters);
        text = this.processSkills(jobSkillsValue, text, { color: red }, specialCharacters);
        if (this.props.onClick instanceof Function) {
          text = text.replace(/\n/g, "<br />");
          this.props.onClick(text);
        }
        else if (!showOnDrawer) {
          this.addToNewWindow(text);
        } else {
          text = text.replace(/\n/g, "<br />");
          renderTextCV(text);
          this.fetched = true;
          this.fetching = false;
        }
      });
    });
  }

  render() {
    const { element, showOnDrawer, onClick = (method = () => { }) => event => method(event) } = this.props;
    if (showOnDrawer && !this.fetching && !this.fetched) {
      this.fetching = true;
      this.matchResume();
    }
    return (
      !!element ? element(this.matchResume) : (
        <Menu.Item onClick={onClick(this.matchResume)} divider>
          View Text Resume
        </Menu.Item>
      )
    );
  }
}

export default ResumeMatch;