import {
  Route,
  Routes
} from "react-router-dom";
import AccountEdit from "../components/Accounts/Edit/AccountEdit";
import {
  ManageAccountSubrolesPage
} from '../components/Accounts/Edit/ManageAccountSubroles';
import {
  AdminATSMappingPage
} from '../components/Admin/ATSMapping/AdminATSMappingPage';
import {
  AdminManageTemplatesPage
} from '../components/Admin/TemplatesEditor/Page';
import Announcements from "../components/Announcements/Announcements";
import AnnouncementCreate from "../components/Announcements/AnnouncementsCreate";
import CandidateEdit from "../components/Candidates/Edit/CandidateEdit";
import CandidateResumeSubmission from "../components/Candidates/ResumeSubmission/CandidateResumeSubmission";
import EmployerEdit from "../components/Employers/Edit/EmployerEdit";
import {
  EmployerManageTemplatesPage
} from '../components/Employers/EmployerManageTemplates/EmployerManageTemplatesPage';
import EmployerPendings from '../components/Employers/EmployerPendings/EmployerPendings';
import EmployerSettings from '../components/Employers/EmployerSettings';
import Main from "../components/Home/Main";
import JobEdit from "../components/Jobs/Edit/JobEdit";
import ManageJobsPage from "../components/Jobs/ManageJobsPage";
import Navigate from '../components/Layout/Wrappers/Navigate';
import Login from '../components/Login/Login';
import ResetPassword from '../components/Login/ResetPassword';
import MatchPage from "../components/Match/MatchPage";
import {
  ManagePrescreenQuestionsPage
} from '../components/PrescreenQuestions/ManagePrescreenQuestionsPage';
import Core from '../lib/Core';
import {
  ACCOUNT_ACTION__ACCESS_ADMIN_TOOLS,
  ACCOUNT_ACTION__CREATE_ANNOUNCEMENTS,
  ACCOUNT_ACTION__EDIT_ACCOUNT,
  ACCOUNT_ACTION__EDIT_CANDIDATE,
  ACCOUNT_ACTION__EDIT_EMPLOYER,
  ACCOUNT_ACTION__EDIT_JOB,
  ACCOUNT_ACTION__EMPLOYER_PENDINGS,
  ACCOUNT_ACTION__LIST_ACCOUNTS,
  ACCOUNT_ACTION__LIST_CANDIDATES,
  ACCOUNT_ACTION__LIST_EMPLOYERS,
  ACCOUNT_ACTION__LIST_ENGAGEMENTS,
  ACCOUNT_ACTION__LIST_JOBS,
  ACCOUNT_ACTION__MANAGE_CONFIGURATIONS,
  ACCOUNT_ACTION__MANAGE_SUBROLES,
  ACCOUNT_ACTION__MANAGE_TAGS,
  ACCOUNT_ACTION__MATCH_CANDIDATE,
  ACCOUNT_ACTION__MATCH_JOB,
  ACCOUNT_ACTION__RESUME_SUBMISSION,
  ACCOUNT_ACTION__VIEW_ANNOUNCEMENTS,
  ACCOUNT_ACTION__VIEW_ENGAGEMENT,
  ACCOUNT_ACTION__VIEW_REPORTS
} from '../lib/Definition';
import NoMatch from "./NoMatch";

const AdminUser = (props) => {
  const adminRoutes = [
    { path: '/', element: <Navigate replace to="/jobs" /> }
  ];
  if (Core.isAdmin({ action: ACCOUNT_ACTION__LIST_CANDIDATES })) {
    adminRoutes.push(...[
      { path: '/candidates', element: <Main /> },
      { path: '/v3/candidates', element: <Main /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS })) {
    adminRoutes.push(...[
      { path: '/jobs', element: <Main /> },
      { path: '/v3/jobs', element: <Main /> },
      { path: '/pro/jobs', element: <Main /> },
      { path: '/job/view/:id', element: <Main /> },
      { path: '/v3/job/view/:id', element: <Main /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__LIST_EMPLOYERS })) {
    adminRoutes.push(...[
      { path: '/employers', element: <Main /> },
      { path: '/v3/employers', element: <Main /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ENGAGEMENTS })) {
    adminRoutes.push(...[
      { path: '/engagements', element: <Main /> },
      { path: '/v3/engagements', element: <Main /> },
      { path: '/pro/engagements', element: <Main /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ACCOUNTS })) {
    adminRoutes.push(...[
      { path: '/accounts', element: <Main /> },
      { path: '/v3/accounts', element: <Main /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__VIEW_REPORTS })) {
    adminRoutes.push(...[
      { path: '/reports', element: <Main /> },
      { path: '/v3/reports', element: <Main /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__VIEW_ANNOUNCEMENTS })) {
    adminRoutes.push(...[
      { path: '/announcements', element: <Announcements /> },
      { path: '/v3/announcements', element: <Announcements /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_ACCOUNT })) {
    adminRoutes.push(...[
      { path: '/account/create', element: <AccountEdit /> },
      { path: '/account/edit/:id', element: <AccountEdit /> },
      { path: '/account/view/:id', element: <AccountEdit /> },
      { path: '/v3/account/create', element: <AccountEdit /> },
      { path: '/v3/account/edit/:id', element: <AccountEdit /> },
      { path: '/v3/account/view/:id', element: <AccountEdit /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER })) {
    adminRoutes.push(...[
      { path: '/employer/create', element: <EmployerEdit /> },
      { path: '/employer/edit/:id', element: <EmployerEdit /> },
      { path: '/employer/view/:id', element: <EmployerEdit /> },
      { path: '/v3/employer/create', element: <EmployerEdit /> },
      { path: '/v3/employer/edit/:id', element: <EmployerEdit /> },
      { path: '/v3/employer/view/:id', element: <EmployerEdit /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB })) {
    adminRoutes.push(...[
      { path: '/job/create', element: <JobEdit /> },
      { path: '/job/edit/:id', element: <JobEdit /> },
      { path: '/v3/job/create', element: <JobEdit /> },
      { path: '/v3/job/edit/:id', element: <JobEdit /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE })) {
    adminRoutes.push(...[
      { path: '/candidate/create', element: <CandidateEdit /> },
      { path: '/candidate/edit-section/:id', element: <CandidateEdit /> },
      { path: '/candidate/edit/:id', element: <CandidateEdit /> },
      { path: '/candidate/view/:id', element: <CandidateEdit /> },
      { path: '/v3/candidate/create', element: <CandidateEdit /> },
      { path: '/v3/candidate/edit-section/:id', element: <CandidateEdit /> },
      { path: '/v3/candidate/edit/:id', element: <CandidateEdit /> },
      { path: '/v3/candidate/view/:id', element: <CandidateEdit /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__MATCH_JOB })) {
    adminRoutes.push(...[
      { path: '/job/match/:id', element: <MatchPage /> },
      { path: '/job/matchNew/:id', element: <MatchPage /> },
      { path: '/v3/job/match/:id', element: <MatchPage /> },
      { path: '/v3/job/matchNew/:id', element: <MatchPage /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__MATCH_CANDIDATE })) {
    adminRoutes.push(...[
      { path: '/candidate/match/:id', element: <MatchPage /> },
      { path: '/candidate/matchNew/:id', element: <MatchPage /> },
      { path: '/v3/candidate/match/:id', element: <MatchPage /> },
      { path: '/v3/candidate/matchNew/:id', element: <MatchPage /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__VIEW_ENGAGEMENT })) {
    adminRoutes.push(...[
      { path: '/engagement/view/:id', element: <Main /> },
      { path: '/v3/engagement/view/:id', element: <Main /> },
      { path: '/engagement/represent/:id', element: <Main /> },
      { path: '/v3/engagement/represent/:id', element: <Main /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__MANAGE_TAGS })) {
    adminRoutes.push(...[
      { path: '/management/tags', element: <Main /> },
      { path: '/v3/management/tags', element: <Main /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__MANAGE_CONFIGURATIONS })) {
    adminRoutes.push(...[
      { path: '/configurations', element: <Main /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__CREATE_ANNOUNCEMENTS })) {
    adminRoutes.push(...[
      { path: '/create/announcements', element: <AnnouncementCreate /> },
      { path: '/v3/create/announcements', element: <AnnouncementCreate /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__ACCESS_ADMIN_TOOLS })) {
    adminRoutes.push(...[
      { path: '/admin/tools', element: <Main /> },
      { path: '/v3/admin/tools', element: <Main /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__EMPLOYER_PENDINGS })) {
    adminRoutes.push(...[
      { path: '/employer/pendings', element: <EmployerPendings /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__RESUME_SUBMISSION })) {
    adminRoutes.push(...[
      { path: '/candidate/resume-submission/:id/:jobId', element: <CandidateResumeSubmission /> },
      { path: '/v3/candidate/resume-submission/:id/:jobId', element: <CandidateResumeSubmission /> },
    ]);
  }
  if (Core.isAdmin({ action: ACCOUNT_ACTION__MANAGE_SUBROLES })) {
    adminRoutes.push(...[
      { path: '/manage/account-subroles/', element: <ManageAccountSubrolesPage /> },
    ]);
  }
  adminRoutes.push(...[
    { path: '/login', element: <Login /> },
    { path: '/logout', element: <Login /> },
    { path: '/reset/password/:token', element: <ResetPassword /> },
    { path: '/home', element: <Main /> },
    { path: '/admin/manage/templates/', element: <AdminManageTemplatesPage /> },
    { path: '/v3/home', element: <Main /> },
    { path: '/settings', element: <Main /> },
    { path: '/v3/settings', element: <Main /> },
    { path: '/employer/settings/:employerId', element: <EmployerSettings /> },
    { path: '/employer/manage/templates/:employerId', element: <EmployerManageTemplatesPage /> },
    { path: '/job/management', element: <ManageJobsPage /> },
    { path: '/v3/job/management', element: <ManageJobsPage /> },
    { path: '/admin/ats/mapping/:id', element: <AdminATSMappingPage /> },
    { path: '/manage/prescreen-questions/', element: <ManagePrescreenQuestionsPage /> },
    { path: '/matcher', element: <Main /> },
    { path: '/v3/matcher', element: <Main /> },
    { path: '/events', element: <Main /> },
    { path: '/v3/events', element: <Main /> },
    { path: '/calendar', element: <Main /> },
    { path: '/v3/calendar', element: <Main /> },
    { path: '*', element: <NoMatch /> }
  ]);
  return (
    <Routes>
      {adminRoutes.filter(v => !!v).map(props => (
        <Route {...props} key={`admin_route__${props.path}`} />
      ))}
    </Routes>
  );
}
export default AdminUser;
