import {
  Component
} from "react";
import Definition from "../../../../../../lib/Definition";
import {
  getColorsOnBorder
} from "../../../../../../lib/tools/getColorsOnBorders";
import {
  THEME__COLOR__DEFAULT,
  THEME__COLOR__SECONDARY
} from '../../../../../Layout/Libraries/Theme.lib';
import Chip from '../../../../../Layout/Wrappers/Chip';
import ComparisonHaves from "../../../../../Match/Candidate/ComparisonHaves";
import BasicChip from "../../../../../Match/Haves/v1/Chip";
import ChipGroup from "../../../../../Match/Haves/v1/ChipGroup";
import ChipTag from "../../../../../Match/Haves/v1/ChipTag";

class ChipElement extends Component {

  constructor() {
    super(...arguments);
    this.state = {
      element: this.props.element
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.element !== this.props.element) {
      this.setState({
        element: this.props.element
      })
    }
  }

  handlerValue = (ev) => {
    const { element } = this.state;
    element.specialInstruction = ev.target.value;
    this.setState({ element }, () => {
      const { onApply } = this.props;
      !!onApply && onApply(element);
    });
  }

  handlerCategory = (category) => {

    let { element } = this.state;
    element.category = category;
    this.setState({ element }, () => {
      const { onApply } = this.props;
      !!onApply && onApply(element);
    });
  }

  render() {
    let { element } = this.state;
    let { onlyView, toMatchWith, isMatchedComplex, source, candidateResume, pageSource } = this.props;
    let defRaw = !!Definition.getRawDef(element.key) ? Definition.getRawDef(element.key) : [];

    let groupEntry = Array.isArray(defRaw.groups) ? defRaw.groups.find(group => group.id === element.value) : {};
    let label = element.type === 'chipGroup' ? groupEntry.name : Definition.getLabel(element.key, element.value);
    label = element.type === 'chipTag' ? element.value : label;
    let isMatched = false;
    if (!source && !!toMatchWith) {
      if (!!element.type && element.type === "chipGroup") {
        isMatched = ChipGroup.result(element, toMatchWith);
      } else if (!!element.type && element.type === "chipTag") {
        isMatched = ChipTag.result(element, candidateResume);
      } else {
        isMatched = BasicChip.isChipMatched(element, toMatchWith);
      }
    }

    return (
      <>
        {source !== 'complex' && !!onlyView && !!toMatchWith && (
          <ComparisonHaves
            element={element}
            toMatchWith={toMatchWith}
            candidateResume={candidateResume}
          />
        )}
        {!!label &&
          <Chip outlined
            role='ChipElement'
            color={!!onlyView
              ? THEME__COLOR__DEFAULT
              : THEME__COLOR__SECONDARY
            }
            style={!!onlyView
              ? {
                margin: '2px 0px',
                display: "inline-block",
                border: getColorsOnBorder(
                  pageSource,
                  toMatchWith,
                  isMatchedComplex,
                  isMatched
                )
              }
              :
              {
                margin: '2px 0px',
                display: "inline-block",

              }
            }
          >
            {
              element.preferred
                ? <strong >*{label}</strong>
                : (element.type === 'chipGroup')
                  ? <strong >{label}*</strong>
                  : label
            }
          </Chip>
        }
        &nbsp;
        {!!onlyView && (
          <span>{element.specialInstruction}</span>
        )}
        &nbsp;
        {!onlyView && !!element.specialInstruction && (
          <input
            class="fc-value-editor"
            value={element.specialInstruction}
            onChange={(ev) => this.handlerValue(ev)}
          />
        )}
        &nbsp;
      </>
    );
  }
}

export default ChipElement;
