import {
  useTranslation
} from "react-i18next";
import AppUI from '../../../../dictionaries/AppUI.dic';
import {
  MATERIAL_ICON_NAME__EYE
} from '../../../../lib/constants/MaterialIconName.dic';
import Core from "../../../../lib/Core";
import Definition, {
  IN_OFFICE_REMOTE__FULL_WEEK_ID,
  IN_OFFICE_REMOTE__PART_WEEK_ID,
  IN_OFFICE_REMOTE__REMOTE_ONLY_ID,
  LOCATION__AMERICAS,
  LOCATION__CALIFORNIA,
  LOCATION__UNITED_STATES,
  LOC_TYPE__ALIAS_ID,
  LOC_TYPE__CITY_ID,
  LOC_TYPE__COUNTRY_ID,
  LOC_TYPE__METRO_AREA_ID,
  LOC_TYPE__METRO_AREA_SEC_ID,
  LOC_TYPE__MULTI_CONT_ID,
  LOC_TYPE__STATE_ID,
  RELOCATION__LABELS,
  RELOCATION__NO_ID,
  RELOCATION__UNKNOWN_ID,
  RELOCATION__YES_ID
} from "../../../../lib/Definition";
import FilterControlLib from '../../../../lib/FilterControl';
import {
  NOT,
  YES
} from "../../../../lib/GenericTools.lib";
import Job from '../../../../lib/Job';
import {
  joinKeyName
} from '../../../../lib/String.lib';
import {
  joinClassName
} from '../../../Layout/Libraries/Theme.lib';
import Box, {
  RefBox
} from '../../../Layout/Wrappers/Box';
import Checkbox from '../../../Layout/Wrappers/Checkbox';
import Fieldset from '../../../Layout/Wrappers/Fieldset';
import Menu from '../../../Layout/Wrappers/Menu';
import RadioGroup from '../../../Layout/Wrappers/RadioGroup';
import TextField from '../../../Layout/Wrappers/TextField';
import Typography from '../../../Layout/Wrappers/Typography';
import {
  JOB_SECTION__SUMMARY,
  openJobView
} from '../../Card/JobDetails';

/**
 * 
 * @param {React.Component} Controller  [JobEditController]
 * @returns 
 */
function LocationSection({
  Controller = {},
  className = '',
  style = {},
  ...props
}) {
  const {
    desiredEmploymentTypes = [],
    inOfficeRemoteFlags = [],
    officeLocations = [],
    candidateLocations = [],
    employer = {},
    locationDetails = '',
    relocation = null,
    relocationExtraInfo = '',
  } = Controller.state;
  const { t } = useTranslation();
  const {
    candidateLocations: employerCandidateLocations = []
  } = employer;
  const inOfficeRemoteTags = Definition.get("inOfficeRemote");
  const locationTags = Definition.get("location");
  const desiredEmploymentTypeTags = Definition.get("desiredEmploymentType");
  const candidateLocationTags = [...locationTags];
  if (!!employerCandidateLocations.length) {
    candidateLocationTags.unshift({
      "id": -1,
      "label": "Employer Hiring Locations",
      "type": 1,
      "aliasLocationIds": [...employerCandidateLocations]
    });
  }

  const isRemoteWork = (
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
  );

  const isInOfficeWork = (
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID) ||
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID)
  );

  /**
   * @todo
   *
   * Review to cleanup
   *
   * epic-3338(new locations)-story-3385 | 2021-07-16 Fri µ
   *
   * covering possible rollback
   *
   */
  function _patch3385(state) {
    /** * /
    {

      remote: 0,              // old field | remote(TagId)
      inOfficeRemoteFlags: [],  // new field | inOfficeRemote(TagId)

      locations: [],          // old field | location(TagId)
      officeLocations: [],    // new field | location(TagId)

    }
    {
      "key": "remote",
      "label": "Remote",
      "values": [
        { "id": 1, "label": "Case by Case" },
        { "id": 2, "label": "No option" },
        { "id": 3, "label": "Policy unknown" },
        { "id": 4, "label": "Full week possible" },
        { "id": 5, "label": "Part week possible" }
      ]
    }
    {
      "label": "Remote preference",
      "key": "inOfficeRemote",
      "values": [
        { "id": 1, "label": "In office full week" },
        { "id": 2, "label": "In office part week" },
        { "id": 3, "label": "100% remote" }
      ]
    }
    {
      "key": "location",
      "values": [
        ...,
        {
          "id": 11,
          "label": "Remote",
          "archived": true,
          "parentId": null
        },
        ...
      ]
    }
    /** */
    if (!!state.desiredEmploymentTypes) {
      const map = {
        1: 6,
        2: 2
      };
      state.jobType = map[state.desiredEmploymentTypes.sort()[0]];
    }
    else if (!!state.inOfficeRemoteFlags) {
      state.remote = (
        state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID)
          ? 2
          : state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
            ? 5
            : state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
              ? 4
              : 2
      );
      state.locations = Controller.state.locations || [];
      state.locations = state.locations.filter(n => n !== 11);
      if (state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)) {
        state.locations.push(11);
      }
    }
    else if (!!state.officeLocations) {
      const SEPARATOR_SECTION = '<div>___________';
      const SEPARATOR_LINE = '<br/>';
      state.privateNotes = Controller.state.privateNotes || '';
      state.privateNotes = state.privateNotes.split(SEPARATOR_SECTION)[0] + SEPARATOR_SECTION;
      state.locations = state.officeLocations.filter(officeLocationId => {
        if (1 <= officeLocationId && officeLocationId <= 10) {
          return true;
        }
        else if (12 <= officeLocationId) {
          const officeLocationLabel = FilterControlLib.getItemValue({
            menu: Job.getMenu({ key: 'officeLocations' }),
            itemLabel: Definition.getLabel('location', officeLocationId)
          });
          if (!state.privateNotes?.includes(officeLocationLabel)) {
            state.privateNotes = [
              state.privateNotes,
              `${SEPARATOR_LINE}${officeLocationLabel}`
            ].filter(n => !!n.trim()).join('');
          }
        }
        return false;
      });

      if (Controller.state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)) {
        state.locations.push(11);
      }

      state.candidateLocations = Controller.state.candidateLocations || [];
      state.candidateLocations.forEach(candidateLocationId => {
        const candidateLocationLabel = FilterControlLib.getItemValue({
          menu: Job.getMenu({ key: 'candidateLocations' }),
          itemLabel: Definition.getLabel('location', candidateLocationId)
        });
        if (!state.privateNotes?.includes(candidateLocationLabel)) {
          state.privateNotes = [
            state.privateNotes,
            `${SEPARATOR_LINE}${candidateLocationLabel}`
          ].filter(n => !!n.trim()).join('');
        }
      });
      if (state.privateNotes.includes(SEPARATOR_SECTION)) {
        state.privateNotes += '</div>';
      }
    }
    else if (!!state.candidateLocations) {
      const SEPARATOR_SECTION = '<div>___________';
      const SEPARATOR_LINE = '<br/>';
      state.privateNotes = Controller.state.privateNotes || '';
      state.privateNotes = state.privateNotes.split(SEPARATOR_SECTION)[0] + SEPARATOR_SECTION;

      state.officeLocations = Controller.state.officeLocations || [];
      state.officeLocations.forEach(officeLocationId => {
        if (12 <= officeLocationId) {
          const officeLocationLabel = FilterControlLib.getItemValue({
            menu: Job.getMenu({ key: 'officeLocations' }),
            itemLabel: Definition.getLabel('location', officeLocationId)
          });
          if (!state.privateNotes?.includes(officeLocationLabel)) {
            state.privateNotes = [
              state.privateNotes,
              `${SEPARATOR_LINE}${officeLocationLabel}`
            ].filter(n => !!n.trim()).join('');
          }
        }
      });

      state.candidateLocations.forEach(candidateLocationId => {
        const candidateLocationLabel = FilterControlLib.getItemValue({
          menu: Job.getMenu({ key: 'candidateLocations' }),
          itemLabel: Definition.getLabel('location', candidateLocationId)
        });
        if (!state.privateNotes?.includes(candidateLocationLabel)) {
          state.privateNotes = [
            state.privateNotes,
            `${SEPARATOR_LINE}${candidateLocationLabel}`
          ].filter(n => !!n.trim()).join('');
        }
      });

      if (state.privateNotes.includes(SEPARATOR_SECTION)) {
        state.privateNotes += '</p>';
      }

    }
    // epic-3038(new locations)-story-3705-m2 | 2021-08-06 Fri µ
    else if (!!state.locationDetails && !!state.locationDetails.trim()) {
      state.remoteExtraInfo = state.locationDetails;
    }

    return state;

  }

  const _hasNoCandidateLocationsAndIsRemote = NOT(candidateLocations.length) && YES(isRemoteWork);
  const _hasNoOfficeLocationsAndIsInOfficeWork = NOT(officeLocations.length) && YES(isInOfficeWork);

  return (
    <Box column {...props}
      role='LocationSection'
      className={
        joinClassName([
          'bg-main p-1 m-1 rounded',
          className
        ])
      }
      style={{
        width: 'calc(100% - 1rem)',
        ...style
      }}
    >
      <RefBox ref={self => Core.setKeyValue('location-section', self)} />

      <Fieldset
        title={
          <>
            {t('job.desiredEmploymentTypes.label')}
          </>
        }
        info={AppUI.publicInfo.tooltip}
        infoIconName={MATERIAL_ICON_NAME__EYE}
        infoProps={{
          onClick: openJobView({
            jobId: Controller.state.id,
            sectionName: JOB_SECTION__SUMMARY
          })
        }}
      >
        {desiredEmploymentTypeTags.map((item, index) => (
          <Checkbox
            key={
              joinKeyName(
                [
                  'location_section',
                  'desired_employment_type',
                  index,
                  item.id
                ]
              )
            }
            label={item.label}
            checked={desiredEmploymentTypes.includes(item.id)}
            onCheck={(ev, checked) => {
              Controller.setState(
                _patch3385(
                  {
                    desiredEmploymentTypes: !!checked
                      ? [...desiredEmploymentTypes, item.id]
                      : desiredEmploymentTypes.filter((n) => n !== item.id),
                  }
                )
              );
            }}
          />
        ))}
      </Fieldset>

      <Fieldset
        title={
          <>
            {t('job.inOfficeRemoteFlags.label')}
          </>
        }
        dot='orange'
      >
        {inOfficeRemoteTags.map((tag, index) => (
          <Checkbox
            key={
              joinKeyName(
                [
                  'location_section',
                  'desired_employment_type',
                  index,
                  tag.id
                ]
              )
            }
            label={tag.label}
            checked={inOfficeRemoteFlags.includes(tag.id)}
            onCheck={(ev, checked) => {
              Controller.setState(
                /* epic-3338(new locations)-story-3385 | 2021-07-16 Fri µ */
                _patch3385(
                  {
                    inOfficeRemoteFlags: !!checked
                      ? [...inOfficeRemoteFlags, tag.id]
                      : inOfficeRemoteFlags.filter((n) => n !== tag.id)
                  }
                ),
                then => {
                  const state = Controller.state;
                  let update;
                  if (
                    NOT(
                      state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
                    )
                  ) {
                    update = {};
                    update.candidateLocations = [];
                  }
                  if (
                    NOT(
                      state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID) ||
                      state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
                    )
                  ) {
                    update = update || {}
                    update.officeLocations = [];
                  }
                  YES(update) && Controller.setState(_patch3385(update));
                }
              );
            }}
          />
        ))}
      </Fieldset>

      {/** OFFICE & CANDIDATE LOCATIONS */}
      <Box row>

        <Fieldset
          title={
            <>{t('job.officeLocations.label')}</>
          }
          subtitle={
            <>
              <Typography className="c-red" acl={_hasNoOfficeLocationsAndIsInOfficeWork}>
                {t('job.officeLocations.danger')}
              </Typography>
              <Typography className="c-black-medium" acl={NOT(_hasNoOfficeLocationsAndIsInOfficeWork)}>
                {t('job.officeLocations.subtitle')}
              </Typography>
              <Box acl={_hasNoCandidateLocationsAndIsRemote}>
                &nbsp;
              </Box>
            </>
          }
          info={NOT(isInOfficeWork) ? t('job.officeLocations.disabled') : ''}
        >
          <Menu tree
            layers={[
              LOC_TYPE__MULTI_CONT_ID,
              LOC_TYPE__COUNTRY_ID,
              LOC_TYPE__STATE_ID,
              LOC_TYPE__CITY_ID
            ]}
            treeDefaultExpandedIds={[
              LOCATION__AMERICAS,
              LOCATION__UNITED_STATES,
              LOCATION__CALIFORNIA
            ]}
            value={officeLocations}
            onChange={(officeLocations) => {
              Controller.setState(
                /* epic-3338(new locations)-story-3385 | 2021-07-16 Fri µ */
                _patch3385(
                  { officeLocations }
                )
              );
            }}
            options={locationTags}
            disabled={!isInOfficeWork}
            fullWidth
          />
        </Fieldset>

        <Fieldset
          title={t('job.candidateLocations.label')}
          subtitle={
            <>
              <Typography sub error
                acl={_hasNoCandidateLocationsAndIsRemote}
              >
                {t('job.candidateLocations.danger')}
              </Typography>
              <Typography sub
                acl={NOT(_hasNoCandidateLocationsAndIsRemote)}
              >
                {t('job.candidateLocations.subtitle')}
              </Typography>
              <Box
                acl={_hasNoOfficeLocationsAndIsInOfficeWork}
              >
                &nbsp;
              </Box>
            </>
          }
          info={NOT(isRemoteWork) && t('job.candidateLocations.disabled')}
        >
          <Menu tree
            layers={[
              LOC_TYPE__ALIAS_ID,
              LOC_TYPE__MULTI_CONT_ID,
              LOC_TYPE__COUNTRY_ID,
              LOC_TYPE__STATE_ID,
              LOC_TYPE__METRO_AREA_ID,
              LOC_TYPE__METRO_AREA_SEC_ID,
              LOC_TYPE__CITY_ID
            ]}
            treeDefaultExpandedIds={[
              LOCATION__AMERICAS,
              LOCATION__UNITED_STATES
            ]}
            value={candidateLocations}
            onChange={(candidateLocations) => {
              Controller.setState(
                /* epic-3338(new locations)-story-3385 | 2021-07-16 Fri µ */
                _patch3385(
                  { candidateLocations }
                )
              );
            }}
            options={candidateLocationTags}
            disabled={!isRemoteWork}
          />
        </Fieldset>

      </Box>

      {/** LOCATION DETAILS */}
      <Fieldset
        title={t('job.locationDetails.label')}
      >
        <TextField
          minRows={1}
          maxRows={7}
          placeholder="Type here"
          value={locationDetails}
          style={{ fontSize: "13px" }}
          onChange={(event) =>
            Controller.setState(
              _patch3385(
                {
                  locationDetails: event.target.value
                }
              )
            )
          }
        />
      </Fieldset>

      {/** RELOCATION ASSISTANCE */}
      <Fieldset
        title={t('job.relocation.label')}
      >
        <RadioGroup
          name="relocation"
          options={[
            { id: RELOCATION__YES_ID, label: RELOCATION__LABELS[RELOCATION__YES_ID] },
            { id: RELOCATION__NO_ID, label: RELOCATION__LABELS[RELOCATION__NO_ID] },
            { id: RELOCATION__UNKNOWN_ID, label: RELOCATION__LABELS[RELOCATION__UNKNOWN_ID] }
          ]}
          value={relocation}
          onChange={(event, relocation) => Controller.setState({ relocation })}
          fullWidth
        />
      </Fieldset>

      {/** RELOCATION INFO */}
      <Fieldset
        title={t('job.relocationExtraInfo.label')}
      >
        <TextField
          minRows={1}
          maxRows={7}
          placeholder="Specify additional information"
          value={relocationExtraInfo}
          onChange={event =>
            Controller.setState({
              relocationExtraInfo: event.target.value
            })
          }
        />
      </Fieldset>

    </Box>
  );
}

export {
  LocationSection, LocationSection as default
};
