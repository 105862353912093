import logoImage from '../../assets/images/10x10icon.png';
import backgroundImage from '../../assets/images/background.jpg';
import Core from '../../lib/Core';
import {
  ACCOUNT_ACTION__LIST_JOBS
} from '../../lib/Definition';
import useState from '../../lib/hooks/useState.hook';
import {
  exchangeJWTToken,
  JWT__EXPIRED_URL,
  JWT__SESSION_URL,
  login
} from '../../lib/services/Accounts/Session.lib';
import {
  getLocation
} from '../../lib/URL.lib';
import {
  getVariableFromURLQuery,
  setURLQueryString
} from '../../lib/URLQuery.lib';
import Button from '../Layout/Wrappers/Button';
import Navigate from '../Layout/Wrappers/Navigate';
import NavLink from '../Layout/Wrappers/NavLink';
import Paper from '../Layout/Wrappers/Paper';
import TextField from '../Layout/Wrappers/TextField';
import Typography from '../Layout/Wrappers/Typography';

export const loginUI = {
  errorText: 'This field is required'
};

export default function Login(props) {
  const [_state, _updateState] = useState({
    email: '',
    password: '',
    confirm: '',
    error: {
      email: false,
      password: false
    }
  });
  const _doLogin = async (event) => {
    try {
      const WEEKS_12 = 60 * 60 * 24 * 7 * 12;
      const inputs = {
        email: _state.email.toLowerCase(),
        password: _state.password,
        ttl: WEEKS_12,
      };
      if (!String(inputs['email']).trim().length) {
        _state.error.email = loginUI.errorText;
        return await _updateState({ error: _state.error });
      }
      if (!String(inputs['password']).trim().length) {
        _state.error.password = loginUI.errorText;
        return await _updateState({ error: _state.error });
      }
      inputs['time'] = new Date().toJSON();
      await login({ ...inputs });
    }
    catch (error) {
      Core.showError(error);
    }
  };
  const isV3Enabled = (Core.getUIVersion() === 3);
  const jwt = getVariableFromURLQuery('jwt');
  const employerId = getVariableFromURLQuery('employerId');
  const engagementId = getVariableFromURLQuery('engagementId');
  const jobId = getVariableFromURLQuery('jobId');

  if (Core.isLogged()) {
    return (
      (
        Core.isAdmin() && employerId
      ) ? (
        <Navigate
          to={setURLQueryString({
            url: '/employer/pendings',
            update: { employerId, engagementId, jobId },
            exact: false
          })}
        />
      ) : (
        Core.isEmployer()
      ) ? (
        <Navigate
          to={setURLQueryString({
            url: '/employer/pendings',
            update: { engagementId, jobId },
            exact: false
          })}
        />
      ) : (
        Core.isTrusted()
      ) ? (
        isV3Enabled ? <Navigate to="/v3/home" /> : <Navigate to="/home" />
      ) : (
        Core.isLimited() ||
        Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS })
      ) ? (
        isV3Enabled ? <Navigate to="/v3/jobs" /> : <Navigate to="/jobs" />
      ) : (
        <Navigate to="/error" />
      )
    );
  }
  else if (employerId && jwt) {
    exchangeJWTToken({ employerId, jwt });
    return null;
  }
  else if (!!getLocation().match(/jwt/i)) {
    return (
      <Navigate
        to={String(JWT__SESSION_URL).replace('#/', '')}
      />
    );
  }
  else if (!!getLocation().match(/employer\/pendings/i)) {
    return (
      <Navigate
        to={String(JWT__EXPIRED_URL).replace('#/', '')}
      />
    );
  }

  return (
    <LoginLayout>
      <TextField required autoFocus
        placeholder='Email'
        id="email"
        type="email"
        name="email"
        autoComplete="email"
        value={_state.email}
        onChange={async (event, email) => {
          _state.error.email = false;
          await _updateState({ email, error: _state.error });
        }}
        errorText={_state.error.email}
      />
      <br />
      <TextField required autoFocus
        placeholder='Password'
        id="password"
        type="password"
        name="password"
        autoComplete="password"
        value={_state.password}
        onChange={async (event, password) => {
          _state.error.password = false;
          await _updateState({ password, error: _state.error });
        }}
        errorText={_state.error.password}
        className='mt-1'
      />
      <Button
        label="LOG IN"
        className="mt-3"
        onClick={_doLogin}
        fullWidth
      />
      <NavLink decoration
        to='/forgot/password'
        className='flex-align-center mt-2'
      >
        Forgot Password
      </NavLink>
      <Typography italic xs alignCenter
        className="c-black-dark mt-2 align-center"
      >
        By signing in, you agree to 10x10's Terms of Service and
        Privacy Policy. You also agree to receive subsequent email
        and third-party communications, which you may opt out of at
        any time.
      </Typography>
    </LoginLayout>
  );
}

export function LoginLayout({ children, ...props }) {
  return (
    <div
      className='flex-column vh-100 vw-100'
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      {/** HEADER */}
      <div className="bg-cyan p-1 px-2">
        <img alt="Logo" src={logoImage} className='mx-2' style={{ height: 26 }} />
      </div>

      {/** CONTENT */}
      <div className="flex-1 flex-row flex-align-center">
        <div className='flex-column'>
          <strong className="c-white mb-4" style={{ fontSize: 42 }}>
            Say “You’re Hired” to Every Single Candidate
          </strong>
          <Paper
            className="p-2 m-auto"
            style={{ width: sizes.m6 }}
          >
            {children}
          </Paper>
        </div>
      </div>

      {/** FOOTER */}
      <div className="login-footer">
        <div className="inline-blocks">
          <Button flat
            label="Privacy Policy"
            href="https://www.iubenda.com/privacy-policy/73371936"
            className="footer-button"
          />
          <span className="ui-m-min">|</span>
          <Button flat
            label="Terms & Conditions"
            href="http://www.go10x10.com.s3-website-us-east-1.amazonaws.com/"
            className="footer-button"
          />
        </div>
      </div>
    </div>
  );
}