import {
  Input
} from 'antd';
import {
  uniqueId
} from 'lodash';
import {
  join
} from '../../../lib/Array.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  convertHtmlToPlainText
} from '../../../lib/String.lib';
import Box from '../../Layout/Wrappers/Box';
import Col from '../../Layout/Wrappers/Col';
import Divider from '../../Layout/Wrappers/Divider';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import {
  Html
} from '../../Layout/Wrappers/Html';
import IconButton from '../../Layout/Wrappers/IconButton';
import Toggle from '../../Layout/Wrappers/Toggle';
import {
  generateSingleHaveHTMLString,
  HAVE__OPTIONS
} from '../../Match/Haves/v2/Generators.lib';
import RejectionAdditionalInfo from './RejectionAdditionalInfo';

const HAVES_EDIT_KEY = 'match__haves__haves_edit';

export default function RejectionHavesEdit(props) {
  const { required, desirable, reference = () => null, EngagementCardController } = props;
  reference({ values: () => ({ required, desirable }) });
  const _mapHave = (have) => (
    <SingleHave
      have={have}
      key={uniqueId(HAVES_EDIT_KEY)}
    />
  );
  return (
    <Col className='p-0' fullWidth>
      {EngagementCardController && (
        <RejectionAdditionalInfo
          EngagementCardController={EngagementCardController}
          autoFocus={false}
        />
      )}

      <Fieldset column
        title='Required'
        wrapperProps={{ className: 'p-0', fullWidth: true }}
      >
        {required.not.map(_mapHave)}
        {required.yes.map(_mapHave)}
        {required.maybe.map(_mapHave)}
      </Fieldset>

      <Fieldset column
        title='Desirable'
        wrapperProps={{ className: 'p-0', fullWidth: true }}
      >
        {desirable.not.map(_mapHave)}
        {desirable.yes.map(_mapHave)}
        {desirable.maybe.map(_mapHave)}
      </Fieldset>

    </Col>
  )
}

export function SingleHave({ have }) {
  const [{ showCommentInput }, _updateState] = useState({});
  const _toggleComment = async (event) => _updateState({ showCommentInput: !showCommentInput });
  const htmlValue = generateSingleHaveHTMLString({
    have,
    icon: false
  });
  const renderHtmlElement = ({ className, style }) => (
    <Html
      value={htmlValue}
      className={join(['flex-column', className], ' ')}
      style={style}
    />
  );
  const textLength = convertHtmlToPlainText(htmlValue).length;
  return (
    <>
      <Box row noWrap
        className='px-1 mt-1'
      >
        <Toggle
          data={HAVE__OPTIONS}
          value={have.met}
          onChange={async (selected) => {
            have.met = selected.id;
            await _updateState();
          }}
          renderSelected={(selected) => (
            <IconButton small
              icon={selected.label}
              color={selected.color}
            />
          )}
          className='mr-1'
        />
        <Box row flex1 noWrap scrollX
          onClick={_toggleComment}
        >
          {
            (
              showCommentInput
            ) ? (
              <Input
                autoFocus
                className='mb-1'
                placeholder='Type a comment'
                value={have.comment}
                onChange={async (event) => {
                  have.comment = event.target.value;
                  await _updateState();
                }}
                onPressEnter={_toggleComment}
                onBlur={_toggleComment}
              />
            ) : (
              <Box row w100 wrap
                title={(textLength >= 133) && (
                  <div className='p-1 pb-2' style={{ width: sizes.m7 }}>
                    {renderHtmlElement({ className: 'c-white-all' })}
                  </div>
                )}
                className='pointer'
              >
                {renderHtmlElement({ className: 'nowrap' })}
              </Box>
            )
          }
        </Box>
      </Box>
      <Divider />
    </>
  );
}
