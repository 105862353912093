import {
  compile
} from 'handlebars';
import {
  Arr,
  join
} from '../../../lib/Array.lib';
import {
  CSS_TRANSPARENT,
  CSS_YELLOW_RGB
} from '../../../lib/Constants';
import Definition, {
  DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH,
  ENGAGEMENT_MATCH_STRENGTH__NO,
  ENGAGEMENT_MATCH_STRENGTH__STRONG_NO,
  ENGAGEMENT_MATCH_STRENGTH__STRONG_YES,
  ENGAGEMENT_MATCH_STRENGTH__WEAK_YES,
  ENGAGEMENT_MATCH_STRENGTH__YES
} from '../../../lib/Definition';
import EngagementDisagreement from '../../../lib/EngagementDisagreement';
import {
  compileText
} from '../../../lib/String.lib';
import {
  QUERY__MATCH_DECISIONS
} from '../../../lib/queries/MatchDecisions.query';
import {
  TEMPLATE__EMPLOYER_PENDINGS__SINGLE_MATCH_DECISION
} from '../../../lib/templates/EmployerPendings.templates';

export async function fetchMatchDecisions({ engagement: { id: ENGAGEMENT_ID } }) {
  return EngagementDisagreement.get(undefined, compileText(
    QUERY__MATCH_DECISIONS,
    { ENGAGEMENT_ID }
  ));
}

export async function getMatchDecisionsForEmail({ engagement, accepted, rejected }) {
  return join(
    Arr(
      await fetchMatchDecisions({ engagement })
        .catch(error => console.error(
          'getMatchDecisionsForEmail :error\n', error
        ))
    ).map((matchDecisionTableRecord) => {
      const {
        annotator = '',
        matchDecision = ''
      } = matchDecisionTableRecord;
      let isYes = isMatchDecisionYes({ label: matchDecision });
      let isNo = isMatchDecisionNo({ label: matchDecision });
      return compile(TEMPLATE__EMPLOYER_PENDINGS__SINGLE_MATCH_DECISION)({
        MATCH_DECISION: matchDecision,
        ANNOTATOR: annotator,
        COLOR: ((isYes && rejected) || (isNo && accepted)) ? CSS_YELLOW_RGB : CSS_TRANSPARENT
      });
    })
  );
}

export function isMatchDecisionYes({ id, label }) {
  let _matchDecisionId = id || Definition.getId(
    DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH, label
  );
  return [
    ENGAGEMENT_MATCH_STRENGTH__STRONG_YES,
    ENGAGEMENT_MATCH_STRENGTH__YES,
    ENGAGEMENT_MATCH_STRENGTH__WEAK_YES,
  ].includes(_matchDecisionId);
}

export function isMatchDecisionNo({ id, label }) {
  let _matchDecisionId = id || Definition.getId(
    DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH, label
  );
  return [
    ENGAGEMENT_MATCH_STRENGTH__STRONG_NO,
    ENGAGEMENT_MATCH_STRENGTH__NO
  ].includes(_matchDecisionId);
}
