import {
  Collapse
} from '@mui/material';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import dig from 'object-dig';
import {
  Component
} from 'react';
import Candidate from '../../../lib/Candidate';
import CandoPermittedJob from '../../../lib/CandoPermittedJob';
import {
  MDASH
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import {
  IN_OFFICE_REMOTE__FULL_WEEK_ID,
  IN_OFFICE_REMOTE__PART_WEEK_ID,
  IN_OFFICE_REMOTE__REMOTE_ONLY_ID
} from '../../../lib/Definition';
import LocationLib from '../../../lib/DefinitionLocation.lib';
import Engagement from '../../../lib/Engagement';
import Job from '../../../lib/Job';
import {
  getVariableFromURLQuery
} from '../../../lib/URLQuery.lib';
import {
  ENGAGEMENT__STATE_CLOSED,
  ENGAGEMENT__STATE_OPEN,
  STAGE_CONFIRMATION,
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN
} from '../../../dictionaries/Engagement.dic';
import {
  getCandidateModel
} from '../../../lib/models/candidate';
import copyHtml from '../../../lib/tools/copyHtml';
import {
  CandidateNameCell
} from '../../Home/CellCandidateNameColumn';
import MoreOptionsMenu from '../../Home/3DotsMenu/MoreOptionsMenu';
import {
  moreMenuOptionsCandidates
} from '../../Home/3DotsMenu/moreMenuOptionsCandidates';
import Box from '../../Layout/Wrappers/Box';
import Card from '../../Layout/Wrappers/Card';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import Divider from '../../Layout/Wrappers/Divider';
import IconButton from '../../Layout/Wrappers/IconButton';
import Table from '../../Layout/Wrappers/Table';
import Typography from '../../Layout/Wrappers/Typography';
import PrintMlScore from '../../Shared/PrintMlScore';
import {
  Candidates__state,
  Candidates__updateState
} from '../Candidates';
import ManageOwnershipPermittedJobs from '../ManageOwnershipPermittedJobs';
import {
  CandidateEngagementsDetails
} from './CandidateEngagementsDetails';
import {
  CandidateEngagementsInfo
} from './CandidateEngagementsInfo';
import {
  joinClassName
} from '../../Layout/Libraries/Theme.lib';

const mdash = '—';

class CandidateCard extends Component {
  timeout;
  constructor() {
    super(...arguments);

    this.parent = this.props.parent;
    this.state = {
      ...getCandidateModel({ extended: true }),
      /** controller states */
      isEngagementsRetrieved: true,
      expanded: false,
      openedColor: null,
      CardStyle: null,
      rightArrow: {},
      checked: false,
      boxes: [],
      /** load */
      ...this.props.model,
      blacklisted: '',
      mustHavesDialog: false,
    };
    if (dig(this.parent, 'parent', 'name') === 'JobMatch') {
      const jobBlackList = this.parent.parent.state.jobBlackList;
      const employerBlackList = Object(
        this.parent.parent.state.employer
      ).employerBlackList;
      setTimeout((st) => {
        if (
          !!jobBlackList.find(
            (recruiter) => recruiter.id === this.state.recruiter.id
          ) ||
          !!employerBlackList.find(
            (blacklisted) => blacklisted.id === this.state.recruiter.id
          )
        ) {
          this.setState({
            blacklisted: <b style={{ display: 'block' }}>Blacklisted</b>,
          });
        }
      }, 100);
    }
  }
  unexpandCard = (event) => {
    // if (Core.isAdmin()) {
    this.setState({
      expanded: false,
      CardStyle: null,
      openedColor: null,
      rightArrow: { transform: 'rotate(0deg)' },
    });
    // }
  };
  expandCard = (ev) => {
    // if (Core.isAdmin()) {
    Engagement.getWhere({ candidateId: this.state.id }, (res) => {
      const allJobIds = res.map((eng) => eng.jobId);
      CandoPermittedJob.get(
        {
          where: {
            jobId: {
              inq: allJobIds,
            },
            candidateId: this.state.id,
          },
        },
        (candoPermittedJobs) => {
          res.forEach((eng) => {
            eng.candoPermittedJobs = candoPermittedJobs.filter(
              (cpj) => cpj.jobId === eng.jobId
            );
          });

          this.setState(
            {
              expanded: true,
              CardStyle: { margin: '10px auto' },
              openedColor: { color: '#715EFF' },
              rightArrow: { transform: 'rotate(180deg)' },
              engagements: res,
            },
            (then) => Core.log(this.state)
          );
        }
      );
    });
  };
  fetchEngagements = (cb) => {
    let where = {};
    if (Core.isAdminOrCoordinator()) {
      where = { candidateId: this.state.id };
    } else {
      where = { candidateId: this.state.id, state: 'Open' };
    }
    Engagement.getWhere(where, (engagements) => {
      this.setState({ engagements }, (then) => {
        if (cb instanceof Function) {
          cb(engagements);
        }
      });
    });
  };
  handleToggle = (event) => {
    this.state.expanded ? this.unexpandCard() : this.expandCard();
  };
  onCheckStar = (ev, checked) => {
    const updateLocalList = (response) => {
      let candidate = this.state;
      let update = {
        starredId: response.starred ? response.id : null,
        starred: response.starred,
        filters: {
          ...this.state.filters,
          Starred: ['Non Starred', 'Starred'][~~Boolean(response.starred)],
        },
      };
      Object.assign(candidate, update);
      let allCandidates = (Candidates__state().allCandidates || []).map(c => {
        if (c.id === candidate.id) {
          Object.assign(c, update);
        }
        return c;
      });
      this.setState(update);
      Candidates__updateState({ allCandidates });
    };
    Candidate.updateStarred(
      this.state.id,
      this.state.starredId,
      checked,
      updateLocalList
    );
    this.unexpandCard();
  };
  copyString = (str) => {
    if (
      copy(str, {
        debug: true,
        message: 'Press #{key} to copy',
      })
    ) {
      Core.showSuccess(`${str} copied!`);
    } else {
      Core.showError('Fail copy!');
    }
  };
  bulkCopy() {
    this.fetchEngagements((engagements) => {
      const contents = [];
      engagements.forEach((eng) => {
        if ((eng.stage === STAGE_CONFIRMATION) && (eng.state === ENGAGEMENT__STATE_OPEN)) {
          // Core.log({ job: Job.getPreview(eng.job) });
          contents.push(Job.getPreview(eng.job));
        }
      });
      if (!!contents.length) {
        copyHtml('<p>' + contents.join('</p><p>') + '</p>')
          .then((em) => {
            Core.log('Copy email command was successful');
            Core.showSuccess('Copied!');
          })
          .catch((ex) => {
            Core.log('Oops, unable to copy');
            Core.showError('Fail copy!');
          });
      } else {
        Core.showWarning('There are no Engagements on Confirmation stage');
      }
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    this._forceMlOn = false;
  };

  staticRowDisplayColor = () => {
    let obj = {};
    let candidate = this.state;
    let job = this.props.parentModel;

    obj.getSalaryColor = ((candidate, job) => () => {
      if (!candidate.minimumSalary || !job.salaryMax) {
        return '';
      }

      let color;

      if (candidate.minimumSalary <= job.salaryMax) {
        color = 'green';
      } else if (candidate.minimumSalary <= 1.15 * job.salaryMax) {
        color = 'grey';
      } else if (candidate.minimumSalary <= 1.4 * job.salaryMax) {
        color = 'grey';
      } else {
        color = 'red';
      }

      return color;
    })(candidate, job);

    obj.getVisaColor = ((candidate, job) => () => {
      let menu = Candidate.menus.find((obj) => obj.key === 'visa');
      let myMappings = menu.mappings[job._visaTransfer] || [];
      return myMappings.includes(candidate._visa) ? 'green' : 'red';
    })(candidate, job);

    obj.getLocationColor = ((candidate, job) => () => {
      let hasMatched = job._locations.split(',').some((label) => {
        let regx = new RegExp(label, 'i');
        return regx.test(candidate._workLocationIds);
      });
      return hasMatched ? 'green' : 'red';
    })(candidate, job);

    obj.getYearsXp = ((candidate, job) => () => {
      let color = 'red';
      let diff =
        parseFloat(job.minYearsOfExperience) -
        parseFloat(candidate._yearsOfExperienceForCalc);
      let diffPercent = diff / parseInt(job.minYearsOfExperience);

      if (diff <= 0) {
        color = 'green';
      } else if (diff > 0 && diffPercent <= 0.4) {
        color = 'grey';
      }

      return color;
    })(candidate, job);
    return obj;
  };

  staticRowDisplayTag = (jobAtr, candAtr, color) => {
    if (!jobAtr || !candAtr) {
      return candAtr;
    }

    return (
      <span
        style={{
          backgroundColor: `${color} !important`,
          color: 'white !important',
          padding: '5px !important',
        }}
      >
        {candAtr}
      </span>
    );
  };

  addEngagementToPermittedJobs = (eng) => {
    const { id, jobsPermitted } = this.state;

    Candidate.update(
      id,
      { jobsPermitted: [...jobsPermitted, eng.jobId] },
      (cando) => {
        alert('candidate updated successfully with added permitted job');
        this.setState({ jobsPermitted: [...cando.jobsPermitted] });
      }
    );
  };

  render() {
    const candidate = this.state;
    const {
      inOfficeRemoteFlags = [],
      officeLocations = [],
      candidateLocations = [],
    } = this.state;
    const engaged = this.props.onCheck && (!!this.parent.parent.state.engagements || []).find(
      (eng) => eng.candidateId === this.state.id
    );
    const tdStyle = Core.isAdminOrCoordinator()
      ? { verticalAlign: 'top !important' }
      : { verticalAlign: 'middle !important' };

    let _candidateLocationsLabels = '';
    if (inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)) {
      /* epic-3038(new locations)-story-3689-m2 | 2021-08-05 Thu µ */
      _candidateLocationsLabels = LocationLib.getLocationsString({
        locations: candidateLocations,
      });
    }

    let _officeLocationsLabels = '';
    if (
      inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID) ||
      inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
    ) {
      /* epic-3038(new locations)-story-3689-m2 | 2021-08-05 Thu µ */
      _officeLocationsLabels = LocationLib.getLocationsString({
        locations: officeLocations,
      });
    }

    let accountId = getVariableFromURLQuery('accountId');

    return (
      <Card
        className={
          joinClassName([
            'row-card candidate scroll-x',
            (
              Core.isAdminOrCoordinator() &&
              this.state.isDuplicate
            ) ? (
              this.state.duplicatedLevel === 'primary'
            ) ? (
              'bg-orange-light'
            ) : (
              'bg-red-light'
            ) : (
              ''
            )
          ])
        }
        style={this.state.CardStyle}
      >
        <Box row
          className='flex-align-left-top'
          style={{ minWidth: 1200 }}
        >

          <div className='py-1'>
            <Checkbox
              checked={candidate.__selected}
              onChange={event => {
                let update = { __selected: !candidate.__selected };
                Object.assign(candidate, update);
                let allCandidates = (Candidates__state().allCandidates || []).map(c => {
                  if (c.id === candidate.id) {
                    c.__selected = !c.__selected;
                  }
                  return c;
                });
                Candidates__updateState({ allCandidates });
                this.setState({});
              }}
            />
          </div>

          {(Core.isRecruiter() || accountId) && (
            <CandidateEngagementsInfo
              candidateId={candidate.id}
              onLoad={({ engagements = [] }) => {
                if (!!engagements.length) {
                  this.expandCard();
                }
              }}
            />
          )}

          {/** COLLAPSED VIEW */}
          <Table className='collapsed-view'>
            <Table.Body>

              {/** ROW 01 */}
              <Table.Row
                style={{ marginBottom: 10, opacity: engaged ? 0.4 : 1 }}
                onClick={this.handleToggle}
              >
                {/** COL 00: checkbox for match job */}
                {!!this.props.onCheck && (
                  <Table.Cell
                    title="Check to Select for Matching"
                    style={{
                      width: 36,
                      textAlign: 'right',
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                    rowSpan="2"
                  >
                    <Checkbox
                      checked={engaged || this.state.checked}
                      onClick={(e) => e.stopPropagation()}
                      onCheck={(event, checked) => {
                        this.setState({ checked });
                        this.props.onCheck(this.state.id, checked);
                      }}
                      /** disabled checkbox if candidate is engaged with job */
                      disabled={engaged || !!this.state.blacklisted}
                    />
                  </Table.Cell>
                )}
                {/** COL 01 */}
                <Table.Cell
                  className="first-item v-align-mid p-0 pt-1 v-align-top"
                  style={{ width: 256, ...tdStyle }}
                  colSpan="2"
                >
                  <CandidateNameCell candidate={this.state} />
                </Table.Cell>
                {/** COL 02 */}
                <Table.Cell
                  title="Roles"
                  onClick={this.handleToggle}
                  className="mid-col p-0 pt-1 v-align-top"
                  colSpan="2"
                  style={{ width: 256 }}
                >
                  {this.state._roles || <i>&mdash;</i>}
                </Table.Cell>
                {/** COL 03 */}
                <Table.Cell
                  className="link-item mid-col v-align-mid p-0 pt-1 v-align-top"
                  colSpan="2"
                  style={{ width: 256 }}
                >
                  {!!this.state.recruiter.email ? (
                    <div className='d-flex flex-column'>
                      <div className='d-flex flex-align-left-top'>
                        <Box className='mr-1'>
                          <Box
                            title="Recruiter"
                            onClick={this.handleToggle}
                            className='mr-1'
                          >
                            {this.state.recruiter._name}
                          </Box>
                          <IconButton
                            title="Copy Recruiter email"
                            iconStyle={this.state.openedColor}
                            className="icon16 mr-1"
                            onClick={event => {
                              event.stopPropagation();
                              this.copyString(this.state.recruiter.email);
                            }}
                            icon='email'
                          />
                        </Box>
                        <Box>
                          {!!this.state.recruiter.companyName && (
                            <Box className='flex-row'>
                              <Box className='mr-1'>
                                {MDASH}
                              </Box>
                              <Box>
                                <Typography title="Agency">
                                  {this.state.recruiter.companyName}
                                </Typography>
                                <div title='Candidate Recruiters'>
                                  {candidate._contactNames.join(', ')}
                                </div>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </div>
                    </div>
                  ) : (
                    <i>&mdash;</i>
                  )}
                </Table.Cell>
                {/** COL 04 */}
                <Table.Cell
                  title="Visa"
                  className="mid-col p-0 pt-1 v-align-top"
                  onClick={this.handleToggle}
                  colSpan="2"
                  style={{ width: 256 }}
                >
                  {this.state._visa}
                </Table.Cell>
                {/** COL 05 */}
                {Core.isAdminOrCoordinator() && (
                  <Table.Cell
                    title="Introduced Date"
                    className="mid-col align-center p-0 pt-1 v-align-top"
                    onClick={this.handleToggle}
                    colSpan="1"
                    style={{ width: 256 }}
                  >
                    {this.state._introduced}
                  </Table.Cell>
                )}
                {/** LAST COL: options */}
                <Table.Cell
                  title="Options"
                  rowSpan={Core.isAdminOrCoordinator() ? 3 : 1}
                  className="ui-candidate-card-options no-truncate p-0 pt-1"
                  style={{ width: 160 }}
                >
                  <Box row w100 alignRight className='px-1'>
                    <Checkbox
                      title="Click to Starred"
                      className="c-purple-hover"
                      checked={this.state.starred}
                      onCheck={this.onCheckStar}
                      checkedIcon='star'
                      icon='star_border'
                      iconStyle={this.state.openedColor}
                    />
                    {Core.isRecruiter() &&
                      Core.isProduction() &&
                      !this.state.resumes.length ? (
                      false
                    ) : (
                      <MoreOptionsMenu
                        row={{ original: { ...this.state } }}
                        context={{ config: { moreMenuOptions: moreMenuOptionsCandidates } }}
                      />
                    )}
                    <IconButton
                      title="Toggle Expand/Collapse"
                      icon={!!this.state.expanded ? 'arrow_drop_up' : 'arrow_drop_down'}
                      iconStyle={this.state.openedColor}
                      onClick={this.handleToggle}
                    />
                  </Box>
                </Table.Cell>
              </Table.Row>
              {/** ROW 02 */}
              {Core.isAdminOrCoordinator() && (
                <Table.Row
                  style={{ marginBottom: 10, opacity: engaged ? 0.4 : 1 }}
                  onClick={this.handleToggle}
                >
                  {/** COL 01 */}
                  <Table.Cell
                    title="Technical Skills"
                    className="first-col p-0 pt-1 v-align-top"
                    colSpan="2"
                    onClick={this.handleToggle}
                    style={{ width: 256 }}
                  >
                    {this.state._technicalSkills}
                  </Table.Cell>
                  <Table.Cell
                    title="Positive Signals"
                    className="mid-col p-0 pt-1 v-align-top"
                    colSpan="2"
                    onClick={this.handleToggle}
                    style={{ width: 256 }}
                  >
                    {this.state._positiveSignals}
                  </Table.Cell>
                  <Table.Cell
                    title="Negative Signals"
                    className="mid-col p-0 pt-1 v-align-top"
                    colSpan="2"
                    onClick={this.handleToggle}
                    style={{ width: 256 }}
                  >
                    {this.state._negativeSignals}
                  </Table.Cell>
                  <Table.Cell
                    title="Locations"
                    className="mid-col p-0 pt-1 v-align-top"
                    colSpan="2"
                    onClick={this.handleToggle}
                    style={{ width: 256 }}
                  >
                    {_candidateLocationsLabels && (
                      <div className="d-block">
                        Remotely from: {_candidateLocationsLabels}
                      </div>
                    )}
                    {_officeLocationsLabels && (
                      <div className="d-block">
                        In Office: {_officeLocationsLabels}
                      </div>
                    )}
                  </Table.Cell>
                  <Table.Cell
                    className="mid-col align-center p-0 pt-1 v-align-top"
                    colSpan="1"
                    onClick={this.handleToggle}
                    style={{ width: 256 }}
                  >
                    <span title="Minimum Years of Experience">
                      {this.state._years}
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    <span title="Minimum Salary">
                      {this.state._minimumSalary}
                    </span>
                  </Table.Cell>
                </Table.Row>
              )}
              {/** ROW 03 */}
              {Core.isAdminOrCoordinator() && (
                <Table.Row
                  style={{ marginBottom: 10, opacity: engaged ? 0.4 : 1 }}
                >
                  {/** COL 01 */}
                  <Table.Cell
                    title="Technical Skills"
                    className="first-col p-0 pt-1 v-align-top"
                    colSpan="8"
                    onClick={this.handleToggle}
                  >
                    {this.props.params && this.props.params.count ? (
                      <EngagementsInfo candidate={this.state} />
                    ) : (
                      ''
                    )}
                    {
                      <PrintMlScore
                        candidate={this.state}
                        job={this.props.parentModel}
                        visible={
                          (this.props.source === 'JobMatch' &&
                            this.parent.parent._mlOn) ||
                          this._forceMlOn
                        }
                      />
                    }
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>

        </Box>
        <Divider />
        {/** EXPANDED VIEW */}
        <Collapse className="expanded-view" in={!!this.state.expanded}>
          {Core.isAdmin() && this.props.source === 'candoDuplicateDetails' && (
            <ManageOwnershipPermittedJobs candidate={this.state} />
          )}
          {!!this.state.engagements.length ? (
            <CandidateEngagementsDetails
              candidate={candidate}
              header={false}
              addEngagementToPermittedJobs={this.addEngagementToPermittedJobs}
            />
          ) : (
            this.state.isEngagementsRetrieved && (
              <Table styled>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>No engagements</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            )
          )}
        </Collapse>
      </Card >
    );
  }
}

class EngagementsInfo extends Component {
  render() {
    const { candidate } = this.props;
    const engagements = candidate.engagements;
    const reviewEngagements = engagements.filter(
      (eng) => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_REVIEW)
    ).length;
    const screenEngagements = engagements.filter(
      (eng) => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_SCREEN)
    ).length;
    const onsiteEngagements = engagements.filter(
      (eng) => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_ONSITE)
    ).length;
    const confEngagements = engagements.filter(
      (eng) => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_CONFIRMATION)
    ).length;
    const inactiveEngagements = engagements.filter(
      (eng) => (eng.state === ENGAGEMENT__STATE_CLOSED)
    ).length;
    const submissions = engagements.map((eng) =>
      eng.submitted ? moment(eng.submitted).toDate().getTime() : 0
    ).filter((time) => !!time);
    const lastSubmissionTime = Math.max.apply(null, submissions);
    const lastSubmissionDate = moment(lastSubmissionTime).toISOString();
    const lastSubmission = submissions.length
      ? moment(lastSubmissionTime).format('MM/DD/YY')
      : 0;
    const latestStage = Engagement.stageOrder[Math.max.apply(null,
      engagements.map((eng) => Engagement.stageOrder.indexOf(eng.stage))
    )];
    const componentEngagements = (
      <div className="cursor-default">
        <span title="Open, Confirmation">{confEngagements} conf |&nbsp;</span>
        <span title="Open, Review">{reviewEngagements} review |&nbsp;</span>
        <span title="Open, Screen">{screenEngagements} screen |&nbsp;</span>
        <span title="Open, Onsite">{onsiteEngagements} onsite</span>
        <br />
        <span title="Most advanced stage">
          <b>{latestStage || mdash}</b> |&nbsp;
        </span>
        <span title="Closed">{inactiveEngagements} inactive |&nbsp;</span>
        <span title={`Last submission: ${lastSubmissionDate}`}>
          <b>{lastSubmission || mdash}</b>
        </span>
      </div>
    );
    const alternativeCmp = <div />;
    return Core.isAdminOrCoordinator() ? componentEngagements : alternativeCmp;
  }
}

export default CandidateCard;
