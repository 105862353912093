import {
  Component
} from "react";
import AppUI from '../../../../dictionaries/AppUI.dic';
import {
  MATERIAL_ICON_NAME__EYE
} from '../../../../lib/constants/MaterialIconName.dic';
import Core from "../../../../lib/Core";
import Definition, {
  ACCOUNT_ACTION__EDIT_EMPLOYER
} from "../../../../lib/Definition";
import {
  Str
} from '../../../../lib/String.lib';
import formatMoney from "../../../../lib/tools/formatMoney";
import formatURL from "../../../../lib/tools/formatURL";
import {
  getLocation
} from '../../../../lib/URL.lib';
import StepHeader from '../../../Forms/StepHeader';
import {
  joinClassName
} from '../../../Layout/Libraries/Theme.lib';
import Box, { RefBox } from '../../../Layout/Wrappers/Box';
import Col from "../../../Layout/Wrappers/Col";
import Fieldset from '../../../Layout/Wrappers/Fieldset';
import IconButton from '../../../Layout/Wrappers/IconButton';
import InputNumber from '../../../Layout/Wrappers/InputNumber';
import Menu from '../../../Layout/Wrappers/Menu';
import RadioGroup from '../../../Layout/Wrappers/RadioGroup';
import RichTextBox from "../../../Layout/Wrappers/RichTextBox";
import Row from "../../../Layout/Wrappers/Row";
import Slider from "../../../Layout/Wrappers/Slider";
import TextField from '../../../Layout/Wrappers/TextField';
import InputArray from "../../../Shared/InputArray";
import {
  JOB_SECTION__DESCRIPTION,
  JOB_SECTION__MUST_HAVE_QUESTIONS,
  JOB_SECTION__SUMMARY,
  openJobView
} from '../../Card/JobDetails';
import JobTags from "../Forms/JobTags";
import LocationSection from "../Forms/LocationSection";
import JobRequirement from "../Forms/MustHave/JobRequirement";

class Basics extends Component {

  renderCandidateAttrs = () => {
    let { parent: JobEditController } = this.props;
    let collection = [
      //            {key: 'additionalNotes', limited: true, label: 'MUST have (original field)'},
      { key: "candidateMust", limited: true, label: "MUST have" },
      { key: "candidateMustNot", limited: true, label: "MUST NOT have" },
      {
        key: "candidatePositives",
        limited: true,
        label: "Strongly Preferred (Positives)",
      },
      {
        key: "candidateNegatives",
        limited: true,
        label: "Unlikely to Hire (Negatives)",
      },
    ];

    return collection.map((item, index) => {
      return (
        <Row className='mt-3' key={`candidate_edit__basics__candidate_attributes__${index}_${item.label}`}>
          <Fieldset
            title={
              <>
                {item.label}
              </>
            }
            info={AppUI.publicInfo.tooltip}
            infoIconName={MATERIAL_ICON_NAME__EYE}
            infoProps={{
              onClick: openJobView({
                jobId: JobEditController.state.id,
                sectionName: JOB_SECTION__MUST_HAVE_QUESTIONS
              })
            }}
          >
            <RichTextBox
              name={item.key}
              value={JobEditController.state[item.key]}
              onChange={retObj => {
                let ret = {};
                ret[item.key] = retObj;
                JobEditController.setState(ret);
              }}
            />
          </Fieldset>
        </Row>
      );
    });
  };

  render() {

    const {
      parent: JobEditController
    } = this.props;

    const error = {};

    const _displayEmployerIcon = (
      !!JobEditController.state.employerId &&
      Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER })
    );

    return (
      <Box column w100>

        <StepHeader {...this.props} />

        {/** ROLES + EMPLOYER */}
        <Box row w100
          role='RolesPlusEmployer'
        >

          <Fieldset
            role='Basics__JobRoles'
            title='Job Role(s)'
            dot='red'
            info={AppUI.publicInfo.tooltip}
            infoIconName={MATERIAL_ICON_NAME__EYE}
            infoProps={{
              onClick: openJobView({
                jobId: JobEditController.state.id,
                sectionName: JOB_SECTION__SUMMARY
              })
            }}
            className='mt-3'
          >
            <Menu multiple
              name='job__roles'
              value={JobEditController.state.roles}
              onChange={(roles) => {
                console.warn('onChange', roles);
                JobEditController.setState({
                  roles,
                  role: roles[0],
                });
              }}
              options={Definition.get("roles")}
            />
          </Fieldset>

          <Fieldset row noWrap
            title='Employer'
            dot='red'
          >
            <Menu dropdown required name="employer"
              value={JobEditController.state.employerId}
              onChange={(employerId) => {
                const employer = JobEditController.state.employers.find(
                  (employer) => employer.id === employerId
                );
                JobEditController.setState(
                  {
                    employerId,
                    employer,
                    visaTransfer: employer.visaTransfer,
                    remote: employer.remote,
                    addressCity: employer.addressCity,
                    location: employer.location,
                    error,
                  },
                  (then) => Core.log({ jobState: JobEditController.state })
                );
              }}
              className={_displayEmployerIcon ? 'mr-1' : ''}
              error={JobEditController.state.error.employer}
              options={
                JobEditController.state.employers.sort(
                  (a, b) => {
                    a = a.name.toLowerCase();
                    b = b.name.toLowerCase();
                    return a > b ? 1 : a < b ? -1 : 0;
                  }
                )
              }
              paperStyle={{ width: 380 }}
            />
            <IconButton
              acl={_displayEmployerIcon}
              className="icon24"
              onClick={(ev) => Core.go({
                ...this.props,
                to: `/employer/edit/${JobEditController.state.employerId}`
              })
              }
              icon='business'
            />
          </Fieldset>

        </Box>

        {/** JOB TITLE + LEVEL */}
        <Row>

          <Fieldset
            title='Job Title'
            dot='red'
          >
            <TextField
              name="jobTitle"
              type="text"
              value={JobEditController.state.jobTitle}
              onChange={(event, jobTitle) => JobEditController.setState({ jobTitle })}
              errorText={JobEditController.state.errorJobTitle}
            />
          </Fieldset>

          <Fieldset
            title={
              <>Level</>
            }
            info={AppUI.publicInfo.tooltip}
            infoIconName={MATERIAL_ICON_NAME__EYE}
            infoProps={{
              onClick: openJobView({
                jobId: JobEditController.state.id,
                sectionName: JOB_SECTION__SUMMARY
              })
            }}
          >
            <Menu dropdown required name="level"
              value={JobEditController.state.level}
              onChange={(level) => JobEditController.setState({ level })}
              options={Definition.get('level')}
              paperStyle={{ width: 380 }}
            />
          </Fieldset>

        </Row>

        {/** HIRING TARGET */}
        <Fieldset
          title={
            <>Hiring Target</>
          }
          info={AppUI.publicInfo.tooltip}
          infoIconName={MATERIAL_ICON_NAME__EYE}
          infoProps={{
            onClick: openJobView({
              jobId: JobEditController.state.id,
              sectionName: JOB_SECTION__SUMMARY
            })
          }}
          className='w-50'
        >
          <InputNumber
            name="hiringTarget"
            value={JobEditController.state.hiringTarget}
            onChange={(hiringTarget) => {
              JobEditController.setState({ hiringTarget });
            }}
            fullWidth
          />
        </Fieldset>

        {/** JOB TYPE + LOCATION OPTIONS */}
        <LocationSection
          Controller={JobEditController}
          className='mt-3'
        />

        {/** ELEVATOR PITCH */}
        <Row className='mt-3'>
          <Col fullWidth>
            <label>Elevator Pitch</label>
            <RichTextBox
              name="tagline"
              value={JobEditController.state.employer.tagline || ""}
              disabled
            />
          </Col>
        </Row>

        {/** VISA */}
        <Row className='mt-3'>
          <Col fullWidth>
            <label className='tt-unset'>
              US work visa
            </label>
            <RadioGroup
              name="visaTransfer"
              options={Definition.get("visa")}
              value={JobEditController.state.visaTransfer}
              onChange={(event, visaTransfer) => JobEditController.setState({ visaTransfer })}
            />
          </Col>
        </Row>

        {/** SALARY SECTION */}
        <RefBox ref={self => Core.setKeyValue('location-section', self)} />
        <Box column
          acl={JobEditController.state.id}
          className="bg-main p-1 m-1 mt-3 rounded"
          style={{ width: 'calc(100% - 1rem)' }}
        >
          <Box row w100>

            <Fieldset
              title={
                <>Minimum Salary:&nbsp; ${formatMoney(JobEditController.state.salaryMin, 0)}</>
              }
              info={AppUI.publicInfo.tooltip}
              infoIconName={MATERIAL_ICON_NAME__EYE}
              infoProps={{
                onClick: openJobView({
                  jobId: JobEditController.state.id,
                  sectionName: JOB_SECTION__SUMMARY
                })
              }}
            >
              <Slider
                name="salaryMin"
                min={0}
                max={500000}
                step={10000}
                value={JobEditController.state.salaryMin}
                onChange={(event, salaryMin) => {
                  JobEditController.setState({ salaryMin });
                }}
              />
            </Fieldset>

            <Fieldset row noWrap
              title={
                <>Equity Range</>
              }
            >
              <InputNumber
                name="equityMin"
                value={JobEditController.state.equityMin}
                onChange={(equityMin) => {
                  JobEditController.setState({ equityMin });
                }}
                className='mr-2 flex-1'
                units='%'
              />
              <InputNumber
                name="equityMax"
                type="number"
                value={JobEditController.state.equityMax}
                onChange={(equityMax) => {
                  JobEditController.setState({ equityMax });
                }}
                className='flex-1'
                units='%'
              />
            </Fieldset>

          </Box>

          <Box row w100>

            <Fieldset
              title={
                <>Maximum Salary:&nbsp; ${formatMoney(JobEditController.state.salaryMax, 0)}</>
              }
              fullWidth={false}
            >
              <Slider
                name="salaryMax"
                min={0}
                max={500000}
                step={10000}
                value={JobEditController.state.salaryMax}
                onChange={(event, salaryMax) => {
                  JobEditController.setState({ salaryMax });
                }}
              />
            </Fieldset>

            <Fieldset
              title={
                <>Additional Information on Salary</>
              }
              fullWidth={false}
            >
              <TextField
                name="salaryNote"
                className='bg-white'
                placeholder="ex: remote candidate's salary will be adjusted for local cost of living.  ex: above market equity."
                multiline
                minRows={2}
                maxRows={11}
                value={JobEditController.state.salaryNote}
                onChange={(event) => JobEditController.setState({ salaryNote: event.target.value })}
                fullWidth
              />
            </Fieldset>

          </Box>

        </Box>

        {/** JOB DESCRIPTION */}
        <Fieldset
          title={
            <>Public Job Description</>
          }
          info={AppUI.publicInfo.tooltip}
          infoIconName={MATERIAL_ICON_NAME__EYE}
          infoProps={{
            onClick: openJobView({
              jobId: JobEditController.state.id,
              sectionName: JOB_SECTION__DESCRIPTION
            })
          }}
          actionBar={
            <div className='µ-public-job-desc' style={{ minWidth: sizes.m6 }}>
              <input
                type="text"
                placeholder="Write company's job description link here"
                value={JobEditController.state.jobDescriptionPublicURL}
                onChange={(event) =>
                  JobEditController.setState({
                    jobDescriptionPublicURL: formatURL(event.target.value),
                  })
                }
              />
            </div>
          }
        >
          <RichTextBox
            name="jobDescription"
            value={JobEditController.state.jobDescription}
            onChange={jobDescription => {
              JobEditController.setState({
                jobDescription,
              });
            }}
          />
        </Fieldset>

        {/** TAGS */}
        <Fieldset
          title={
            <>Add Tags</>
          }
          subtitle={
            AppUI.askTechnicalExpert
          }
        >
          <Box row w100 role='JobEdit__Basics__AddTags__InputArray'
            className={joinClassName([
              'rounded-sm',
              !Str(getLocation()).includes('match') && 'bg-main'
            ])}
          >
            <InputArray
              heading="Tags"
              existing={JobEditController.state.jobTags}
              elementComp={JobTags}
              parentUpdater={(result) => {
                JobEditController.setState({ jobTags: result });
              }}
            />
          </Box>
        </Fieldset>

        {/** MUST HAVE + NICE TO HAVE */}
        <JobRequirement
          mustHave={JobEditController.state.mustHave}
          niceToHave={JobEditController.state.niceToHave}
          jobId={JobEditController.state.id}
          jobTags={JobEditController.state.jobTags}
          onSave={(haves, key) => {
            JobEditController.setState({
              [key]: haves,
            });
          }}
          className='mt-3'
        />

        {/** POSITIVE & NEGATIVE NOTES? */}
        {this.renderCandidateAttrs()}

        {/** ACCEPTED/REJECTED PROFILES */}
        {Core.isAdminOrCoordinator() && (
          <>

            <Fieldset
              title={
                <>Accepted Profiles</>
              }
              subtitle={
                <>Profiles accepted by the employer for interview invites</>
              }
            >
              <RichTextBox
                name="examplesOfAccepted"
                value={JobEditController.state.examplesOfAccepted}
                onChange={examplesOfAccepted => {
                  JobEditController.setState({ examplesOfAccepted });
                }}
              />
            </Fieldset>

            <Fieldset
              title='Rejected Profiles'
            >
              <RichTextBox
                name="examplesOfRejected"
                value={JobEditController.state.examplesOfRejected}
                onChange={examplesOfRejected => {
                  JobEditController.setState({ examplesOfRejected });
                }}
              />
            </Fieldset>

          </>
        )}

      </Box>
    );
  }
}

export default Basics;
