import moment from "moment";
import Core from "./Core";
import {
  NOT
} from './GenericTools.lib';
import Http from "./Http";
import {
  Obj
} from './Object.lib';
import {
  decodeBase64,
  Str,
  trim
} from './String.lib';

export const STREAK_PIPELINE_NAME__PRODUCTION = 'Engagement';
export const STREAK_PIPELINE_NAME__DEVELOPMENT = 'TestQA';

const DEBUG_ENABLE_PUT_STREAK_EMAIL_THREAD = Core.debug('DEBUG_ENABLE_PUT_STREAK_EMAIL_THREAD');

let pipelineKey;
const streakMap = {};
const mdash = "—";

export const mapStreakEngagementPipeline = pipeline => {
  if (typeof pipeline === 'string') {
    try {
      pipeline = JSON.parse(decodeBase64(pipeline));
    }
    catch (exception) {
      Core.failure({ exception, omitUIMessage: true });
      console.error(exception);
    }
  }
  pipeline = Obj(pipeline);
  pipelineKey = Str(pipeline.key);
  streakMap[pipeline.key] = {
    streakPipeline: pipeline.name,
    streakPipelineKey: pipeline.key,
    streakStage: {},
    streakStageOrder: []
  };
  streakMap[pipeline.key].streakStageOrder = pipeline.stageOrder;
  /** SET STAGE VALUES */
  pipeline.stages &&
    Object.keys(pipeline.stages).forEach(
      key =>
        (streakMap[pipeline.key].streakStage[key] = pipeline.stages[key].name)
    );
  /** SET FIELD VALUES */
  setFieldValues("Employer");
  setFieldValues("Introduced");
  setFieldValues("Matched");
  setFieldValues("Confirmed");
  setFieldValues("Onsite");
  setFieldValues("Recruiter");
  setFieldValues("Reviewed");
  setFieldValues("Role");
  setFieldValues("Screened");
  setFieldValues("State");
  setFieldValues("Status");
  setFieldValues("Submitted");
  setFieldValues("Last Action");
  setFieldValues("Offered");
  setFieldValues("Hired");
  setFieldValues("StartDate");
  setFieldValues("GuaranteeDate");
  setFieldValues("Screen 1");
  setFieldValues("Screen 2");
  setFieldValues("Screen 3");
  setFieldValues("Onsite 1");
  setFieldValues("Onsite 2");
  setFieldValues("HoldDate");
  setFieldValues("Closed");
  setFieldValues("EngagementURL");
  setFieldValues("Platform Rating");
  setFieldValues("Rejection Reason");
  setFieldValues("Job Title");
  setFieldValues("Platform Rating");
  setFieldValues("statusUpdatedDate");
  function setFieldValues(field) {
    /** field is the name of the item from pipeline.fields */
    const codeName = `streak${field.replace(" ", "")}`;
    const key = pipeline.key;
    (
      Object(
        Object(
          (pipeline.fields || []).find(item => {
            if (item.name === field) {
              streakMap[key][`${codeName}FK`] = item.key;
              streakMap[key][`${codeName}Type`] = item.type;
              return true;
            }
            return false;
          })
        ).dropdownSettings
      ).items || []
    ).forEach(item => {
      streakMap[key][codeName] = streakMap[key][codeName] || {};
      streakMap[key][codeName][item.key] = item.name;
    });
  }
  const mappedStreakPipeline = { ...streakMap[pipeline.key] };
  if (
    Core.isAdmin() &&
    (
      NOT(trim(pipeline.key)) ||
      NOT(trim(mappedStreakPipeline.streakPipelineKey))
    )
  ) {
    Core.showError(
      <>
        <div className='f-xl fw-600'>Something went wrong parsing Streak - an external resource</div>
        <div className='f-lg'>Streak action may not be performed</div>
        <div className='c-black-medium f-sm'>Unexpected error, message only for admin</div>
      </>
    );
  }
  // console.debug('mappedStreakPipeline...', '\n', pipeline.key, '\n', mappedStreakPipeline);
  return mappedStreakPipeline;
};

const getValue = (box, field, absolute) => {
  const codeName = `streak${field.replace(" ", "")}`;
  const pipeline = Object(streakMap[box.pipelineKey]);
  const fieldKey = String(pipeline[`${codeName}FK`]);
  const fieldList = Object(pipeline[codeName]);
  const boxField = box.fields[fieldKey];
  const defaultValue = absolute ? undefined : mdash;
  switch (pipeline[`${codeName}Type`]) {
    case "FORMULA":
      return Object(boxField).calculationStatus || defaultValue;
    case "DATE":
      return boxField ? moment(boxField).format() : defaultValue;
    case "DROPDOWN":
      return fieldList[boxField] || defaultValue;
    case "TEXT_INPUT":
      return boxField || defaultValue;
    default:
      /** STAGE */
      return fieldList[box[`${field.toLowerCase()}Key`]] || defaultValue;
  }
};
const getPipeline = () => {
  const pipeline = streakMap[pipelineKey];
  if (pipeline) { return pipeline; }
  else {
    return { stageStatus: {}, matchOptions: [] };
  }
};
const getBox = (key, success) => {
  Http.get(
    Core.getApi("Engagements/streak"),
    { path: `/boxes/${key}` },
    success,
    error => Core.showFailure("Streak: " + error)
  );
};
const getBoxes = (engagements, callback) => {
  let boxes = [];
  engagements.forEach(
    item =>
      item.boxKey &&
      Http.get(
        Core.getApi("Engagements/streak"),
        { path: `/boxes/${item.boxKey}` },
        box =>
          box &&
          box.pipelineKey &&
          callback(
            (boxes = [...boxes, box]
              /** sorting by stage */
              .sort(
                (a, b) =>
                  streakMap[a.pipelineKey].streakStageOrder.indexOf(
                    a.stageKey
                  ) -
                  streakMap[b.pipelineKey].streakStageOrder.indexOf(b.stageKey)
              )
              /** sorting by state */
              .sort(
                (a, b) =>
                  (getValue(a, "State", "common") === "Open" ? 0 : 1) -
                  (getValue(b, "State", "common") === "Open" ? 0 : 1)
              ))
          ),
        error => Core.showFailure("Streak: " + error)
      )
  );
};
const getPipelineBoxes = success =>
  Http.get(
    Core.getApi("Engagements/streak"),
    { path: `/pipelines/{{KEY}}/boxes` },
    success,
    error => Core.showFailure("Streak: " + error)
  );
const createBox = (
  options,
  onSuccess = () => { },
  onFailure = () => { }
) => {
  const pipeline = streakMap[pipelineKey];
  if (pipeline) {
    /** BASED MODEL */
    const model = {
      name: options.name,
      recruiter: findKey(pipeline.streakRecruiter, options.recruiter),
      role: findKey(pipeline.streakRole, options.role, true),
      stage:
        findKey(pipeline.streakStage, options.stage) ||
        pipeline.streakStageOrder[0],
      status: findKey(pipeline.streakStatus, options.status || "W - 10x10"),
      state: findKey(pipeline.streakState, options.state || "Open"),
      introduced: date(options.introduced, true),
      matched: date(options.matched, true),
      lastAction: date(null, true),
      confirmed: date(options.confirmed),
      platformRating: options.platformRating,
      jobTitle: options.jobTitle,
      engagementURL: options.engagementURL || ""
    };
    function date(valid, alwaysReturnDate) {
      return valid
        ? new Date(valid).getTime()
        : alwaysReturnDate
          ? new Date().getTime()
          : null;
    }
    function findKey(dropdown, name, exact) {
      if (exact) {
        return Object.keys(dropdown).find(key => name === dropdown[key]);
      } else {
        return Object.keys(dropdown).find(key =>
          new RegExp(String(name).replace(/\W/g, ".?"), "i").test(
            String(dropdown[key])
          )
        );
      }
    }
    Core.log(options, model);
    /** CREATE NEW STREAK BOX */
    Http.put(
      Core.getApi("Engagements/streak"),
      {
        path: `/pipelines/{{KEY}}/boxes`,
        options: { name: model.name, stageKey: model.stage }
      },
      box => {
        Core.log(box);
        if (box.key) {
          onSuccess(box);
          const fields = [
            {
              field: "State",
              value: model.state
            },
            {
              field: "Role",
              value: model.role
            },
            {
              field: "Status",
              value: model.status
            },
            {
              field: "Introduced",
              value: model.introduced
            },
            {
              field: "Platform Rating",
              value: model.platformRating
            },
            {
              field: "Job Title",
              value: model.jobTitle
            },
            {
              field: "Matched",
              value: model.matched
            },
            {
              field: "Last Action",
              value: model.lastAction
            },
            {
              field: "Recruiter",
              value: model.recruiter
            },
            {
              field: "EngagementURL",
              value: model.engagementURL
            }
          ];
          model.confirmed &&
            fields.push({
              field: "Confirmed",
              value: model.confirmed
            });
          updateBox({ boxKey: box.key, fields });
        }
      },
      error => {
        Core.showFailure("Streak: " + error);
        onFailure(error);
      }
    );
  }
};

async function updateBox({ boxKey, fields, engagementId }) {

  console.debug('updateBox', engagementId, boxKey);

  /** @todo [ 2023-03-29 ][ MS: to review how to reimplement this ] */
  /**
   * We are avoiding all update streak box from FE
   * story_8463
   * @todo remove all updateBox reference after beta period.
  * /
  fields = Arr(fields);
  if (fields.length > 1 || !fields.find(item => item.field === 'EngagementURL')) {
    sendSafeEmail({
      to: TEN_BY_TEN_ADMIN_CONTACT.email,
      subject: `Streak call from FE unexpectedly`,
      html: `
      <p>
      Update box was call for&nbsp;
      the engagementId: ${engagementId},&nbsp;
      and the boxKey: ${boxKey}
      </p>
      `
    });
    return { response: {}, failures: [] }
  }
  /** */

  const pipeline = streakMap[pipelineKey];
  const failures = [];
  const _fields = {};
  let _stageKey;
  let response;

  fields.forEach(({ field, value, stageKey }) => {
    if (stageKey) { _stageKey = stageKey; return; }
    if (!pipeline[`streak${field.replace(" ", "")}FK`]) {
      return Core.failure({
        message: `Streak Library: Non registered field. (${field})`,
        omitUIMessage: true
      });
    }
    _fields[pipeline[`streak${field.replace(" ", "")}FK`]] = value;
    if (field === "Status" && !/h -/i.test(value)) {
      _fields[pipeline[`streakHoldDateFK`]] = '';
    }
  });

  try {
    response = await Http.post(
      Core.getApi("Engagements/streak"),
      { path: `/boxes/${boxKey}`, options: { stageKey: _stageKey, fields: _fields } }
    );
  }
  catch (failure) {
    console.debug(`Fails to update Streak: ${failure};`);
    if (!Core.isLocalHost() && !Core.isDevelopment()) {
      Core.failure({
        source: 'Streak.js(lib)>updateBox:catch',
        exception: failure,
        params: {
          boxKey,
          fields,
          engagementId,
        },
        omitUIMessage: true
      });
    }
    failures.push(failure);
  }

  window.streakFailures = window.streakFailures || {};
  window.streakFailures[engagementId] = failures;

  console.debug({ boxKey, fields, _fields, pipeline, _stageKey, engagementId, response, failures });

  return {
    response,
    failures
  }

}

const getDropdownStrings = field => {
  const pipeline = streakMap[pipelineKey];
  if (pipeline) {
    const codeName = `streak${field.replace(" ", "")}`;
    const dropdownList = pipeline[codeName];
    if (dropdownList) {
      return Object.keys(dropdownList).map(key => dropdownList[key]);
    }
  }
  return [];
};
const getDropdownKey = (field, option) => {
  const pipeline = streakMap[pipelineKey];
  if (pipeline) {
    const codeName = `streak${field.replace(" ", "")}`;
    if (!pipeline[codeName]) {
      return (Core.isAdmin() ? Core.showError : console.debug)(`${field} ${codeName} is missing in pipeline`);
    }
    const dropdownList = pipeline[codeName];
    return Object.keys(dropdownList).find(key => dropdownList[key] === option);
  } else {
    return null;
  }
};
const fetch = async keyName => {
  if (Core.isLogged()) {
    return Http.get(
      Core.getApi("Engagements/streak/pipeline"),
      { path: `/pipelines/{{KEY}}` }
    ).catch(Core.showError).then(mapStreakEngagementPipeline);
  }
};
const Streak = {
  fetch,
  getPipeline,
  getBox,
  getBoxes,
  getPipelineBoxes,
  getDropdownStrings,
  getDropdownKey,
  getValue,
  getDateValue: (box, field, format) => {
    const codeName = `streak${field.replace(" ", "")}`;
    const pipeline = Object(streakMap[box.pipelineKey]);
    const fieldKey = String(pipeline[`${codeName}FK`]);
    const boxField = box.fields[fieldKey];
    return boxField ? moment(boxField).format(format || "") : mdash;
  },
  createBox,
  updateBox,
  deleteBox: ({ boxKey, onSuccess, onFailure }) => {
    Http.delete(
      Core.getApi("Engagements/streak"),
      { path: `/boxes/${boxKey}` },
      onSuccess,
      onFailure
    );
  },
  createComment: async ({ boxKey, message }, onSuccess, onFailure) => {
    return Http.post(
      Core.getApi("Engagements/streak/v2"),
      {
        path: `/boxes/${boxKey}/comments`,
        options: { message }
      },
      onSuccess,
      error => (onFailure ? onFailure(error) : Core.failure({ error, omitUIMessage: true }))
    );
  },
  getComments: (boxKey, onSuccess, onFailure) => {
    Http.get(
      Core.getApi("Engagements/streak"),
      {
        path: `/boxes/${boxKey}/comments`
      },
      onSuccess,
      error => (onFailure ? onFailure(error) : Core.failure({ error, omitUIMessage: true }))
    );
  },
  getThreads: (boxKey, onSuccess, onFailure) => {
    Http.get(
      Core.getApi("Engagements/streak"),
      {
        path: `/boxes/${boxKey}/threads`
      },
      onSuccess,
      error => (onFailure ? onFailure(error) : Core.failure({ error, omitUIMessage: true }))
    );
  },
  updateNotes: ({ boxKey, notes }, onSuccess, onFailure) => {
    Http.post(
      Core.getApi("Engagements/streak"),
      { path: `/boxes/${boxKey}`, options: { notes } },
      onSuccess,
      error => (onFailure ? onFailure(error) : Core.failure({ error, omitUIMessage: true }))
    );
  },
  putEmailInBox: async ({ boxKey, threadGmailId }, onSuccess = async () => null, onFailure) => {
    if (Core.isOnDev() && !DEBUG_ENABLE_PUT_STREAK_EMAIL_THREAD) { return onSuccess(); }
    return Http.put(
      Core.getApi("Engagements/streak"),
      {
        path: `/boxes/${boxKey}/threads`,
        options: { boxKey, threadGmailId }
      },
      onSuccess,
      error =>
        onFailure
          ? onFailure("Streak: " + error)
          : Core.failure({ error, omitUIMessage: true })
    );
  },
  getSnippets: async () => Http.get(Core.getApi("Engagements/streak"), { path: `/snippets` })
};
export default Streak;
