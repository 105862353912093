import { compile } from 'handlebars';
import moment from 'moment';
import { useRef, useState } from 'react';
import Core from '../../../lib/Core';
import { isValidDate } from '../../../lib/Date.lib';
import { NOT } from '../../../lib/GenericTools.lib';
import {
  COLLECTION__EMPLOYER_EMAIL_TEMPLATES,
  getLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import { isNullOrUndefined } from '../../../lib/String.lib';
import Button from '../../Layout/Wrappers/Button';
import Menu from '../../Layout/Wrappers/Menu';
import {
  EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE_FIRSTNAME,
  EMPLOYER_MANAGE_TEMPLATES_TOKEN__EMPLOYER_NAME,
  EMPLOYER_MANAGE_TEMPLATES_TOKEN__MY_FIRSTNAME,
  getTemplateSamples
} from './EmployerManageTemplatesContent';

export function EmployerTemplateBar(props) {
  /**
   * @note
   * Don't change the persistent reference.
   * It is required since the component flags being lost,
   * because there are multiple instances 
   * that overwrite the current rendering when they take control.
   */
  const { current: mem } = useRef({});
  let {
    employerId,
    templateId,
    onChange = async selected => null,
    macros = {
      [EMPLOYER_MANAGE_TEMPLATES_TOKEN__MY_FIRSTNAME]: Core.getUserName().split(' ')[0] || '',
      [EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE_FIRSTNAME]: null,
      [EMPLOYER_MANAGE_TEMPLATES_TOKEN__EMPLOYER_NAME]: null,
    }
  } = props;
  let [state, setState] = useState({ selected: {} });
  const _updateState = async (update = { __updatedAt: Date.now() }) => new Promise((resolve) => {
    setState((prevState) => (update = { ...prevState, ...update }));
    setTimeout(() => resolve(update));
  });
  let {
    selected = {},
    /**
     * @sample  template record...
      {
        id: 'employer_template_id',
        employerId: 'employer_fk_id',
        name: 'template_name',
        subject: `${EMPLOYER_MANAGE_TEMPLATES_TOKEN__EMPLOYER_NAME} message:`,
        body: `Hi ${EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE_FIRSTNAME}`,
        createdBy: Core.getUserId(),
        createdAt: getPacificTimeIsoString()
      }
     */
    data = []
  } = state;
  async function _fetchData({ templateId }) {
    mem.busy = true;
    try {
      data = await getLoopbackRecord({
        collection: COLLECTION__EMPLOYER_EMAIL_TEMPLATES,
        where: { employerId },
        include: [
          {
            relation: 'account',
            scope: { fields: ['firstName', 'lastName'] }
          }
        ],
      });
      if (templateId) {
        selected = data.find(item => (item.id === templateId));
        if (selected) {
          await onChange(_replaceMacros(selected));
        }
      }
      await _updateState({
        data: data.length ? data : getTemplateSamples({ employerId }),
        selected
      });
      mem.ready = true;
    }
    catch (error) {
      mem.error = error;
    }
    mem.busy = false;
  }
  function _replaceMacros(selected) {
    let _selected = { ...selected };
    _selected.subject = compile(_selected.subject)(macros);
    _selected.body = compile(_selected.body)(macros);
    return _selected;
  }
  (async () => {
    if (mem.prevCandidate !== macros[EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE_FIRSTNAME]) {
      mem.prevCandidate = macros[EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE_FIRSTNAME];
      delete mem.ready;
    }
    if (NOT(mem.busy || mem.error || mem.ready)) {
      await _fetchData({ templateId });
    }
  })();
  return (
    <div className='d-flex flex-align-left-center mt-1'>
      <div className='c-cyan-darker fw-600 f-lg mr-1 min-w-80'>
        Template
      </div>
      <Menu dropdown
        name='emp_mng_select_templates'
        value={selected.id}
        onClick={_fetchData}
        onChange={async (value, option) => {
          const selected = { ...option };
          if (isNullOrUndefined(value)) {
            Object.assign(selected, {
              subject: '',
              body: '&nbsp;'
            });
          }
          else {
            Object.assign(selected,
              _replaceMacros(selected)
            );
          }
          delete selected.label;
          await _updateState({ selected });
          await onChange(selected);
        }}
        options={data}
        renderOption={({ option }) => (
          <div style={{ minWidth: 224 }}>
            <div>
              {option.name}
            </div>
            <div className='f-sm c-black-medium t-align-right'>
              {`${option.account?.firstName || ""} ${option.account?.lastName || ''}`.trim()}
              {isValidDate(option.createdAt)
                ? ` - ${moment(option.createdAt).format('MM/DD HH:mm z')}`
                : ''}
            </div>
          </div>
        )}
        className='mr-1'
        style={{ width: 390 }}
        small
      />
      <Button
        variant='contained'
        color='secondary'
        size='small'
        className='nowrap min-w-160'
        onClick={event => {
          Core.openPopUp(Core.getPath(`employer/manage/templates/${employerId}`), 1200);
        }}
        small
      >
        Manage Templates
      </Button>
    </div>
  );
}
