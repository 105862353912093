import {
  compile
} from 'handlebars';
import {
  Arr,
  join,
  sanitizeArr
} from '../../../../lib/Array.lib';
import {
  CSS_BLACK_RGB,
  CSS_RED_RGB
} from '../../../../lib/Constants';
import Core from '../../../../lib/Core';
import Definition from '../../../../lib/Definition';
import {
  NOT,
  capitalize
} from '../../../../lib/GenericTools.lib';
import {
  joinHTML
} from '../../../../lib/HTML.lib';
import {
  Obj
} from '../../../../lib/Object.lib';
import {
  trim
} from '../../../../lib/String.lib';
import {
  TEMPLATE__MATCH_REJECTION_HAVES,
  TEMPLATE__MATCH_REJECTION_HAVES__SINGLE_LINE
} from '../../../../lib/templates/RejectionHaves.template';
import {
  showConfirm
} from '../../../Dialogs/AppConfirmationDialog';
import RejectionHavesEdit from '../../../Engagements/Card/RejectionHavesEdit';
import {
  THEME__COLOR__ERROR,
  THEME__COLOR__INHERIT,
  THEME__COLOR__SUCCESS
} from '../../../Layout/Libraries/Theme.lib';
import {
  evaluateHaves
} from './Evaluations.lib';

export const HAVE__YES_MET__KEY = 'yes';
export const HAVE__NOT_MET__KEY = 'not';
export const HAVE__MAYBE__KEY = 'maybe';
export const HAVE__YES_MET__ICON = `✅`;
export const HAVE__NOT_MET__ICON = `❌`;
export const HAVE__MAYBE__ICON = `︖`;
export const MUST_HAVE_KEY = 'required';
export const NICE_TO_HAVE_KEY = 'desirable';

export const HAVE__OPTIONS = [
  {
    id: HAVE__YES_MET__KEY,
    label: 'done',
    color: THEME__COLOR__SUCCESS,
  },
  {
    id: HAVE__NOT_MET__KEY,
    label: 'close',
    color: THEME__COLOR__ERROR
  },
  {
    id: HAVE__MAYBE__KEY,
    label: 'question_mark',
    color: THEME__COLOR__INHERIT
  }
]

export const HTML_GENERATORS = {
  and: generateSingleHaveHTMLString,
  or: generateSingleHaveHTMLString,
  not: generateSingleHaveHTMLString,
  chip: getChipHaveLabel,
  chipGroup: getChipHaveLabel,
  chipTag: getChipHaveLabel,
  yearsOfExperience: getYoEString,
  text: getTextHaveString
};

export async function editHaves(options) {
  const HAVES_EDIT_KEY = 'haves_edit_form';
  return new Promise((resolve) => {
    showConfirm({
      paperStyle: {
        width: 1024,
        maxWidth: 'calc(100vh - 2rem)'
      },
      title: 'Editing Candidate met Job haves',
      content: (
        <RejectionHavesEdit
          reference={self => Core.setKeyValue(HAVES_EDIT_KEY, self)}
          {...options}
        />
      ),
      onAccept: () => resolve(
        Core.getKeyValue(HAVES_EDIT_KEY).values()
      )
    });
  })
}

export async function generateRejectionHavesHTMLString({
  job,
  candidate,
  EngagementCardController,
  readOnly
}) {
  console.debug('generateRejectionHavesHTMLString :job :candidate\n', job, candidate);
  const { required, desirable } = (
    readOnly
      ? await evaluateHaves({ job, candidate })
      : await editHaves({
        ...(await evaluateHaves({ job, candidate })),
        EngagementCardController
      })
  );
  const result = compile(TEMPLATE__MATCH_REJECTION_HAVES)({
    REQUIRED: join([
      ...required.not.map((have) => generateSingleHaveHTMLString({ have })),
      ...required.yes.map((have) => generateSingleHaveHTMLString({ have })),
      ...required.maybe.map((have) => generateSingleHaveHTMLString({ have }))
    ], ''),
    DESIRABLE: join([
      ...desirable.not.map((have) => generateSingleHaveHTMLString({ have })),
      ...desirable.yes.map((have) => generateSingleHaveHTMLString({ have })),
      ...desirable.maybe.map((have) => generateSingleHaveHTMLString({ have }))
    ], '')
  });
  console.debug('generateRejectionHavesHTMLString :REQ :DES :RES\n', required, desirable, result);
  return result;
}

export function generateSingleHaveHTMLString({ have, icon = true, colored }) {
  const isYoEType = (have.type === 'yearsOfExperience');
  const atLeast = parseInt(have.atLeast);
  const condition = (isYoEType
    ? !!atLeast ? `${atLeast}yrs+:` : ''
    : (!!atLeast && (atLeast >= 2)) ? `${atLeast}+:` : ''
  )
  const labels = join((have.value ? [have] : sanitizeArr(have.args)).map((have) => {
    if (HTML_GENERATORS[have.type]) {
      return HTML_GENERATORS[have.type]({ have });
    }
    const label = trim(have.value);
    return label ? `<b>${label}</b>` : label;
  }));
  let specialInstruction = capitalize(have.specialInstruction);
  if (NOT(labels) && specialInstruction) {
    specialInstruction = `<b>${specialInstruction}</b>`;
  }
  const ICON = trim(icon && join([
    '&nbsp;&nbsp;&nbsp;&nbsp;',
    {
      [HAVE__YES_MET__KEY]: `${HAVE__YES_MET__ICON}&nbsp;&nbsp;`,
      [HAVE__NOT_MET__KEY]: `${HAVE__NOT_MET__ICON}&nbsp;&nbsp;`,
      [HAVE__MAYBE__KEY]: `&nbsp;${HAVE__MAYBE__ICON}&nbsp;&nbsp;`,
    }[have.met]
  ], ''));
  let result = compile(TEMPLATE__MATCH_REJECTION_HAVES__SINGLE_LINE)({
    ICON,
    COLOR: colored ? CSS_RED_RGB : CSS_BLACK_RGB,
    OPERATOR: (
      (have.type === 'and') ? `All: `
        : (have.type === 'not') ? `Not: `
          : ''
    ),
    CONDITION: condition ? `${condition} ` : '',
    LABELS: labels ? `${labels}${specialInstruction ? ' - ' : ' '}` : '',
    INSTRUCTION: specialInstruction ? `${specialInstruction} ` : '',
    COMMENT: joinHTML(have.comment),
  });
  return result;
}

export function getChipHaveLabel({ have }) {
  let defRaw = !!Definition.getRawDef(have.key) ? Definition.getRawDef(have.key) : [];
  let groupEntry = Obj(Arr(defRaw.groups).find(group => group.id === have.value));
  let label = have.type === 'chipGroup' ? groupEntry.name : Definition.getLabel(have.key, have.value);
  label = have.type === 'chipTag' ? have.value : label;
  label = trim(label);
  return (label && (have.match === true)) ? `<b>${label}</b>` : label;
}

export function getYoEString({ have }) {
  const value = parseInt(have.value);
  return value ? `<b>${value}yrs</b>` : '';
}

export function getTextHaveString({ have }) {
  return capitalize(trim(have.value));
}
