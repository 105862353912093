import {
  Menu
} from '@mui/material';
import React, {
  useState
} from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable
} from 'react-beautiful-dnd';
import Core from '../../lib/Core';
import {
  copyString
} from '../../lib/GenericTools.lib';
import {
  getBookmarkModel
} from '../../lib/models/account';
import { getPathname, getSearch } from '../../lib/URL.lib';
import Box from '../Layout/Wrappers/Box';
import IconButton from '../Layout/Wrappers/IconButton';
import Loader from '../Shared/Loader';
import {
  IndeterminateCheckbox
} from './IndeterminateCheckbox';
import {
  useAccountBookmarks
} from './useAccountBookmarks.hook';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'var(--blackLighter)',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'var(--blackLighter)',
  borderRadius: 3,
  padding: grid,
  width: '50%',
});

function ColumnsSettings({
  enhancedReactTableContext: {
    context: { location },

    // react-table-hook
    allColumns,
    getToggleHideAllColumnsProps,
    setColumnOrder,

    // enhanced-hook
    latestQuery,
  },
  style,
  className,
}) {

  const [anchorEl, setAnchorEl] = useState();
  const open = !!anchorEl;

  let { loading, bookmarks, fetchBookmarks, upsertBookmark } = useAccountBookmarks({ accountId: Core.getUserId() });

  let hiddenColumns = [];
  let visibleColumns = [];

  allColumns.forEach((column, index) => {
    if (column.getToggleHiddenProps().checked) {
      visibleColumns.push(column);
    } else {
      if (column.id === 'actions') {
        column.toggleHidden();
      } else {
        hiddenColumns.push(column);
      }
    }
  });

  const isActionsLastItem = !!visibleColumns.find(
    ({ id }, index) => id === 'actions' && index === visibleColumns.length - 1
  );

  if (!!visibleColumns.length && !isActionsLastItem) {
    let columnOrder = [...visibleColumns]
      .map(({ id }) => id)
      .filter((id) => id !== 'actions');
    columnOrder.push('actions');
    setColumnOrder(columnOrder);
  }

  let updates = true;
  let defaultBookmark = bookmarks.find(({ label }) => label === getPathname());
  let { columnOrder = [] } = defaultBookmark || {};
  if (columnOrder.toString() === visibleColumns.map(c => c.id).toString()) {
    updates = false;
  }

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    console.debug({ source, destination });

    if (source.droppableId === 'visible') {
      source.list = visibleColumns;
      if (source.droppableId !== destination.droppableId) {
        let columnId = source.list[source.index].id;
        if (columnId !== 'actions') {
          source.list[source.index].toggleHidden();
        }
      } else {
        if (source.list) {
          let columnId = source.list[source.index].id;
          let columnOrder = [...visibleColumns]
            .map(({ id }) => id)
            .filter((id) => id !== columnId && id !== 'actions');
          if (columnId !== 'actions') {
            columnOrder.splice(destination.index, 0, columnId);
          }
          columnOrder.push('actions');
          setColumnOrder(columnOrder);
          console.debug('columnOrder', columnOrder);
          console.debug('allColumns', allColumns);
        }
      }
    }
    if (source.droppableId === 'hidden') {
      source.list = hiddenColumns;
      if (source.droppableId !== destination.droppableId) {
        source.list[source.index].toggleHidden();
        if (source.list) {
          let columnId = source.list[source.index].id;
          let columnOrder = [...visibleColumns]
            .map(({ id }) => id)
            .filter((id) => id !== columnId && id !== 'actions');
          if (columnId !== 'actions') {
            columnOrder.splice(destination.index, 0, columnId);
          }
          columnOrder.push('actions');
          setColumnOrder(columnOrder);
          console.debug('columnOrder', columnOrder);
          console.debug('allColumns', allColumns);
        }
      }
    }
  }

  return (
    <div {...{ style, className }}>
      <Box
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          fetchBookmarks();
          setColumnOrder([...visibleColumns].map(({ id }) => id));
        }}
      >
        <IconButton
          title="Table Settings"
        >
          <i className="material-icons">settings</i>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(event) => {
          if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
          ) {
            return;
          }
          setAnchorEl();
        }}
      >
        <div style={{ minWidth: 360 }}>
          <div className="d-flex flex-align-left-center px-1 col sticky-top bg-white">
            <label className="m-0">Table Settings</label>
            <Loader loading={loading} text="" />
            <IconButton
              size="small"
              onClick={(event) => {
                let defaultBookmark = bookmarks.find(
                  ({ label }) => (label === getPathname())
                );
                if (defaultBookmark) {
                  upsertBookmark({
                    bookmark: {
                      ...defaultBookmark,
                      hiddenColumns: hiddenColumns.map(({ id }) => id),
                      columnOrder: visibleColumns.map(({ id }) => id),
                    },
                  });
                } else {
                  upsertBookmark({
                    bookmark: {
                      ...getBookmarkModel(),
                      label: getPathname(),
                      pathname: getPathname(),
                      search: getSearch(),
                      hiddenColumns: hiddenColumns.map(({ id }) => id),
                      columnOrder: visibleColumns.map(({ id }) => id),
                    },
                  });
                }
              }}
              title="Save Configuration"
              className={updates ? 'bg-yellow-light ml-auto' : 'ml-auto'}
            >
              <i className="material-icons">save</i>
            </IconButton>
          </div>
          <div className="p-1">
            <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} className="mr-1" />
            Toggle All
          </div>
          <div className="d-flex">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="visible">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    className="col"
                  >
                    <div className="c-black-medium p-1">Visible</div>
                    {visibleColumns.map((column, index) => (
                      <Draggable
                        key={column.id}
                        draggableId={column.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {typeof column.Header === 'string'
                              ? column.Header
                              : column.id}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <Droppable droppableId="hidden">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    className="col"
                  >
                    <div className="c-black-medium p-1">Hidden</div>
                    {hiddenColumns.map((column, index) => (
                      <Draggable
                        key={column.id}
                        draggableId={column.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {typeof column.Header === 'string'
                              ? column.Header
                              : column.id}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <pre
            className="pointer"
            onClick={(event) =>
              copyString(JSON.stringify(latestQuery, null, 2))
            }
          >
            <code>{JSON.stringify(latestQuery, null, 2)}</code>
          </pre>

          <div className="d-none">
            <div>
              <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />{' '}
              Toggle All
            </div>
            {allColumns.map((column) => (
              <div key={`toggle-hide-show-column-${column.id}`}>
                <label>
                  <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                  {typeof column.Header === 'string'
                    ? column.Header
                    : column.id}
                </label>
              </div>
            ))}
          </div>
        </div>
      </Menu>
    </div>
  );
}

export { ColumnsSettings, ColumnsSettings as default };
