import Box from '../../Layout/Wrappers/Box';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import { Label } from '../../Layout/Wrappers/Typography';
import stringToChips from '../Libraries/Tools/stringToChips.tool';

export default function WordChips({
  acl = true,
  label = '',
  words = '',
  inline = false,
}) {
  if (!acl) { return null; }
  return (
    (
      inline
    ) ? (
      <Box row flex1 noWrap scrollX
        role='Fieldset'
        className='flex-align-left-top my-05 px-1'
      >
        <Label className='w-auto'>
          {label}:
        </Label>
        <Box row wrap flex1>
          {stringToChips(words)}
        </Box>
      </Box>
    ) : (
      <Fieldset row
        title={label}
      >
        {stringToChips(words)}
      </Fieldset>
    )
  );
};