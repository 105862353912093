import {
  Select
} from "antd";
import React from "react";

function SingleSelectDropdown({
  open = false,
  autoFocus = false,
  name,
  data = [],
  placeholder = 'Select one item',
  value,
  defaultValue,
  onChange = () => null,
  onSearch = () => null,
  onClear = () => null,
  onBlur = () => null,
  onFocus = () => null,
  onDropdownVisibleChange = () => null,
  renderLabel = (option) => (option.label),
  ...props
}) {


  const [_value, setValue] = React.useState(defaultValue);

  const children = data.map((option, index) => (
    <Select.Option
      key={`option+${name}.${option.id}(required-by-react)`}
      value={option.id}
    >
      {renderLabel(option)}
    </Select.Option>
  ));

  function _onChange(value, option) {
    console.debug('CHANGE', value, option);
    setValue(value);
    onChange(value, option);
  }

  function _onBlur() {
    console.debug('BLUR');
    onBlur();
  }

  function _onFocus() {
    console.debug('FOCUS');
    onFocus();
  }

  function _onSearch(value) {
    console.debug('SEARCH', value);
    onSearch(value);
  }

  function _onClear() {
    console.debug('CLEAR');
    onClear();
  }

  function _onDropdownVisibleChange(open) {
    onDropdownVisibleChange(open);
  }

  return (
    <Select
      open={open}
      autoFocus={autoFocus}
      autocomplete={`new-${name}`}
      internalProps={{ autocomplete: `new-${name}` }}
      showSearch
      allowClear
      style={{ width: "100%", textAlign: "left" }}
      placeholder={placeholder}
      value={value || _value}
      onChange={_onChange}
      onFocus={_onFocus}
      onBlur={_onBlur}
      onSearch={_onSearch}
      onClear={_onClear}
      onDropdownVisibleChange={_onDropdownVisibleChange}
      dropdownStyle={{ borderRadius: 4, zIndex: 1301 }}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {children}
    </Select>
  );

}

/* EXPORTS ==================================== */

export {
  SingleSelectDropdown as default,
  SingleSelectDropdown
};

/* ============================================ */
