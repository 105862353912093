import React, {
  Component
} from "react";
import NewCategoryDropdown from '../Dropdowns/NewCategoryDropdown';

class TextElement extends Component {

  constructor() {
    super(...arguments);
    this.state = {
      element: this.props.element
    }
  }

  handlerValue = (ev) => {
    const { element } = this.state;
    element.value = ev.target.value;
    this.setState({ element }, () => {
      const { onApply } = this.props;
      !!onApply && onApply(element);
    });
  }

  handlerCategory = (category) => {

    let { element } = this.state;
    element.category = category;
    this.setState({ element }, () => {
      const { onApply } = this.props;
      !!onApply && onApply(element);
    });
  }

  render() {
    let { element } = this.state;
    let { onlyView } = this.props;

    return <>
      {!onlyView && !!element.value && <input className="fc-value-editor" value={element.value} onChange={(ev) => this.handlerValue(ev)} />}&nbsp;
      {!!onlyView && <span>{element.value}</span>}&nbsp;
      {!!element.category &&
        <NewCategoryDropdown
          category={element.category}
          handlerCategory={this.handlerCategory}
          onlyView={onlyView}
        />
      }
    </>
  }
}

export default TextElement;
