import {
  compile
} from 'handlebars';
import moment from 'moment';
import AppUI from '../../../dictionaries/AppUI.dic';
import {
  Arr,
  join
} from '../../../lib/Array.lib';
import {
  PLAINTEXT__LOG__SEPARATOR,
  TIMEZONE__LA
} from '../../../lib/Constants';
import {
  ENGAGEMENT__FEEDBACK_LOG,
  ENGAGEMENT__STATE_CLOSED,
  ENGAGEMENT__STATE_OPEN,
  REJECTION_REASON__10X10__BAD_MATCH,
  REJECTION_REASON__EMPLOYER__NOT_INTERESTED,
  STAGE_OFFER,
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STATUS_E_10X10,
  STATUS_E_EMPLOYER,
  STATUS_W_10X10_ASK_PERMISSION,
  STATUS_W_CANDIDATE_SCHEDULE,
  STATUS_W_CANDIDATE_SUBMIT_HOMEWORK,
  STATUS_W_EMPLOYER_FEEDBACK,
  STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK,
  STATUS_W_EMPLOYER_SCHEDULE,
  STATUS_W_EMPLOYER_SEND_HOMEWORK,
  STATUS_W_ONSITE,
  STATUS_W_SCREEN
} from '../../../dictionaries/Engagement.dic';
import Core from '../../../lib/Core';
import {
  DATE_FORMAT__DATETIME__US_24H
} from '../../../lib/Date.lib';
import {
  REC_MSG_TYPE__REJECTION_ID,
  REC_MSG_TYPE__STAGE_TRANSITION_ID
} from '../../../lib/Definition';
import Engagement from '../../../lib/Engagement';
import {
  getHTMLLinkString
} from '../../../lib/HTML.lib';
import {
  prependJobFeedbackLog
} from '../../../lib/Job';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  COLLECTION__ENGAGEMENTS,
  readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import {
  combineEmailsLists,
  mapEmailsListToString,
  sendSafeEmail
} from '../../../lib/services/Email/Email.lib';
import {
  generateRejectionEmailContent,
  generateStageTransitionEmailInputs
} from '../../../lib/services/Email/EmailRecruiter.lib';
import {
  Str,
  compileText,
  isNonEmptyString,
  trim
} from '../../../lib/String.lib';
import TemplateLib from '../../../lib/Template.lib';
import {
  TEMPLATE__EMPLOYER_PENDINGS__ACCEPTANCE_INTERVIEW__SUBJECT__TO_CANDIDATE,
  TEMPLATE__EMPLOYER_PENDINGS__ACCEPTANCE_INTERVIEW__TO_10X10,
  TEMPLATE__EMPLOYER_PENDINGS__ACCEPTANCE_INTERVIEW__TO_CANDIDATE,
  TEMPLATE__EMPLOYER_PENDINGS__CALIBRATION_ACCEPTANCE__TO_10X10,
  TEMPLATE__EMPLOYER_PENDINGS__CALIBRATION_REJECTION__TO_10X10,
  TEMPLATE__EMPLOYER_PENDINGS__FEEDBACK__SUBJECT,
  TEMPLATE__EMPLOYER_PENDINGS__ONLY_FEEDBACK__TO_10X10,
  TEMPLATE__EMPLOYER_PENDINGS__REJECTION_INTERVIEW__TO_10X10,
  TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__FIRST_RENDER__BODY,
  TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__FIRST_RENDER__SUBJECT,
  TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__USER_VIEWING__BODY,
  TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__USER_VIEWING__SUBJECT
} from '../../../lib/templates/EmployerPendings.templates';
import {
  getMatchDecisionsForEmail
} from '../../Match/Libraries/MatchDecisions.lib';
import {
  EmployerPendings_updateState
} from './EmployerPendings';
import {
  NEXT_STEP_ADDITIONAL__INTERVIEWED_DECISION_TBD,
  NEXT_STEP_ADDITIONAL__INTERVIEW_BOOKED,
  NEXT_STEP_ADDITIONAL__NEED_HOMEWORK_BACK,
  NEXT_STEP_ADDITIONAL__NEED_TIME_FROM_CANDO,
  NEXT_STEP_ADDITIONAL__TO_ASK_FOR_INTERVIEW,
  NEXT_STEP_ADDITIONAL__TO_GRADE_HOMEWORK,
  NEXT_STEP_ADDITIONAL__TO_SEND_HOMEWORK,
  NEXT_STEP__CXO_INTERVIEW, NEXT_STEP__FINAL_ROUND_INTERVIEW,
  NEXT_STEP__LIVE_CODING, NEXT_STEP__ONSITE_INTERVIEW,
  NEXT_STEP__VERBAL_OFFER, NEXT_STEP__WRITTEN_OFFER,
  getAcceptAdditionalPresets,
  getAcceptPresets
} from './NextSteps.lib';
import {
  getRejectionReasonPresets,
  mapRejectionReasonToStreakValue
} from './RejectionReasons.lib';

export const ACTION_TYPE__NO_ACTION = '';
export const ACTION_TYPE__FEEDBACK = 'Update';
export const ACTION_TYPE__ACCEPT_STRONG = 'Strong Yes';
export const ACTION_TYPE__ACCEPT_WEAK = 'Weak Yes';
export const ACTION_TYPE__ACCEPT = 'Yes';
export const ACTION_TYPE__REJECT_STRONG = 'Strong No';
export const ACTION_TYPE__REJECT_WEAK = 'Weak No';
export const ACTION_TYPE__REJECT = 'No';
export const ACTION_TYPE__OTHER = 'other';
export const ACTION_TYPE__VIEW_CANDIDATE = 'view_candidate';
export const ACTION_TYPE__UPDATE = 'Update';

export const ACTION_SOURCE__ADMIN = '10x10 admin';
export const ACTION_SOURCE__ATS = 'ATS';
export const ACTION_SOURCE__ENGAGEMENT = 'Manual';
export const ACTION_SOURCE__PENDING = '10x10 site';

export const EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK = 'calibration_feedback';
export const EMPLOYER__PROCESSING_MODEL_KEY__OFFER_IN_PROGRESS = 'offer_in_progress';
export const EMPLOYER__PROCESSING_MODEL_KEY__ONSITE_SCHEDULE = 'onsite_schedule';
export const EMPLOYER__PROCESSING_MODEL_KEY__ONSITE_FEEDBACK = 'onsite_feedback';
export const EMPLOYER__PROCESSING_MODEL_KEY__SCREEN_SCHEDULE = 'screen_schedule';
export const EMPLOYER__PROCESSING_MODEL_KEY__SCREEN_FEEDBACK = 'screen_feedback';
export const EMPLOYER__PROCESSING_MODEL_KEY__HOMEWORK_TO_SEND = 'homework_to_send';
export const EMPLOYER__PROCESSING_MODEL_KEY__HOMEWORK_TO_REVIEW = 'homework_to_review';
export const EMPLOYER__PROCESSING_MODEL_KEY__CV_FEEDBACK = 'cv_feedback';
export const EMPLOYER__PROCESSING_MODEL_KEY__SCREEN_STAGE = 'screen_stage';
export const EMPLOYER__PROCESSING_MODEL_KEY__ONSITE_STAGE = 'onsite_stage';

export function sendFeedback(options) {
  let {
    actionType = ACTION_TYPE__FEEDBACK,
    selected
  } = options;
  const isForCalibration = (selected.engagementKey && (selected.engagementKey === EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK));
  return async event => {
    await setLoadingFlag({ loading: true, context: options, stateUpdates: { actionType } });
    if (!!String(actionType || '').match(ACTION_TYPE__ACCEPT)) {
      if (isForCalibration) {
        await sendAcceptanceCalibrationEmail(options);
      }
      else {
        await sendAcceptanceInterviewEmail(options);
      }
    }
    if (!!String(actionType || '').match(ACTION_TYPE__REJECT)) {
      if (isForCalibration) {
        await sendRejectionCalibrationEmail(options);
      }
      else {
        await sendRejectionInterviewEmail(options);
      }
    }
    else if (actionType === ACTION_TYPE__FEEDBACK) {
      await sendOnlyFeedbackEmail(options);
    }
    await setLoadingFlag({ loading: false, context: options });
  }
}

export async function setLoadingFlag({ context, loading, stateUpdates }) {
  let {
    selected,
    feedbacks,
  } = context;
  if (selected.id) {
    feedbacks[selected.id] = {
      ...(feedbacks[selected.id] || {}),
      loading
    };
    return EmployerPendings_updateState({ feedbacks, ...(stateUpdates || {}) });
  }
  return null;
}

export async function onFeedbackSent({ context, emailSent }) {
  let {
    selected,
    feedbacks,
  } = context;
  if (selected.id) {
    feedbacks[selected.id] = {
      ...(feedbacks[selected.id] || {}),
      sent: emailSent,
      pendingsCounter: selected.pendingsCounter
    };
    selected.thread = emailSent;
    EmployerPendings_updateState({ feedbacks, actionType: ACTION_TYPE__NO_ACTION });
  }
}

function getFeedbackSubject(options) {
  let { actionType, selected = {}, employer, previousStage } = options;
  let { recruiter = {} } = selected;
  actionType = String(actionType || '');
  const isForCalibration = selected.engagementKey && (
    selected.engagementKey ===
    EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK
  );
  const isAccepted = !!actionType.match(ACTION_TYPE__ACCEPT);
  const isRejected = !!actionType.match(ACTION_TYPE__REJECT);
  const isReview = (previousStage === STAGE_REVIEW);
  return compile(TEMPLATE__EMPLOYER_PENDINGS__FEEDBACK__SUBJECT)({
    ACTION_TYPE: (isForCalibration
      ? `Calibration: ${actionType}`
      : actionType
    ),
    CANDIDATE__FULLNAME: selected.candidate._name || '[candidate]',
    RECRUITER__FULLNAME: recruiter._name ? ` (${recruiter._name})` : '',
    EMPLOYER__NAME: employer.name || '[employer]',
    JOB_TITLE: selected.job.jobTitle || '[job]',
    STAGE: selected.stage || '[stage]',
    ACTION_TYPE__REJECT: isRejected,
    ACTION_TYPE__ACCEPT: isAccepted,
    STAGE__REVIEW: isReview,
    USER__NAME: Core.getUserName() || '[user]',
    ADMIN: Core.isAdmin()
  });

}

function getViewEngagementHTML({ engagementId }) {
  return getHTMLLinkString({
    url: Core.getPath(
      `engagement/view/${engagementId}`
    ),
    name: 'View Engagement'
  });
}

function getPendingsLinkHTML({ employerId, engagementId }) {
  return getHTMLLinkString({
    url: Core.getPath(
      `employer/pendings/?employerId=${employerId}&engagementId=${engagementId}`
    ),
    name: 'Employer Pendings'
  });
}

export function openEmployerPendings({ employerId, engagementId, jobId }) {
  const path = Core.getPath(join([
    `employer/pendings/?employerId=${employerId}`,
    engagementId && `&engagementId=${engagementId}`,
    jobId && `&jobId=${jobId}`
  ], ''));
  return Core.openPopUp(path, 1600);
}

export async function sendAcceptanceCalibrationEmail(options) {
  let {
    actionType = ACTION_TYPE__ACCEPT,
    selected,
    feedback = {}
  } = options;

  // UPDATING engagement for "TU" calibration
  let update = {
    state: ENGAGEMENT__STATE_OPEN,
    status: STATUS_W_10X10_ASK_PERMISSION,
    closed: ''
  };
  await Engagement.update(selected, update);
  Object.assign(selected, update);

  // SENDING "TU" calibration email to 10x10
  let emailSent = await sendSafeEmail({
    from: Core.getNewCandidateReceiverNotiFromEmail(),
    to: Core.getNewCandidateReceiverNotiToEmail(),
    boxKey: selected.boxKey,
    subject: getFeedbackSubject(options),
    html: compile(TEMPLATE__EMPLOYER_PENDINGS__CALIBRATION_ACCEPTANCE__TO_10X10)({
      FEEDBACK: trim(feedback.text).replace(/\n/g, '<br/>'),
      MATCH_INFO: await getMatchDecisionsForEmail({
        engagement: selected,
        accepted: true
      }),
      ENGAGEMENT_LINK: getViewEngagementHTML({
        engagementId: selected.id
      }),
      PENDINGS_LINK: getPendingsLinkHTML({
        employerId: selected.employerId,
        engagementId: selected.id
      })
    })
  });
  if (!!emailSent) {
    emailSent.actionType = actionType;
    onFeedbackSent({ context: options, emailSent });
  }

  updateFeedbackLog({ selected, feedback, actionType, stage: selected.stage });

}

async function sendRejectionCalibrationEmail(options) {
  let {
    actionType = ACTION_TYPE__REJECT,
    selected,
    feedback
  } = options;

  let fullPresets = getRejectionReasonPresets().flat();
  let presets = [...(feedback.presets || [])].filter(v => !!fullPresets.includes(v));

  const feedbackText = trim(feedback.text);

  // [2023-12-12][story_441] For "Calibration"
  // presets will be just used for email and rejectionReasonAdditionalInfo.
  // set Engagement.rejectionReason to "10x10 Bad Match" streak value.
  let rejectionReason = (
    mapRejectionReasonToStreakValue({ value: REJECTION_REASON__10X10__BAD_MATCH }) ||
    REJECTION_REASON__EMPLOYER__NOT_INTERESTED
  );

  // UPDATING engagement for "TD" calibration
  let now = moment().tz(TIMEZONE__LA).toISOString();
  let update = {
    state: ENGAGEMENT__STATE_CLOSED,
    status: STATUS_E_10X10,
    rejectionReason,
    rejectionReasonAdditionalInfo: join([join(presets, ', '), feedbackText], ': '),
    closed: now
  };
  await Engagement.update(selected, update);
  Object.assign(selected, update);

  // SENDING "TD" calibration email to 10x10
  let emailSent = await sendSafeEmail({
    from: Core.getNewCandidateReceiverNotiFromEmail(),
    to: Core.getNewCandidateReceiverNotiToEmail(),
    boxKey: selected.boxKey,
    subject: getFeedbackSubject(options),
    html: compile(TEMPLATE__EMPLOYER_PENDINGS__CALIBRATION_REJECTION__TO_10X10)({
      REJECTION_REASONS: join(presets) || REJECTION_REASON__EMPLOYER__NOT_INTERESTED,
      FEEDBACK: trim(feedback.text).replace(/\n/g, '<br/>'),
      MATCH_INFO: await getMatchDecisionsForEmail({
        engagement: selected,
        rejected: true
      }),
      ENGAGEMENT_LINK: getViewEngagementHTML({
        engagementId: selected.id
      }),
      PENDINGS_LINK: getPendingsLinkHTML({
        employerId: selected.employerId,
        engagementId: selected.id
      })
    })
  });
  if (!!emailSent) {
    emailSent.actionType = actionType;
    onFeedbackSent({ context: options, emailSent });
  }

  updateFeedbackLog({ selected, feedback, actionType, stage: selected.stage });

}

async function sendOnlyFeedbackEmail(options) {
  let {
    actionType = ACTION_TYPE__FEEDBACK,
    selected,
    feedback,
  } = options;
  if (!feedback.text) {
    return Core.showWarning('Type some feedback to share with us');
  }
  let { stage } = selected;
  let context = {
    ...options,
    // we need to preserve current selected in context since user can go to select a diff one before process done
    selected
  };

  // TO 10BY10 "UPDATE"
  let emailSent = await sendSafeEmail({
    from: Core.getNewCandidateReceiverNotiFromEmail(),
    to: Core.getNewCandidateReceiverNotiToEmail(),
    boxKey: selected.boxKey,
    subject: getFeedbackSubject(options),
    html: compile(TEMPLATE__EMPLOYER_PENDINGS__ONLY_FEEDBACK__TO_10X10)({
      FEEDBACK: trim(feedback.text).replace(/\n/g, '<br/>'),
      MATCH_INFO: await getMatchDecisionsForEmail({
        engagement: selected
      }),
      ENGAGEMENT_LINK: getViewEngagementHTML({
        engagementId: selected.id
      }),
      PENDINGS_LINK: getPendingsLinkHTML({
        employerId: selected.employerId,
        engagementId: selected.id
      })
    })
  });
  if (!!emailSent) {
    emailSent.actionType = actionType;
    onFeedbackSent({ context, emailSent });
  }

  updateFeedbackLog({ selected, feedback, actionType, stage });

}

async function sendRejectionInterviewEmail(options) {
  let {
    actionType = ACTION_TYPE__REJECT,
    selected,
    feedback
  } = options;
  let { stage } = selected;
  let emailContent = Obj(
    await generateRejectionEmailContent({ engagement: selected })
  );
  let fullPresets = getRejectionReasonPresets().flat();
  let presets = [...(feedback.presets || [])].filter(v => !!fullPresets.includes(v));

  let rejectionReason = mapRejectionReasonToStreakValue({ value: presets[0] }) || REJECTION_REASON__EMPLOYER__NOT_INTERESTED;
  let now = moment().tz(TIMEZONE__LA).toISOString();

  let update = {
    state: ENGAGEMENT__STATE_CLOSED,
    status: STATUS_E_EMPLOYER,
    rejectionReason,
    rejectionReasonAdditionalInfo: (`
      ${presets.join(', ')}
      ${String(feedback.text || '').trim() ? `: ${feedback.text}` : ``}
    `),
    closed: now
  };
  if (stage === STAGE_REVIEW) { update.reviewed = now; }
  else if (stage === STAGE_SCREEN) { update.screened = now; }
  else if (stage === STAGE_ONSITE) { update.onsite = now; }

  await Engagement.update(selected, update);
  Object.assign(selected, update);

  const rejectionReasons = trim(
    join(presets) || REJECTION_REASON__EMPLOYER__NOT_INTERESTED
  );

  // TO 10BY10 "TD"
  let emailSent = await sendSafeEmail({
    from: Core.getNewCandidateReceiverNotiFromEmail(),
    to: Core.getNewCandidateReceiverNotiToEmail(),
    boxKey: selected.boxKey,
    subject: getFeedbackSubject(options),
    html: compile(TEMPLATE__EMPLOYER_PENDINGS__REJECTION_INTERVIEW__TO_10X10)({
      REJECTION_REASONS: rejectionReasons,
      FEEDBACK: trim(feedback.text).replace(/\n/g, '<br/>'),
      MATCH_INFO: await getMatchDecisionsForEmail({
        engagement: selected,
        rejected: true
      }),
      ENGAGEMENT_LINK: getViewEngagementHTML({
        engagementId: selected.id
      }),
      PENDINGS_LINK: getPendingsLinkHTML({
        employerId: selected.employerId,
        engagementId: selected.id
      }),
      BODY: emailContent.body
    })
  });
  if (!!emailSent) {
    emailSent.actionType = actionType;
    onFeedbackSent({ context: options, emailSent });
  }

  // TO CANDIDATE
  sendEmailToCandidate({
    candidateId: selected.candidateId,
    subject: emailContent.subject,
    body: emailContent.body,
    messageTypes: [REC_MSG_TYPE__REJECTION_ID]
  });

  updateFeedbackLog({ selected, feedback, actionType, stage });

}

export async function sendAcceptanceInterviewEmail(options) {
  let {
    actionType = ACTION_TYPE__ACCEPT,
    selected,
    employer,
    feedback = {}
  } = options;
  let { stage: currentStage, nextScreenLabel, nextOnsiteLabel, employerId } = selected;
  let { presets = [], additionalPresets = [] } = feedback;
  let fullPresets = getAcceptPresets().flat();
  presets = [...(feedback.presets || [])].filter(v => !!fullPresets.includes(v));
  let prev = {
    engagementId: selected.id + '',
    eventType: String(selected.stage || '').toLowerCase(),
    previousStage: selected.stage + '',
    previousStatus: selected.status + ''
  };

  let now = moment().tz(TIMEZONE__LA).toISOString();
  let update = {
    state: ENGAGEMENT__STATE_OPEN,
    status: STATUS_W_EMPLOYER_SCHEDULE,
    closed: ''
  };

  function _mapStage() {
    if (
      presets.includes(NEXT_STEP__ONSITE_INTERVIEW) ||
      presets.includes(NEXT_STEP__FINAL_ROUND_INTERVIEW)
    ) {
      update.stage = STAGE_ONSITE;
      update.reviewed = update.reviewed || now;
      update.screened = update.screened || now;
    }
    if (
      !!presets.find(v => [
        NEXT_STEP__VERBAL_OFFER,
        NEXT_STEP__WRITTEN_OFFER
      ].includes(v))
    ) {
      update.stage = STAGE_OFFER;
      update.reviewed = update.reviewed || now;
      update.screened = update.screened || now;
      update.onsite = update.onsite || now;
    }
  }

  function _mapStatus() {
    if (additionalPresets.includes(NEXT_STEP_ADDITIONAL__TO_ASK_FOR_INTERVIEW)) {
      update.status = STATUS_W_EMPLOYER_SCHEDULE;
    }
    else if (additionalPresets.includes(NEXT_STEP_ADDITIONAL__INTERVIEW_BOOKED)) {
      update.status = (
        (update.stage === STAGE_SCREEN)
          ? STATUS_W_SCREEN
          : (update.stage === STAGE_ONSITE)
            ? STATUS_W_ONSITE
            : update.status
      );
    }
    else if (additionalPresets.includes(NEXT_STEP_ADDITIONAL__NEED_TIME_FROM_CANDO)) {
      update.status = STATUS_W_CANDIDATE_SCHEDULE;
    }
    else if (additionalPresets.includes(NEXT_STEP_ADDITIONAL__INTERVIEWED_DECISION_TBD)) {
      update.status = STATUS_W_EMPLOYER_FEEDBACK;
      if ((currentStage === STAGE_SCREEN) && isNonEmptyString(nextScreenLabel)) {
        update[nextScreenLabel] = moment().tz(TIMEZONE__LA).toISOString();
      }
      else if ((currentStage === STAGE_ONSITE) && isNonEmptyString(nextOnsiteLabel)) {
        update[nextOnsiteLabel] = moment().tz(TIMEZONE__LA).toISOString();
      }
    }
    else if (additionalPresets.includes(NEXT_STEP_ADDITIONAL__TO_SEND_HOMEWORK)) {
      update.status = STATUS_W_EMPLOYER_SEND_HOMEWORK;
    }
    else if (
      additionalPresets.includes(NEXT_STEP_ADDITIONAL__NEED_HOMEWORK_BACK)
    ) {
      update.status = STATUS_W_CANDIDATE_SUBMIT_HOMEWORK;
    }
    else if (
      additionalPresets.includes(NEXT_STEP_ADDITIONAL__TO_GRADE_HOMEWORK)
    ) {
      update.status = STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK;
    }
  }

  // CASE stage review
  if (currentStage === STAGE_REVIEW) {

    update.stage = STAGE_SCREEN;
    update.status = STATUS_W_EMPLOYER_SCHEDULE;
    update.reviewed = now;
    update.screened = '';
    update.onsite = '';

    prev.eventType = String(STAGE_SCREEN).toLowerCase();

  }
  else if ((currentStage === STAGE_SCREEN) || presets.includes(NEXT_STEP__LIVE_CODING)) {
    // update.status = STATUS_W_SCREEN;
    update.screened = '';
    update.onsite = '';
  }
  else if (
    (currentStage === STAGE_ONSITE) ||
    presets.includes(NEXT_STEP__CXO_INTERVIEW) ||
    presets.includes(NEXT_STEP__FINAL_ROUND_INTERVIEW)
  ) {
    // update.status = STATUS_W_ONSITE;
    update.onsite = '';
  }

  _mapStage();
  _mapStatus();

  await Engagement.update(selected, update);
  Object.assign(selected, update);

  let emailContent = await generateStageTransitionEmailInputs(prev);

  // TO 10BY10 "TU"
  let emailSent = await sendSafeEmail({
    from: Core.getNewCandidateReceiverNotiFromEmail(),
    to: Core.getNewCandidateReceiverNotiToEmail(),
    boxKey: selected.boxKey,
    subject: getFeedbackSubject({ ...prev, ...options }),
    html: compile(TEMPLATE__EMPLOYER_PENDINGS__ACCEPTANCE_INTERVIEW__TO_10X10)({
      NEXT_STEPS: join(presets),
      ADDITIONAL_STEPS: join(feedback.additionalPresets),
      FEEDBACK: trim(feedback.text),
      MATCH_INFO: await getMatchDecisionsForEmail({
        engagement: selected,
        accepted: true
      }),
      ENGAGEMENT_LINK: getViewEngagementHTML({ engagementId: selected.id }),
      PENDINGS_LINK: getPendingsLinkHTML({ employerId, engagementId: selected.id }),
      BODY: emailContent.body
    })
  });
  if (!!emailSent) {
    emailSent.actionType = actionType;
    onFeedbackSent({ context: options, emailSent });
  }

  // TO CANDIDATE
  sendEmailToCandidate({
    candidateId: selected.candidateId,
    subject:
      compileText(
        TEMPLATE__EMPLOYER_PENDINGS__ACCEPTANCE_INTERVIEW__SUBJECT__TO_CANDIDATE,
        {
          CANDIDATE__NAME: selected.candidateName || '[candidate]',
          EMPLOYER__NAME: employer.name || '[employer]',
          JOB_TITLE: selected.job.jobTitle ? ` (${selected.job.jobTitle})` : ``,
          NEXT_STEP: presets[0] ? ` (Next step: ${presets[0]})` : ''
        }
      ),
    body: compile(TEMPLATE__EMPLOYER_PENDINGS__ACCEPTANCE_INTERVIEW__TO_CANDIDATE)({
      NEXT_STEPS: join(presets),
      BODY: emailContent.body
    }),
    messageTypes: [REC_MSG_TYPE__STAGE_TRANSITION_ID]
  });

  updateFeedbackLog({ selected, feedback, actionType, stage: currentStage });

}

export async function sendViewCandidateEmail(options) {
  let {
    selected = {},
    employer,
    firstRender
  } = options;
  const subjectTemplate10x10 = (firstRender
    ? TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__FIRST_RENDER__SUBJECT
    : TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__USER_VIEWING__SUBJECT
  );
  const bodyTemplate = (firstRender
    ? TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__FIRST_RENDER__BODY
    : TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__USER_VIEWING__BODY
  )
  let recruiter = selected.recruiter || {};
  let now = moment().tz(TIMEZONE__LA).format('HH:mm (z)');
  let candidate = selected.candidate;
  const employerName = employer.name || '[employer]';
  const candidateName = candidate._name || `[candidate]`;
  const userName = Core.getUserName() || `[user]`;
  const recruiterName = recruiter._name ? ` (${recruiter._name})` : '';
  if (subjectTemplate10x10) {
    await sendSafeEmail({
      from: Core.getNewCandidateReceiverNotiFromEmail(),
      to: Core.getNewCandidateReceiverNotiToEmail(),
      subject: compile(subjectTemplate10x10)({
        EMPLOYER__NAME: employerName,
        USER__NAME: userName,
        CANDIDATE__FULLNAME: candidateName,
        RECRUITER__FULLNAME: recruiterName,
        NOW: now
      }),
      html: compile(bodyTemplate)({
        ENGAGEMENT_LINK: getViewEngagementHTML({
          engagementId: selected.id
        }),
        PENDINGS_LINK: getPendingsLinkHTML({
          employerId: selected.employerId,
          engagementId: selected.id
        }),
        USER__NAME: userName,
        EMPLOYER__NAME: employerName,
        CANDIDATE__FULLNAME: candidateName
      })
    });
  }
}

async function sendEmailToCandidate({ candidateId, subject, body, messageTypes = [] }) {
  if (!candidateId || !Array.isArray(messageTypes) || !messageTypes.length) { return; }
  let {
    from = '',
    to = [],
    cc = [],
    bcc = []
  } = {};
  const renderCandidate = await TemplateLib.getRender({
    templateName: 'candidate-message',
    candidateId,
    messageTypes
  });
  if (renderCandidate?.mixins) {
    from = renderCandidate.mixins.from;
    to = combineEmailsLists(to, renderCandidate.mixins.to || []);
    cc = combineEmailsLists(cc, renderCandidate.mixins.cc || []);
    bcc = combineEmailsLists(bcc, renderCandidate.mixins.bcc || []);
    // console.debug(mapEmailsListToString(to), { from, to, cc, bcc });
  }
  return sendSafeEmail(
    {
      from,

      /** @todo [ 2022-10-26 ][ MS: beta period test email ] */
      // to: `recruiter+test@10by10.io`,
      to: mapEmailsListToString(to),
      cc: mapEmailsListToString(cc),
      bcc: mapEmailsListToString(bcc),
      subject,
      html: body
    }
  );
}

/**
 * 
 * @param {object} options 
 * @param {object} options.selected pending page selected object | partial engagement object
 * @param {object} options.feedback {presets,additionalPresets,text,pendingsCounter,loading,sent}
 * @param {string} options.actionType
 * @param {string} options.stage this may be diff from selected.stage, for that reason it is a separated param.
 * @returns {void}
 */
async function updateFeedbackLog(options) {
  try {
    let { selected = {}, feedback = {}, actionType: ACTION, actionType, stage } = options;
    actionType = Str(actionType);
    const { id, jobId = '' } = selected;
    const _isAccepted = actionType.match(ACTION_TYPE__ACCEPT);
    const _isRejected = actionType.match(ACTION_TYPE__REJECT);
    const USER = Core.getUserName();
    const PRESETS = join(
      [...Arr(feedback.presets), ...Arr(feedback.additionalPresets)].filter((preset) => (
        _isAccepted ? [
          ...getAcceptPresets().flat(),
          ...getAcceptAdditionalPresets({
            preset: Arr(feedback.presets)[0]
          })
        ].includes(preset)
          : _isRejected ? getRejectionReasonPresets().flat().includes(preset)
            : false
      ))
    );
    const NOTE = Str(feedback.text).replace(/\n/g, ' ').trim();
    await prependJobFeedbackLog({
      jobId,
      context: {
        ACTION,
        SOURCE: ACTION_SOURCE__PENDING,
        CANDIDATE__NAME: selected.candidateName,
        USER,
        PRESETS,
        NOTE
      },
      stage
    });
    selected[ENGAGEMENT__FEEDBACK_LOG] = Obj(
      await prependEngagementFeedbackLog({
        engagementId: id,
        context: {
          ACTION,
          USER,
          PRESETS,
          NOTE
        },
      })
    )[ENGAGEMENT__FEEDBACK_LOG];
    EmployerPendings_updateState({ selected });
  }
  catch (error) {
    Core.showError(error);
  }
}

export function removeContactInfoFromSubmissionNote(submissionNote) {
  submissionNote = trim(submissionNote);
  submissionNote = submissionNote.replace(/<ul>/gi, '<ul>\n');
  submissionNote = submissionNote.replace(/<\/li>/gi, '</li>\n');
  submissionNote = join(
    submissionNote.split('\n').filter(line => !!trim(line) && !Str(line).match(/phone|email|calendar link/i)),
    ''
  );
  return submissionNote;
}

/**
 *
 * @param {object} options
 * @param {string} options.engagementId
 * @param {object} options.context
 * @param {string} options.context.ACTION  [plaintext][required][option: ACTION_TYPE__ACCEPT | ACTION_TYPE__REJECT,...]
 * @param {string} options.context.USER  [plaintext][required]
 * @param {string} options.context.PRESETS  [plaintext][optional]
 * @param {string} options.context.NOTE  [plaintext][optional]
 * @returns {*} results  [object:engagement]
 */
export async function prependEngagementFeedbackLog(options) {
  console.debug('prependEngagementFeedbackLog', options);
  try {
    const { engagementId, context } = options;
    const engagement = await readLoopbackRecord({
      collection: COLLECTION__ENGAGEMENTS,
      where: { id: engagementId },
      fields: ['id', ENGAGEMENT__FEEDBACK_LOG],
      limit: 1
    });
    const _isAccepted = Str(context.ACTION).match(ACTION_TYPE__ACCEPT);
    const _isRejected = Str(context.ACTION).match(ACTION_TYPE__REJECT);
    const _note = Str(context.NOTE).replace(/\n/g, ' ').trim();
    const _presets = trim(context.PRESETS);
    const _entry = compileText('{{ACTION}} - {{USER}} | {{DATE_TIME}}{{PRESETS}}{{NOTE}}', {
      ...context,
      DATE_TIME: `${(
        moment.tz(TIMEZONE__LA).format(DATE_FORMAT__DATETIME__US_24H)
      )} PT`,
      PRESETS: _presets ? (
        _isAccepted ? `\nNext step: ${_presets}`
          : _isRejected ? `\nReasons: ${_presets}` : ''
      ) : '',
      NOTE: _note ? `\nNote: ${_note}` : '',
    })
    const update = {
      [ENGAGEMENT__FEEDBACK_LOG]: join(
        [_entry, trim(engagement[ENGAGEMENT__FEEDBACK_LOG])],
        PLAINTEXT__LOG__SEPARATOR
      )
    };
    return await Engagement.update(engagement, update);
  }
  catch (error) {
    if (Core.isProduction()) {
      Core.showError(AppUI.unexpectedError.message, error);
    }
    else {
      Core.showError(error);
    }
    return 'prependEngagementFeedbackLog:NO entry added';
  }
}
