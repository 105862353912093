import Core from '../../lib/Core';
import {
  NOT
} from '../../lib/GenericTools.lib';

const SUMMARY_FEEDBACK__TOOLTIP = 'Feedback summary for all candidates';
const LOG_FEEDBACK__TOOLTIP = 'Cumulative raw / minimal edited feedback including repetition';

export const JobUI = {
  feedbackSectionTitle: NOT(Core.isAdmin()) && 'Employer feedback summary',
  feedbackSectionInsiderSubtitle: NOT(Core.isAdmin() || Core.isEmployer()) && (
    'Aggregated feedback from all candidates to give you broader context; not specific to your candidate(s)'
  ),
  feedbackSectionEmpty: '~No employer feedback yet~',
  feedbackResume: {
    title: 'Resume Feedback'
  },
  feedbackInterview: {
    title: 'Interview Feedback'
  },
  feedbackSummaryResume: {
    title: 'Feedback summary',
    tooltip: SUMMARY_FEEDBACK__TOOLTIP,
    empty: '~No resume summary~'
  },
  feedbackLogResume: {
    title: 'Feedback log',
    tooltip: LOG_FEEDBACK__TOOLTIP,
    empty: '~No resume log~'
  },
  feedbackSummaryInterview: {
    title: 'Feedback summary',
    tooltip: SUMMARY_FEEDBACK__TOOLTIP,
    empty: '~No interview summary~'
  },
  feedbackLogInterview: {
    title: 'Feedback log',
    tooltip: LOG_FEEDBACK__TOOLTIP,
    empty: '~No interview log~'
  }
}
export default JobUI;