import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import moment from "moment";
import {
  Component
} from "react";
import Account from "../../lib/Account";
import Core from "../../lib/Core";
import {
  DATE_FORMAT__DATE__ISO
} from '../../lib/Date.lib';
import Employer from "../../lib/Employer";
import Engagement from "../../lib/Engagement";
import Store from "../../lib/Store";
import onReady from "../../lib/tools/onReady";
import { getLocation } from '../../lib/URL.lib';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import DatePicker from '../Layout/Wrappers/DatePicker';
import Dialog from '../Layout/Wrappers/Dialog';
import Divider from '../Layout/Wrappers/Divider';
import Menu from '../Layout/Wrappers/Menu';
import Navigate from '../Layout/Wrappers/Navigate';
import NavLink from '../Layout/Wrappers/NavLink';
import Paper from '../Layout/Wrappers/Paper';
import Snackbar from '../Layout/Wrappers/Snackbar';
import Table from '../Layout/Wrappers/Table';
import Typography from '../Layout/Wrappers/Typography';

class Reports extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      linked: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      snackBarOpen: false,
      snackBarMessage: "...",
      engagementsLoading: false,
      open: false,
      scroll: 'paper',
      clickedCountEngagements: [],
      recruiters: [],
      employers: [],
      recruiter: {},
      employer: {},
      duration: "Monthly",
      viewBy: "Engagements",
      startDate: moment()
        .tz("America/Los_Angeles")
        .startOf("Day")
        .subtract(15, "months")
        .startOf("month")
        .toISOString(),
      endDate: moment()
        .tz("America/Los_Angeles")
        .endOf("day")
        .toISOString()
    };
    Store.set("path", getLocation());
    onReady(this, "rowsContainer").then(em => {
      Account.getActives(recruiters => {
        recruiters = [
          {
            id: "0",
            firstName: "All",
            lastName: "",
            companyName: "",
            email: ""
          },
          ...recruiters
        ]; // Add "All" option
        Employer.getActives(employers => {
          employers = [
            {
              id: "0",
              name: "All"
            },
            ...employers
          ]; // Add "All" option
          this.setState(
            { accountId: "0", recruiters, employerId: "0", employers },
            then => {
              this.getData();
            }
          );
        });
      });
    });
  };

  handleCountClicked = (scroll, val, dateAttr, stage = []) => () => {
    this.setState({ engagementsLoading: true });
    this.setState({ open: true, scroll });

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let month = monthNames.indexOf(val.split('/')[0]);
    let year = +2000 + +val.split('/')[1];
    let startMonth = moment
      .tz([year, month], "America/Los_Angeles")
      .startOf("Day")
      .startOf("month")
      .toISOString();

    let endMonth = moment([year, month])
      .tz("America/Los_Angeles")
      .startOf("Day")
      .endOf("month")
      .toISOString();

    let and1 = {};
    and1[dateAttr] = { neq: null };
    let and2 = {};
    and2[dateAttr] = { gte: startMonth };
    let and3 = {};
    and3[dateAttr] = { lte: endMonth };

    let condCombined = [and1, and2, and3];

    if (stage.length > 0) {
      condCombined.push({ "or": stage });
    }

    let params = {
      and: condCombined
    };

    Engagement.getWhere(params, response => {
      this.setState({ clickedCountEngagements: response });
      this.setState({ engagementsLoading: false });
    },
      error => {
        Core.showFailure("Unable to retrieve data: " + error);
        this.setState({ engagementsLoading: false });

      }
    );

  };

  handleClose = () => {
    this.setState({ open: false });
  };

  showMessage = msg => {
    this.setState({
      snackBarMessage: msg,
      snackBarOpen: true
    });
  };
  hideMessage = () => {
    this.setState({
      snackBarMessage: "",
      snackBarOpen: false
    });
  };
  getData = event => {
    let params = {
      recruiterId: this.state.accountId,
      employerId: this.state.employerId,
      duration: this.state.duration,
      viewBy: this.state.viewBy,
      startDate: this.state.startDate,
      endDate: this.state.endDate
    };
    Engagement.newPipelineReport(
      params,
      response => {
        this.setRows(response);
      },
      error => {
        Core.showFailure("Unable to retrieve new pipeline data: " + error);
      }
    );
  };
  setRows = data => {
    /*
    [
      {
        pipeline: "New Submission",
          cols: [
            { group: "1/14-2/14", value: 40 },
            { group: "2/14-3/14", value: 24 },
            { group: "3/14-4/14", value: 34 },
            { group: "3/14-5/14", value: 45 }
          ]
      }, ...
    ]*/
    const rows = [];
    if (data[0]) {
      let myPaddingStyle = {
        paddingLeft: 0,
        paddingRight: 0,
        textAlign: 'center'
      }
      /** TABLE HEADERS */
      rows.push(
        <Table.Row key={Core.getKey()} className="bgcolor2">
          <Table.Cell className="bolder f-big" />
          {data[0].cols.map(col => (
            <Table.Cell key={Core.getKey()} style={myPaddingStyle} className="bolder">
              {col.group}
            </Table.Cell>
          ))}
        </Table.Row>
      );

      let that = this;
      /** TABLE BODY */
      data.forEach((item, rowIndex) => {
        rows.push(
          <Table.Row key={`reports__data__row__${rowIndex}`}>
            <Table.Cell style={myPaddingStyle} className="inline-blocks">
              {item.pipeline}
            </Table.Cell>
            {item.cols.map((col, colIndex) => (
              <Table.Cell
                key={`reports__data__row__${rowIndex}__col__${colIndex}`}
                style={myPaddingStyle}
              >
                <span
                  onClick={that.handleCountClicked('paper', col.group, col.dateAttr, col.stage)}>
                  {col.value}
                </span>
              </Table.Cell>

            ), this)}
          </Table.Row>
        );
      });
      this.setState({ rows });
    }
  };
  render() {
    if (Core.isLoggedOut()) {
      return <Navigate to="/login" />;
    }
    return (
      <div className="main-padding cdarker">
        <h3>New Pipeline</h3>
        <Paper className="main-padding">
          <Snackbar
            open={this.state.snackBarOpen}
            message={this.state.snackBarMessage}
            className="snack-bar"
            autoHideDuration={4000}
            onClose={this.hideMessage}
          />
          <Table className="collapsed-view">
            <Table.Body>
              <Table.Row className="v-align-top">
                <Table.Cell className="col blocks">
                  <label>Recruiter</label>
                  <Menu dropdown
                    name="recruiter"
                    value={this.state.accountId || Core.getUserId()}
                    onChange={(accountId) => {
                      const recruiter = this.state.recruiters.find(
                        recruiter => recruiter.id === accountId
                      );
                      this.setState({ accountId, recruiter }, then => {
                        this.getData();
                      });
                    }}
                    paperStyle={{ maxHeight: 640 }}
                    options={this.state.recruiters
                      .sort((a, b) => {
                        if (a.id === "0") {
                          return -1;
                        }
                        a = String(a.firstName || a.email).toLowerCase();
                        b = String(b.firstName || b.email).toLowerCase();
                        return a > b ? 1 : a < b ? -1 : 0;
                      })}
                  />
                </Table.Cell>
                <Table.Cell className="col blocks">
                  <label>Employer</label>
                  <Menu dropdown
                    name="employer"
                    value={this.state.employerId}
                    onChange={(employerId) => {
                      const employer = this.state.employers.find(
                        employer => employer.id === employerId
                      );
                      this.setState({ employerId, employer }, then => {
                        this.getData();
                      });
                    }}
                    paperStyle={{ maxHeight: 640 }}
                    options={this.state.employers
                      .sort((a, b) => {
                        if (a.id === "0") {
                          return -1;
                        }
                        a = String(a.name).toLowerCase();
                        b = String(b.name).toLowerCase();
                        return a > b ? 1 : a < b ? -1 : 0;
                      })}
                  />
                </Table.Cell>
                <Table.Cell className="col blocks">
                  <label>Duration</label>
                  <Menu dropdown
                    name="duration"
                    value={this.state.duration}
                    onChange={(duration) => {
                      this.setState({ duration }, then => {
                        if (/Weekly/i.test(duration)) {
                          this.setState(
                            {
                              startDate: moment()
                                .tz("America/Los_Angeles")
                                .startOf("Day")
                                .subtract(15, "weeks")
                                .startOf("week")
                                .toISOString(),
                              endDate: moment()
                                .tz("America/Los_Angeles")
                                .endOf("day")
                                .toISOString()
                            },
                            then => {
                              this.getData();
                            }
                          );
                        } else {
                          this.setState(
                            {
                              startDate: moment()
                                .tz("America/Los_Angeles")
                                .startOf("Day")
                                .subtract(11, "months")
                                .startOf("month")
                                .toISOString(),
                              endDate: moment()
                                .tz("America/Los_Angeles")
                                .endOf("day")
                                .toISOString()
                            },
                            then => {
                              this.getData();
                            }
                          );
                        }
                      });
                    }}
                    options={["Weekly", "Monthly"]}
                  />
                </Table.Cell>
                <Table.Cell className="col blocks">
                  <label>View By</label>
                  <Menu dropdown
                    name="viewBy"
                    value={this.state.viewBy}
                    onChange={(viewBy) => {
                      this.setState({ viewBy }, then => {
                        this.getData();
                      });
                    }}
                    paperStyle={{ maxHeight: 640 }}
                    options={["Engagements", "Candidates"]}
                  />
                </Table.Cell>
                <Table.Cell className="col blocks">
                  <label>State Date</label>
                  <DatePicker
                    name="startDate"
                    formatDate={date => moment(date).format(DATE_FORMAT__DATE__ISO)}
                    value={
                      this.state.startDate
                        ? new Date(this.state.startDate)
                        : null
                    }
                    onChange={(startDate) => {
                      this.setState(
                        {
                          startDate: moment.tz(startDate, "America/Los_Angeles")
                            .startOf("day")
                            .toISOString()
                        },
                        then => {
                          this.getData();
                        }
                      );
                    }}
                  />
                </Table.Cell>
                <Table.Cell className="col blocks">
                  <label>End Date</label>
                  <DatePicker
                    name="endDate"
                    formatDate={date => moment(date).format(DATE_FORMAT__DATE__ISO)}
                    value={
                      this.state.endDate ? new Date(this.state.endDate) : null
                    }
                    onChange={(endDate) => {
                      this.setState(
                        {
                          endDate: moment.tz(endDate, "America/Los_Angeles")
                            .endOf("day")
                            .toISOString()
                        },
                        then => {
                          this.getData();
                        }
                      );
                    }}
                  />
                </Table.Cell>
                <Table.Cell className="col blocks" />
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider className=" margin-top" />
          <Table className="collapsed-view">
            <Table.Body ref={self => (this.rowsContainer = self)}>
              {this.state.rows}
            </Table.Body>
          </Table>
        </Paper>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          actions={[
            <Button
              onClick={this.handleClose}
              className='min-w-120'
              primary
            >
              Close
            </Button>
          ]}
        >
          <Typography className="align-center">Engagements</Typography>
          <Divider />
          <Box className="align-center">
            {this.state.engagementsLoading && <CircularProgress color="inherit" />}
            {(this.state.clickedCountEngagements.length > 0) ? (<List subheader={<li />}>
              {this.state.clickedCountEngagements.map(obj => (
                <p key={obj.id}>
                  <NavLink
                    title="Click to see engagement "
                    to={"/engagement/view/" + obj.id}
                  >
                    <b>{obj.id}</b>
                  </NavLink>
                </p>

              ))}
            </List>) : (<p>No Records found</p>)}
          </Box>
        </Dialog>
      </div >
    );
  }
}

export default Reports;
