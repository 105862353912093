/** ============================================ µ
 * @description DefinitionLocation [JS]
 *              Library
 * @createdAt   2021-06-16
 * @updatedAt   2021-08-12 Thu
 * ============================================ */
/* IMPORTS ==================================== */

import Definition, {
  LOC_TYPE__ALIAS_ID,
  LOC_TYPE__CITY_ID,
  LOC_TYPE__CONTINENT_ID,
  LOC_TYPE__CONT_SECTION_ID,
  LOC_TYPE__COUNTRY_ID,
  LOC_TYPE__METRO_AREA_ID,
  LOC_TYPE__METRO_AREA_SEC_ID,
  LOC_TYPE__MULTI_CONT_ID,
  LOC_TYPE__REGION_ID,
  LOC_TYPE__STATE_ID,
  LOC_TYPE__STATE_SECTION_ID
} from "./Definition";

/* CONSTANTS ================================== */

/* FUNCTIONS ================================== */

function getLineage(values, nodeId) {
  const node = values.find(v => v.id === nodeId);
  const parent = values.find(v => v.id === node?.parentId);
  if (parent) {
    return [parent].concat(getLineage(values, parent.id));
  }
  return [];
}

function addChildren(values, nodes) {
  return nodes.map((node) => {
    if (node.type === LOC_TYPE__ALIAS_ID) {
      /**
       * @todo
       * Experimental
       * Trying to make alias expandable
       * 2021-06-11
       * µ
       */
      /** * /
      node.children = values
        .filter(n => node.aliasLocationIds.includes(n.id))
        .map((o) => ({ ...o, value: `alias-child-${node.value}:${o.id}`, key: `alias-child-${node.value}:${o.id}` }))
      /** * /
      const children = (
        values
        .filter(n => node.aliasLocationIds.includes(n.id))
        .map((o) => ({ ...o, value: o.id, key: `alias-child-${node.value}:${o.id}` }))
      );
      if (children.length > 0) {
        node.children = addChildren(values, children);
      }
      /** */
    } else {
      const children = values
        .filter((v) => v.parentId === node.id)
        .map((o) => ({
          ...o,
          value: o.id,
        }));
      if (children.length > 0) {
        node.children = addChildren(values, children);
      }
    }
    return node;
  });
}

function descendants({ data = [], rootIds = [] }) {
  let d = data.filter((v) => {
    return rootIds.includes(v.parentId);
  });
  if (!!d.length) {
    d = d.concat(descendants({ data, rootIds: d.map((v) => v.id) }));
  }
  return d;
}

/* METHODS ==================================== */

/**
 * @todo
 * TO UPDATE COMMENT
 * @param {*} param0
 * @returns
 */
function evalAlias({
  aliasId,
  locationId
}) {
  const locationTags = Definition.get('location');
  const aliasLocation = locationTags.find(v => v.id === aliasId);
  return !!aliasLocation?.aliasLocationIds?.find(
    aliasLocationId => evalLineage({
      memberA: aliasLocationId,
      memberB: locationId
    })
  );
}

/**
 * Returns true if ancest is part of the lineage of descendant.
 *
 * In other words evaluates if ancest and descendant are from same lineage.
 *
 * @param {object} options
 * @param {number} options.ancestId
 * @param {number} options.descendantId
 * @returns
 */
function evalAncest({
  ancestId,
  descendantId,
}) {
  const locationTags = Definition.get('location');
  const lineage = getLineage(locationTags, descendantId);
  return lineage.map(n => n.id).includes(ancestId);
}

/**
 *
 * @param {object} options
 * @param {number} options.locationId
 * @param {number[]} options.typeIds
 * @returns {object[]} LocationTags
 */
function getAncest({
  locationId = NaN,
  typeIds = [],
}) {
  const locationTags = Definition.get('location');
  const lineage = getLineage(locationTags, locationId);
  return typeIds.length
    ? lineage.filter(n => typeIds.includes(n.type))
    : lineage;
}

/**
 * Returns true if members are from same lineage.
 *
 * @param {object} params
 * @param {number} params.memberA
 * @param {number} params.memberB
 * @returns {boolean}
 */
function evalLineage({ memberA, memberB }) {
  return (
    (memberA === memberB) ||
    evalAncest({
      ancestId: memberA,
      descendantId: memberB,
    }) ||
    evalAncest({
      ancestId: memberB,
      descendantId: memberA,
    }) ||
    evalAlias({
      aliasId: memberA,
      locationId: memberB,
    }) ||
    evalAlias({
      aliasId: memberB,
      locationId: memberA,
    })
  );
}

function findTreeDataNodesByTagId(treeData, nodeIds) {
  const nodes = [];
  treeData.forEach(node => {
    if (nodeIds.includes(node.id)) {
      nodes.push(node);
      if (node.hasOwnProperty('children') && node.children && node.children.hasOwnProperty('length')) {
        nodes.push(...findTreeDataNodesByTagId(node.children, nodeIds));
      }
    }
  });
  return nodes;
}

function getLocation({
  locationId
}) {
  return Definition.get('location').filter(n => n.id === locationId)[0];
}

/**
 * Returns tags type alias related the locationIds.
 *
 * @param {object} params
 * @param {number[]} params.locationIds
 * @returns {object[]} Location Tags
 */
function getLocationsAliases({
  locationIds = []
}) {
  const locationTags = Definition.get('location');
  return locationTags.filter(n => locationIds.find(
    locationId => n.aliasLocationIds?.includes(locationId))
  ).map(n => n.id);
}

function getRootIds({ data = [] }) {
  return data.filter((n) => !n.parentId).map(({ id }) => id);
}

function getTreeData(values, rootIds) {
  // console.debug({ values, rootIds });
  let nodes = values
    .filter((v) => rootIds.includes(v.id))
    .map((o) => ({ ...o, value: o.id }));
  return addChildren(values, nodes);
}

/* epic-3038(new locations)-story-3675-m1 | 2021-08-04 Wed µ */
/**
 * Returns the treeData but filtering by the location-types specified in the layers
 *
 * @param {object} options
 * @param {object[]} options.treeData
 * @param {number[]} options.layers
 * @returns {object[]}
 */
function filterTreeDataLayers({ treeData, layers }) {
  let nodes = [];
  !!treeData && treeData.forEach(n => {
    n.children = filterTreeDataLayers({ treeData: n.children, layers });
    if (!!layers.includes(n.type)) {
      nodes.push(n);
    }
    else {
      nodes = [...nodes, ...n.children];
    }
  });
  return nodes;
}

/* epic-3038(new locations)-story-3689-m2 | 2021-08-05 Thu µ */
/**
 *
 * @param {object} options
 * @param {number[]} options.locations locationTagIds
 * @returns {string} `<city|metro-area-section>(<nearest-parent-up-metro-area>)|<location>,...`
 */
function getLocationsString({
  locations = []
}) {
  let locationsString = locations.map(locationId => {
    const locationTag = Definition.getTag({ categoryKey: 'location', tagId: locationId });
    if ([LOC_TYPE__CITY_ID, LOC_TYPE__METRO_AREA_SEC_ID].includes(locationTag.type)) {
      const ancestLabels = getAncest({
        locationId,
        typeIds: [
          LOC_TYPE__METRO_AREA_ID,
          LOC_TYPE__STATE_ID,
          LOC_TYPE__STATE_SECTION_ID,
          LOC_TYPE__REGION_ID,
          LOC_TYPE__COUNTRY_ID,
          LOC_TYPE__CONT_SECTION_ID,
          LOC_TYPE__CONTINENT_ID,
          LOC_TYPE__MULTI_CONT_ID
        ]
      }).map(n => n.label)[0];
      return `${locationTag.label} (${ancestLabels})`
    }
    return locationTag.label;
  }).join(', ');
  return locationsString;
}

/* DICTIONARIES =============================== */

const LocationLib = {
  evalAlias,
  evalAncest,
  getAncest,
  evalLineage,
  findTreeDataNodesByTagId,
  getLocation,
  getLocationsAliases,
  getRootIds,
  getTreeData,
  filterTreeDataLayers,
  getLocationsString,
  descendants,
}

window.LocationLib = LocationLib;

// window['LocationLib'] = LocationLib;

/* EXPORTS ==================================== */

export {
  LocationLib as default,
  LocationLib,
};

/* ============================================ */
