import Candidate from "../../../lib/Candidate";
import Box from '../../Layout/Wrappers/Box';
import Message from '../../Layout/Wrappers/Message';
import TextCVContentDrawer from "./TextCVContentDrawer";

export default function DoubleContentDrawer({
  candidate = {},
  job = {},
  ...props
}) {
  return (
    <Box row w100 h100 wrap
      {...props}
    >
      <Box column flex1 h100 scrollY
        style={{ minWidth: 320 }}
      >
        {!!candidate.resumeTxtUrl && !!job.id
          ? (
            <TextCVContentDrawer
              style={{ width: "100%", height: "100%" }}
              candidate={candidate}
              job={job}
            />
          ) : (
            <Message>No Text CV</Message>
          )}
      </Box>

      <Box column flex1 h100
        style={{ minWidth: 320 }}
      >
        {!!candidate.id && !!candidate.resumePdfUrl
          ? (
            <object
              aria-label="pdf"
              data={`${Candidate.getMyPdfUrl(candidate)}?#view=fitH&toolbar=1`}
              type="application/pdf"
              width="100%"
              height="100%"
              className='h-100'
            />
          ) : (
            <Message>No CV</Message>
          )}
      </Box>
    </Box>
  );
}
