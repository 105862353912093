import FuzzySearch from "fuzzy-search";
import _ from "lodash";
import {
  Component,
  Fragment
} from "react";
import Core from "../../lib/Core";
import Definition from "../../lib/Definition";
import Button from '../Layout/Wrappers/Button';
import Chip from '../Layout/Wrappers/Chip';
import Dialog from '../Layout/Wrappers/Dialog';
import Divider from '../Layout/Wrappers/Divider';
import Icon from '../Layout/Wrappers/Icon';
import IconButton from '../Layout/Wrappers/IconButton';
import TextField from '../Layout/Wrappers/TextField';
import Box from '../Layout/Wrappers/Box';

class Chips extends Component {
  constructor() {
    super(...arguments);
    let items = (this.props.items && this.props.items.length) ? this.props.items : Definition.get(this.props.name);
    items = _.sortBy(items, o => o.label)
    //items.sort((a,b) => (a.label.toLowerCase()  - b.label.toLowerCase()));
    this.state = {
      selected: [],
      items,
      search: "",
      open: false
    };
  }
  open = ev => {
    this.setState(state => {
      state.open = true;
      state.selected = [];
      let propsValues = this.props.values.map(o => String(o));

      state.items.forEach(item => {
        if (!!~propsValues.indexOf(String(item.id))) {
          state.selected.push({ ...item });
          item.selected = true;
        } else {
          delete item.selected;
        }
      });

      return state;
    });
  };

  componentDidUpdate(prevProps) {
    // Check for changes in items and update state if necessary
    if (!_.isEmpty(this.props.items) && this.props.items !== prevProps.items) {
      this.setState({ items: this.props.items });
    }
  }

  apply = ev => {
    this.setState(state => {
      state.open = false;
      this.props.onChange &&
        this.props.onChange(state.selected.map(item => item.id));
      return state;
    });
  };

  findValue = id => {
    let item = {};
    let items = this.props.items;
    if (items) {
      item = items.filter(item => (item.id === id));
      item = item[0] || {};
    }

    return item ? item.label : 'notFound';
  };

  render() {
    const { decisionArray } = this.props;
    const chipOption = (model, selected) => {
      return (
        <Chip
          key={Core.getKey()}
          className="chip slim-chip"
          variant="outlined"
          size="small"
          label={model.label}
          style={{
            color: selected ? "#536DFF" : "#454545",
            margin: 4,
            display: "inlineBlock",
            border: selected
              ? "1px solid #536DFF"
              : !selected && model.selected
                ? "1px dotted #7A7A7A"
                : "1px solid #7A7A7A"
          }}
          onClick={ev => {
            if (selected) {
              this.setState(state => {
                state.selected = state.selected.filter(
                  item => item.id !== model.id
                );
                delete state.items.find(item => item.id === model.id).selected;
                return state;
              });
            } else {
              if (!this.state.selected.find(item => item.id === model.id)) {
                this.setState(state => {
                  state.selected.push(model);
                  state.items.find(
                    item => item.id === model.id
                  ).selected = true;
                  return state;
                });
              }
            }
          }}
        />
      );
    };
    return (
      <>
        {!!this.props.label && <label>{this.props.label}</label>}
        <span className="f-small c-black-medium">{this.props.sub}</span>
        <div >
          <div >
            {this.props.values.map((id, index) => (
              <Chip
                key={`${this.props.name}-tag-${id}`}
                className="chip slim-chip"
                variant="outlined"
                size="small"
                label={this.props.commonCase ? this.findValue(id) : Definition.getLabel(this.props.name, id)}
                style={{
                  color: "#454545",
                  display: "inlineBlock",
                  border: !!decisionArray && !!decisionArray[index] ? "1px solid green" : (!!decisionArray && decisionArray[index] === null) || !decisionArray ? "1px solid rgba(0, 0, 0, 0.23)" : "2px solid red"
                }}
                deleteIcon={<Icon icon='clear' />}
                onDelete={ev => {
                  this.props.onChange &&
                    this.props.onChange(
                      this.props.values.filter(_id => _id !== id)
                    );
                }}
              />
            ))}
            <Chip outlined slim primary
              className='ml-auto mb-05 mr-05 min-w-60'
              label="+ Add"
              style={{
                color: colors.purple.common,
                display: "inlineBlock",
                border: "1px solid " + colors.purple.common
              }}
              onClick={this.open}
            />
          </div>
          <Dialog
            title={
              <>
                {this.props.label}
                {this.props.topRow}
                <Box row noWrap w100>
                  <Icon xl mr icon='search'/>
                  <TextField required autoFocus
                    name="searcher"
                    placeholder="Separate search terms by comma or space"
                    value={this.state.search}
                    onChange={(event, search) => this.setState({ search })}
                  />
                  {!!this.state.search.length && (
                    <IconButton
                      onClick={ev => this.setState({ search: "" })}
                      icon='clear'
                    />
                  )}
                </Box>
              </>
            }
            open={this.state.open}
            onClose={() => this.setState({ open: false })}
            paperStyle={{ width: 640 }}
            content={
              <>
                {!!this.state.selected.length && (
                  <Fragment>
                    <div className="padding-top-bottom">
                      {this.state.selected.map((item, i) => chipOption(item, true))}
                    </div>
                    <Divider />
                  </Fragment>
                )}
                <div className="padding-top-bottom">
                  {(!!this.state.search.length
                    ? (em => {
                      const result = [];
                      const words = this.state.search
                        .trim()
                        .split(/,\s*|\s|\||;/)
                        .filter(term => !!term.trim());
                      words.forEach(word => {
                        new FuzzySearch(this.state.items, ["label"], {
                          caseSensitive: false
                        })
                          .search(word)
                          .forEach(
                            item =>
                              !result.find(
                                resItem => item.label === resItem.label
                              ) && result.push(item)
                          );
                      });
                      return result;
                    })()
                    : this.state.items
                  ).map((item, i) => chipOption(item))}
                </div>
              </>
            }
            actions={[
              <Button outlined minW120
                label="Cancel"
                onClick={ev => this.setState({ open: false })}
              />,
              <Button primary minW120
                label="Apply"
                onClick={this.apply}
              />
            ]}
          />
        </div>
      </>
    );
  }
}

export default Chips;
