import {
  compile
} from 'handlebars';
import _, {
  isEqual
} from 'lodash';
import dig from 'object-dig';
import {
  Component
} from 'react';
import {
  withTranslation
} from 'react-i18next';
import {
  ENGAGEMENT__STATE_OPEN,
  STATUS_H_CANDIDATE
} from '../../../dictionaries/Engagement.dic';
import Account from '../../../lib/Account';
import {
  Arr,
  sanitizeArr
} from '../../../lib/Array.lib';
import Candidate, {
  deleteResumeFile
} from '../../../lib/Candidate';
import CandidateSkillsAction from '../../../lib/CandidateSkillsAction';
import Core from '../../../lib/Core';
import Definition, {
  ACCOUNT_ACTION__EDIT_CANDIDATE,
  ACCOUNT_ACTION__LIST_CANDIDATES,
  ACCOUNT_ACTION__LIST_ENGAGEMENTS,
  ACCOUNT_ACTION__MATCH_CANDIDATE,
  STATE_ACTIVE,
  STATE_DRAFT,
  STATE_LEAD
} from '../../../lib/Definition';
import Engagement from '../../../lib/Engagement';
import {
  getHighlightedDifferences,
  NOT,
  removeRichTextFormat,
  YES
} from '../../../lib/GenericTools.lib';
import HistoryLog from '../../../lib/HistoryLog';
import Job from '../../../lib/Job';
import {
  getPersonName,
  Obj
} from '../../../lib/Object.lib';
import SovrenData from '../../../lib/SovrenData';
import Store from '../../../lib/Store';
import Streak from '../../../lib/Streak';
import {
  Str
} from '../../../lib/String.lib';
import {
  getLocation,
  getParams,
  getSearch,
  matchLocation,
  reloadLocation
} from '../../../lib/URL.lib';
import {
  getCandidateModel,
  mapBasicCandidate,
  mapCandidate,
  normalizeCandidateEducation,
  normalizeCandidateExperience
} from '../../../lib/models/candidate';
import {
  COLLECTION__ACCOUNTS,
  getLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import {
  sendSafeEmail
} from '../../../lib/services/Email/Email.lib';
import {
  TEMPLATE__CANDIDATE_EDIT__MAKE_COVER__BODY
} from '../../../lib/templates/CandidateEdit.templates';
import {
  validateGithubURL,
  validateLinkedinURL
} from '../../../lib/tools/processURL.tool';
import {
  showAlert
} from '../../Dialogs/AlertDialog';
import SuccessDialog from '../../Dialogs/Success';
import HistoryMenu from '../../HistoryMenu/HistoryMenu';
import {
  joinClassName
} from '../../Layout/Libraries/Theme.lib';
import Page from '../../Layout/Page';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Dialog from '../../Layout/Wrappers/Dialog';
import Gravatar from '../../Layout/Wrappers/Gravatar';
import IconButton from '../../Layout/Wrappers/IconButton';
import JsonView from '../../Layout/Wrappers/JsonView';
import { WarningMessage } from '../../Layout/Wrappers/Message';
import NavLink from '../../Layout/Wrappers/NavLink';
import Navigate from '../../Layout/Wrappers/Navigate';
import Paper, {
  getDefaultPaperStyle
} from '../../Layout/Wrappers/Paper';
import Stepper, { stepperScrollToTop } from '../../Layout/Wrappers/Stepper';
import {
  fetchQuestionFromBank
} from '../../PrescreenQuestions/PrescreenQuestions.lib';
import AccessDenied from '../../Shared/AccessDenied';
import ResumeMatch from '../../Shared/ResumeMatch';
import mapCandidateSignals from '../CandidateSignalTags.lib';
import CandidateSummaryStickyNote from '../CandidateSummaryStickyNote';
import ConflictDetector from '../ConflictDetector';
import Admin from '../Forms/Admin';
import Basics from '../Forms/Basics';
import {
  CandidateEditStep3
} from '../Forms/CandidateEditStep3';
import Match from '../Forms/Match';
import CandidateDocumentPreview from "./CandidateDocumentPreview";
import {
  CandidateWarningInvalidFieldsForEmail,
  confirmCandidateUpdateAction
} from './CandidateEdit.lib';
import { getPdfLocalUrlCache } from '../../../lib/File/getPdfLocalUrl.tool';

const queryString = require('query-string');
const required = 'This field is required';
export const warningBackBtnText = 'NO-TAKE ME BACK';
export const warningContinueBtnText = 'YES - Submit';

const CANDIDATE_EDIT__PERSISTED__KEY = 'candidate__persisted';

class CandidateEdit extends Component {
  continue = 0;
  confirm = false;
  constructor() {
    super(...arguments);
    const candidateId = (
      this.props.candidateId ||
      getParams({ pattern: 'edit/:id' }).id
    );
    const stepperHeaders = sanitizeArr([
      'BASICS',
      'MATCH',
      'SUBMIT',
      Core.isAdmin() && 'ADMIN'
    ]);
    this.state = {
      ...getCandidateModel({ extended: true }),
      /** controller states */
      categories: [],
      allCategories: [],
      candidates: [],
      recruiters: [],
      errorFirstName: '',
      errorLastName: '',
      errorEmail: '',
      errorPhone: '',
      errorLinkedIn: '',
      errorGitHub: '',
      errorPlatformRating: '',
      loadingComplete: true,
      step: matchLocation(/section/i) ? 3 : 1,
      isDirty: false,
      dirtyAttrs: [],
      jobTypes: [Definition.getId('jobType', 'Fulltime')],
      /** SET DRAFT AS DEFAULT value */
      state: Definition.getId('state', 'Lead'),
      undergraduateDegree: null,
      stepperHeaders,
      initiallyIsDuplicate: false,
      tmpIntroduced: new Date().toISOString(),
      _isMounted: false,
      showLinkedInForm: false,
      candidateId,
      permittedJobs: [],
      candidateNotFound: false,
      hideRight: true,
    };
    this._prolongPopup = false;
    this._isMounted = false;
    this._isDone = false;
    this._isLeavingComponent = false;
    this._warningAlreadyShown = false;
    this._sendNewCandidateEmail = false;

    Store.set('path', getLocation());

  }

  async componentDidMount() {
    this._isMounted = true;

    if (Core.isAdminOrCoordinator()) {
      await getLoopbackRecord({
        collection: COLLECTION__ACCOUNTS,
        where: { state: STATE_ACTIVE },
        fields: ['id', 'firstName', 'lastName', 'email', 'companyName']
      }).then((recruiters) => {
        this.setState((state) => {
          this.recruiters = state.recruiters = Arr(recruiters);
          return state;
        });
      });
    }

    else {
      await Account.get(Core.getUserId(), (recruiter) => {
        this.setState({
          accountId: Core.getUserId(),
          recruiter: recruiter,
          recruiters: [recruiter],
        });
      });
    }

    const update = {
      queryParams: queryString.parse(getSearch())
    };

    this.setState(
      update,
      () => {
        const candFromParams = getParams({
          pattern: 'edit/:id'
        }).id || null;
        const candidateId = this.props.candidateId || candFromParams;

        // CANDIDATE EDIT
        if (candidateId) {
          Candidate
            .proxy('CandidatesEdit__componentDidMount_setState_callback__get')
            .get(
              candidateId,
              (candidate) => {
                setPersistedCandidate(candidate);
                this.setState(
                  {
                    ...candidate,
                    initiallyIsDuplicate: candidate.isDuplicate,
                    loadingComplete: true
                  },
                  (then) => {
                    Core.log({ candidateState: candidate });
                    HistoryLog.set({
                      group: 'candidates',
                      label: candidate._name,
                    });
                    this.loadDefaultValues();
                    setTimeout(() => {
                      this.checkPotentialDuplicatedCandidates();
                    }, 2000); //wait for all page to load
                  }
                );
              },
              (error) => {
                if (error === 404) {
                  this.setState({ candidateNotFound: true });
                }
              }
            );
        }

        // CANDIDATE CREATE
        else {
          this.setState(
            {
              ...setPersistedCandidate(),
              loadingComplete: true
            },
            () => this.loadDefaultValues()
          );
        }

        // ENABLING LEFT PANEL
        setTimeout(() => this.setState({
          hideRight: false
        }), 1000);

      }
    );
  }

  componentWillUnmount() {
    this._isMounted = false;

    unsetPersistedCandidate();

    if (this.state.id && this.state._isDraft && !this.state.draftEmailSentAt) {
      Candidate.update(this.state.id, {
        draftEmailSentAt: new Date().toISOString(),
      });
    }

    let doSave = false;
    if (
      this.state.isDirty &&
      !this._isDone &&
      this.props.source !== 'jobsPage' &&
      window.confirm('Want to save your data?')
    ) {
      doSave = true;
    }

    if (!this.state.id) {
      (this.state.resumes || []).forEach((resume) => {
        setTimeout(async () => {
          let candidate = this.state;
          await deleteResumeFile({ resume });
          SovrenData.destroyData({ resume, candidate, callback: this.afterDestroySovrenData });
        });
      });
    }

    this.leavingPageUpdates(doSave);
  }

  leavingPageUpdates(doSave) {
    this._isLeavingComponent = true;

    if (this.state._isDraft && !this.state.draftEmailSentAt) {
      //get all candidate details first
      Candidate
        .proxy('CandidatesEdit__leavingPageUpdates__get')
        .get(this.state.id, (candidate) => {
          this.sendDraftCandidateEmail(candidate);
        });
    }

    if (doSave) {
      this.update((action) => (res) => { });
    }

    window.removeEventListener('beforeunload', this.handleWindowClose);

  }

  handleWindowClose = (event) => {
    event.preventDefault();
    return (event.returnValue = 'Are you sure you want to close?');
  };

  mapSignals = async (update = {}) => {
    let CandidateEditController = this;
    let candidate = { ...CandidateEditController.state, ...update };
    CandidateEditController.__mappedSignals = true;
    if (!CandidateEditController.__fixedEmploymentIssues) {
      CandidateEditController.__fixedEmploymentIssues = true;
      CandidateEditController.setStateStore({
        ...candidate,
        ...await mapCandidateSignals({ candidate }),
        recruiters: this.recruiters || candidate.recruiters || []
      });
    }
    else {
      CandidateEditController.setStateStore(await mapCandidateSignals({ candidate }));
    }
  }


  loadDefaultValues = () => {

    let roles = dig(
      this.state,
      'queryParams',
      'defaultRoles'
    );

    let jobsPermitted = dig(
      this.state,
      'queryParams',
      'defaultJobs'
    );

    if (!!roles) {
      roles = Str(roles).split(',');
      if (
        Array.isArray(this.state.roles) &&
        Array.isArray(roles)
      ) {
        roles = roles.concat(this.state.roles);
        roles = Array.from(
          new Set(
            roles.map(
              (el) => String(el)
            )
          )
        );
      }
      this.setStateStore({ roles, key: 'roles' });
    }

    if (!!jobsPermitted) {
      jobsPermitted = Str(jobsPermitted).split(',');
      if (
        Array.isArray(this.state.jobsPermitted) &&
        Array.isArray(jobsPermitted)
      ) {
        jobsPermitted = jobsPermitted.concat(
          this.state.jobsPermitted
        );
        jobsPermitted = Array.from(
          new Set(
            jobsPermitted.map(
              (el) => String(el)
            )
          )
        );
      }

      this.setStateStore(
        {
          jobsPermitted,
          key: 'jobsPermitted'
        },
        () => {
          this.setState({
            loadDefaultValuesComplete: true
          });
        }
      );
    }
    else {
      this.setState({
        loadDefaultValuesComplete: true
      });
    }
  };

  resetDirty = () => this.setState((state) => Object.assign(state, { isDirty: false, dirtyAttrs: [] }));

  checkForDirty = (state = this.state) => {
    let persistedState = getPersistedCandidate();
    let isDirty = false;
    let dirtyAttrs = state.dirtyAttrs.filter((attr) => (attr !== state.key));
    let persistedValue = persistedState[state.key];
    let currentValue = state[state.key];
    if (Array.isArray(currentValue)) {
      if (!Array.isArray(persistedValue)) { persistedValue = []; }
      isDirty = !isEqual(currentValue.sort(), persistedValue.sort());
    }
    else if (typeof currentValue === 'string') {
      isDirty = removeRichTextFormat(currentValue) !== removeRichTextFormat(persistedValue);
    }
    else { isDirty = true; }
    if (currentValue === undefined || persistedValue === undefined) { isDirty = false; }
    if (isDirty) {
      dirtyAttrs.push(state.key);
      window.addEventListener('beforeunload', this.handleWindowClose);
    }
    else {
      window.removeEventListener('beforeunload', this.handleWindowClose);
    }
    let uniqueValues = Array.from(new Set(dirtyAttrs));
    dirtyAttrs = uniqueValues;
    this.setState({ dirtyAttrs, isDirty });
  };

  setStateStore = (update, callback) => {
    this.setState((state) => {
      if (update instanceof Function) {
        update = Obj(update(state));
      }
      Object.assign(state, update);
      if (update.hasOwnProperty('key')) {
        this.checkForDirty(state);
      }
      delete state.key;
      return state;
    }, callback);
  };

  checkPotentialDuplicatedCandidates = (next = () => null) => {
    /* Skip duplicate check if it was already a duplicate */
    Candidate.proxy(
      'CandidatesEdit__checkPotentialDuplicatedCandidates__getPotentialDuplicated'
    ).getPotentialDuplicated(
      this.state,
      (potentialDuplicatedCandidateList) => {
        new ConflictDetector(
          this.state,
          potentialDuplicatedCandidateList,
          (result) => {
            console.log({ result });
            const isDuplicated = result.isStrongConflict || result.isWeakConflict;
            if (isDuplicated) {
              if (!result.isRecruiterCando) {
                if (result.isStrongConflict) {
                  let msg = `WARNING - Another recruiter has representation for the jobs marked in Red.`;
                  let type = 'error';
                  showAlert({ message: msg, severity: type });
                } else if (result.isTbdConflict) {
                  let msg = `WARNING - You can still submit the candidate! Another recruiter may already have representation for the jobs marked in Gray. Your account team will review them once you submit or save your changes.`;
                  let type = 'warning';
                  showAlert({ message: msg, severity: type });
                }
              }
            }

            if (isDuplicated || result.isLegacyConflict) {
              this.setState({
                ...this.operateDuplicate(
                  result,
                  result.isLegacyConflict && isDuplicated
                ),
                isDuplicate: true,
              });
            }

            this.setState(
              {
                potentialDuplicatedCandidateList,
                alreadyOwnIt: result.iOwnIt,
                duplicateResult: result,
                isOwnedRightNow: this.state.id
                  ? this.state.isOwnedRightNow
                  : !result.someoneOwnIt,
                duplicateTriggeredBy: '',
                ownershipResult: result.ownershipResult,
                ownershipDupEmail: !!isDuplicated ? result.fnSendEmail : null,
              },
              () => {
                /** CANDIDATE: potential duplicated */
                next();
              }
            );
          }
        );
      }
    );
  };

  operateDuplicate = (dupResult, showDuplicateWarnings) => {
    let pdc = dupResult.identifiedDuplicateCando;
    const state = {
      errorGitHub: '',
      errorLinkedIn: '',
      errorPhone: '',
      errorEmail: '',
      errorFirstName: '',
      errorLastName: '',
    };
    if (showDuplicateWarnings) {
      if (dupResult.attrsThatConflicted.includes('_gitHubURL')) {
        state.isDuplicate = true;
      }
      if (dupResult.attrsThatConflicted.includes('_linkedInURL')) {
        state.isDuplicate = true;
      }
      if (dupResult.attrsThatConflicted.includes('phone')) {
        state.isDuplicate = true;
      }
      if (dupResult.attrsThatConflicted.includes('email')) {
        state.isDuplicate = true;
      }
      if (dupResult.attrsThatConflicted.includes('Name')) {
        state.isDuplicate = true;
      }
    }
    if (pdc.duplicatedLevel === 'primary' || !pdc.duplicatedLevel) {
      this._duplicatedFrom = pdc;
      state.duplicatedFrom = pdc.id;
      state.duplicatedLevel = 'secondary';
    } else {
      state.duplicatedFrom = pdc.duplicatedFrom;
      state.duplicatedLevel = 'secondary';
    }
    return state;
  };

  isValid = (step) => {
    const { state } = this;
    const {
      yearsOfExperience: relevantYearsOfExperience = '',
      minimumSalary,
      salaryConfidence
    } = state;
    const _diffStepText = (
      (
        step !== this.state.step
      ) ? (
        ` (step ${step})`
      ) : (
        ''
      )
    );
    if (step === 1) {
      /** FIRST NAME: required */
      if (String(this.state.firstName).trim().length < 1) {
        stepperScrollToTop();
        Core.showError(
          'First Name is required' + _diffStepText
        );
        this.setState({ errorFirstName: required });
        return false;
      }
      /** LAST NAME: required */
      if (String(this.state.lastName).trim().length < 1) {
        stepperScrollToTop();
        Core.showError(
          'Last Name is required' + _diffStepText
        );
        this.setState({ errorLastName: required });
        return false;
      }
      /** EMAIL: validation */
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.state.email
        )
      ) {
        stepperScrollToTop();
        Core.showError(
          'Email is invalid' + _diffStepText
        );
        this.setState({ errorEmail: 'Please enter a valid email' });
        return false;
      }

      // LinkedIn: validation
      if (String(this.state.linkedInURL) && !String(validateLinkedinURL(this.state.linkedInURL))) {
        stepperScrollToTop();
        Core.showWarning(
          'LinkedIn profile url is invalid' + _diffStepText
        );
        this.setState({ errorLinkedIn: 'Please enter a valid profile url' });
        return false;
      }

      // GitHub: validation
      if (String(this.state.gitHubURL) && !String(validateGithubURL(this.state.gitHubURL))) {
        stepperScrollToTop();
        Core.showWarning(
          'Github profile url is invalid' + _diffStepText
        );
        this.setState({ errorGitHub: 'Please enter a valid profile url' });
        return false;
      }

      if (!relevantYearsOfExperience) {
        Core.showError(
          'Relevant Years of Experience is required' + _diffStepText
        );
        this.setState({ errorRelevantYearsOfExperience: 'Please set a valid number for relevant years of experience.' });
        return false;
      }
    }
    if (step === 2) {
      if (!!minimumSalary && !salaryConfidence) {
        Core.showError(
          'Salary confidence is required' + _diffStepText
        );
        this.setState({ errorSalaryConfidence: 'Please choose an option.' });
        return false;
      }
    }

    /** ALL TESTS PASSED */
    return true;
  };

  processDirtyAttrAndStates = () => {
    const { dirtyAttrs } = this.state;
    const oldState = getPersistedCandidate();
    const newState = this.state;
    let finalDirtyAttrs = [];

    finalDirtyAttrs = dirtyAttrs.map((val, index) => {
      let dirtyObject = {};
      dirtyObject.key = val;
      dirtyObject.label = val;
      dirtyObject.oldState = oldState[val];
      dirtyObject.newState = newState[val];

      return dirtyObject;
    });

    if (!!finalDirtyAttrs.length)
      CandidateSkillsAction.processUpdate(this.state, finalDirtyAttrs);
  };

  update = (success, final) => {
    if (this.isValid(0)) {
      this.setState({ formIsSubmitting: true });
      this.checkPotentialDuplicatedCandidates((em) => {
        const candidate = { ...this.state };

        if (!candidate.introduced && this.isSubmitting()) {
          candidate.introduced = candidate.tmpIntroduced;
          this._sendNewCandidateEmail = true;
        }

        // UPDATE
        if (candidate.id) {
          /* Jira Ticket Ticket VER-195 ===>>> */
          /* >Match candidate & job - prefill roles & visa. */
          /* -It is needed to remove searchConfig from the candidate
            in order to preset the chips on the filter for match
            when the candidate change.
          */
          candidate.searchConfig = {};
          /* <<< === Jira Ticket Ticket VER-195 */
          const queryParams = this.state.queryParams;
          if (
            parseInt(queryParams.conflict_attempt) === 1 &&
            queryParams.ownership === 'false'
          ) {
            let introduced = new Date().toISOString();
            let privateNotes = `${candidate.privateNotes} <br/> candidate was submitted on ${candidate.introduced} and resubmitted on ${introduced}`;
            candidate.privateNotes = privateNotes;
            candidate.introduced = introduced;
            candidate.isOwnedRightNow = true;
            this.setState({ privateNotes, introduced, isOwnedRightNow: true });
          }
          Candidate.update(
            candidate.id,
            candidate,
            (response) => {

              response = mapBasicCandidate(response);

              this.checkPotentialDuplicatedCandidates();

              this.processDirtyAttrAndStates();

              /** @todo [2023-08-01][story_][MS: we should investigate the proper candidate state] */
              // this.state.candidate; // outdate | deprecated (5y ago)
              // this.state = candidate; //current form (2y ago)
              this.setState({ candidate: response }); //getting the latest state

              /**
               * WHEN state Hold and holdDate is set
               * there will be additional request running
               * on background
               */
              if (
                Definition.test('state', candidate.state, /Hold/) &&
                !!candidate.holdDate
              ) {
                const status = STATUS_H_CANDIDATE;
                const streakStatusKey = Streak.getDropdownKey('Status', status);
                if (streakStatusKey) {
                  const engagements = candidate.engagements.filter(
                    (eng) => (eng.state === ENGAGEMENT__STATE_OPEN)
                  );
                  const next = (em) => {
                    setTimeout((st) => {
                      if (!!engagements.length) {
                        const eng = engagements.pop();
                        Engagement.update(
                          eng,
                          {
                            status,
                            holdDate: candidate.holdDate,
                          },
                          next,
                          next
                        );
                      }
                    });
                  };
                  next();
                }
              }
              success('edit')(response);
            },
            (error) => {
              this.setState((state) => {
                Object.assign(state, {
                  jobsPermitted: Arr(Obj(getPersistedCandidate()).jobsPermitted),
                  formIsSubmitting: false,
                })
                return state;
              });
              Core.showError(error);
            }
          );
        }

        // CREATE
        else {
          this.insertNewCandidate(candidate, async (result) => {
            result = Obj(
              await Candidate
                .proxy('CandidatesEdit__update_checkPotentialDuplicatedCandidates_callback_insertNewCandidate_callback__get')
                .get(result.id)
            );
            this.setState(result, () => {
              this.checkPotentialDuplicatedCandidates();
              success('new')(result);
            });
          });
        }

        //update duplicated from
        if (!!Object(this._duplicatedFrom).id) {
          Candidate.update(
            candidate.duplicatedFrom,
            { duplicatedLevel: 'primary', isDuplicate: true },
            (response) => { }
          );
        }
      }, final);
    }
  };
  /** Rename resumes uploaded before New Candidate was first saved.  Then insert candidate.
   *  Ex: http(s)://<host>/api/ImportContainer/<S3 bucketname>%2F<container>/download/<filename>
   * */
  insertNewCandidate = (candidate, success) => {
    const resumes = [];
    const currentResumes = [...this.state.resumes];
    const next = (em) => {
      if (!!currentResumes.length) {
        const resume = currentResumes.pop();
        var urlParts = resume.url.split('/');
        var olfFilename = urlParts.pop();
        if (olfFilename.startsWith('_')) {
          urlParts.pop();
          var bucketnameContainer = urlParts.pop();
          var bucketname = bucketnameContainer.replace('%2F', '/');
          var newFilename = `${this.state.firstName
            .replace(/ /g, '')
            .toUpperCase()}_${this.state.lastName
              .replace(/ /g, '')
              .toUpperCase()}${olfFilename.slice(1)}`;
          var newURL = `${urlParts.join(
            '/'
          )}/${bucketnameContainer}/download/${newFilename}`;
          Core.renameS3Resume(
            bucketname,
            olfFilename,
            newFilename,
            (response) => {
              resume.url = newURL;
              resumes.push(resume);
              next();
            },
            (error) => {
              Core.failure({
                source: 'CandidateEdit.js>insertNewCandidate>renameS3Resume',
                exception: error,
                params: {
                  bucketname,
                  olfFilename,
                  newFilename
                }
              });
              resumes.push(resume);
              next();
            }
          );
        } else {
          resumes.push(resume);
          next();
        }
      } else {
        this.setState({ resumes }, (then) => {
          Candidate.post(candidate, success, () => {
            this.setState({ formIsSubmitting: false });
          });
        });
      }
    };
    next();
  };

  makeCoverHTML = (rows, candidate) => {
    const { firstName, lastName } = this.state;
    const html = compile(TEMPLATE__CANDIDATE_EDIT__MAKE_COVER__BODY)({
      FIRST_NAME: firstName,
      LAST_NAME: lastName,
      NO_JOBS_PERMITTED_LENGTH: NOT(Arr(this.state.jobsPermitted).length),
      URL: Core.getPath(`candidate/edit/${candidate.id}`),
      ROWS: rows
    });
    return html;
  };

  /** SEND NEW CANDIDATE EMAIL */
  sendNewCandidateEmail = async (candidate) => {

    confirmCandidateUpdateAction(
      {
        candidate: this.state,
        exportWarnings: true,
        onAccept: async (exportedWarnings) => {

          const from = Core.getNewCandidateReceiverNotiFromEmail();

          let to;

          const recruiter = candidate.recruiter;

          const roles = Definition.getLabels('roles', this.state.roles);

          if (Core.isDuc() || Core.isTeam()) {
            to =
              (recruiter.firstName + ' ' + recruiter.lastName).trim() +
              ' <' +
              recruiter.email +
              '>';
          }
          else {
            to = Core.getNewCandidateReceiverNotiToEmail();
          }

          let cc = Core.getNewCandidateReceiverNotiToEmail();

          const subject = `NEW CANDIDATE | ${this.state.firstName} ${this.state.lastName
            } | submitted by ${recruiter.firstName} ${recruiter.lastName} | ${roles.length ? roles.join(', ') : 'no roles added'
            } (using ${!!getLocation().includes('local')
              ? 'localhost'
              : getLocation().split('/')[2]
            })`;

          let { html = '' } = this.candidateEmailAttrDiffTable(candidate);
          html = Str(html);
          html += Str(exportedWarnings);

          const params = {
            from,
            to,
            cc,
            subject,
            html,
            source: 'New Candidate Email - CandidateEdit.js => line 944',
          };

          Core.log('Email notification for new Candidate', params);

          await sendSafeEmail(params);

        }
      }
    );

  };

  sendMissingAttributeEmail = async (candidate) => {
    if (
      candidate._isDraft ||
      Core.isAdminOrCoordinator() ||
      !this._sendNewCandidateEmail
    ) {
      return;
    }

    const from = Core.getNewCandidateReceiverNotiFromEmail();
    const to = Core.getUserName() + ' <' + Core.getSessionEmail() + '>';
    const cc = Core.getNewCandidateReceiverNotiToEmail();

    const recruiter = candidate.recruiter;

    const roles = Definition.getLabels('roles', this.state.roles);

    const subject = `CANDIDATE MISSING INFO | ${this.state.firstName} ${this.state.lastName
      } | submitted by ${recruiter.firstName} ${recruiter.lastName} | ${roles.length ? roles.join(', ') : 'no roles added'
      }`;
    let rows = CandidateWarningInvalidFieldsForEmail({ candidate });
    if (!!rows.length) {
      const html = this.makeCoverHTML(rows, candidate);
      var params = {
        from,
        to,
        cc,
        subject,
        html,
        source: 'Missing Attribute Email - CandidateEdit.js => line 984',
      };
      Core.log('Email notification for new Candidate', params);
      await sendSafeEmail(params);
    }
  };

  // routine to get the url when the candidate record was edited on a non-production platform
  getAdminHelperUrlPath = (path) => {
    // figure out our current base
    let helperUrl = null;
    if (!!getLocation().includes('beta')) {
      let url = getLocation().split('/');
      helperUrl = `${url[0]}/${url[1]}/${url[2]}/#/${path}`;
    }
    return helperUrl;
  };

  candidateEmailAttrDiffTable = (candidate = CandidateEdit__state()) => {

    let editCandidateUrl = Core.getPath(`candidate/edit/${candidate.id}`);
    let helperEditCandidateUrl = this.getAdminHelperUrlPath(
      `candidate/edit/${candidate.id}`
    );
    let matchCandidateUrl = Core.getPath(`candidate/match/${candidate.id}`);
    let helperMatchCandidateUrl = this.getAdminHelperUrlPath(
      `candidate/match/${candidate.id}`
    );
    let dupCandidateUrl = Core.getPath(
      `candidates?viewDup=t&cId=${candidate.id}`
    );
    let helperDupCandidateUrl = this.getAdminHelperUrlPath(
      `candidates?viewDup=t&cId=${candidate.id}`
    );
    const { ownershipDupEmail } = candidate;
    const mappings = [
      { key: 'acceptOffer', def: 'offerAcceptance' },
      { key: 'candidatePreferIds', def: 'contactPreference' },
      { key: 'workLocationIds', def: 'locationCandidate' },
      { key: 'visa', def: 'visaCandidate' },
      { key: 'desiredStage', def: 'stage' },
      { key: 'workRemotly', def: 'remotelyWork' },
      { key: 'currentlyEmployed', def: 'diversity' },
      { key: 'relationship', def: 'relationShip' },
      { key: 'technicalSkills', def: 'technicalSkills' },
      { key: 'roles', def: 'roles' },
      { key: 'undergraduateDegree', def: 'undergraduateDegree' },
      { key: 'level', def: 'level' },
      { key: 'state', def: 'state' },
      { key: 'platformRating', def: 'platformRating' },
      { key: 'recruiterRating', def: 'recruiterRating' },
      { key: 'positiveSignals', def: 'positiveSignals' },
      { key: 'negativeSignals', def: 'negativeSignals' },
      { key: 'diversity', def: 'diversity' },
      { key: 'diversityCategories', def: 'diversityCategories' },
    ];
    const mappingKeys = mappings.map((obj) => obj.key);
    let oldState = getPersistedCandidate();
    let newState = candidate;
    const _forceJobsPermitted = (oldState.state !== STATE_ACTIVE) && Arr(candidate.jobsPermitted).length;
    if (_forceJobsPermitted) {
      candidate.dirtyAttrs = Arr(candidate.dirtyAttrs);
      candidate.dirtyAttrs.push('jobsPermitted');
      candidate.isDirty = true;
    }
    let finalDirtyAttrs = sanitizeArr(candidate.dirtyAttrs).map((val, index) => {
      let oldStateVal = oldState[val];
      let newStateVal = newState[val];

      if (mappingKeys.includes(val)) {
        let foundOne = mappings.find((obj) => obj.key === val);

        if (!!foundOne) {
          if (Array.isArray(newStateVal)) {
            oldStateVal = Definition.getLabelsWithId(
              foundOne['def'],
              oldStateVal
            )
              .map(
                (label) =>
                  `<span style="font-size: 10px;">${label}</span><br/>`
              )
              .join('');
            newStateVal = Definition.getLabelsWithId(
              foundOne['def'],
              newStateVal
            )
              .map(
                (label) =>
                  `<span style="font-size: 10px;">${label}</span><br/>`
              )
              .join('');
          } else {
            oldStateVal = `<span style="font-size: 10px;">${Definition.getLabelWithId(
              foundOne['def'],
              oldStateVal
            )}</span><br/>`;
            newStateVal = `<span style="font-size: 10px;">${Definition.getLabelWithId(
              foundOne['def'],
              newStateVal
            )}</span><br/>`;
          }
        }
      }

      const jobsFields = ['jobsPermitted', 'jobsPitched', 'jobsDeclined'];

      if (jobsFields.includes(val)) {

        const list = Array.isArray(newState[`_${val}`])
          ? newState[`_${val}`]
          : [];

        oldStateVal = (oldStateVal || [])
          .map((id) => {
            let job = list.find((job) => job.id === id) || {};
            return `${dig(job, 'employer', 'name') || ''} | ${job.jobTitle || ''
              } | ${job.addressCity || ''} | jid:${dig(job, 'id')} | eid:${dig(
                job,
                'employer',
                'id'
              )}`;
          })
          .map(
            (label) => `<span style="font-size: 10px;">${label}</span><br/>`
          )
          .join('<br/>');

        newStateVal = (newStateVal || [])
          .map((id) => {
            let job = list.find((job) => job.id === id) || {};
            return `${dig(job, 'employer', 'name') || ''} | ${job.jobTitle || ''
              } | ${job.addressCity || ''} | jid:${dig(job, 'id')} | eid:${dig(
                job,
                'employer',
                'id'
              )}`;
          })
          .map(
            (label) => `<span style="font-size: 10px;">${label}</span><br/>`
          )
          .join('<br/>');
      }

      /** @todo [ 2023-01-20 ][ MS: to deprecate ] */
      if (val === 'permittedJobsAnswers') {
        oldStateVal = Object.keys(Object(oldStateVal))
          .map((jobId) => {
            let questions = (oldStateVal[jobId] || []).map((obj) => {
              return `<span style="font-size: 10px;">question: ${dig(
                obj,
                'question'
              )}</span><br/><span style="font-size: 10px;">answer: ${dig(
                obj,
                'ans'
              )}</span>`;
            });

            return `<span style="font-size: 10px;"><strong>For Job ${jobId}</strong></span><hr/><span style="font-size: 10px;">${questions.join(
              ''
            )}</span><hr/>`;
          })
          .join('');
        newStateVal = Object.keys(Object(newStateVal))
          .map((jobId) => {
            let questions = (newStateVal[jobId] || []).map((obj) => {
              return `<span style="font-size: 10px;">question: ${dig(
                obj,
                'question'
              )}</span><br/><span style="font-size: 10px;">answer: ${dig(
                obj,
                'ans'
              )}</span>`;
            });

            return `<span style="font-size: 10px;"><strong>For Job ${jobId}</strong></span><hr/><span style="font-size: 10px;">${questions.join(
              ''
            )}</span><hr/>`;
          })
          .join('');
      }
      return { key: val, oldStateVal, newStateVal };
    });

    let rows = finalDirtyAttrs.filter(obj => {
      return (
        (
          (obj.key === 'jobsPermitted') &&
          _forceJobsPermitted
        ) ||
        !_.isEqual(obj.oldStateVal, obj.newStateVal)
      );
    }).map((obj) => {
      try {
        if (Array.isArray(obj.oldStateVal)) {
          obj.oldStateVal = `<pre>${JSON.stringify(obj.oldStateVal, null, 2)}</pre>`;
        }
        if (Array.isArray(obj.newStateVal)) {
          obj.newStateVal = `<pre>${JSON.stringify(obj.newStateVal, null, 2)}</pre>`;
        }
      }
      catch (error) {
        console.error(error);
      }
      return (
        `<tr>
          <td>${obj.key}</td>
          <td>${obj.oldStateVal}</td>
          <td>${getHighlightedDifferences(obj.oldStateVal, obj.newStateVal)}</td>
        </tr>`
      );
    });

    let html = `<html>
        <head></head>
        <body>
            ${!!ownershipDupEmail ? ownershipDupEmail() : ''}<br />
            The following candidate attributes were edited:<br /><br />
            <a href="${editCandidateUrl}">View Candidate </a>
            <a href="${matchCandidateUrl}"> | Match Candidate </a>
            <a href="${dupCandidateUrl}"> | View Conflict Details</a>
            ${!!candidate.linkedInURL?.trim().length ? `<a href="${candidate.linkedInURL}"> | LinkedIn</a>` : ''}<br />
            ${!!helperEditCandidateUrl ? `<a href=${helperEditCandidateUrl}>View Candidate </a>` : ''}
            ${!!helperMatchCandidateUrl
        ? `<a href=${helperMatchCandidateUrl}> | Match Candidate </a>`
        : ''
      }
            ${!!helperDupCandidateUrl
        ? `<a href=${helperDupCandidateUrl}> | View Conflict Details</a>`
        : ''
      }
            <br />
            <table style="width:60%" border="1">
              <tr>
                <th>Attribute</th>
                <th>Old Value</th> 
                <th>New Value</th>
              </tr>
              ${rows.join('')}
            </table>
        </body></html>`;

    return { html, rowsLength: rows.length };
  };

  sendCandidateEditEmail = async (candidate) => {

    if (
      candidate.state === STATE_DRAFT ||
      candidate.state === STATE_LEAD ||
      Core.isAdminOrCoordinator() ||
      !this.state.isDirty
    ) { return; }


    let from = Core.getNewCandidateReceiverNotiFromEmail();
    let to = Core.getNewCandidateReceiverNotiToEmail();
    let cc = Core.getNewCandidateReceiverNotiToEmail();

    let subject = `Edit CANDIDATE | ${this.state.firstName} ${this.state.lastName
      } | edited by ${Core.getUserName()}`;

    const { html, rowsLength } = this.candidateEmailAttrDiffTable(candidate);

    if (!!rowsLength) {

      // story_9106: remove the "Duplicate -" from the Edit Candidate subject when a duplicate is detected. Subject should just be Edit Candidate.
      // let { ownershipDupEmail } = this.state;
      // subject = !!ownershipDupEmail ? `Duplicate - ${subject}` : subject;

      let params = {
        from,
        to,
        cc,
        subject,
        html,
        source: 'Candidate Edit Email - CandidateEdit.js => line 1118',
      };
      Core.log('Email notification for existing Candidate', params);
      await sendSafeEmail(params);
    }

  };

  /** SEND DUPLICATE CANDIDATE EMAIL */
  sendDuplicateCandidateEmail = (candidate) => {
    // Deprecated
    var from = Core.getNewCandidateReceiverNotiFromEmail();
    var to;
    const recruiter = candidate.recruiter;

    if (Core.isDuc() || Core.isTeam()) {
      to =
        (recruiter.firstName + ' ' + recruiter.lastName).trim() +
        ' <' +
        recruiter.email +
        '>';
    } else {
      to = Core.getNewCandidateReceiverNotiToEmail();
    }
    let cc = Core.getNewCandidateReceiverNotiToEmail();
    let subject = `Duplicate Candidate: ${recruiter.firstName} ${recruiter.lastName} attempt to submit ${this.state.firstName} ${this.state.lastName}`;

    let html = `<html><head></head><body><table>`;

    Core.log(
      'potential duplicated candidate list',
      this.state.potentialDuplicatedCandidateList
    );
    /** CANDIDATE: potential duplicated */
    if (!!this.state.potentialDuplicatedCandidateList.length) {
      const pdcs = this.state.potentialDuplicatedCandidateList;
      let state = { isDuplicate: false };
      let duplicative = `<tr><td>Duplicative `;

      pdcs.forEach((pdc) => {
        //state = this.operateDuplicate(pdcs[i]);
        // if(state.isDuplicate){
        //   return;
        // }

        if (
          !!pdc.firstName.length &&
          pdc.firstName.toLowerCase() === this.state.firstName.toLowerCase() &&
          !!pdc.lastName.length &&
          pdc.lastName.toLowerCase() === this.state.lastName.toLowerCase()
        ) {
          state.isDuplicate = true;
          duplicative += `Name: ${this.state.firstName} ${this.state.lastName}, `;
        }

        if (!!pdc.gitHubURL.length && pdc.gitHubURL === this.state.gitHubURL) {
          state.isDuplicate = true;
          duplicative += `GitHub: ${this.state.gitHubURL}, `;
        }

        if (
          !!pdc.linkedInURL.length &&
          pdc.linkedInURL === this.state.linkedInURL
        ) {
          state.isDuplicate = true;
          duplicative += `LinkedIn: ${this.state.linkedInURL}, `;
        }

        if (!!pdc.phone.length && pdc.phone === this.state.phone) {
          state.isDuplicate = true;
          duplicative += `Phone: ${this.state.phone}, `;
        }

        if (!!pdc.email.length && pdc.email === this.state.email) {
          state.isDuplicate = true;
          duplicative += `Email: ${this.state.email}, `;
        }
      });

      duplicative += `</td></tr>`;
      html += `${duplicative}`;
    }
    html += `</table></body></html>`;

    var params = {
      from,
      to,
      cc,
      subject,
      html,
      source: 'Duplicate Candidate Email - CandidateEdit.js => line 1204',
    };
    Core.log('Email notification for Duplicate Candidate', params);
    sendSafeEmail(params);
  };

  sendDraftCandidateEmail = (candidate) => {
    var from = Core.getNewCandidateReceiverNotiFromEmail();
    var to;
    const recruiter = candidate.recruiter;

    const roles = Definition.getLabels('roles', this.state.roles);

    if (Core.isDuc() || Core.isTeam()) {
      to =
        (recruiter.firstName + ' ' + recruiter.lastName).trim() +
        ' <' +
        recruiter.email +
        '>';
    } else {
      to = Core.getDraftCandidateReceiverNotiToEmail();
    }

    var cc = Core.getNewCandidateReceiverNotiToEmail();
    const subject = `DRAFT CANDIDATE | ${this.state.firstName} ${this.state.lastName
      } | created by ${recruiter.firstName} ${recruiter.lastName} | ${roles.length ? roles.join(', ') : 'no roles added'
      }`;

    var editCandidateUrl = Core.getPath(`candidate/edit/${candidate.id}`);
    var html = `<html><head></head><body>Candidate is in draft state<br/><br><a href="${editCandidateUrl}">View Candidate</a></body></html>`;

    var params = {
      from,
      to,
      cc,
      subject,
      html,
      source: 'Incomplete Candidate Email - CandidateEdit.js => line 1236',
    };
    Core.log('Email notification for Draft Candidate', params);
    sendSafeEmail(params);
  };

  /** CANCEL BUTTON */
  cancel = (ev) => {
    if (!!this.props.backAction) {
      this.props.backAction();
    }
    else {
      Core.goBack(this.props);
    }
  };

  /** PREVIOUS BUTTON */
  previous = (ev) => {
    Stepper.previous();
  };

  isSubmitting = () => {
    return this._action === 'submit';
  };
  isSaving = () => {
    return this._action === 'save';
  };
  save = update => {

    const _save = () => {
      const isSaveAsDraft = this.isNewRecord() || this.state._isDraft;
      if (isSaveAsDraft) {
        this.submitThenGoNext('save', { next: false })();
      }
      else {
        confirmCandidateUpdateAction({
          candidate: this.state,
          onAccept: this.submitThenGoNext('save', { next: false })
        });
      }
    }

    if (update) {
      this.setStateStore(update, _save);
    }
    else {
      _save();
    }

  }
  /** SAVE & NEXT BUTTON */
  submitThenGoNext = (action, opts) => (event) => {

    const continueWithExecution = () => {
      if (NOT(this.isValid(1) && this.isValid(2))) {
        return;
      }
      if (this.state.formIsSubmitting) {
        console.log('Tried to resubmit but stopped making a duplicate request');
        return;
      }
      this._action = action;
      const isSaveAsDraft = /save/i.test(this._action);
      this.update(
        (act) => (response) => {
          let msg = 'Candidate has been successfully ';
          this.setState(
            async (state) => {
              const candidate = Object.assign(state, { ...response, formIsSubmitting: false });
              if (this._sendNewCandidateEmail) {
                await this.sendNewCandidateEmail(candidate);
              }
              else if (candidate.id) {
                await this.sendCandidateEditEmail(candidate);
              }
              await this.sendMissingAttributeEmail(candidate);
              if (candidate.isDirty) {
                this.resetDirty();
              }
              if (CandidateEdit__state().id && !opts.next) {
                msg = isSaveAsDraft ? msg + 'saved' : msg + 'submitted';
              }
              else {
                msg = isSaveAsDraft ? msg + 'saved' : msg + 'submitted';
              }
              Object.assign(state, response);
              return state;
            },
            () => {
              if (!!this.props.backAction) {
                this.props.backAction();
              }
              else if (opts.next) {
                Stepper.next();
              }
              else {
                this.successDialog.open(msg);
              }
              this._isDone = true;
            }
          );
        },
        true
      );
    };
    /**
     * Route(/candidate/create)
     * Route(/candidate/edit/:id)
     * On click submit, the state of candidate...
     * will become active.
     * START >>>
     */
    if (action === 'submit') {
      this.setState({ state: Definition.getId('state', 'Active') }, () => {
        continueWithExecution();
      });
    } else {
      continueWithExecution();
    }
    /** <<< END */
  };

  isOwner = () => {
    return (
      this.isNewRecord() ||
      (this.state.accountId && this.state.accountId === Core.getUserId())
    );
  };

  isNewRecord = () => {
    return this.state.id == null;
  };

  isLastStep = () => this.state.step >= (this.state.stepperHeaders.length);
  isFirstStep = () => this.state.step === 1;
  isSecondStep = () => this.state.step === 2;

  /** NEXT BUTTON */
  next = (ev) => {
    if (this.isFirstStep() && !this.isValid(1)) {
      return;
    }
    if (this.isSecondStep() && !this.isValid(2)) {
      return;
    }
    this.checkPotentialDuplicatedCandidates(() => Stepper.next());
  };

  openPdf = () => {
    if (!!this.state.resumes.length) {
      Core.openPopUp(this.state.resumePdfUrl);
    } else {
      alert('no resumes found to show pdf');
    }
  };

  saveAsDraftBtn = ({
    primary = false,
    outlined = true,
    className = ''
  } = {}) => {
    const isSaveAsDraft = this.isNewRecord() || this.state._isDraft;
    const formIsSubmitting = this.state.formIsSubmitting;
    return (
      <Button minW120
        disabled={formIsSubmitting}
        className={className}
        outlined={outlined}
        primary={primary}
        onClick={
          (
            (
              isSaveAsDraft
            ) ? (
              this.submitThenGoNext('save', { next: false })
            ) : (
              event => this.isValid(1) && this.isValid(2) && confirmCandidateUpdateAction(
                {
                  candidate: this.state,
                  onAccept: this.submitThenGoNext('save', { next: false })
                }
              )
            )
          )
        }
      >
        {
          (
            formIsSubmitting
          ) ? (
            'Please Wait...'
          ) : (
            isSaveAsDraft
          ) ? (
            'SAVE AS DRAFT'
          ) : (
            'SAVE'
          )
        }
      </Button>
    );
  };

  afterDestroySovrenData = (sovrenDataId) => {
    sovrenDataId = Str(sovrenDataId);

    let CandidateEditController = this;
    const pdfUrl = CandidateEditController.state.resumePdfUrl;
    const regex = new RegExp(sovrenDataId, 'i');
    if (regex.test(pdfUrl)) {
      CandidateEditController.setStateStore(
        (state) => {
          state.sovrenDataId = '';
          state.resumePdfUrl = '';
          state.resumeTxtUrl = '';
          state.htmlResumeUrl = '';
          state.resumeScrubbedUrl = '';
          state.resumeJsonUrl = '';
          state.rawSovren = null;
          return state;
        },
        () => {
          if (CandidateEditController.state.id) {
            CandidateEditController.save({
              sovrenDataId: '',
              resumePdfUrl: '',
              resumeTxtUrl: '',
              htmlResumeUrl: '',
              resumeScrubbedUrl: '',
              resumeJsonUrl: '',
            });
          }
        }
      );
    }
  }

  render() {
    // const { t } = this.props; // this would be used in future

    let CandidateEditController = this;
    let { state: candidate, state } = this;
    let {
      rawSovren
    } = state;
    let {
      jobsPermitted = [],
      sovrenDataId,
    } = candidate;

    if (Core.isLoggedOut()) {
      return <Navigate to="/login" />;
    }
    if (Core.isAdmin() && !Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE })) {
      return <AccessDenied />;
    }

    const { candidateNotFound, formIsSubmitting } = candidate;

    const _candidateId = (
      this.props.candidateId ||
      getParams({ pattern: '/candidate/edit/:id' }).id
    );

    Core.setKeyValue('CandidateEditController', this);

    async function fetchSovrenData() {
      CandidateEditController.__fetchingSovrenData = true;
      rawSovren = await SovrenData.get({ where: { id: sovrenDataId } }).catch(error => {
        CandidateEditController.__errorFetchingSovrenData = error;
        Core.showError(error);
      });
      CandidateEditController.setStateStore({ rawSovren }, () => {
        delete CandidateEditController.__fetchingSovrenData;
      });
    }

    if (
      sovrenDataId &&
      !rawSovren &&
      !CandidateEditController.__fetchingSovrenData &&
      !CandidateEditController.__errorFetchingSovrenData
    ) {
      fetchSovrenData();
    }

    normalizeCandidateExperience({ candidate, filterInvalidSignals: false });
    normalizeCandidateEducation({ candidate, filterInvalidSignals: false });

    if (
      !CandidateEditController.__mappedSignals &&
      (
        !!candidate.id ||
        getLocation().includes('create')
      )
    ) {
      this.mapSignals();
    }

    const _fetchPermittedJobs = async () => {
      CandidateEditController.__fetchingPermittedJobs = true;
      CandidateEditController.__jobsPermitted = jobsPermitted;
      return Job.getWhere({ id: { inq: jobsPermitted } }, async permittedJobs => {
        CandidateEditController.setStateStore({ permittedJobs }, () => {
          CandidateEditController.__fetchedPermittedJobs = true;
          CandidateEditController.__fetchingPermittedJobs = false;
        });
      }).catch(error => CandidateEditController.__fetchingErrorPermittedJobs = error);
    }

    if (!!candidate.id) {
      setTimeout(async () => {
        if (
          !this.state.__globalQuestions &&
          !CandidateEditController.__fetchedGlobalQuestions &&
          !CandidateEditController.__fetchingGlobalQuestions &&
          !CandidateEditController.__errorFetchingGlobalQuestions
        ) {
          CandidateEditController.__fetchingGlobalQuestions = true;
          await fetchQuestionFromBank()
            .then(async (_data) => {
              CandidateEditController.setStateStore({ __globalQuestions: _data }, () => {
                CandidateEditController.__fetchedGlobalQuestions = true;
                CandidateEditController.__fetchingGlobalQuestions = false;
              });
            }).catch(error => CandidateEditController.__errorFetchingGlobalQuestions = error);
        }
        if (
          !!CandidateEditController.__fetchedGlobalQuestions &&
          !CandidateEditController.__fetchingPermittedJobs &&
          !CandidateEditController.__fetchingErrorPermittedJobs &&
          !CandidateEditController.__fetchedPermittedJobs
        ) {
          await _fetchPermittedJobs();
        }
        else if (!isEqual(CandidateEditController.__jobsPermitted, jobsPermitted)) {
          await _fetchPermittedJobs();
        }
      }, 1000);
    }

    const isLeftPanelVisible = (
      NOT(state.hideRight) &&
      YES(getPdfLocalUrlCache()[candidate.id])
    );
    const maxWidth = isLeftPanelVisible ? 800 : 1200;
    const bgColor = (
      Core.isAdminOrCoordinator() &&
      !!this.state.id &&
      this.state.isDuplicate &&
      'bg-red-lighter'
    );

    const candidateName = getPersonName(candidate);

    return (
      <Page
        title={(
          <>
            <span className='mr-1'>
              {(!!state.id ? 'Edit ' : 'New ')}
              Candidate
            </span>
            {(candidateName
              ? (
                <>
                  <span className='mr-1'>
                    {candidateName}
                  </span>
                  <Gravatar
                    label={candidateName}
                    email={candidate.email}
                    className='mr-1 bg-green-lighter c-cyan-darker'
                    hideOnError
                  />
                </>
              )
              : ``
            )}
          </>
        )}
        contentRight={(
          <>

            <CandidateSummaryStickyNote
              candidate={candidate}
              reference={self => CandidateEditController.StickyNote = self}
            />

            {!!getPdfLocalUrlCache()[candidate.id] && (
              <Button flat
                startIcon={
                  <i className='material-icons c-inherit'>{!state.hideRight ? 'visibility' : 'visibility_off'}</i>
                }
                onClick={event => {
                  CandidateEditController.setState(state => ({ hideRight: !state.hideRight }));
                }}
                className={
                  joinClassName([
                    'tt-unset mr-1 hidden-on-small-screen',
                    !state.hideRight ? 'c-purple' : 'c-gray-medium',
                  ])
                }
                style={{ minWidth: 160 }}
              >
                {state.hideRight ? 'Show right panel' : 'Hide right panel'}
              </Button>
            )}

            {Core.isAdmin() && (
              <>
                {!!state.id && (
                  <>

                    {(!Core.isAdmin() || Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ENGAGEMENTS })) && (
                      <IconButton
                        aria-label='Open engagements details'
                        className='hint--left'
                        onClick={event => {
                          candidate.openEngagementsDetails();
                        }}
                        icon='sync'
                      />
                    )}

                    <NavLink
                      title='Go to match'
                      to={`/candidate/match/${state.id}`}
                      icon='fact_check'
                      acl={
                        (
                          NOT(Core.isAdmin()) ||
                          Core.isAdmin({
                            action: ACCOUNT_ACTION__MATCH_CANDIDATE
                          })
                        )
                      }
                    />

                  </>
                )}

                <HistoryMenu />

                <IconButton
                  aria-label='Open a message box'
                  className='hint--left'
                  onClick={state.openMessage}
                >
                  <i className="material-icons">email</i>
                </IconButton>

              </>
            )}

            {!!state.resumes.length && (
              <>

                {state.resumeTxtUrl && (
                  <ResumeMatch
                    candidateResume={state.resumeTxtUrl}
                    element={(cb) => {
                      return (
                        <IconButton
                          aria-label='Open resume text in a popup'
                          className='hint--left'
                          onClick={(ev) => {
                            cb();
                          }}
                        >
                          <i className="material-icons">book</i>
                        </IconButton>
                      );
                    }}
                  />
                )}

                <IconButton
                  aria-label='Open PDF in a popup'
                  className='hint--left'
                  onClick={(ev) => {
                    Core.openPopUp(Candidate.getMyPdfUrl(state), 1200);
                  }}
                >
                  <i className="material-icons">picture_as_pdf</i>
                </IconButton>

              </>
            )}

          </>
        )}
      >

        <Box row h100 w100
          acl={!candidateNotFound}
          className='flex-align-center-top'
        >

          <Box column h100 w100
            role='CandidateEdit__LeftSide'
            className='px-05'
            style={{ maxWidth }}
          >
            <Paper
              className={joinClassName(['mx-auto', bgColor])}
              style={
                getDefaultPaperStyle({
                  height: '100%',
                  width: '100%',
                  maxWidth
                })
              }
            >

              <Stepper
                acl={NOT(
                  this.state.loadingComplete &&
                  Core.isAdminOrCoordinator() &&
                  this.state.showLinkedInForm
                )}
                toolBar={
                  <Box column wAuto role='TopActions' alignRight>

                    {this.saveAsDraftBtn()}

                    <Box row w100
                      role='SovrenRawDialog'
                      acl={Core.isAdmin()}
                    >
                      {!!this.state.rawSovren && (
                        <Button
                          color='primary'
                          variant='text'
                          className='tt-unset'
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ viewSovrenData: true });
                          }}
                        >
                          View sovren raw data
                        </Button>
                      )}

                      <Dialog alignLeft
                        name="candidate_edit__dialog__view_sovren_raw_data"
                        open={!!this.state.viewSovrenData}
                        onClose={() => {
                          this.setState({ viewSovrenData: false });
                        }}
                      >
                        <JsonView src={this.state.rawSovren} />
                      </Dialog>

                    </Box>

                  </Box>
                }
                headers={this.state.stepperHeaders}
                views={
                  sanitizeArr([
                    <Basics
                      parent={this}
                      cbOpenPdf={this.openPdf}
                      title={this.state.stepperHeaders[0]}
                      subtitle={(
                        <>
                          Resume, contact information, work history, education, social links<br />
                          We use email, phone, linkedIn, and github to check if same candidate was submitted by another recruiter for the same employer
                        </>
                      )}
                    />,
                    <Match
                      parent={this}
                      title={this.state.stepperHeaders[1]}
                      subtitle={(
                        <>
                          Work authorization, compensation, location, company type preferences, skill summary<br />
                          Make money on autopilot. Tell us about your candidate and get notified when a new job match is found
                        </>
                      )}
                    />,
                    <CandidateEditStep3
                      CandidateEditController={this}
                      title={this.state.stepperHeaders[2]}
                      subtitle="Roles and jobs applying, recruiter submission note, recruiter's email, logistics"
                    />,
                    Core.isAdminOrCoordinator() && (
                      <Admin
                        parent={this}
                        title={this.state.stepperHeaders[3]}
                      />
                    )
                  ])
                }
                step={this.state.step}
                onChange={(step) => {
                  const candidate = this.state;
                  if (candidate.id) {
                    Candidate.isStale(
                      candidate.id,
                      candidate.updatedAt,
                      (result) => {
                        if (result.isStale) {
                          Core.showError(
                            <div className='d-flex flex-column'>
                              <span>
                                The candidate record has been edited by someone else. Please refresh the page and re-apply your changes before saving
                              </span>
                              <Button
                                title='Refresh page'
                                variant='outlined'
                                color='error'
                                className='ml-auto'
                                style={{ minWidth: 120 }}
                                onClick={ev => reloadLocation()}
                                endIcon={<i className='material-icons c-red'>refresh</i>}
                              >
                                Refresh
                              </Button>
                            </div>
                          );
                        }
                      }
                    );
                  }
                  this.setState({ step });
                }}
                footerBar={
                  <Box row w100>
                    <Box row wAuto className='mr-auto'>
                      <Button outlined error
                        onClick={this.cancel}
                        className='min-w-120'
                      >
                        Cancel
                      </Button>
                    </Box>
                    <Box row flex1 alignRight>
                      {this.isFirstStep() ? (
                        ''
                      ) : (
                        <Button outlined
                          onClick={this.previous}
                          className='min-w-120 mr-1'
                        >
                          Previous
                        </Button>
                      )}
                      {!this.isLastStep() && (
                        <Button
                          onClick={this.next}
                          className='min-w-120'
                        >
                          Next
                        </Button>
                      )}
                      {
                        this.isLastStep() &&
                          (this.isNewRecord() || this.state._isDraft)
                          ?
                          <Button
                            disabled={formIsSubmitting}
                            onClick={
                              (event) => (
                                this.isValid(1) &&
                                this.isValid(2) &&
                                confirmCandidateUpdateAction({
                                  candidate: this.state,
                                  onAccept: this.submitThenGoNext('submit', { next: !this.isLastStep(), })
                                })
                              )
                            }
                            className='min-w-120'
                          >
                            {formIsSubmitting ? 'Please Wait...' : 'Submit'}
                          </Button>
                          :
                          this.isLastStep()
                            ?
                            this.saveAsDraftBtn({
                              primary: true,
                              outlined: false
                            })
                            : null
                      }
                    </Box>
                  </Box>
                }
              />

              <SuccessDialog Controller={this}
                modal={true}
                width={420}
                actions={
                  ((actions) => {
                    actions.push(
                      <Button outlined minW120
                      >
                        Stay
                      </Button>
                    );
                    Core.isAdmin({ action: ACCOUNT_ACTION__MATCH_CANDIDATE }) &&
                      actions.push(
                        <Button outlined
                          onClick={
                            (event) => Core.go({
                              to: `/candidate/match/${this.state.id}`,
                            })
                          }
                          className='min-w-120'
                        >
                          Go to match
                        </Button>
                      );
                    actions.push(
                      <Button primary autoFocus
                        key={`action-button-done`}
                        onClick={
                          (event) => Core.go({
                            to: (
                              !Core.isAdmin() ||
                              Core.isAdmin({ action: ACCOUNT_ACTION__LIST_CANDIDATES })
                            )
                              ? '/candidates'
                              : '/'
                          })
                        }
                        className='min-w-120'
                      >
                        Done
                      </Button>
                    );
                    return actions;
                  })([])
                }
              />

            </Paper>

          </Box>

          <Box column h100 w100
            role='CandidateEdit__LeftSide'
            className={
              joinClassName([
                'px-05 hidden-on-small-screen',
                (
                  YES(state.hideRight) ||
                  NOT(getPdfLocalUrlCache(candidate.id))
                ) && 'd-none',
              ])
            }
            style={{ resize: 'both', maxWidth }}
          >
            <Paper
              className="mx-auto"
              style={
                getDefaultPaperStyle({
                  height: '100%',
                  width: '100%',
                  maxWidth
                })
              }
            >
              <CandidateDocumentPreview
                candidate={candidate}
                onLoad={() => this.setState()}
              />
            </Paper>
          </Box>

        </Box>

        <WarningMessage show={!!candidateNotFound}>
          <strong>Candidate {_candidateId} not found</strong>
        </WarningMessage>

      </Page>
    );
  }
}

export default withTranslation()(CandidateEdit);

export function CandidateEdit__state() {
  return Obj(Core.getKeyValue('CandidateEditController')).state;
}

export function CandidateEdit__updateState(update = {}, callback = () => null) {
  let method = Core.getKeyValue('CandidateEditController')?.setStateStore;
  return (method instanceof Function ? method : (() => null))(update, callback);
}

export function CandidateEdit__cancel() {
  let method = Core.getKeyValue('CandidateEditController')?.cancel;
  return (method instanceof Function ? method : (() => null))();
}

export function getPersistedCandidate() {
  return Core.getKeyValue(CANDIDATE_EDIT__PERSISTED__KEY) || setPersistedCandidate();
}

export function setPersistedCandidate(candidate) {
  candidate = Obj(candidate);
  return Core.setKeyValue(
    CANDIDATE_EDIT__PERSISTED__KEY,
    candidate.id ? candidate : mapCandidate(getCandidateModel({ extended: true }))
  );
}

export function unsetPersistedCandidate() {
  return Core.setKeyValue(CANDIDATE_EDIT__PERSISTED__KEY);
}
