import Core from '../../../lib/Core';
import {
  getParams
} from '../../../lib/URL.lib';
import Page from '../../Layout/Page';
import Paper from '../../Layout/Wrappers/Paper';
import {
  EmployerManageTemplatesContent
} from './EmployerManageTemplatesContent';

export function EmployerManageTemplatesPage(props) {
  const { employerId } = getParams({ pattern: '/employer/manage/templates/:employerId' });
  console.debug({ employerId });
  return (
    <Page showBack={false}
      title={<>{Core.getUserName()} - Manage Templates</>}
    >
      <Paper className='p-1 h-100 flex-col m-auto scroll' style={{ maxWidth: 900 }}>
        <EmployerManageTemplatesContent employerId={employerId} />
      </Paper>
    </Page>
  )
}
