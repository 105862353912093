import Chip from '../../../Layout/Wrappers/Chip';

export default function stringToChips(words) {

  if (typeof words === 'number') {
    words = words.toString();
  }
  if (typeof words === 'string') {
    if (words === '') {
      return null;
    } else if (words.includes(',')) {
      words = words.split(/[,]/);
    } else {
      words = [words];
    }
  }

  return (
    words.map((word, index) => {
      return (
        <Chip
          key={index}
          className="chip slim-chip"
          variant="outlined"
          size="small"
          label={word}
        />
      );
    })
  );
};