import {
  useRef
} from "react";
import Core from "../../../lib/Core";
import {
  getPdfLocalUrl
} from '../../../lib/File/getPdfLocalUrl.tool';
import {
  Fun
} from '../../../lib/Function.lib';
import {
  getShortURL,
  validateURL
} from '../../../lib/GenericTools.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  mapEmployer
} from '../../../lib/models/employer';
import {
  mapJob
} from '../../../lib/models/job';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  COLLECTION__EMPLOYERS,
  COLLECTION__JOBS, readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import {
  Str
} from '../../../lib/String.lib';
import formatURL from "../../../lib/tools/formatURL";
import {
  joinClassName
} from '../../Layout/Libraries/Theme.lib';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import {
  Html
} from '../../Layout/Wrappers/Html';
import ResumeMatch from "../../Shared/ResumeMatch";

export default function CandidateDocumentPreview(props) {
  let {
    candidate = {},
    jobId,
    employerId,
  } = props;
  const iframeRef = useRef();
  let { current: $iframe = {} } = iframeRef;
  let [state, updateState] = useState({
    source: 'PDF',
    resumeHTML: null,
    resumeTXT: null
  });
  let {
    resumePdfUrl,
    resumeTxtUrl,
    htmlResumeUrl,
    /** */
    gitHubURL,
    linkedInURL,
    stackoverflowUrl,
    otherLinks = '',
    resumes = [],
    /** */
  } = candidate;
  let resumeFiles = [
    { url: formatURL(linkedInURL), type: 'SITE', label: <>LinkedIn</> },
    { url: formatURL(resumePdfUrl || (resumes[0]?.url)), type: 'PDF', label: 'PDF' },
    { url: formatURL(resumeTxtUrl), type: 'TXT', label: 'Skill Text' },
    { url: formatURL(htmlResumeUrl), type: 'HTML', label: 'HTML' },
    /** */
    { url: formatURL(gitHubURL), type: 'SITE', label: 'GitHub' },
    { url: formatURL(stackoverflowUrl), type: 'SITE', label: 'Stack overflow' },
    [...String(otherLinks || '').split(/\n| - |\s|(,|;|\|)\s?/).map(
      (url) => ({ url: formatURL(url), type: 'SITE', label: getShortURL(url) })
    ).filter(
      ({ url }) => validateURL(url)
    )][0],
    /** */
  ].filter(f => !!f && !!f.url);
  const _fetch = async () => {
    await updateState({
      jobSkills: Str(
        !!jobId && Obj(
          await readLoopbackRecord({
            collection: COLLECTION__JOBS,
            where: { id: jobId },
            limit: 1,
            fields: ['technicalSkills'],
            mapper: mapJob
          }).catch(Core.showError)
        )._technicalSkills
      ),
      employerSkills: Str(
        !!employerId && Obj(
          await readLoopbackRecord({
            collection: COLLECTION__EMPLOYERS,
            where: { id: employerId },
            limit: 1,
            fields: ['technicalSkills'],
            mapper: mapEmployer
          }).catch(Core.showError)
        )._technicalSkills
      )
    });
    CandidateDocumentPreview.fetching = false;
    CandidateDocumentPreview.fetched = true;
  }
  const _setIframeUrl = async (url) => {
    if (url) {
      $iframe.src = await getPdfLocalUrl({ id: candidate.id, url });
      Fun(props.onLoad)();
    }
  }
  if (candidate && candidate.id !== state.prev) {
    let resumePDF = resumeFiles.find(r => r.type === 'PDF');
    _setIframeUrl(resumePDF?.url).then(async () => {
      await updateState({ prev: candidate.id, source: resumePDF?.label });
      if (!CandidateDocumentPreview.fetching && !CandidateDocumentPreview.fetched) {
        CandidateDocumentPreview.fetching = true;
        CandidateDocumentPreview.fetched = false;
        _fetch();
      }
    });
  }
  return (
    <Box column h100 w100
      role='CandidateDocumentPreview'
    >
      <Box row w100 scrollX noWrap alignCenter
        role='CandidateDocumentPreview__Header'
        className="p-1"
        style={{ minHeight: 42 }}
      >
        {(() => {
          return resumeFiles.map((resume) =>
            resume.type === 'TXT'
              ? (
                <ResumeMatch
                  key={`document-preview-${resume.url}`}
                  candidateResume={resume.url}
                  jobSkills={Str(state.jobSkills)}
                  employerSkills={Str(state.employerSkills)}
                  element={(cb) => {
                    return (
                      <Button small mr1 minW80
                        variant={state.source === resume.type ? 'outlined' : 'standard'}
                        className="p-0 px-1"
                        onClick={ev => cb()}
                      >
                        {resume.label}
                      </Button>
                    );
                  }}
                  onClick={resumeTXT => {
                    updateState({ source: resume.type, resumeTXT });
                  }}
                />
              )
              : (
                <Button small mr1 minW80
                  key={`document-preview-${resume.url}`}
                  variant={state.source === resume.label ? 'outlined' : 'standard'}
                  className="p-0 px-1"
                  onClick={ev => {
                    if (resume.type === 'SITE') {
                      Core.openPopUp(resume.url);
                    }
                    else if (resume.type === 'HTML') {
                      const onSuccess = result => {
                        updateState({ source: resume.label, resumeHTML: result });
                      }
                      function getText() {
                        // read text from URL location
                        var request = new XMLHttpRequest();
                        request.open('GET', resume.url, true);
                        request.send(null);
                        request.onreadystatechange = function () {
                          if (request.readyState === 4 && request.status === 200) {
                            var type = request.getResponseHeader('Content-Type');
                            if (type.indexOf("text") !== 1) {
                              onSuccess(request.responseText);
                              return request.responseText;
                            }
                          }
                        }
                      }
                      getText();
                    }
                    else {
                      updateState({ source: resume.label });
                    }
                  }}
                >
                  {resume.label}
                </Button>
              )
          );
        })()}
      </Box>
      <Box column h100 scrollY
        role='CandidateDocumentPreview__Content'
        className='flex-align-left-top'
      >
        {state.source === 'TXT' && state.resumeTXT && (
          <Html
            role='CandidateDocumentPreview__TXT'
            className="flex-wrap flex-align-left-top break-all p-2"
          >
            {state.resumeTXT}
          </Html>
        )}
        {state.source === 'HTML' && state.resumeHTML && (
          <Html
            role='CandidateDocumentPreview__HTML'
            className="flex-wrap flex-align-left-top break-all p-2"
          >
            {state.resumeHTML}
          </Html>
        )}
        <iframe
          ref={iframeRef}
          title="resume"
          style={{ border: 0, width: '100%', height: '100%' }}
          className={
            joinClassName([
              (
                state.source === 'TXT' ||
                state.source === 'HTML'
              ) && 'd-none'
            ])
          }
          onLoad={() => {
            updateState({ iframeLoaded: true })
          }}
          src={resumePdfUrl}
        />
      </Box>
    </Box>
  );
}
