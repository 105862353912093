
const LocationSectionUI = {
  candidateLocations: {
    label: 'Home location',
    subtitle: 'Please select candidate\'s home city (or state)',
    danger: 'Looks like you are looking for remote position, please specify your home location. contact admin to add more locations',
    placeholder: 'Type or select city or metro area'
  },
  inOfficeRemoteFlags: {
    title: 'Desired Location Options: select all applicable',
    info: 'Desired Location Options'
  },
  officeLocations: {
    title: 'Desired in office locations',
    subtitle: 'Contact admin to add more locations',
    disabled: 'Choose full-week or part-week to enable this input',
    danger: 'Looks like you are looking for in office position, please specify your desired office locations. contact admin to add more locations'
  },
}
export default LocationSectionUI;