import moment from "moment";
import React, {
  useEffect,
  useRef,
  useState
} from "react";
import {
  useTranslation
} from "react-i18next";
import {
  ENGAGEMENT__STATE_CLOSED,
  ENGAGEMENT__STATE_OPEN,
  STAGE_CONFIRMATION,
  STAGE_GUARANTEE,
  STAGE_OFFER,
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STAGE_SUBMISSION
} from '../../../dictionaries/Engagement.dic';
import {
  MDASH
} from '../../../lib/Constants';
import Core from "../../../lib/Core";
import Engagement, {
  getEngagementUrl
} from "../../../lib/Engagement";
import {
  mapAccountEmployerDetails
} from '../../../lib/mappers/AccountEmployerDetails.mapper';
import {
  mapAccount
} from '../../../lib/models/account';
import {
  AccountEmployerDetailsQuery
} from '../../../lib/queries/AccountEmployerDetails.query';
import {
  ROLE__EMPLOYER_RECRUITER
} from '../../../lib/services/Accounts/Session.lib';
import {
  COLLECTION__ACCOUNTS,
  COLLECTION__CANDIDATES,
  readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import {
  compileText
} from '../../../lib/String.lib';
import {
  showAlert
} from '../../Dialogs/AlertDialog';
import Button from '../../Layout/Wrappers/Button';
import {
  ErrorMessage
} from '../../Layout/Wrappers/Message';
import Paper from '../../Layout/Wrappers/Paper';
import Loader from "../../Shared/Loader";

function AccountDetails(props) {
  let { account, model } = props;
  account = account || model;
  return (
    (account.role === ROLE__EMPLOYER_RECRUITER)
      ? <AccountEmployerDetails account={account} />
      : <AccountRecruiterDetails account={account} />
  );
}

export function AccountEmployerDetails(props) {
  let { account } = props;
  const [state, setState] = useState({});
  let { busy, error, accountDetails } = state;
  const _updateState = async (update = { __updatedAt: Date.now() }) => new Promise((resolve) => {
    setState((prevState) => (update = { ...prevState, ...update }));
    setTimeout(() => resolve(update));
  });
  setTimeout(async () => {
    if (busy || error || accountDetails) { return; }
    try {
      await _updateState({ busy: true });
      await _updateState({
        accountDetails: await readLoopbackRecord({
          collection: COLLECTION__ACCOUNTS,
          mapper: mapAccount,
          ...(compileText(AccountEmployerDetailsQuery, { ACCOUNT_ID: account.id }))
        }),
        busy: false
      });
    }
    catch (error) {
      await _updateState({ error });
    }
  });
  const { offers = [], placements = [] } = mapAccountEmployerDetails(accountDetails);
  console.debug('AccountEmployerDetails:state', state);
  return (
    <>
      <Loader loading={!error && busy} />
      {accountDetails && (
        <Paper className="cursor-default p-2 c-gray">
          <div>
            {offers.length} offers
          </div>
          <ul>
            {offers.map(engagement => (
              <EngagementInfo
                key={`account_employer_details__offer__${engagement?.id}`}
                engagement={engagement}
              />))}
          </ul>
          <div>
            {placements.length} placements
          </div>
          <ul>
            {placements.map(engagement => (
              <EngagementInfo
                key={`account_employer_details__placement__${engagement?.id}`}
                engagement={engagement} />)
            )}
          </ul>
        </Paper >
      )}
      <ErrorMessage show={!!error}>
        {error}
      </ErrorMessage>
    </>
  );

}

export function EngagementInfo({ engagement }) {
  const { job, candidate } = engagement;
  return (
    <li>
      <Button flat small mr1
        onClick={(event) => {
          Core.openPopUp(getEngagementUrl({ engagement }), 1600);
        }}
        startIcon='visibility'
      >
        Engagement view
      </Button>
      <span
        className='anchor mr-1'
        onClick={(event) => {
          Core.openPopUp(Core.getPath(`job/edit/${job.id}`), 1600);
        }}
      >
        {job.jobTitle}
      </span>
      {MDASH}
      <span
        className='anchor ml-1'
        onClick={(event) => {
          Core.openPopUp(Core.getPath(`candidate/edit/${candidate.id}`), 1600);
        }}
      >
        {candidate._name}
      </span>

    </li>
  )
}

export function AccountRecruiterDetails(props) {
  let { account } = props;
  const accountId = useRef();
  const [candidates, setCandidates] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  async function fetchCandidates() {
    setLoading(true);
    setTimeout(async () => {
      await readLoopbackRecord({
        collection: COLLECTION__CANDIDATES,
        where: { accountId: account.id },
        fields: ['id']
      })
        .catch(message => {
          setError(message);
          showAlert({ message, severity: 'error' });
        })
        .then(candidates => {
          account.candidates = candidates;
          setCandidates(candidates);
        });
      accountId.current = account.id;
      setLoading(false);
    });
  }
  if (!error && !loading && !candidates) {
    fetchCandidates();
  }
  const [engagements, setEngagements] = React.useState();
  function fetchEngagements() {
    setLoading(true);
    setTimeout(async () => {
      let _engagements = [];
      let candidatesBatch = [];
      let callCount = 0;
      await Promise.allSettled(
        candidates.map(async ({ id }, index) => {
          if ((candidatesBatch.length === 100) || (index === candidates.length - 1)) {
            if (candidates.length === 1) {
              candidatesBatch.push(id)
            }
            let _candidateBatch = [...candidatesBatch];
            candidatesBatch = [];
            console.debug(_candidateBatch.length, index, candidates.length - 1, callCount++);
            await Engagement.getWhere(
              { candidateId: { inq: _candidateBatch } }, undefined,
              { fields: ['id', 'stage', 'state', 'status'] },
              false,
              { source: `Accounts__Card__AccountDetails` }
            ).then(engagements => {
              _engagements = [..._engagements, ...engagements];
            });
          }
          else {
            candidatesBatch.push(id);
          }
          return candidatesBatch;
        })
      ).catch(message => {
        setError(message);
        showAlert({ message, severity: 'error' });
      });
      account.engagements = _engagements;
      setEngagements(_engagements);
      setLoading(false);
    });
  }

  if (!error && !loading && !engagements && candidates) {
    fetchEngagements();
  }

  useEffect(() => {
    if (!loading && candidates && !!accountId.current && !!account?.id && accountId.current !== account?.id) {
      setLoading(true);
      setTimeout(async () => {
        setCandidates();
        setEngagements();
        setLoading(false);
      });
    }
  }, [account, candidates, error, loading])

  const modelIsAnAccount = (account.___model___ === 'Account');

  const { t } = useTranslation();

  const alternativeCmp = <Loader />;

  if (modelIsAnAccount && candidates && engagements) {
    const totalCandidates = candidates.length;
    const totalEngagements = engagements.length;
    const closedEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_CLOSED)
    ).length;
    const confirmationEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_CONFIRMATION)
    ).length;
    const reviewEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_REVIEW)
    ).length;
    const screenEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_SCREEN)
    ).length;
    const onsiteEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_ONSITE)
    ).length;
    const guaranteeEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_GUARANTEE)
    ).length;
    const offerEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_OFFER)
    ).length;
    const submissionEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_SUBMISSION)
    ).length;
    const submissions = candidates
      .map(cand =>
        cand.introduced
          ? moment(cand.introduced)
            .toDate()
            .getTime()
          : 0
      )
      .filter(time => !!time);
    const lastSubmissionTime = Math.max.apply(null, submissions);
    const lastSubmissionDate = moment(lastSubmissionTime).toISOString();
    const lastSubmission = submissions.length
      ? moment(lastSubmissionTime).format("MM/DD/YY")
      : 0;
    const componentEngagements = (
      <Paper className="cursor-default p-2 c-black-medium">
        <span title={t('Total of Candidates')}>{totalCandidates} candidates</span>
        &nbsp;|&nbsp;
        <span title="Total of Engagements">{totalEngagements} engagements</span>
        <br />
        {loading ? <Loader /> : (
          engagements.length
            ? (
              <>
                <span title="Engagements Closed status">
                  Closed ({closedEngagements})
                </span>
                &nbsp;|&nbsp;
                <span title="Engagements Open and Review stage">
                  Review ({reviewEngagements})
                </span>
                &nbsp;|&nbsp;
                <span title="Engagements Open and Onsite stage">
                  On Site ({onsiteEngagements})
                </span>
                &nbsp;|&nbsp;
                <span title="Engagements Open and Offer stage">
                  Offer ({offerEngagements})
                </span>
                &nbsp;|&nbsp;
                <span title="Engagements Open and Guarantee stage">
                  Guarantee ({guaranteeEngagements})
                </span>
                &nbsp;|&nbsp;
                <span title="Engagements Open and Screen stage">
                  Screen ({screenEngagements})
                </span>
                &nbsp;|&nbsp;
                <span title="Engagements Open and Confirmation stage">
                  Confirmation ({confirmationEngagements})
                </span>
                &nbsp;|&nbsp;
                <span title="Engagements Open and Submission stage">
                  Submission ({submissionEngagements})
                </span>

                <br />

                <span title={`Last submission: ${lastSubmissionDate}`}>
                  {!!lastSubmission ? `Last submission date: ${lastSubmission}` : ""}
                </span>

              </>
            )
            : ''
        )}

      </Paper >
    );
    return (Core.isAdminOrCoordinator() && modelIsAnAccount) ? componentEngagements : alternativeCmp;
  }

  return alternativeCmp;

}

export {
  AccountDetails,
  AccountDetails as default
};
