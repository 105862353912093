import {
  createTheme
} from '@mui/material/styles';
import {
  uniqueId
} from 'lodash';
import {
  join,
  sanitizeArr
} from '../../../lib/Array.lib';
import {
  NOT
} from '../../../lib/Boolean.lib';
import {
  flattenObject,
  Obj,
  stringify
} from '../../../lib/Object.lib';
import {
  joinKeyName,
  Str,
  trim
} from '../../../lib/String.lib';

export const KEY__WRAPPER_FLAGS = '___flags___';

export const THEME__COLOR__PRIMARY = 'primary';
export const THEME__COLOR__SECONDARY = 'secondary';
export const THEME__COLOR__SUCCESS = 'success';
export const THEME__COLOR__ERROR = 'error';
export const THEME__COLOR__WARNING = 'warning';
export const THEME__COLOR__DEFAULT = 'default';
export const THEME__COLOR__INHERIT = 'inherit';
export const THEME__VARIANT__CONTAINED = 'contained';
export const THEME__VARIANT__OUTLINED = 'outlined';
export const THEME__VARIANT__FILLED = 'filled';
export const THEME__VARIANT__TEXT = 'text';
export const THEME__VARIANT__STANDARD = 'standard';
export const THEME__VARIANT__STYLED = 'styled';
export const THEME__VARIANT__FLAT = 'flat-styled';
export const THEME__SIZE__XS = 'xs';
export const THEME__SIZE__SMALL = 'small';
export const THEME__SIZE__MEDIUM = 'medium';
export const THEME__SIZE__LARGE = 'large';
export const THEME__SIZE__XL = 'xl';

const Theme = createTheme({
  palette: {
    primary: {
      main: colors.purple.common,
    },
    secondary: {
      main: colors.cyan.common,
    },
    gray: {
      main: colors.gray.common,
    },
    currentColor: {
      main: colors.gray.common,
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          color: colors.black.dark
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: colors.black.medium
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: '1.25rem'
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: 36,
          '&.MuiOutlinedInput-root': {
            backgroundColor: colors.white.common,
            '& fieldset': {
              borderColor: colors.black.light,
            },
            '&:hover fieldset': {
              borderColor: colors.black.common,
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.purple.common,
            },
          },
          '& input': {
            padding: '4.5px 11px !important',
          },
          '&.MuiInputBase-multiline': {
            backgroundColor: colors.white.common,
            padding: '4.5px 11px',
            '& textarea': {
              padding: '0',
            },
          },
          '&.bg-transparent': {
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.selector': {
            height: 36,
            backgroundColor: colors.white.common,
            border: `1px solid ${colors.black.light}`,
            borderRadius: '4px',
            '&:hover': {
              outline: `1px solid ${colors.purple.common}`,
            },
          },
          '&.selector-focused': {
            border: `2px solid ${colors.purple.common}`,
          }
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: colors.purple.lighter,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '&:not(.Mui-disabled):hover *': {
            color: `${colors.purple.common} !important`,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          width: '100%',
          '&.styled tbody td': {
            backgroundColor: colors.green.lighter,
          },
          '&.styled tbody td.sticky-start, &.styled tbody td.sticky-end': {
            backgroundColor: colors.green.solid.lighter,
          },
          '&.styled tbody tr:hover td': {
            backgroundColor: colors.purple.solid.lighter,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: colors.cyan.common,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td, &:last-child th': {
            border: 0,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
        head: {
          fontWeight: 'bold',
          fontSize: '1rem',
          color: colors.white.common
        },
        body: {
          fontSize: '0.875rem',
        },
      },
    },
  }
});
export default Theme;

export function joinClassName(classes) {
  return join(sanitizeArr(classes), ' ');
}

export function getCssVar(variable) {
  const rootStyles = getComputedStyle(document.documentElement);
  return trim(rootStyles.getPropertyValue(variable))
}

/**
 * 
 * @param {string} wrapperName 
 * @param {object} wrapperProps
 * @returns 
 */
export function mapWrapperName(
  wrapperName,
  wrapperProps,
  flags = false
) {
  const _tag = Symbol.toStringTag;
  wrapperName = (
    wrapperProps.role ||
    trim(wrapperName) ||
    'UnknownWrapper'
  );
  wrapperProps = Obj(wrapperProps);
  Object.assign(wrapperProps, {
    id: trim(wrapperProps.id) || joinKeyName(
      [
        wrapperName,
        uniqueId()
      ]
    ),
    role: wrapperName,
    [_tag]: wrapperName
  });
  if (flags) {
    wrapperProps[KEY__WRAPPER_FLAGS] = (
      wrapperProps[KEY__WRAPPER_FLAGS] ||
      stringify(flattenObject(flags))
    );
  }
  return wrapperProps;
}

export function mapWrapper({
  props = {},
  id = props.id || uniqueId(),
  role = props.role || 'Unknown',
  name = props.name || joinKeyName([role, id]),
  assign = {},
  flags = {}
}) {
  assign = {
    role,
    id,
    name,
    ...assign,
    [Symbol.toStringTag]: joinKeyName([role, name, id])
  };
  if (flags.debug) {
    assign[KEY__WRAPPER_FLAGS] = (
      props[KEY__WRAPPER_FLAGS] ||
      stringify(flattenObject(flags))
    );
  }
  Object.assign(props, assign);
  if (flags.debug) {
    console.debug(
      'Theme__mapWrapper__flags...',
      '\n', flags,
      '\n', props,
    );
  }
  return props;
}

export function hasNoColor({
  className = '',
  style = {}
} = {}) {
  return NOT(
    joinClassName([
      ...Object.keys(Obj(style)),
      Str(className)
    ]).match(
      /((^|\W)c-|color)/i
    )
  );
}

export function hasNoMargin({
  className = '',
  style = {}
}) {
  return NOT(
    joinClassName([
      ...Object.keys(style),
      className
    ]).match(
      /((^|\W)m(t|b|r|l|x|y)?-|margin)/i
    )
  );
}

export function hasNoHeight({
  className = '',
  style = {}
} = {}) {
  return NOT(
    joinClassName([
      ...Object.keys(Obj(style)),
      Str(className)
    ]).match(
      /((^|\W)h(t|b|r|l|x|y)?-|height)/i
    )
  );
}

export function hasNoWidth({
  className = '',
  style = {}
} = {}) {
  return NOT(
    joinClassName([
      ...Object.keys(Obj(style)),
      Str(className)
    ]).match(
      /((^|\W)w(t|b|r|l|x|y)?-|width)/i
    )
  );
}

export function hasNoTextTransform({
  className = '',
  style = {}
} = {}) {
  return NOT(
    joinClassName([
      ...Object.keys(Obj(style)),
      Str(className)
    ]).match(
      /((^|\W)tt-|textTransform)/i
    )
  );
}

export function hasNoSize({
  className = '',
  style = {}
} = {}) {
  return NOT(
    joinClassName([
      ...Object.keys(Obj(style)),
      Str(className)
    ]).match(
      /((^|\W)f-|fontSize)/i
    )
  );
}

export function hasNoAlignment({
  className = '',
  style = {}
} = {}) {
  return NOT(
    joinClassName([
      ...Object.keys(Obj(style)),
      Str(className)
    ]).match(
      /((^|\W)align|space-between)/i
    )
  )
}
