import { Button } from "@mui/material";
import React, { useState } from "react";
import AccountLib from "../../lib/Account";
import Employer from "../../lib/Employer";
import { getShortURL } from "../../lib/GenericTools.lib";
import { EMPLOYER__PRIMARY_CONTACT_NOTES } from "../../lib/models/employer";
import { EditCandidateRecruiters } from "../Candidates/Forms/EditCandidateRecruiters";
import EmployerUI from '../Employers/EmployerUI.dic';
import InternalSubmissionInstructions from '../Employers/Edit/Forms/InternalSubmissionInstructions';
import Fieldset from '../Layout/Wrappers/Fieldset';
import RichTextBox from "../Layout/Wrappers/RichTextBox";
import Row from "../Layout/Wrappers/Row";
import AppUI from '../../dictionaries/AppUI.dic';
import { FeedbackLog } from './FeedbackLog';
import { SubmissionNote } from './SubmissionNotes';

export function ContactsAndATS({
  engagement,
  employerTodoReminderRecipients
}) {
  const {
    candidate,
    job
  } = engagement;
  const {
    employer
  } = job;
  const {
    account: recruiter
  } = candidate;
  console.debug({
    candidate,
    job,
    recruiter,
    employer,
    employerTodoReminderRecipients
  });
  const [primaryContactNotes, setPrimaryContactNotes] = useState(employer.primaryContactNotes);
  const [privateNotes, setPrivateNotes] = useState(recruiter.privateNotes);
  return (
    <>

      <InternalSubmissionInstructions
        title={
          <>
            Employer:
            &nbsp;
            {EmployerUI.internalSubmissionInstructions.title}
          </>
        }
        entity={employer}
      />

      <Row className='mt-3'>
        <InternalSubmissionInstructions
          title={
            <>
              Job:
              &nbsp;
              {EmployerUI.internalSubmissionInstructions.title}
            </>
          }
          entity={job}
        />
      </Row>

      <Fieldset
        title='Submission Methods'
        subtitle={
          <div className='f-md'>
            {employer._submissionMethods.length ? employer._submissionMethods : <span className="c-black-medium f-sm">Empty List</span>}
            {employer.atsUrl && (
              <div>
                <a href={employer.atsUrl} target="_blank" rel="noreferrer">{employer.atsApiProviders[employer.atsTypeId]?.apiName || getShortURL(employer.atsUrl)}</a>
              </div>
            )}
          </div>
        }
      />

      <Row className='mt-3'>
        <FeedbackLog
          title='Engagement feedback'
          engagement={engagement}
          wrapperProps={{ className: '' }}
        />
      </Row>

      <Row className='mt-3'>
        <SubmissionNote
          engagement={engagement}
        />
      </Row>

      <Fieldset
        title='Employer Contact Notes'
        subtitle='Who did the intake, role (founder, hiring manager, recruiter, coordinator), best way to contact, how firm is SLA, ok to text call'
        actionBar={
          <div className="d-flex flex-align-left-center">
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              className="ml-2"
              onClick={ev => {
                setPrimaryContactNotes(`${EMPLOYER__PRIMARY_CONTACT_NOTES}<br/>${primaryContactNotes}`);
                clearTimeout(ContactsAndATS.timeout);
                ContactsAndATS.timeout = setTimeout(() => Employer.update(employer.id, { primaryContactNotes }), 3000);
              }}>
              <span className="capitalize">
                Insert template
              </span>
            </Button>
          </div>
        }

      >
        <RichTextBox
          value={primaryContactNotes}
          onChange={(primaryContactNotes) => {
            setPrimaryContactNotes(primaryContactNotes);
            clearTimeout(ContactsAndATS.timeout);
            ContactsAndATS.timeout = setTimeout(() => Employer.update(employer.id, { primaryContactNotes }), 3000);
          }}
        />
      </Fieldset>

      <Fieldset
        title='Employer Reminder Email'
        subtitle={
          <div className='f-md'>
            <div>
              To: {employerTodoReminderRecipients.to.map(c => c.name).join(', ')}
            </div>
            {!!employerTodoReminderRecipients.cc?.length && (
              <div>
                Cc: {employerTodoReminderRecipients.cc.map(c => c.name).join(', ')}
              </div>
            )}
          </div>
        }
      />

      <Fieldset
        title='Recruiter Info (10x10 Private Note)'
        subtitle='Account > Process Step > Admin Block'
      >
        <RichTextBox
          name="privateNotes"
          placeholder={AppUI.placeholder.multiline}
          value={privateNotes}
          onChange={privateNotes => {
            setPrivateNotes(privateNotes);
            clearTimeout(ContactsAndATS.timeout);
            ContactsAndATS.timeout = setTimeout(() => AccountLib.update(recruiter.id, { privateNotes }), 3000);
          }}
        />
      </Fieldset>

      <Row className='mt-3 px-2'>
        <EditCandidateRecruiters
          recruiter={recruiter}
          candidate={candidate}
        />
      </Row>

    </>
  );
}

export default ContactsAndATS;
