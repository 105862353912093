import {
  Tooltip as MuiTooltip,
  tooltipClasses
} from '@mui/material';
import { styled } from '@mui/system';
import { NOT } from '../../../lib/Boolean.lib';
import {
  joinClassName,
  mapWrapper
} from '../Libraries/Theme.lib';
import { RefBox } from './Box';
import { getTooltipDefaultStyle } from './StyledTooltip';
import { Str } from '../../../lib/String.lib';

export default function Wrapper({
  title,
  titleStyle,
  placement,
  // ---- //
  component: Children = RefBox,
  acl = true,
  debug = false,
  disabled = false,
  onClick,
  // ---- //
  wrapper = null,
  wrapperProps = {},
  childrenProps = {},
  ...props
}) {
  if (!acl) { return null; }
  const flags = { debug, disabled };
  mapWrapper({
    role: 'Wrapper',
    props: wrapperProps,
    flags
  });
  mapWrapper(
    {
      role: 'Children',
      props: childrenProps,
      assign: props,
      flags
    }
  );
  if (!!Str(props.name).match(/typography/i)) {
    childrenProps.component = 'span';
  }
  if (disabled) {
    childrenProps.className = joinClassName([
      childrenProps.className,
      'disabled'
    ]);
  }
  else {
    childrenProps.onClick = onClick;
  }
  title = (title || props['aria-label']);
  if (title) {
    Object.assign(
      wrapperProps,
      {
        title,
        placement,
        children: <Children {...childrenProps} />,
      }
    );
    const Tooltip = styled(
      (styledProps) =>
        <MuiTooltip {...styledProps} arrow
          classes={{ popper: styledProps.className }}
        />
    )({
      [`& .${tooltipClasses.tooltip}`]: getTooltipDefaultStyle(titleStyle),
      [`& .${tooltipClasses.arrow}`]: { color: colors.black.darker },
    });
    wrapper = (
      <Tooltip {...wrapperProps} />
    );
  }
  else {
    if (NOT(disabled)) {
      Object.assign(childrenProps, {
        onClick
      });
    }
    wrapper = (
      <Children {...childrenProps} />
    );
  }
  return (wrapper);
}
