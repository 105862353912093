import _ from "lodash";
import Candidate from "../../../lib/Candidate";
import CandidateSkillsAction from "../../../lib/CandidateSkillsAction";
import Core from "../../../lib/Core";
import Engagement from "../../../lib/Engagement";
import FilterControl from "../../../lib/FilterControl";
import Job from "../../../lib/Job";
import {
  Obj
} from '../../../lib/Object.lib';
import FilterLib from "../../../lib/services/Filtering/Filter.lib";
import formatMoney from "../../../lib/tools/formatMoney";
import {
  YearsOfExperienceColor
} from "../../../lib/YearOfExperienceColor";
import mapCandidateSignalsOnChange from '../../Candidates/mapCandidateSignalsOnChange.tool';
import Button from '../../Layout/Wrappers/Button';
import Chip from '../../Layout/Wrappers/Chip';
import Dialog from '../../Layout/Wrappers/Dialog';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Slider from "../../Layout/Wrappers/Slider";
import CandidatePipe from "../Candidate/CandidatePipe";
import {
  NewFilterControl__state
} from '../FilterControl/NewFilterControl';
import {
  unitTestingPresetLocationMenusAll
} from "../Libraries/Match.lib";
import {
  getMatchEntities
} from '../Libraries/MatchList.lib';
import SingleCandidateCard from "../List/SingleCandidateCard";
import JobPipe from "./JobPipe";

let job = {};
let allMatches = [];

const fetchProfile = (jobId) => (cb) => {
  Job.get(jobId, (result) => {
    job = Obj(result);
    Engagement.getWhere({ jobId: job.id }, (engagement) => {
      job["tempSalaryMin"] = job.salaryMax;
      //April doesn't want it anymore as we have loose match buffer available
      // j['tempMinimumSalary'] = j.salaryMax*1.4;
      job["tempMinimumSalary"] = job.salaryMax;
      job["tempMinYearsOfExperience"] = job.minYearsOfExperience;
      job["tempMinimumExperience"] = job.minYearsOfExperience;
      !!cb && cb(job, engagement);
    }, {}, { source: `job__match__strategy__fetch_profile` });
  });
};

const fetchMatches = (params) => (cb, paramsExtra = {}) => {
  if (params.loadAll) {
    Candidate.getAll((candidates) => {
      allMatches = candidates;
      !!cb && cb(candidates);
    });
  }
  else {

    /* FILTERING FIELD FROM DB!! */
    const filters = {
      fields: [
        "id",
        "firstName",
        "lastName",
        "email",
        "phone",
        "yearsOfExperience",
        "minimumSalary",
        "workLocationIds",
        "visa",
        "platformRating",
        "technicalSkills",
        "linkedinUrl",
        "githubUrl",
        "stage",
        "state",
        "roles",
        "introduced",
        "accountId",
        "jobsPermitted",
        "positiveSignals",
        "negativeSignals",
        "jobsPitched",
        "jobsDeclined",
        "isDuplicate",
        "techSkillsInResume",
        "jobsLinkedIn",
        "techSkillsInNotes",
        "technologyDomain",
        "officeLocations",
        "desiredEmploymentTypes",
        "candidateLocations",
        "inOfficeRemoteFlags",
        'matchExclusions',
        'updatedAt',
        'calendarBookingLink',
        'jobsPermitted',
        'jobsPermittedAnswers',
        'employmentHistories',
        'educationHistories',
        'positiveSignalsManual',
        'negativeSignalsManual',
        'salaryConfidence',
        'sovrenDataId'

      ],
    };
    const { dateRange } = NewFilterControl__state();
    Candidate.getActiveCandidates(
      (candidates) => {
        allMatches = candidates;
        !!cb && cb(candidates);
      },
      {
        dateRange,
        filters
      }
    );
  }
};

const getDefaultFields = (job) => {
  return [
    { key: "visa", label: job._visaTransfer, checked: true },
    { key: "locationCandidate", label: job._locations, checked: true },
    { key: "stage", label: job._employeeStage, checked: false },
  ];
};

/** 
 * [2024-07-03][story_9166] 
 * @todo  Rename "object" param.
 * @todo  Why this strategy method doesn't use param "selected", opposed to Candidate?
 */
const filterControl = ({
  profile, // JOB
  object // LIST OF CANDIDATES
}) => {

  let preset = "^Active$";
  if (profile._roles && profile._roles.length) {
    preset += ("|^" + profile._roles.replace(/, |,/g, "$|^") + "$");
  }

  /**
   * If it is Job._jobType defined
   * then preset it to filter Candidates by
   * Candidate._desiredEmploymentTypes
   * story-3083-M1-1
   */
  if (profile._jobType && !!profile._jobType.length) {
    preset += `|^${profile._jobType.trim().replace(/\(|\)/g, ".+")}$`;
  }

  const label = getDefaultFields(profile);
  const fields = label;

  const filters = FilterControl.setInitialSearchMenu(
    object,
    _.cloneDeep(Candidate.menus),
    _.cloneDeep(Candidate.more),
    preset,
    profile,
    [],
    label,
    fields,
    getChips,
    { job: profile }
  );

  const { menus, more, sources, chips } = filters;

  const filteredMatches = filterCandidates(profile, object, [], menus, more);

  return {
    menus,
    more,
    sources,
    chips,
    filteredMatches,
    constraintsObjects: [],
    withoutConstraintsObjects: object,
  };
};

const skillsHandler = (candidate, cbUpdateCandidates) => async (skills, type) => {
  let oldSkills;
  let oldCandidateState;
  let oldCandidateStateIndex;
  let withoutThisCandidate;
  let updateOldCandidateState;
  let updatedCandidate;

  switch (type) {
    case "technicalSkills":
      oldSkills = candidate.technicalSkills;

      oldCandidateState =
        allMatches.find((c) => c.id === candidate.id) || candidate;
      oldCandidateStateIndex = allMatches.findIndex(
        (c) => c.id === candidate.id
      );
      withoutThisCandidate = allMatches.filter((c) => c.id !== candidate.id);

      updateOldCandidateState = {
        ...oldCandidateState,
        technicalSkills: skills,
      };

      updatedCandidate = [...withoutThisCandidate];

      updatedCandidate.splice(
        oldCandidateStateIndex,
        0,
        updateOldCandidateState
      );
      allMatches = [...updatedCandidate];

      !!cbUpdateCandidates && cbUpdateCandidates(allMatches);
      Candidate.update(
        candidate.id,
        {
          technicalSkills: skills,
        },
        (response) => {
          const dirtyAttrs = [
            {
              key: type,
              label: "Technical Skills",
              oldState: oldSkills,
              newState: skills,
            },
          ];
          CandidateSkillsAction.processUpdate(candidate, dirtyAttrs);
          Core.showSuccess("Saved Successfully!");
        }
      );
      break;
    case "technologyDomain":
      oldSkills = candidate.technologyDomain;

      oldCandidateState =
        allMatches.find((c) => c.id === candidate.id) || candidate;
      oldCandidateStateIndex = allMatches.findIndex(
        (c) => c.id === candidate.id
      );
      withoutThisCandidate = allMatches.filter((c) => c.id !== candidate.id);

      updateOldCandidateState = {
        ...oldCandidateState,
        technologyDomain: skills,
      };

      updatedCandidate = [...withoutThisCandidate];

      updatedCandidate.splice(
        oldCandidateStateIndex,
        0,
        updateOldCandidateState
      );

      allMatches = [...updatedCandidate];

      !!cbUpdateCandidates && cbUpdateCandidates(allMatches);
      Candidate.update(
        candidate.id,
        {
          technologyDomain: skills,
        },
        (response) => {
          const dirtyAttrs = [
            {
              key: type,
              label: "Technology Domain",
              oldState: oldSkills,
              newState: skills,
            },
          ];
          CandidateSkillsAction.processUpdate(candidate, dirtyAttrs);
          Core.showSuccess("Saved Successfully!");
        }
      );

      break;
    case "positiveSignals":
      oldSkills = [...candidate.positiveSignals];
      oldCandidateState = allMatches.find((c) => c.id === candidate.id) || candidate;
      await mapCandidateSignalsOnChange({
        candidate,
        signals: skills,
        key: 'positiveSignals',
        updater: update => {
          updateOldCandidateState = Object.assign(oldCandidateState, update);
          Candidate.update(
            candidate.id,
            update,
            (response) => {
              const dirtyAttrs = [
                {
                  key: type,
                  label: "Positive Signals",
                  oldState: oldSkills,
                  newState: update.positiveSignals,
                },
              ];
              CandidateSkillsAction.processUpdate(candidate, dirtyAttrs);
              Core.showSuccess("Saved Successfully!");
            }
          );
        }
      });
      break;
    case "negativeSignals":
      oldSkills = [...candidate.negativeSignals];
      oldCandidateState = allMatches.find((c) => c.id === candidate.id) || candidate;
      await mapCandidateSignalsOnChange({
        candidate,
        signals: skills,
        key: 'negativeSignals',
        updater: update => {
          updateOldCandidateState = Object.assign(oldCandidateState, update);
          Candidate.update(
            candidate.id,
            update,
            (response) => {
              const dirtyAttrs = [
                {
                  key: type,
                  label: "Negative Signals",
                  oldState: oldSkills,
                  newState: update.negativeSignals,
                },
              ];
              CandidateSkillsAction.processUpdate(candidate, dirtyAttrs);
              Core.showSuccess("Saved Successfully!");
            }
          );
        }
      });
      break;
    default:
      break;
  }
};

const segregateRolesChip = (candidate = {}, job = {}) => {
  let colorAlarm = "red";

  if (!!candidate.id && !!candidate._roles) {
    candidate._roles.split(",").forEach((roles) => {
      const rolesRegExp = new RegExp(roles.trim(), "gi").test(job._roles);
      if (!!rolesRegExp) colorAlarm = "";
    });

    return candidate._roles.split(",").map((roles) => {
      return staticRowDisplayChip(
        roles,
        job._roles,
        staticRowDisplayColor(roles, job, colorAlarm).getRolesColor()
      );
    });
  }
};

const segregateLocationChip = (candidate, job) => {
  let colorAlarm = "red";

  if (!!candidate.id && !!candidate._workLocationIds) {
    candidate._workLocationIds.split(",").forEach((loc) => {
      const locRegExp = new RegExp(loc.trim(), "gi").test(job._locations);
      if (!!locRegExp) colorAlarm = "";
    });

    return candidate._workLocationIds.split(",").map((loc) => {
      return staticRowDisplayChip(
        loc,
        job._locations,
        staticRowDisplayColor(loc, job, colorAlarm).getLocationColor()
      );
    });
  }
};

const saveContentCandidate = (candidate, callback) => (key, content) => {
  const oldCandidateState = allMatches.find((c) => c.id === candidate.id);
  const oldCandidateStateIndex = allMatches.findIndex(
    (c) => c.id === candidate.id
  );
  const withoutThisCandidate = allMatches.filter(
    (c) => c.id !== candidate.id
  );

  const updateOldCandidateState = {
    ...oldCandidateState,
    [key]: content,
  };

  let updatedCandidate = [...withoutThisCandidate];

  updatedCandidate.splice(oldCandidateStateIndex, 0, updateOldCandidateState);

  allMatches = [...updatedCandidate];

  !!callback && callback(allMatches);

  Candidate.update(
    candidate.id,
    {
      [key]: content,
    },
    (response) => {
      Core.showSuccess("Saved Successfully!");
    }
  );
};

const saveJob = (key, content) => {
  job = {
    ...job,
    [key]: content,
  };

  Job.update(
    job.id,
    {
      [key]: content,
    },
    () => {
      Core.showSuccess("Saved Successfully!");
    }
  );
};

const staticRowDisplayChip = (jobAtr, candAtr, color) => {
  const singleAttrJob = !!jobAtr ? jobAtr.toString().split(",") : [];

  if (!jobAtr || !candAtr) {
    return singleAttrJob.map((attr, index) => {
      return (
        <Chip
          key={index}
          className="slim-chip ui-job-match-strategy-chip-a"
          label={attr}
          size="small"
          variant="outlined"
          style={{
            border: `1px solid gray`,
          }}
        />
      );
    });
  }

  return singleAttrJob.map((attr, index) => {
    return (
      <Chip
        key={index}
        className="slim-chip ui-job-match-strategy-chip-b"
        label={attr}
        size="small"
        variant="outlined"
        style={{
          border: `${color === 'red' ? 2 : 1}px solid ${color}`,
        }}
      />
    );
  });
};
const staticRowDisplayColor = (candidate, job, color) => {
  let obj = {};

  obj.getSalaryColor = ((candidate, job) => () => {
    if (!candidate.minimumSalary || !job.salaryMax) {
      return "";
    }

    let color;

    if (candidate.minimumSalary <= job.salaryMax) {
      color = "green";
    } else if (candidate.minimumSalary <= 1.15 * job.salaryMax) {
      color = "grey";
    } else if (candidate.minimumSalary <= 1.4 * job.salaryMax) {
      color = "grey";
    } else {
      color = "red";
    }
    // console.log("salary " + color + " cand sal "+candidate.minimumSalary+ " job sal " + job.salaryMax)
    return color;
  })(candidate, job);

  obj.getVisaColor = ((candidate, job) => () => {
    let menu = Candidate.menus.find((obj) => obj.key === "visa");
    let myMappings = menu.mappings[job._visaTransfer] || [];
    return myMappings.includes(candidate._visa) ? "green" : "red";
  })(candidate, job);

  obj.getRolesColor = ((candidateRole, job) => () => {
    const rolesRegExp = new RegExp(candidateRole.trim(), "gi").test(job._roles);
    return !!rolesRegExp ? "green" : color;
  })(candidate, job);

  obj.getLocationColor = ((candidateLocationLabel, job) => () => {
    const locRegExp = new RegExp(candidateLocationLabel.trim(), "gi").test(
      job._locations
    );
    return !!locRegExp ? "green" : color;
  })(candidate, job);

  obj.getYearsXp = ((candidate, job) => () => {
    return YearsOfExperienceColor(job, candidate);
  })(candidate, job);
  return obj;
};

const renderJobPipe = ({ profile, selectedMatch }, callback) => {
  const { candidate, job } = getMatchEntities({ profile, selectedMatch });
  return (
    <JobPipe
      job={job}
      candidate={candidate}
      saveJob={saveJob}
      staticRowDisplayChip={staticRowDisplayChip}
      staticRowDisplayColor={staticRowDisplayColor}
      source="jobMatch"
    />
  );
};

const renderCandidatePipe = ({ profile, selectedMatch }, callback) => {
  const { candidate, job } = getMatchEntities({ profile, selectedMatch });
  return (
    <CandidatePipe
      candidate={candidate}
      job={job}
      skillsHandler={skillsHandler(candidate, callback)}
      saveContentCandidate={saveContentCandidate(
        candidate,
        callback
      )}
      source="jobMatch"
    />
  );
};

const renderListCard = ({
  match = {},
  ...props
}) => {
  return (
    <SingleCandidateCard {...props}
      key={`match_job__render_card__${match.id}`}
      match={match}
      staticRowDisplayChip={staticRowDisplayChip}
      staticRowDisplayColor={staticRowDisplayColor}
      segregateRolesChip={segregateRolesChip}
      segregateLocationChip={segregateLocationChip}
    />
  );
};

const renderEngagementCard = ({
  engagement = {},
  ...props
}) => {
  return (
    <SingleCandidateCard {...props}
      key={`match_job__render_card__${engagement.id}`}
      engagement={engagement}
      staticRowDisplayChip={staticRowDisplayChip}
      staticRowDisplayColor={staticRowDisplayColor}
      segregateRolesChip={segregateRolesChip}
      segregateLocationChip={segregateLocationChip}
    />
  );
};

const getChips = (job, menus, more, keywords) => {
  const chips = [];

  menus.forEach((menu) => {
    menu.items &&
      Object.keys(menu.items).forEach(
        (name) => menu.items[name] === true && chips.push({ name, menu: true })
      );
  });

  more.forEach((menu) => {
    menu.items &&
      Object.keys(menu.items).forEach((name) => {
        if (name === "Active") {
          const activeLabel = `${menu.label}:${name}`;
          menu.items[name] === true &&
            chips.push({ name: activeLabel, more: true });
        } else {
          menu.items[name] === true && chips.push({ name, more: true });
        }
      });
  });

  !!keywords.length && keywords.forEach((item) => chips.push(item));

  if (!!job.tempMinimumSalary && job.tempMinimumSalary !== 0) {
    let prefix = "";
    prefix = "Accepts Salary <=";

    chips.push({
      name: `${prefix} $${formatMoney(job.tempMinimumSalary, 0)}`,
      minimumSalary: true,
    });
  }

  if (!!job.maximumSalary && job.maximumSalary !== 500000) {
    chips.push({
      name: `Max Salary: $${formatMoney(job.maximumSalary, 0)}`,
      maximumSalary: true,
    });
  }

  if (!!job.tempMinimumExperience && job.tempMinimumExperience > 0) {
    let prefix = "";
    prefix = `has >= ${job.tempMinimumExperience}y exp`;

    chips.push({
      name: `${prefix}`,
      minimumXp: true,
    });
  }

  return chips;
};

const filterCandidates = (job, candidates, keywords, menus, more) => {

  unitTestingPresetLocationMenusAll({ job });

  let filtered = candidates;
  if (!filtered) {
    return [];
  }

  /** FILTER BY MINIMUM AND MAXIMUM SALARY */
  if (job && job.tempMinimumSalary !== 0) {
    filtered = filtered.filter((item) => {
      const candidate = item;
      let salaryGreaterThan = job.tempMinimumSalary;

      if (!!job.applyLooseMatch) {
        //increase salary requirement of given job for highly ranked candidates
        if (+candidate.platformRating === 5) {
          // A+
          salaryGreaterThan = 1.5 * salaryGreaterThan;
        } else if (+candidate.platformRating === 1) {
          // A-Top
          salaryGreaterThan = 1.3 * salaryGreaterThan;
        } else if (+candidate.platformRating === 2) {
          // B-Strong
          salaryGreaterThan = 1.15 * salaryGreaterThan;
        }
      }

      return (
        Number(String(item.minimumSalary || 0).replace(/(\..*)|\D/g, "")) <=
        Number(salaryGreaterThan)
      );
    });
  }

  if (job && job.tempMinimumExperience !== 0) {
    filtered = filtered.filter((item) => {
      const candidate = item;
      let experienceLessThan = job.tempMinimumExperience;

      if (!!job.applyLooseMatch) {
        //reduce years of experience requirement of given job for highly ranked candidates
        if (+candidate.platformRating === 5) {
          // A+
          experienceLessThan = 0.5 * experienceLessThan;
        } else if (+candidate.platformRating === 1) {
          // A-Top
          experienceLessThan = 0.7 * experienceLessThan;
        } else if (+candidate.platformRating === 2) {
          // B-Strong
          experienceLessThan = 0.85 * experienceLessThan;
        }
      }

      return (
        Number(String(item.yearsOfExperience || 0).replace(/(\..*)|\D/g, "")) >= Number(experienceLessThan)
      );
    });
  }

  /** FILTER BY KEYWORDS */
  if (!!keywords.length) {
    filtered = filtered.filter((item) => {
      return keywords.every((objKeyword) => {
        return item.___keys___.some((label) =>
          new RegExp(
            String(objKeyword.name)
              .replace("+", "\\+")
              .replace(".", "\\.")
              .replace("#", "\\#")
              .replace("(", "\\(")
              .replace(")", "\\)"),
            "i"
          ).test(label)
        );
      });
    });
  }
  filtered = FilterLib.filterCandidatesByMatchExclusion({
    candidates: filtered
  });
  filtered = FilterLib.filterItemsByCheckedOptionsInFilterBar({
    items: filtered,
    menus,
    more,
  });
  return [...filtered];
};

const candidateOfferSalary = (
  closeEvent,
  applyEvent,
  onChangeEvent,
  salary
) => {
  return (
    <Dialog open
      title='Filter by'
      content={
        <Fieldset
          title={
            <>
              Can Work for Salary less than: &nbsp; ${formatMoney(salary, 0)}
            </>
          }
        >
          <Slider
            name="minimumSalary"
            min={0}
            max={500000}
            step={10000}
            value={salary}
            onChange={(event, salary) => {
              !!onChangeEvent && onChangeEvent(salary);
            }}
          />
        </Fieldset>
      }
      actions={[
        <Button outlined minW120
          label="Cancel"
          onClick={(ev) => closeEvent()}
        />,
        <Button primary minW120
          label="Apply"
          onClick={(ev) => applyEvent(salary)}
        />
      ]}
    />
  );
};

const candidateOfferExperience = (
  closeEvent,
  applyEvent,
  onChangeEvent,
  experience
) => {
  return (
    <Dialog open
      title='Filter by'
      content={
        <Fieldset
          title={
            <>
              Candidate has xp equal or greater than:&nbsp;{" "}
              {formatMoney(experience, 0)} years
            </>
          }
        >
          <Slider
            name="minimumExperience"
            min={0}
            max={40}
            step={1}
            value={experience}
            onChange={(event, experience) => {
              !!onChangeEvent && onChangeEvent(experience);
            }}
          />
        </Fieldset>
      }
      actions={[
        <Button outlined minW120
          label="Cancel"
          onClick={(ev) => closeEvent()}
        />,
        <Button primary minW120
          label="Apply"
          onClick={(ev) => applyEvent(experience)}
        />
      ]}
    />
  );
};

const jobPutDownCandidates = (id, putDownJobs) => {
  Job.update(id, {
    putDownJobs,
  });
};

const disEngagementModel = ({
  matchStrength = '',
  candidate = {},
  employer = {},
  job = {},
  engagement = {},
  shouldTag = "",
  shouldNotTag = "",
  whyNoPrivateNote = "",
  whyNeedToReadCV = "",
  whyNoCategories = [],
  whyNoDetails = "",
  whyNoFieldsValues = [],
  reviewed,
}) => {
  return {
    source: "jobMatch",
    annotator: Core.getSessionEmail(),
    matchDecision: matchStrength,
    candidateName: job._name,
    employerName: Obj(employer).name,
    jobName: job.jobTitle,
    engagementStage: Obj(engagement).stage,
    engagementStatus: Obj(engagement).status,
    matchingUrl: !!candidate.id && !!job.id 
    ? Core.getPath(`job/match/${job.id}?selected=${candidate.id}`)
    : '',
    engagementUrl: !!engagement.id
      ? Core.getPath(`engagement/view/${engagement.id}`)
      : '',
    candidateUrl: !!candidate.id 
      ? Core.getPath(`candidate/edit/${candidate.id}`)
      : '',
    jobUrl: !!job.id
      ? Core.getPath(`job/edit/${job.id}`)
      : '',
    engagementId: engagement.id,
    jobId: job.id,
    candidateId: candidate.id,
    jobRoles: job._roles,
    reviewed,
    shouldTag,
    whyNoDetails,
    whyNoFieldsValues,
    whyNoCategories,
    shouldNotTag,
    whyNoPrivateNote,
    whyNeedToReadCV,
  };
};

export default function StrategyMain(id, param) {
  return {
    fetchProfile: fetchProfile(id),
    fetchMatches: fetchMatches(param),
    renderProfilePipe: renderJobPipe,
    renderMatchPipe: renderCandidatePipe,
    renderListCard,
    renderEngagementCard,
    filterControl,
    filterMatches: filterCandidates,
    salaryPopup: candidateOfferSalary,
    experiencePopup: candidateOfferExperience,
    getChips: getChips,
    putDownCallback: jobPutDownCandidates,
    disEngagementModel,
    matchKey: "candidate",
  };
};
