import {
  Component
} from 'react';
import { MDASH } from '../../../lib/Constants';
import JobFeedbacks from '../../Jobs/Edit/Forms/JobFeedbacks';
import styles from '../../Layout/Libraries/Styles.dic';
import Card from '../../Layout/Wrappers/Card';
import Chip from '../../Layout/Wrappers/Chip';
import Divider from '../../Layout/Wrappers/Divider';
import { Html } from '../../Layout/Wrappers/Html';
import Typography from '../../Layout/Wrappers/Typography';

class RequirementsSection3 extends Component {
  renderList = (data) => {
    if (!Array.isArray(data)) {
      data = [];
    }

    return data.map((obj, index) => {
      return (
        <div key={index} style={styles.CandidateMatch.margins.mBottom20}>
          <div style={styles.CandidateMatch.margins.mBottom10}>
            {obj.chips.map((chip, key) => {
              return (
                <Chip
                  key={key}
                  variant='outlined'
                  style={{ border: 'solid 0.5px #3fb213' }}
                  label={chip}
                  onClick={() => { }}
                />
              );
            })}
          </div>
          <div>{obj.label}</div>
        </div>
      );
    });
  };

  render() {

    const { job = {} } = this.props;

    const jobFeedbacks = (
      <JobFeedbacks readOnly
        job={job}
        className='border-none'
      />
    );

    return (
      <>

        {!!job.qualificationNotes &&
          <Card
            role='QualificationNotesCard'
            className='mb-05'
          >
            <Typography alignCenter
              className='m-1'
            >
              <Typography strong blackDark large>
                INSIDER SCOOP
              </Typography>
              <Typography className='mx-1'>
                {MDASH}
              </Typography>
              <Typography>qualificationNotes</Typography>
            </Typography>
            <Divider />
            <Html
              value={job.qualificationNotes}
              className='w-unset m-1 f-md fw-400 c-black-dark'
            />
          </Card>
        }

        {jobFeedbacks &&
          <Card
            role='JobFeedbacksCard'
            className='p-0 mb-05'
          >
            {jobFeedbacks}
          </Card>
        }

        {!!job && !!job.publicNotes && (
          <Card
            role='PublicNotesCard'
            className='mb-05'
          >
            <Typography strong large alignCenter blackDark
              className='m-1'
            >
              PUBLIC NOTES
            </Typography>
            <Divider />
            <Html
              value={job.publicNotes}
              className='w-unset m-1 f-md fw-400 c-black-dark'
            />
          </Card>
        )}

        {!!job && !!job.privateNotes && (
          <Card
            role='PrivateNotesCard'
            className='mb-05'
          >
            <Typography strong large alignCenter blackDark
              className='m-1'
            >
              PRIVATE NOTES
            </Typography>
            <Divider />
            <Html
              value={job.privateNotes}
              className='w-unset m-1 f-md fw-400 c-black-dark'
            />
          </Card>
        )}

        {!!job && !!job.jobDescription && (
          <Card
            role='JobDescriptionCard'
            className='mb-05'
          >
            <Typography strong large alignCenter blackDark
              className='m-1'
            >
              JOB DESCRIPTION
            </Typography>
            <Divider />
            <Html
              value={job.jobDescription}
              className='w-unset m-1 f-md fw-400 c-black-dark'
            />
          </Card>
        )}

      </>
    );
  }
}

export default RequirementsSection3;
