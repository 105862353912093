import {
  Container
} from '@mui/material';
import React, {
  useState
} from 'react';
import {
  validateEmail
} from '../../../lib/GenericTools.lib';
import {
  generateMagicLink,
  JWT__EXPIRED_URL
} from '../../../lib/services/Accounts/Session.lib';
import Message from '../../Layout/Wrappers/Message';
import Loader from '../../Shared/Loader';
import Logo from '../../Shared/Logo';
import TextField from '../../Layout/Wrappers/TextField';
import Paper from '../../Layout/Wrappers/Paper';
import Button from '../../Layout/Wrappers/Button';
import { getLocation } from '../../../lib/URL.lib';

export function EmployerSession() {
  let [state, setState] = useState({});
  async function _updateState(update, delay) {
    update = Object(update) === update ? update : {};
    state = { ...state, ...update };
    delay ? setTimeout(() => setState(state), delay) : setState(state);
    return await new Promise((resolve) => setTimeout(() => resolve(state), delay));
  }
  let {
    email = '',
    error = '',
    sent = {},
    loading = false
  } = state;
  let isExpiredPage = !!getLocation().match(JWT__EXPIRED_URL);
  async function _onSubmit(event) {
    event.preventDefault();
    await _updateState({ loading: true });
    sent = await generateMagicLink({ email }).catch(error => { _updateState({ error, loading: false }); });
    await _updateState({ loading: false, error: false, sent });
  }
  console.debug('DEBUG_EMPLOYER_SESSION:state', state);
  return (
    <div className='w-100 d-flex flex-align-center' style={{ height: '70vh' }}>
      <Container maxWidth="xs">
        <div className='d-flex flex-align-center'>
          <Logo />
        </div>
        <div style={{ height: 256 }}>
          {sent.expiration || sent.error ? (
            <Paper elevation={2} className='mt-2 p-1'>
              <Message className={'m-0'}>
                <div className='align-left'>
                  <div>
                    Please check your email including spam folder for your magic link.
                  </div>
                  <div className='mt-1'>
                    If you don’t receive the email or provided a wrong email address, please request the magic link again or contact 10 By 10.
                  </div>
                  <Button
                    variant='contained'
                    color='primary'
                    className='mt-2'
                    fullWidth
                    onClick={event => _updateState({ sent: false })}
                  >
                    Request Another
                  </Button>
                </div>
              </Message>
            </Paper>
          ) : (
            <>
              <div className='f-md fw-500 c-black-medium mt-2'>
                Enter your email and we will send you a magic link for password-less login.<br />
              </div>
              {isExpiredPage && (
                <div className='c-red fw-400 mt-2'>
                  Your magic link has expired.
                </div>
              )}
              <TextField
                label={<div className='mr-1'>Email</div>}
                value={email}
                onChange={event => {
                  let value = event.target.value;
                  _updateState({
                    email: value,
                    error: !!value.trim().length && !validateEmail(value) ? `Invalid email` : false
                  });
                }}
                onKeyPress={async event => {
                  if (event.key === 'Enter') { _onSubmit(event); }
                }}
                variant="outlined"
                error={!!error}
                helperText={error}
                size='small'
                fullWidth
                className='mt-2'
                disabled={loading}
              />
              {loading
                ? (
                  <Loader />
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    variant='contained'
                    color='secondary'
                    className='mt-1'
                    onClick={_onSubmit}
                  >
                    Request Magic Link
                  </Button>
                )}
              <div className='mt-2 c-black-medium f-sm'>
                By clicking on continue, you agree to all the terms in our recruiting contract and our&nbsp;
                <a href={`http://www.go10x10.com.s3-website-us-east-1.amazonaws.com/`} target='_blank' rel='noreferrer'>
                  Terms of Use
                </a>
                &nbsp;and&nbsp;
                <a href={`https://www.iubenda.com/privacy-policy/73371936`} target='_blank' rel='noreferrer'>
                  Privacy Policy
                </a>.
              </div>
            </>
          )}
        </div>
      </Container>
    </div >
  );
}
