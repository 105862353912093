import {
  isEqual
} from 'lodash';
import {
  Component
} from "react";
import {
  NOT
} from '../../../lib/Boolean.lib';
import Box from '../../Layout/Wrappers/Box';
import Card from '../../Layout/Wrappers/Card';
import {
  PrescreenQuestionsReadOnly
} from '../../PrescreenQuestions/PrescreenQuestionsSection';
import JobHeader from './JobHeader';
import Requirements from "./Requirements";
import RequirementsSection2 from "./RequirementsSection2";
import RequirementsSection3 from "./RequirementsSection3";
import SubmissionHistory from "./SubmissionHistory";

class JobPipe extends Component {

  constructor() {
    super(...arguments);
    this.state = {
      candidateResume: null
    }
  }

  componentDidMount() {
    const { candidate = {} } = this.props;
    if (!!candidate.id) {
      this.fetchResume(candidate.resumeTxtUrl);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      NOT(
        isEqual(
          prevProps.candidate?.id,
          this.props.candidate?.id
        )
      ) &&
      this.props.candidate?.resumeTxtUrl
    ) {
      this.fetchResume(this.props.candidate.resumeTxtUrl);
    }
  }

  fetchResume = (resume) => {
    fetch(resume).then(responseText => {
      responseText.text().then(text => {
        this.setState({ candidateResume: text });
      });
    });
  }

  render() {
    const {
      candidate = {},
      job = {}
    } = this.props;
    const {
      saveJob,
      source,
    } = this.props;
    const {
      candidateResume
    } = this.state;
    return (
      <>

        {!!job.id &&
          <Box column w100>

            <Box className='sticky-top bg-main border-bottom'>
              <JobHeader
                candidate={candidate}
                job={job}
              />
            </Box>

            <Box column w100 className='px-05'>

              {!!candidate.id &&
                <Requirements
                  candidate={candidate}
                  job={job}
                  saveJob={saveJob}
                  candidateResume={candidateResume}
                  source={source}
                />
              }

              <RequirementsSection2
                job={job}
              />

              {!!candidate.id &&
                <RequirementsSection3
                  candidate={candidate}
                  job={job}
                />
              }

              {!!candidate.id &&
                <PrescreenQuestionsReadOnly divider
                  name={`candidates__match_new__job_pipe__job_pipe`}
                  candidate={candidate}
                  job={job}
                  wrapper={Card}
                  editable={false}
                  className='mb-05'
                />
              }

              <SubmissionHistory job={job} />

            </Box>

          </Box>
        }

      </>
    )
  }
}

export default JobPipe;