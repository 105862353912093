import LocationSectionUI from '../../../dictionaries/LocationSectionUI.dic';
import {
  Arr
} from '../../../lib/Array.lib';
import Candidate from '../../../lib/Candidate';
import Core from "../../../lib/Core";
import Definition, {
  IN_OFFICE_REMOTE__FULL_WEEK_ID,
  IN_OFFICE_REMOTE__PART_WEEK_ID,
  IN_OFFICE_REMOTE__REMOTE_ONLY_ID,
  LOCATION__AMERICAS,
  LOCATION__CALIFORNIA,
  LOCATION__SAN_FRANCISCO_BAY_AREA,
  LOCATION__UNITED_STATES,
  LOC_TYPE__ALIAS_ID, LOC_TYPE__CITY_ID,
  LOC_TYPE__COUNTRY_ID,
  LOC_TYPE__METRO_AREA_ID,
  LOC_TYPE__METRO_AREA_SEC_ID,
  LOC_TYPE__MULTI_CONT_ID,
  LOC_TYPE__STATE_ID
} from "../../../lib/Definition";
import LocationLib from '../../../lib/DefinitionLocation.lib';
import FilterControlLib from '../../../lib/FilterControl';
import {
  NOT,
  YES
} from '../../../lib/GenericTools.lib';
import {
  joinKeyName
} from '../../../lib/String.lib';
import {
  RefBox
} from '../../Layout/Wrappers/Box';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Menu from '../../Layout/Wrappers/Menu';
import TextField from '../../Layout/Wrappers/TextField';
import Typography from '../../Layout/Wrappers/Typography';
import {
  CANDIDATE_SUMMARY__INCLUDE_FLAGS
} from '../SubmissionNotes.lib';
import {
  IncludeSubmissionCheckbox
} from './IncludeSubmissionCheckbox';

function LocationSection(props) {
  const {
    CandidateEditController = {}
  } = props;
  const {
    candidateLocations = [],
    inOfficeRemoteFlags = [],
    officeLocations = [],
    locationDetails = '',
  } = CandidateEditController.state;
  const locationTags = Definition.get("location");
  const inOfficeRemoteTags = Definition.get("inOfficeRemote");

  const isRemoteWork = YES(
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
  );

  const isInOfficeWork = YES(
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID) ||
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID)
  );

  /**
   * @todo
   *
   * Review to cleanup
   *
   * epic-3338(new locations)-story-3385 | 2021-07-15 Thu µ
   *
   * covering possible rollback
   *
   */
  function _patch3385(state) {
    /** * /
    const REMOTE_MAPPING = {
      'None': 'In office full week',
      '1 - 2 Days a Week': 'In office part week',
      '3 - 4 Days a Week': 'In office part week',
      '5 Days a Week': '100% remote',
    };
    {
      "key": "remotelyWork",
      "label": "Work from home partial week",
      "values": [
        { "id": 1, "label": "No" },
        { "id": 2, "label": "1 - 2 Days a Week" },
        { "id": 3, "label": "2 - 3 Days a Week" },
        { "id": 4, "label": "5 Days a Week" }
      ]
    }
    location: {
      "id": 11,
      "label": "Remote",
      "archived": true,
      "parentId": null
    }
    locationCandidate: [
      { "id": 7, "label": "Remotly" }
    ]
    /** */
    if (state.inOfficeRemoteFlags) {
      state.workRemotly = (
        state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID)
          ? 1
          : state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
            ? 2
            : state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
              ? 4
              : 1
      );
      state.workLocationIds = CandidateEditController.state.workLocationIds || [];
      state.workLocationIds = state.workLocationIds.filter(n => n !== 7);

      if (state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)) {
        state.workLocationIds.push(7);
      }

    }
    else if (!!state.officeLocations) {
      const SEPARATOR_SECTION = '\n___________';
      const SEPARATOR_LINE = '\n';
      const mapping = {
        '88': 6, // San Francisco Bay Area > San Francisco
        '4': 6, // San Francisco > San Francisco
        '3': 5, // Peninsula
        '1': 2, // East Bay
        '2': 4, // North Bay
        '5': 3, // South Bay
      };
      state.privateNotes = CandidateEditController.state.privateNotes || '';
      state.privateNotes = state.privateNotes.split(SEPARATOR_SECTION)[0] + SEPARATOR_SECTION;

      state.workLocationIds = Arr(state.officeLocations).map(workLocationId => {
        const _workLocationId = (
          mapping[workLocationId] || (
            LocationLib.evalAncest({ ancestId: 3, descendantId: workLocationId })
              ? 5
              : LocationLib.evalAncest({ ancestId: 1, descendantId: workLocationId })
                ? 2
                : LocationLib.evalAncest({ ancestId: 2, descendantId: workLocationId })
                  ? 4
                  : LocationLib.evalAncest({ ancestId: 5, descendantId: workLocationId })
                    ? 3
                    : 0
          )
        );
        if (12 <= workLocationId) {
          const workLocationLabel = FilterControlLib.getItemValue({
            menu: Candidate.getMenu({ key: 'officeLocations' }),
            itemLabel: Definition.getLabel('location', workLocationId)
          });
          if (!state.privateNotes?.includes(workLocationLabel)) {
            state.privateNotes = [
              state.privateNotes,
              `${SEPARATOR_LINE}${workLocationLabel}`
            ].filter(n => !!n.trim()).join('');
          }
        }
        return _workLocationId;
      }).filter(n => !!n);

      if (CandidateEditController.state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)) {
        state.workLocationIds.push(7);
      }

      const candidateLocationLabel = FilterControlLib.getItemValue({
        menu: Candidate.getMenu({ key: 'candidateLocations' }),
        itemLabel: Definition.getLabel('location',
          CandidateEditController.state.candidateLocations[0]
        )
      });

      if (!state.privateNotes?.includes(candidateLocationLabel)) {
        state.privateNotes = [
          state.privateNotes,
          `${SEPARATOR_LINE}${candidateLocationLabel}`
        ].filter(n => !!n.trim()).join('');
      }

    }
    else if (!!Arr(state.candidateLocations).length) {
      const SEPARATOR_SECTION = '\n___________';
      const SEPARATOR_LINE = '\n';
      state.privateNotes = CandidateEditController.state.privateNotes || '';
      state.privateNotes = state.privateNotes.split(SEPARATOR_SECTION)[0] + SEPARATOR_SECTION;

      state.officeLocations = Arr(CandidateEditController.state.officeLocations);
      state.officeLocations.forEach(workLocationId => {
        if (12 <= workLocationId) {
          const workLocationLabel = FilterControlLib.getItemValue({
            menu: Candidate.getMenu({ key: 'officeLocations' }),
            itemLabel: Definition.getLabel('location', workLocationId)
          });
          if (!state.privateNotes?.includes(workLocationLabel)) {
            state.privateNotes = [
              state.privateNotes,
              `${SEPARATOR_LINE}${workLocationLabel}`
            ].filter(n => !!n.trim()).join('');
          }
        }
      });

      const candidateLocationLabel = FilterControlLib.getItemValue({
        menu: Candidate.getMenu({ key: 'candidateLocations' }),
        itemLabel: Definition.getLabel('location', state.candidateLocations[0])
      });

      if (!state.privateNotes?.includes(candidateLocationLabel)) {
        state.privateNotes = [
          state.privateNotes,
          `${SEPARATOR_LINE}${candidateLocationLabel}`
        ].filter(n => !!n.trim()).join('');
      }

    }

    console.debug('_patch3385', state);

    return state;

  }

  console.debug('CandidateEditController.state', CandidateEditController.state);

  return (
    <RefBox
      ref={(self) => Core.setKeyValue("location-section", self)}
      className="bg-main pb-2 m-1 rounded"
    >

      <Fieldset
        title={LocationSectionUI.candidateLocations.label}
        dot='orange'
        subtitle={(NOT(candidateLocations.length) && YES(isRemoteWork))
          ? (<Typography sub error>{LocationSectionUI.candidateLocations.danger}</Typography>)
          : (<Typography sub blackDark>{LocationSectionUI.candidateLocations.subtitle}</Typography>)}
      >
        <Menu tree multiple={false}
          layers={[
            LOC_TYPE__MULTI_CONT_ID,
            LOC_TYPE__COUNTRY_ID,
            LOC_TYPE__STATE_ID,
            LOC_TYPE__METRO_AREA_ID,
            LOC_TYPE__METRO_AREA_SEC_ID,
            LOC_TYPE__CITY_ID
          ]}
          treeDefaultExpandedIds={[
            LOCATION__AMERICAS,
            LOCATION__UNITED_STATES,
            LOCATION__CALIFORNIA,
            LOCATION__SAN_FRANCISCO_BAY_AREA
          ]}
          options={locationTags}
          value={candidateLocations[0]}
          onChange={(candidateLocation) =>
            CandidateEditController.setState(
              /* epic-3338(new locations)-story-3385 | 2021-07-15 Thu µ */
              _patch3385(
                { candidateLocations: [candidateLocation] }
              )
            )
          }
          placeholder={LocationSectionUI.candidateLocations.placeholder}
        />
      </Fieldset>

      <Fieldset
        title={LocationSectionUI.inOfficeRemoteFlags.title}
        info={LocationSectionUI.inOfficeRemoteFlags.info}
        dot='orange'
      >
        {inOfficeRemoteTags.map((tag, index) => (
          <Checkbox
            key={
              joinKeyName([
                'in_office_tag',
                index,
                tag.id,
              ])
            }
            label={tag.label}
            checked={inOfficeRemoteFlags.includes(tag.id)}
            onCheck={(ev, checked) => {
              CandidateEditController.setState(
                /* epic-3338(new locations)-story-3385 | 2021-07-15 Thu µ */
                _patch3385(
                  {
                    inOfficeRemoteFlags: !!checked
                      ? [...inOfficeRemoteFlags, tag.id]
                      : inOfficeRemoteFlags.filter((n) => n !== tag.id)
                  }
                ),
                () => {
                  if (
                    NOT(
                      CandidateEditController.state.inOfficeRemoteFlags.includes(
                        IN_OFFICE_REMOTE__FULL_WEEK_ID
                      ) ||
                      CandidateEditController.state.inOfficeRemoteFlags.includes(
                        IN_OFFICE_REMOTE__PART_WEEK_ID
                      )
                    )
                  ) {
                    CandidateEditController.setState(
                      _patch3385({ officeLocations: [] })
                    );
                  }
                }
              );
            }}
          />
        ))}
      </Fieldset>

      <Fieldset
        title={LocationSectionUI.officeLocations.title}
        subtitle={(NOT(officeLocations.length) && YES(isInOfficeWork))
          ? (<Typography sub error>{LocationSectionUI.officeLocations.danger}</Typography>)
          : (<Typography sub blackDark>{LocationSectionUI.officeLocations.subtitle}</Typography>)}
        info={NOT(isInOfficeWork) ? LocationSectionUI.officeLocations.disabled : ''}
      >
        <Menu tree
          layers={[
            LOC_TYPE__ALIAS_ID,
            LOC_TYPE__MULTI_CONT_ID,
            LOC_TYPE__COUNTRY_ID,
            LOC_TYPE__STATE_ID,
            LOC_TYPE__METRO_AREA_ID,
            LOC_TYPE__METRO_AREA_SEC_ID,
            LOC_TYPE__CITY_ID
          ]}
          treeDefaultExpandedIds={[
            LOCATION__AMERICAS,
            LOCATION__UNITED_STATES,
            LOCATION__CALIFORNIA,
            LOCATION__SAN_FRANCISCO_BAY_AREA
          ]}
          value={officeLocations}
          onChange={(officeLocations) => {
            CandidateEditController.setState(
              _patch3385(
                { officeLocations }
              )
            );
          }}
          options={locationTags}
          disabled={NOT(isInOfficeWork)}
        />
      </Fieldset>

      <Fieldset
        title='Notes on work location and flexibility to relocate'
        actionBar={
          <IncludeSubmissionCheckbox
            CandidateEditController={CandidateEditController}
            fieldId={CANDIDATE_SUMMARY__INCLUDE_FLAGS.locationDetails}
          />
        }
      >
        <TextField multiline small
          autoSize={{ minRows: 1, maxRows: 7 }}
          value={locationDetails}
          onChange={event =>
            CandidateEditController.setState({
              locationDetails: event.target.value
            })
          }
        />
      </Fieldset>

    </RefBox>
  );
}

export { LocationSection, LocationSection as default };
