import {
  CSS_CYAN_DARKER_RGB
} from '../Constants';
import {
  STAGE_REVIEW,
  STAGE_SCREEN
} from '../../dictionaries/Engagement.dic';
import {
  HTML__IN_BLANK_LINE,
  joinHTML
} from '../HTML.lib';
import {
  trim
} from '../String.lib';

/**
 * @param {string} MATCH_INFO  [html]
 */
export const TEMPLATE__EMPLOYER_PENDINGS__MATCH_INFO = joinHTML(`
  {{#if MATCH_INFO}}
    <p style="font-size: 1rem;">
      <strong style="color: ${CSS_CYAN_DARKER_RGB};">
        Match info:
      </strong>
      &nbsp;{{{MATCH_INFO}}}
    </P>
  {{/if}}
`);

/**
 * @param {string} NEXT_STEPS  [html]
 */
export const TEMPLATE__EMPLOYER_PENDINGS__NEXT_STEPS = joinHTML(`
  {{#if NEXT_STEPS}}
    <p style="font-size: 1rem;">
      <strong style="color: ${CSS_CYAN_DARKER_RGB};">
        Next steps:
      </strong>
      &nbsp;{{NEXT_STEPS}}
    </p>
  {{/if}}
`);

/**
 * @param {string} FEEDBACK  [html]
 */
export const TEMPLATE__EMPLOYER_PENDINGS__FEEDBACK = joinHTML(`
  {{#if FEEDBACK}}
    <p style="font-size: 1rem;">
      <strong style="color: ${CSS_CYAN_DARKER_RGB};">
        Note:
      </strong>
      &nbsp;{{{FEEDBACK}}}
    </p>
  {{/if}}
`);

/**
 * @param {string} REJECTION_REASONS  [plaintext]
 */
export const TEMPLATE__EMPLOYER_PENDINGS__REJECTION_REASONS = joinHTML(`
  {{#if REJECTION_REASONS}}
    <p style="font-size: 1rem;">
      <strong style="color: ${CSS_CYAN_DARKER_RGB};">
        Rejection Reasons:
      </strong>
      &nbsp;{{REJECTION_REASONS}}
    </p>
  {{/if}}
`);

/**
 * @param {string} ENGAGEMENT_LINK  [html]
 * @param {string} PENDINGS_LINK  [html]
 */
export const TEMPLATE__EMPLOYER_PENDINGS__LINKS = joinHTML(`
  {{#if ENGAGEMENT_LINK}}
    <p>
      <strong>
        {{{ENGAGEMENT_LINK}}}
      </strong>
    </p>
  {{/if}}
  {{#if PENDINGS_LINK}}
    <p>
      <strong>
        {{{PENDINGS_LINK}}}
      </strong>
    </p>
  {{/if}}
`);

/**
 * @param {string} MATCH_DECISION  [plaintext]
 * @param {string} ANNOTATOR  [plaintext]
 * @param {string} COLOR  [css:background-color]
 */
export const TEMPLATE__EMPLOYER_PENDINGS__SINGLE_MATCH_DECISION = joinHTML(`
  <span style="background-color: {{COLOR}};">
    {{MATCH_DECISION}} - {{ANNOTATOR}}
  </span>
`);

/**
 * @param {string} ACTION_TYPE  [plaintext]
 * @param {string} CANDIDATE__FULLNAME  [plaintext]
 * @param {string} RECRUITER__FULLNAME  [plaintext]
 * @param {string} EMPLOYER__NAME  [plaintext]
 * @param {string} JOB_TITLE  [plaintext]
 * @param {string} STAGE  [plaintext]
 * @param {boolean} ACTION_TYPE__REJECT  
 * @param {boolean} ACTION_TYPE__ACCEPT  
 * @param {boolean} STAGE__REVIEW  
 * @param {string} USER__NAME  [plaintext]
 * @param {boolean} ADMIN  
 */
export const TEMPLATE__EMPLOYER_PENDINGS__FEEDBACK__SUBJECT = joinHTML(`
  [ {{ACTION_TYPE}} ] {{CANDIDATE__FULLNAME}}{{RECRUITER__FULLNAME}}
  &nbsp;{{EMPLOYER__NAME}} ({{JOB_TITLE}})
  {{#if ACTION_TYPE__REJECT}}
    &nbsp;rejected at {{STAGE}} stage
  {{/if}}
  &nbsp;| via pending page (by {{USER__NAME}}{{#if ADMIN}}&nbsp;@10x10{{/if}})
  {{#if ACTION_TYPE__ACCEPT}}
    {{#if STAGE__REVIEW}}
      &nbsp;| ${STAGE_REVIEW} to ${STAGE_SCREEN} stage
    {{else}}
      &nbsp;moved to the next interview round
    {{/if}}
  {{/if}}
`);

/**
 * @param {string} FEEDBACK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__FEEDBACK
 * @param {string} MATCH_INFO  [html] see TEMPLATE__EMPLOYER_PENDINGS__MATCH_INFO
 * @param {string} ENGAGEMENT_LINK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__LINKS
 * @param {string} PENDINGS_LINK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__LINKS
 */
export const TEMPLATE__EMPLOYER_PENDINGS__ONLY_FEEDBACK__TO_10X10 = joinHTML(`
  ${TEMPLATE__EMPLOYER_PENDINGS__LINKS}
  ${TEMPLATE__EMPLOYER_PENDINGS__MATCH_INFO}
  ${TEMPLATE__EMPLOYER_PENDINGS__FEEDBACK}
`);

/**
 * @param {string} FEEDBACK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__FEEDBACK
 * @param {string} MATCH_INFO  [html] see TEMPLATE__EMPLOYER_PENDINGS__MATCH_INFO
 * @param {string} ENGAGEMENT_LINK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__LINKS
 * @param {string} PENDINGS_LINK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__LINKS
 */
export const TEMPLATE__EMPLOYER_PENDINGS__CALIBRATION_ACCEPTANCE__TO_10X10 = joinHTML(`
  ${TEMPLATE__EMPLOYER_PENDINGS__FEEDBACK}
  ${TEMPLATE__EMPLOYER_PENDINGS__MATCH_INFO}
  ${TEMPLATE__EMPLOYER_PENDINGS__LINKS}
`);

/**
 * @param {string} REJECTION_REASONS  [plaintext]  see TEMPLATE__EMPLOYER_PENDINGS__REJECTION_REASONS
 * @param {string} FEEDBACK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__FEEDBACK
 * @param {string} MATCH_INFO  [html] see TEMPLATE__EMPLOYER_PENDINGS__MATCH_INFO
 * @param {string} ENGAGEMENT_LINK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__LINKS
 * @param {string} PENDINGS_LINK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__LINKS
 */
export const TEMPLATE__EMPLOYER_PENDINGS__CALIBRATION_REJECTION__TO_10X10 = joinHTML(`
  ${TEMPLATE__EMPLOYER_PENDINGS__REJECTION_REASONS}
  ${TEMPLATE__EMPLOYER_PENDINGS__FEEDBACK}
  ${TEMPLATE__EMPLOYER_PENDINGS__MATCH_INFO}
  ${TEMPLATE__EMPLOYER_PENDINGS__LINKS}
`);

/**
 * @param {string} NEXT_STEPS  [html] see TEMPLATE__EMPLOYER_PENDINGS__NEXT_STEPS
 * @param {string} ADDITIONAL_STEPS  [plaintext]
 * @param {string} FEEDBACK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__FEEDBACK
 * @param {string} MATCH_INFO  [html] see TEMPLATE__EMPLOYER_PENDINGS__MATCH_INFO
 * @param {string} ENGAGEMENT_LINK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__LINKS
 * @param {string} PENDINGS_LINK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__LINKS
 * @param {string} BODY  [html]
 */
export const TEMPLATE__EMPLOYER_PENDINGS__ACCEPTANCE_INTERVIEW__TO_10X10 = joinHTML(`
  ${TEMPLATE__EMPLOYER_PENDINGS__NEXT_STEPS}
  {{#if ADDITIONAL_STEPS}}
    <p style="font-size: 1rem;">
      <strong style="color: ${CSS_CYAN_DARKER_RGB};">
        Additional steps:
      </strong>&nbsp;
      {{ADDITIONAL_STEPS}}
    </p>
  {{/if}}
  ${TEMPLATE__EMPLOYER_PENDINGS__FEEDBACK}
  ${TEMPLATE__EMPLOYER_PENDINGS__MATCH_INFO}
  ${TEMPLATE__EMPLOYER_PENDINGS__LINKS}
  ${HTML__IN_BLANK_LINE}
  {{{BODY}}}
`);

/**
 * @param {string} REJECTION_REASONS  [plaintext]  see TEMPLATE__EMPLOYER_PENDINGS__REJECTION_REASONS
 * @param {string} FEEDBACK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__FEEDBACK
 * @param {string} MATCH_INFO  [html] see TEMPLATE__EMPLOYER_PENDINGS__MATCH_INFO
 * @param {string} ENGAGEMENT_LINK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__LINKS
 * @param {string} PENDINGS_LINK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__LINKS
 * @param {string} BODY  [html]
 */
export const TEMPLATE__EMPLOYER_PENDINGS__REJECTION_INTERVIEW__TO_10X10 = joinHTML(`
  ${TEMPLATE__EMPLOYER_PENDINGS__REJECTION_REASONS}
  ${TEMPLATE__EMPLOYER_PENDINGS__FEEDBACK}
  ${TEMPLATE__EMPLOYER_PENDINGS__MATCH_INFO}
  ${TEMPLATE__EMPLOYER_PENDINGS__LINKS}
  ${HTML__IN_BLANK_LINE}
  {{{BODY}}}
`);

export const TEMPLATE__EMPLOYER_PENDINGS__ACCEPTANCE_INTERVIEW__SUBJECT__TO_CANDIDATE = trim(`
  ( 10x10 Update ) {{CANDIDATE__NAME}} - {{EMPLOYER__NAME}}{{JOB_TITLE}}, moved to the next interview round{{NEXT_STEP}}
`);

export const TEMPLATE__EMPLOYER_PENDINGS__ACCEPTANCE_INTERVIEW__TO_CANDIDATE = (`
  ${TEMPLATE__EMPLOYER_PENDINGS__NEXT_STEPS}
  {{{BODY}}}
`);

/**
 * @param {string} EMPLOYER__NAME  [plaintext]
 * @param {string} USER__NAME  [plaintext]
 * @param {string} NOW  [plaintext] formatted time to (PDT)
 */
export const TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__FIRST_RENDER__SUBJECT = joinHTML(`
  [ VIEW TODO ] {{EMPLOYER__NAME}} ({{USER__NAME}}
  &nbsp;viewed the pending todo page at {{NOW}}
`);

/**
 * @param {string} ENGAGEMENT_LINK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__LINKS
 * @param {string} PENDINGS_LINK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__LINKS
 * @param {string} EMPLOYER__NAME  [plaintext]
 * @param {string} USER__NAME  [plaintext]
 */
export const TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__FIRST_RENDER__BODY = joinHTML(`
  ${TEMPLATE__EMPLOYER_PENDINGS__LINKS}
  ${HTML__IN_BLANK_LINE}
  <p>
    FYI - {{EMPLOYER__NAME}} - {{USER__NAME}} - is viewing the pending to do page!
  </p>
`);

/**
 * @param {string} EMPLOYER__NAME  [plaintext]
 * @param {string} USER__NAME  [plaintext]
 * @param {string} CANDIDATE__FULLNAME  [plaintext]
 * @param {string} RECRUITER__FULLNAME  [plaintext]
 * @param {string} NOW  [plaintext] formatted time to (PDT)
 */
export const TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__USER_VIEWING__SUBJECT = joinHTML(`
  [ VIEW ] {{EMPLOYER__NAME}} ({{USER__NAME}}) viewed
  &nbsp;{{CANDIDATE__FULLNAME}}{{RECRUITER__FULLNAME}}'s profile at {{NOW}}
`);

/**
 * @param {string} ENGAGEMENT_LINK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__LINKS
 * @param {string} PENDINGS_LINK  [html]  see TEMPLATE__EMPLOYER_PENDINGS__LINKS
 * @param {string} EMPLOYER__NAME  [plaintext]
 * @param {string} USER__NAME  [plaintext]
 */
export const TEMPLATE__EMPLOYER_PENDINGS__VIEW_CANDIDATE__USER_VIEWING__BODY = joinHTML(`
  ${TEMPLATE__EMPLOYER_PENDINGS__LINKS}
  ${HTML__IN_BLANK_LINE}
  <p>
    FYI - {{EMPLOYER__NAME}} is viewing {{CANDIDATE__FULLNAME}}'s profile!
  </p>
`);
