import {
  TextField as MuiTextField
} from '@mui/material';
import React from 'react';
import AppUI from '../../../dictionaries/AppUI.dic';
import {
  Fun
} from '../../../lib/Function.lib';
import useStateObserved from '../../../lib/hooks/useStateObserved.hook';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  Str,
  trim
} from '../../../lib/String.lib';
import {
  joinClassName,
  mapWrapper,
  THEME__VARIANT__OUTLINED,
  THEME__VARIANT__STANDARD
} from '../Libraries/Theme.lib';

/**
 * TextField Component
 * 
 * @see https://mui.com/material-ui/api/text-field/
 *
 * @param {Object} props - Component props
 * @param {boolean} [props.acl=true] - Access control logic flag
 * @param {boolean} [props.debug=false] - Debug flag
 * @param {string} [props.autoComplete=''] - AutoComplete attribute
 * @param {boolean} [props.autoFocus=false] - AutoFocus attribute
 * @param {string} [props.floatingLabelText=''] - Floating label text
 * @param {number} [props.rows] - Number of rows
 * @param {number} [props.rowsMax] - Maximum number of rows
 * @param {number} [props.rowsMin] - Minimum number of rows
 * @param {number} [props.maxRows] - Maximum number of rows for multiline
 * @param {number} [props.minRows] - Minimum number of rows for multiline
 * @param {boolean} [props.multiLine] - Multiline flag
 * @param {boolean} [props.multiline] - Alias for multiline flag
 * @param {Object} [props.autoSize] - Auto size object
 * @param {string} [props.placeholder] - Placeholder text
 * @param {string} [props.label] - Label text
 * @param {string} [props.value=''] - Value of the text field
 * @param {string} [props.type='text'] - Input type
 * @param {boolean} [props.fullWidth=true] - Full width flag
 * @param {boolean} [props.outlined=false] - Outlined variant flag
 * @param {function} [props.onChange=(event, value) => null] - OnChange event handler
 * @param {function} [props.onBlur=null] - OnBlur event handler
 * @param {function} [props.onKeyDown=null] - OnKeyDown event handler
 * @param {function} [props.onKeyPress=null] - OnKeyPress event handler
 * @param {string} [props.errorText=''] - Error text
 * @param {string} [props.variant] - Variant of the text field
 * @param {string} [props.size='medium'] - Size of the text field
 * @param {boolean} [props.small] - Small size flag
 * @param {string} [props.className=''] - CSS class name
 * @param {Object} props - Additional props
 * @param {React.Ref} ref - Ref object
 *
 * @returns {React.Element} - TextField component
 */
function TextField({
  acl = true,
  debug = false,
  autoComplete = '',
  autoFocus = false,
  floatingLabelText = '',
  rows,
  rowsMax,
  rowsMin,
  maxRows,
  minRows,
  multiLine,
  multiline,
  autoSize,
  placeholder,
  label,
  value: propsValue = '',
  type = 'text',
  fullWidth = true,
  outlined = false,
  onChange = (event, value) => null,
  onBlur = null,
  onKeyDown = null,
  onKeyPress = null,
  errorText = '',
  variant,
  size = 'medium',
  small,
  className = '',
  wrapperProps = {},
  ...props
}, ref) {
  const defaultState = {
    value: propsValue
  };
  const [{
    value = ''
  }, updateState] = useStateObserved({
    state: defaultState,
    observed: defaultState
  });

  if (!acl) { return null; }

  const _exportValue = async (event) => {
    const value = Str(event.target.value);
    console.debug(
      'TextField__exportValue...',
      '\n', event,
      '\n', (type === 'password') ? ''.padEnd(value.length, '*') : value,
    );
    await updateState({ value });
    if (value !== propsValue) {
      onChange(event, value);
    }
  };

  const _onChange = async (event) => {
    if (
      trim(autoComplete) ||
      autoFocus ||
      onBlur ||
      onKeyDown ||
      onKeyPress
    ) {
      return _exportValue(event);
    }
    else {
      return updateState({
        value: Str(event.target.value)
      });
    }
  };

  const _onBlur = async (event) => {
    await _exportValue(event);
    Fun(onBlur)(event);
  };

  const _onKeyDown = async (event) => {
    if (!!Str(event.key).match(/enter|tab/i)) {
      await _exportValue(event);
    }
    Fun(onKeyDown)(event);
    Fun(onKeyPress)(event);
  };

  autoSize = Obj(autoSize);
  label = label || floatingLabelText
  maxRows = maxRows || rowsMax || autoSize.maxRows;
  minRows = minRows || rowsMin || rows || autoSize.minRows;
  rows = (minRows || maxRows) ? undefined : rows;
  multiline = multiline || multiLine || !!(minRows || maxRows);
  if (multiline) {
    placeholder = placeholder || AppUI.placeholder.multiline;
    variant = variant || THEME__VARIANT__OUTLINED;
  }
  else {
    placeholder = placeholder || AppUI.placeholder.text;
  }
  if (small) { size = 'small'; }
  if (errorText) { errorText = <i>{errorText}</i> }
  if (outlined) {
    variant = THEME__VARIANT__OUTLINED;
  }

  className = [
    (
      (
        multiLine &&
        (variant === THEME__VARIANT__STANDARD)
      ) &&
      'bg-transparent'
    ),
    className
  ];

  return (
    <MuiTextField
      {...mapWrapper(
        {
          role: 'TextField',
          props: wrapperProps,
          assign: {
            ...props,
            autoComplete,
            autoFocus,
            value,
            type,
            fullWidth,
            onChange: _onChange,
            onBlur: _onBlur,
            onKeyDown: _onKeyDown,
            onKeyPress: _onKeyDown,
            error: !!errorText,
            helperText: errorText,
            variant,
            size,
            label,
            multiline,
            rows,
            minRows,
            maxRows,
            placeholder,
            className: joinClassName(className)
          }
        }
      )}
      ref={ref}
    />
  );
}
export default React.forwardRef(TextField);
