import moment from 'moment';
import React, {
  useState
} from "react";
import Candidate from '../../../lib/Candidate';
import Core from "../../../lib/Core";
import {
  DATE_FORMAT__DATE__ISO
} from '../../../lib/Date.lib';
import Definition, {
  MATCH_EXCLUSION__10X10_PLACEMENT,
  MATCH_EXCLUSION__DO_NOT_MATCH,
  MATCH_EXCLUSION__NEW_JOB_ANOTHER_SOURCE,
  MATCH_EXCLUSION__OTHER,
  MATCH_EXCLUSION__PAUSE,
  MATCH_EXCLUSION__POTENTIAL_FAKE,
  MATCH_EXCLUSION__RECRUITER_OWN_PLACEMENT,
  MATCH_EXCLUSION__STAY_AT_JOB,
  MATCH_EXCLUSION__UNRESPONSIVE
} from "../../../lib/Definition";
import {
  NOT,
  YES
} from '../../../lib/GenericTools.lib';
import {
  getCandidateModel
} from "../../../lib/models/candidate";
import Col from "../../Layout/Wrappers/Col";
import Row from "../../Layout/Wrappers/Row";
import Checkbox from '../../Layout/Wrappers/Checkbox';
import DatePicker from '../../Layout/Wrappers/DatePicker';
import TextField from '../../Layout/Wrappers/TextField';

/**
 * 
 * IF candidateId is set in props
 * - the candidate will be fetched (GET).
 * 
 * IF CandidateEditController is set in props
 * - the state attribute inside will be considered the candidate.
 * 
 * IF BOTH props are set
 * - onChange event, updates DB by invoke CandidateEditController.save.
 * 
 * IF ONLY candidateId is set in props.
 * - onChange event, updates DB by invoke Candidate.update (lib).
 * 
 * IF ONLY CandidateEditController is set in props.
 * - (autosave disabled) the updates will be applied to memory by invoke CandidateEditController.setState.
 * 
 * NOTE: update to DB (autosave) happen after 3 secs.
 * 
 * @param {object} props 
 * @param {object} props.CandidateEditController
 * @param {object} props.candidateId
 * @returns 
 */
function MatchExclusionFieldset(props) {
  let { CandidateEditController, candidateId } = props;
  const [candidate, setCandidate] = useState(candidateId ? getCandidateModel({ extended: true }) : CandidateEditController.state);
  const { matchExclusions = [] } = candidate;
  const _matchExclusions = matchExclusions[0] || getCandidateModel({ matchExclusion: true });
  const {
    reasons = [],
    excludeUntil = '',
    note = '',
    // updatedBy = ''
  } = _matchExclusions;
  const matchExclusionReasonTags = Definition.get('matchExclusionReason');
  const fetch = async () => {
    let _candidate = await Candidate
      .proxy('MatchExclusionFieldset__fetch__get')
      .get(candidateId)
      .catch(exception => Core.failure({ exception }));
    if (_candidate) { setCandidate(_candidate); }
  }
  if (candidateId && !candidate.id) { fetch(); }
  function setState(update) {
    let _update = {
      matchExclusions: [{
        ..._matchExclusions,
        ...update,
        updatedBy: Core.getUserId(),
        updatedAt: new Date().toISOString()
      }]
    };
    setCandidate({ ...candidate, ..._update });
    if (candidateId) {
      clearTimeout(MatchExclusionFieldset.timeout);
      MatchExclusionFieldset.timeout = setTimeout(() => {
        if (CandidateEditController) {
          CandidateEditController.save(_update);
        }
        else {
          Candidate.update(candidateId, _update).catch(exception => Core.failure({ exception }));
        }
      }, 3000);
    }
    else {
      CandidateEditController.setState(_update);
    }
  }
  // console.debug('candidate', candidate);
  Core.setKeyValue('MatchExclusionFieldSet__note', note);
  Core.setKeyValue('MatchExclusionFieldSet__reasons', Definition.getLabels('matchExclusionReason', reasons));
  Core.setKeyValue('MatchExclusionFieldSet__excludeUntil', excludeUntil ? moment(excludeUntil, DATE_FORMAT__DATE__ISO) : null);
  return (
    YES(candidate.id)
      ? (
        <div className="bg-main pb-2 rounded mx-1"  >
          <Row>
            <Col fullWidth>
              <label>Withdraw & do not match</label>
            </Col>
          </Row>
          <Row className='mt-3'>
            <div className='px-2'>
              <div className="c-cyan-darker f-md fw-500">
                Withdraw Reasons
              </div>
              <div className="d-flex flex-wrap">
                {matchExclusionReasonTags.map((item) => (
                  <Checkbox
                    key={item.id}
                    style={{ minWidth: 240, width: `calc(100% / ${matchExclusionReasonTags.length})` }}
                    label={<span className='c-black-medium f-14'>{item.label}</span>}
                    checked={reasons.includes(item.id)}
                    onChange={event => {
                      let { checked } = event.target;
                      let _reasons = (
                        !!checked
                          ? [...reasons, item.id]
                          : reasons.filter((n) => n !== item.id)
                      );
                      let _excludeUntil = '';
                      [
                        { tagId: MATCH_EXCLUSION__DO_NOT_MATCH, wakeup: '5 years' },
                        { tagId: MATCH_EXCLUSION__10X10_PLACEMENT, wakeup: '2 years' },
                        { tagId: MATCH_EXCLUSION__RECRUITER_OWN_PLACEMENT, wakeup: '1 year' },
                        { tagId: MATCH_EXCLUSION__NEW_JOB_ANOTHER_SOURCE, wakeup: '10 months' },
                        { tagId: MATCH_EXCLUSION__STAY_AT_JOB, wakeup: '1 month' },
                        { tagId: MATCH_EXCLUSION__PAUSE, wakeup: '1 month' },
                        { tagId: MATCH_EXCLUSION__OTHER, wakeup: '1 month' },
                        { tagId: MATCH_EXCLUSION__POTENTIAL_FAKE, wakeup: '100 years' },
                        { tagId: MATCH_EXCLUSION__UNRESPONSIVE, wakeup: '3 months' },
                      ].find(({ tagId, wakeup }) => {
                        if (_reasons.includes(tagId)) {
                          let [amount, unit] = wakeup.split(' ');
                          if (NOT(_reasons.length)) {
                            _excludeUntil = '';
                          }
                          else {
                            _excludeUntil = moment().add(amount, unit).toISOString();
                          }
                          return true;
                        }
                        return false;
                      });
                      setState({
                        reasons: _reasons,
                        excludeUntil: _excludeUntil,
                      });
                    }}
                  />
                ))}
              </div>
            </div>
          </Row>
          <Row className='mt-3'>
            <Col>
              <div className="c-cyan-darker f-md fw-500">
                Exclude from matches until
              </div>
              <div className="d-flex align-center">
                <DatePicker
                  disabled={!reasons.length}
                  value={excludeUntil ? moment(excludeUntil, DATE_FORMAT__DATE__ISO) : null}
                  format={DATE_FORMAT__DATE__ISO}
                  onChange={excludeUntil =>
                    setState({
                      excludeUntil: excludeUntil ? excludeUntil.toISOString() : '',
                    })
                  }
                  popupStyle={{ zIndex: 9999 }}
                />
              </div>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col fullWidth>
              <div className="c-cyan-darker f-md fw-500">
                Notes
              </div>
              <TextField
                autoSize={{ minRows: 3, maxRows: 6 }}
                placeholder="Type here"
                value={note}
                style={{ fontSize: "13px", backgroundColor: 'white' }}
                onChange={event =>
                  setState({
                    note: event.target.value,
                  })
                }
              />
            </Col>
          </Row>
        </div >
      ) : ''
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export {
  MatchExclusionFieldset as default,
  MatchExclusionFieldset
};

/* ============================================ */