import {
  compile
} from 'handlebars';
import React from "react";
import EmailPreview from "../../../components/Dialogs/EmailPreview";
import Candidate from "../../Candidate";
import {
  ENGAGEMENT__STATE_OPEN,
  STAGE_REVIEW,
  STAGE_SUBMISSION
} from '../../../dictionaries/Engagement.dic';
import Core from "../../Core";
import {
  REC_MSG_TYPE__REPRESENTATION_ID
} from "../../Definition";
import TemplateLib from "../../Template.lib";
import {
  TEMPLATE__CANDIDATE_REPRESENTATION_EMAIL__BODY,
  TEMPLATE__CANDIDATE_REPRESENTATION_EMAIL__SUBJECT
} from '../../templates/CandidateRepresentation.template';
import cleanHtml from '../../tools/cleanHtml';
import {
  combineEmailsLists,
  sendSafeEmail
} from "./Email.lib";
import Button from '../../../components/Layout/Wrappers/Button';

/* METHODS ==================================== */

async function openRepresentEmailPreview({
  candidateId,
  engagement
}) {
  Candidate.get(candidateId,
    async candidate => {
      /** */
      if (candidate.email) {

        let jobList = [];

        let companies = {};
        candidate.engagements.forEach(eng => {
          if (eng.state === ENGAGEMENT__STATE_OPEN) {
            if (
              [
                STAGE_SUBMISSION,
                STAGE_REVIEW
              ].includes(eng.stage)
            ) {
              companies[eng.job.employer.name] = true;
              jobList.push(
                [
                  (!!eng.job.jobDescriptionPublicURL?.trim()
                    ? `<a href="${eng.job.jobDescriptionPublicURL}" target="_blank"><b>${eng.job.jobTitle}</b></a>`
                    : eng.job.jobTitle
                  ),
                  (!!eng.job.employer.url
                    ? `<a href="${eng.job.employer.url}" target="_blank"><b>${eng.job.employer.name}</b></a>`
                    : eng.job.employer.name
                  )
                ].filter(n => !!n).join(' at ')
              );
            }
          }
        });
        jobList = `<li>${jobList.filter(n => !!n).map(n => n.trim()).join('</li><li>')}</li>`;

        Core.log({ companies: Object.keys(companies).length });

        companies = (
          Object.keys(companies)
            .join(", ")
            .trim()
        );

        if (!!companies.length) {
          const html = compile(cleanHtml(
            TEMPLATE__CANDIDATE_REPRESENTATION_EMAIL__BODY
          ))({
            CANDIDATE__FIRSTNAME: candidate.firstName,
            RECRUITER__NAME: candidate.recruiter.talentAdvisor || candidate.recruiter._name,
            JOB_LIST: jobList,
            CANDIDATE__EMAIL: candidate.email || '?',
            CANDIDATE__PHONE: candidate.phone || '?',
            BEST_TIME_FOR_CALL: candidate.bestTimeForCalls || '?',
            SIGNATURE: Core.getEmailSignature(),
            TEN_X_TEN__EMAIL_ADDRESS: `<a href="mailto:${process.env.REACT_APP_TEAM_EMAIL}">${process.env.REACT_APP_TEAM_EMAIL}</a>`
          });
          let {
            from = '',
            emails = [],
            to = [],
            cc = [],
            bcc = []
          } = {};
          let renderCandidate;
          try {
            renderCandidate = await TemplateLib.getRender({
              templateName: 'candidate-message',
              candidateId: candidate.id,
              messageTypes: [REC_MSG_TYPE__REPRESENTATION_ID]
            });
            from = renderCandidate.mixins.from;
            emails = combineEmailsLists(emails, renderCandidate.mixins.emails);
            to = combineEmailsLists(to, renderCandidate.mixins.to);
            cc = combineEmailsLists(cc, renderCandidate.mixins.cc);
            bcc = combineEmailsLists(bcc, renderCandidate.mixins.bcc);
          }
          catch { }

          Core.dialog.open({
            title: <>Represent Email</>,
            content: (
              <EmailPreview
                ref={self => Core.setKeyValue('CandidateRepresentEmailPreview', self)}
                from={from}
                emails={emails}
                to={to}
                cc={cc}
                bcc={bcc}
                subject={
                  compile(TEMPLATE__CANDIDATE_REPRESENTATION_EMAIL__SUBJECT)({
                    COMPANIES: companies
                  })
                }
                body={html}
              />
            ),
            actions: [
              <Button outlined
                onClick={ev => Core.dialog.close()}
                className='min-w-120'
              >
                Cancel
              </Button>,
              <Button primary
                onClick={ev => {
                  Core.dialog.close();
                  sendSafeEmail({
                    ...(
                      Core.getKeyValue(
                        'CandidateRepresentEmailPreview'
                      ).getParams()
                    ),
                    source: 'CandidateEmail.lib.js',
                    boxKey: engagement.boxKey,
                  }).then(response => Core.showSuccess('Email sent'));
                }}
                className='min-w-120'
              >
                Send
              </Button>
            ],
            paperStyle: {
              width: 1200,
            }
          });
        } else {
          Core.showWarning(
            "There are no engagements in submission or review for this candidate"
          );
        }
      } else {
        Core.showWarning("There is missing the candidate email");
      }
    });
};

/* DICTIONARY ================================= */

const CandidateEmailLib = {
  openRepresentEmailPreview
};

/* EXPORTS ==================================== */

export {
  CandidateEmailLib,
  CandidateEmailLib as default,
  openRepresentEmailPreview as openCandidateRepresentEmailPreview
};

/* ============================================ */
