import queryString from "query-string";
import {
  Component
} from "react";
import { NOT } from '../../lib/Boolean.lib';
import Core from "../../lib/Core";
import {
  ACCOUNT_ACTION__LIST_ENGAGEMENTS,
  STATE_ACTIVE
} from "../../lib/Definition";
import Engagement, {
  getToBeDefinedMatchStrengthLabel
} from "../../lib/Engagement";
import EngagementDisagreement from "../../lib/EngagementDisagreement";
import FilterControl from "../../lib/FilterControl";
import {
  Fun
} from '../../lib/Function.lib';
import {
  getPersonName,
  Obj
} from '../../lib/Object.lib';
import {
  encodeBase64,
  trim
} from '../../lib/String.lib';
import {
  getParams,
  getPathname,
  getSearch,
  matchLocation
} from '../../lib/URL.lib';
import {
  ENGAGEMENTS_STAGES__ALL_STAGES
} from '../Engagements/Engagements';
import {
  configJobs
} from '../Home/configJobs';
import {
  configMatchCandidates
} from '../Home/configMatchCandidates';
import {
  mapCandidateVisaToJobPresets,
  mapJobVisaToCandidatePresets
} from '../Home/mapVisaPresets.lib';
import ReactTable from '../Home/ReactTable';
import {
  mapWrapperName
} from '../Layout/Libraries/Theme.lib';
import Page from '../Layout/Page';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import {
  WarningMessage
} from '../Layout/Wrappers/Message';
import Navigate from '../Layout/Wrappers/Navigate';
import StrategyMainCandidate from './Candidate/Strategy';
import NewFilterControl from "./FilterControl/NewFilterControl";
import StrategyMainJob from './Job/Strategy';
import {
  getMatchEntities,
  isMatchEngageable,
  KEY__CON__MATCH_PAGE,
  KEY__SCROLL_NEXT_MATCH
} from './Libraries/MatchList.lib';
import sendEmailOnAnnotatorAndMatcherConflict from './Libraries/Tools/sendEmailOnAnnotatorAndMatcherConflict.tool';
import ListPipe, {
  ListPipeController
} from "./List/ListPipe";
import MatchOptionsMenu from './List/MatchOptionsMenu';

export default function MatchPage(props) {

  if (matchLocation(/matchNew/i)) {
    return (
      <Navigate
        to={
          Core.getLink(
            getPathname().replace(/matchNew/, 'match')
          )
        }
      />
    );
  }

  const candidateId = (
    getParams({ pattern: '/candidate/match/:id' }).id
  );

  const jobId = (
    getParams({ pattern: '/job/match/:id' }).id
  );
  /**
   * BB - This looks like legacy code with regard to params.  At some time in the past
   * a queryString must have been placed in the URL and this code tries to extract it
   * using getSearch() and use it as parameters for downstream processing. I don't believe 
   * this is done any longer and the params values can be removed here and downstream.
   * ToDo: remove references to params obtained from the URL using getSearch().
   */
  const _wrapperProps = mapWrapperName(
    'MatchPage',
    {
      ...props,
      params: queryString.parse(getSearch())
    }
  );

  if (jobId) {
    Object.assign(_wrapperProps, {
      jobId,
      strategy: StrategyMainJob(
        jobId,
        _wrapperProps.params
      )
    });
  }

  if (candidateId) {
    Object.assign(_wrapperProps, {
      candidateId,
      strategy: StrategyMainCandidate(
        candidateId,
        _wrapperProps.params
      )
    });
  }

  return (<MatchMain {..._wrapperProps} />);

}

export class MatchMain extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      profile: {},
      engagements: [],

      /** @todo rename to matches */
      object: [],

      selectedMatch: {},
      skippedMatches: [],
      selectedMatches: [],
      menus: [],
      more: [],
      sources: [],
      chips: [],
      keywords: [],
      filteredMatches: [],
      shouldShowBlacklisted: false,
    };
    this._menuFlag = false;
  }

  componentDidMount() {
    const _isV3 = !!getPathname().match(/v3/);
    setTimeout(() => {
      this.loadProfile(() => {
        if (!_isV3) {
          this.fetchMatches({}, true)
        }
      });
    });
  }

  loadProfile(
    callback = () => null
  ) {
    this.props.strategy.fetchProfile((profile, engagements) => {

      // in case this was set before record was loaded and it work properly with filters
      profile.applyLooseMatch = this.state.applyLooseMatch;

      this.setState(
        {
          profile,
          engagements
        },
        () => {
          callback();
        }
      );
    });
  }

  fetchMatches = (params = {}, shouldSetInitialFilters = false) => {
    this.setState({ isFetchingEntitiesToMatch: true });
    this.props.strategy.fetchMatches((object) => {
      this.setState({ object, isFetchingEntitiesToMatch: false }, () => {
        const { profile, object } = this.state;
        if (!!profile && !!object.length) {
          if (shouldSetInitialFilters) {
            const getInitialFilters = this.props.strategy.filterControl({
              profile,
              object,
              selected: params.selected,
            });
            let {
              menus,
              more,
              sources,
              chips,
              filteredMatches,
              constraintsObjects,
              withoutConstraintsObjects,
            } = getInitialFilters;
            this.setState({
              menus,
              more,
              sources,
              chips,
              filteredMatches,
              constraintsObjects,
              withoutConstraintsObjects,
            });
          }
          else {
            this.setState({ withoutConstraintsObjects: object }, () => {
              this.updateFilteredObjects();
            });
          }
        }
      });
    }, params);
  };

  deleteChip = (update, index) => {
    console.debug('deleteChip :update :index\n', update, index);
    window.scrollTo(0, 0);
    let { menus, more, keywords, chips, profile } = this.state;

    if (update.keyword) {
      keywords = keywords.filter(
        (objKeyword) => objKeyword.name !== update.name
      );
    } else if (update.menu) {
      menus.find(unselectItem);
    } else if (update.more) {
      more.find(unselectItem);
    } else if (update.minimumSalary) {
      if (!!profile.tempSalaryMin) {
        profile.tempSalaryMin = 0;
      }
      if (!!profile.tempMinimumSalary) {
        profile.tempMinimumSalary = 0;
      }
    } else if (update.minimumXp) {
      if (!!profile.tempMinYearsOfExperience) {
        profile.tempMinYearsOfExperience = 0;
      }
      if (!!profile.tempMinimumExperience) {
        profile.tempMinimumExperience = 0;
      }
    } else if (update.minimumCompanySize) {
      if (!!profile.tempMinimumCompanySize) {
        profile.tempMinimumCompanySize = 0;
      }
    }

    function unselectItem(menu) {
      return Object.keys(menu.items).find((name) => {
        if (
          update.name === "Introduced:Active" &&
          name === "Active" &&
          menu.label === "Introduced"
        ) {
          menu.items[name] = false;
          return true;
        } else if (
          update.name === "State:Active" &&
          name === "Active" &&
          menu.label === "State"
        ) {
          menu.items[name] = false;
          return true;
        } else if (name === update.name) {
          menu.items[name] = false;
          return true;
        }
        return false;
      });
    }

    if (index !== undefined) {
      chips.splice(index, 1);
    }
    else if (update.keyword) {
      chips = chips.filter(c => c.name !== update.name);
    }

    this.setState(
      {
        keywords,
        menus,
        chips,
        profile,
      },
      () => {
        this.updateFilteredObjects();
      }
    );
  };

  updateKeywordChip = (update) => {
    let { keywords, chips } = this.state;
    keywords.push(update);
    chips.push(update);
    this.setState(
      {
        keywords,
        chips,
      },
      () => {
        this.updateFilteredObjects();
      }
    );
  };

  updateSliderValues = (key, update) => {
    let { profile, menus, more, keywords } = this.state;
    profile = {
      ...profile,
      [key]: update,
    };

    const chips = FilterControl.setChipsOnMoreFilters(
      profile,
      menus,
      more,
      keywords,
      this.props.strategy.getChips
    );

    this.setState(
      {
        profile,
        [key]: update,
        chips,
      },
      () => {
        this.updateFilteredObjects();
      }
    );
  };

  onChangeMenusHandler =
    (profile) =>
      (
        label,
        menuKey,
        checked,
        fields = [],
        cb,
        shouldUpdateFilteredObjects = true
      ) => {
        const filters = FilterControl.setSearchMenuOnChange(
          profile,
          label,
          menuKey,
          checked,
          fields,
          cb,
          this.state.menus,
          this.state.more,
          this.state.keywords,
          this.props.strategy.getChips
        );

        const { menus, more, chips } = filters;

        this.setState(
          {
            menus,
            more,
            chips,
          },
          () => {
            if (shouldUpdateFilteredObjects) {
              this.updateFilteredObjects();
            }
          }
        );
      };

  updateFilteredObjects = () => {
    const {
      profile,
      keywords,
      menus,
      more,
      withoutConstraintsObjects,
      constraintsObjects,
    } = this.state;
    const { params } = this.props;
    const filteredMatches = this.props.strategy.filterMatches(
      profile,
      withoutConstraintsObjects,
      keywords,
      menus,
      more,
      Object(params).selected,
      constraintsObjects
    );
    ListPipeController().setState(
      { doneMLScore: false },
      () => this.setState({ filteredMatches })
    );
  };

  onSelectMatch = (selectedMatch = {}) => {
    console.debug(
      'MatchPage__onSelectMatch',
      '\n', selectedMatch, this.state.object
    );
    if (!!selectedMatch.id) {
      this.setState({ selectedMatch });
    }
  };

  handlerOnSelectBox = (match, checked = true) => {
    const { selectedMatches = [] } = this.state;
    if (checked) {
      selectedMatches.push(match);
    }
    else {
      this.setState({
        selectedMatches: selectedMatches.filter(
          (selectedMatch) => (selectedMatch.id !== match.id)
        )
      });
    }
  };

  afterProfileMatch = (match, engagement) => {
    engagement[this.props.strategy.matchKey] = match;
    const { engagements } = this.state;
    this.handlerOnSelectBox(match, false);
    this.loadProfile();
    this.setState({
      engagements: [
        ...engagements,
        engagement
      ]
    });
  };

  createSingleEngagementWithDis = (match, model = {}, callback = () => null) => {
    const {
      candidate = {},
      job = {},
      employer = {},
    } = getMatchEntities({ match });
    const createDis = (model) => (engagement) => {
      const matchStrengthLabel = getToBeDefinedMatchStrengthLabel({
        status: Obj(engagement).status,
        value: model.matchStrengthLabel
      });

      // CREATE OR UPDATE DISAGREEMENT
      this.createDisagreement({
        ...model,
        matchStrength: matchStrengthLabel,
        candidate,
        employer,
        job,
        engagement,
      });
    };
    this.createSingleEngagement(match, model, (engagement) => {
      createDis(model)(engagement);
      callback(engagement);
    });
  };

  createSingleEngagement = async (match, model, callback = () => null) => {
    try {
      const {
        engagements = []
      } = this.state;
      const {
        job = {},
        candidate = {},
      } = getMatchEntities({ match });
      const existingEngagement = engagements.find(
        (engagement) =>
          engagement[`${this.props.strategy.matchKey}Id`]
          ===
          match.id
      );
      if (!Obj(existingEngagement).id && isMatchEngageable(match)) {
        const engagement = await Engagement.match({ candidate, job, ...model });
        this.afterProfileMatch(match, engagement);
        callback(engagement);
      }
      else {
        callback(existingEngagement);
      }
    }
    catch (error) {
      Core.showError(error);
    }
  };

  createDisagreement = async ({
    matchStrength,
    engagement,
    candidate,
    job,
    employer,
    shouldTag,
    reviewed,
    shouldNotTag,
    whyNoPrivateNote,
    whyNeedToReadCV,
    whyNoCategories,
    whyNoDetails,
    whyNoFieldsValues,
  }) => {
    try {

      const disagreementObj = this.props.strategy.disEngagementModel({
        matchStrength,
        candidate,
        employer,
        reviewed,
        job,
        engagement,
        shouldTag,
        shouldNotTag,
        whyNoPrivateNote,
        whyNeedToReadCV,
        whyNoCategories,
        whyNoDetails,
        whyNoFieldsValues,
      });

      const { selectedMatches } = this.state;

      if (!!selectedMatches && !!selectedMatches.length > 0) {
        this.setState({ selectedMatches: [] });
      }

      await EngagementDisagreement.findOrCreate(disagreementObj);

      await sendEmailOnAnnotatorAndMatcherConflict({
        engagement,
        matchStrength,
        disagreementObj,
        candidate,
        job
      });

      Fun(Core.getKeyValue(KEY__SCROLL_NEXT_MATCH))();

      Core.showMessage(
        `Added entry to ML Table with match Strength ${matchStrength}`
      );

    }
    catch (error) {
      Core.showError(error);
    }
  };

  afterMainUpdateEngagement = (eng) => {
    const { engagements } = this.state;
    const existingState = engagements.filter((engg) => engg.id === eng.id);
    const withoutEng = engagements.filter((engg) => engg.id !== eng.id);
    this.setState({
      engagements: [
        ...withoutEng,
        {
          ...existingState,
          ...eng,
        },
      ],
    });
  };

  updateObject = (object) => {
    const { selectedMatch } = this.state;
    const updateSelectedJob = !!selectedMatch
      ? object.find((obj) => obj.id === selectedMatch.id)
      : {};
    this.setState({
      object,
      selectedMatch: updateSelectedJob,
    });
  };

  updateEngagement = (engagement, field) => {
    if (Core.isAdminOrCoordinator()) {
      if (engagement) {
        Engagement.update(
          engagement,
          field,
          (res) => { },
          () => alert("failed to save")
        );
      }
    }
  };

  handlerToggleShouldShowBlacklisted = (value) => {
    this.setState({ shouldShowBlacklisted: value });
  };

  render() {
    const {
      profile = {},
      selectedMatch = {},
      engagements = [],
      selectedMatches = [],
      object,
      skippedMatches,
      filteredMatches,
      isFetchingEntitiesToMatch,
      shouldShowBlacklisted,
    } = this.state;
    const { params } = this.props;
    Core.setKeyValue(KEY__CON__MATCH_PAGE, this);
    const _getTitle = () => {
      if (profile.id) {
        if (matchLocation(/job\/match/i)) {
          return `Job Match: ${profile._name}`;
        }
        else if (matchLocation(/candidate\/match/i)) {
          return `Candidate Match: ${getPersonName(profile)}`;
        }
      }
      return 'Matching page';
    };
    const isV3 = !!matchLocation(/v3/i);
    const maxHeight = isV3 ? 'var(--matchPipeHeightV3)' : 'var(--matchPipeHeightV1)';
    const minWidth = 400;
    return (
      <Page
        role='MathPageContainer'
        title={_getTitle()}
        contentRight={
          <>
            {!!selectedMatches.length && (
              <div className='f-md fw-500 mr-2'>
                Selected elements {selectedMatches.length}
              </div>
            )}
            {(
              (
                Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ENGAGEMENTS }) &&
                matchLocation(/candidate\/match/i)
              ) && (
                <Button flat
                  startIcon={
                    <i className='material-icons c-inherit'>sync</i>
                  }
                  aria-label="Open candidate's engagements in a new window"
                  className='tt-unset hint--left'
                  onClick={event => {
                    Core.openPopUp(
                      Core.getPath(
                        `engagements?o=${encodeBase64(JSON.stringify({
                          candidateId: profile.id,
                          stageFilter: ENGAGEMENTS_STAGES__ALL_STAGES
                        }))}`
                      ),
                      1600
                    )
                  }}
                >
                  Engagements
                </Button>
              )
            )}
          </>
        }
      >
        <Box column w100 role='MathPage'>
          <Box role='MatchPageFilterBar' style={{ height: isV3 ? 232 : 80 }}>
            {
              isV3
                ? (
                  profile.id && (
                    <ReactTable
                      {...this.props} // to pass the router context
                      config={matchLocation(/candidate\/match/)
                        ? configJobs
                        : configMatchCandidates
                      }
                      onChange={data => {
                        this.setState({
                          object: data,
                          filteredMatches: data,
                          isFetchingEntitiesToMatch: false,
                        });
                      }}
                      presets={(() => {
                        let presets = {
                          state: STATE_ACTIVE,
                          inOfficeRemoteFlags: profile.inOfficeRemoteFlags,
                          candidateLocations: profile.candidateLocations,
                          officeLocations: profile.officeLocations,
                          roles: profile.roles,
                          desiredEmploymentTypes: profile.desiredEmploymentTypes,
                          looseMatch: false,
                          blacklisted: false,
                        };
                        if (matchLocation(/candidate\/match/)) {
                          mapCandidateVisaToJobPresets({
                            candidate: profile,
                            presets,
                          });
                          presets.salaryMax = profile.minimumSalary;
                          presets.minYearsOfExperience = profile.yearsOfExperience;
                        }
                        else if (matchLocation(/job\/match/)) {
                          mapJobVisaToCandidatePresets({
                            job: profile,
                            presets
                          });
                          presets.minimumSalary = profile.salaryMax;
                          presets.yearsOfExperience = profile.minYearsOfExperience;
                        }
                        return presets;
                      })()}
                      profile={profile}
                      disableBodyRender
                    />
                  )
                ) : (
                  <NewFilterControl
                    {...this.props.strategy}
                    updateFilteredObjects={this.updateFilteredObjects}
                    handlerToggleShouldShowBlacklisted={
                      this.handlerToggleShouldShowBlacklisted
                    }
                    menus={this.state.menus}
                    more={this.state.more}
                    sources={this.state.sources}
                    chips={this.state.chips}
                    updateKeywordChip={this.updateKeywordChip}
                    onChangeMenusHandler={this.onChangeMenusHandler(profile)}
                    deleteChip={this.deleteChip}
                    minimumSalary={profile.tempMinimumSalary || 0}
                    minimumExperience={profile.tempMinimumExperience || 0}
                    minimumCompanySize={profile.tempMinimumCompanySize || 0}
                    updateSliderValues={this.updateSliderValues}
                    matchKey={this.props.strategy.matchKey}
                    shouldShowBlacklisted={shouldShowBlacklisted}
                    fetchMatches={this.fetchMatches}
                  />
                )
            }
          </Box>
          <Box row w100 role='MatchPageContent'
            className='flex-1 flex-align-left-top scroll-x'
          >
            <Box flex1 h100 scrollY
              style={{ maxHeight, minWidth }}
            >
              {(
                !!profile.id &&
                (isV3 || !!object.length) &&
                this.props.strategy.renderProfilePipe({
                  profile,
                  selectedMatch
                })
              )}
            </Box>
            <Box flex1 h100 scrollY
              className='border-left'
              style={{ maxHeight, minWidth }}
            >
              <WarningMessage show={NOT(selectedMatch.id)}>
                No active matched candidate to process !!!
              </WarningMessage>
              {(
                !!profile.id &&
                (isV3 || !!object.length) &&
                this.props.strategy.renderMatchPipe(
                  { profile, selectedMatch },
                  this.updateObject
                )
              )}
            </Box>
            <Box
              className='sticky-end bg-main'
              style={{
                height: maxHeight,
                width: minWidth,
                maxHeight,
                minWidth,
              }}
            >
              <ListPipe {...this.props.strategy}
                profile={profile}
                matches={filteredMatches}
                shouldShowBlacklisted={shouldShowBlacklisted}
                engagements={engagements}
                fetchMatches={!isV3 && this.fetchMatches}
                isFetchingEntitiesToMatch={isFetchingEntitiesToMatch}
                selectedMatch={selectedMatch}
                skippedMatches={skippedMatches}
                selectedMatches={selectedMatches}
                params={params}
                handlerOnSelectBox={this.handlerOnSelectBox}
                createSingleEngagement={this.createSingleEngagement}
                afterMainUpdateEngagement={this.afterMainUpdateEngagement}
                updateEngagement={this.updateEngagement}
              />
            </Box>
          </Box>
        </Box>
        <MatchOptionsMenu />
      </Page>
    );
  }
}

export function MatchPage__addKeyword(keyword) {
  keyword = trim(keyword);
  if (!keyword) { return; }
  return (
    Fun(
      MatchPageController().updateKeywordChip
    )(
      {
        name: keyword,
        keyword: true
      }
    )
  );
}

export function MatchPage__removeKeyword(keyword) {
  keyword = trim(keyword);
  if (!keyword) { return; }
  return (
    Fun(
      MatchPageController().deleteChip
    )(
      {
        name: keyword,
        keyword: true
      }
    )
  );
}

export function MatchPage__getState() {
  return Obj(MatchPageController().state);
}

export function MatchPageController() {
  return Obj(Core.getKeyValue(KEY__CON__MATCH_PAGE));
}

export function MatchPage__getProfile() {
  return Obj(MatchPage__getState().profile);
}
