import {
  Component
} from "react";
import {
  Obj
} from '../../lib/Object.lib';
import {
  Str
} from '../../lib/String.lib';
import Divider from '../Layout/Wrappers/Divider';
import IconButton from '../Layout/Wrappers/IconButton';
import TextField from '../Layout/Wrappers/TextField';
import TagAttributeList from "./TagAttributeList";

class TagBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: Obj(props.tag),
      changes: false,
    };
  }
  save = (event) => {
    const {
      onUpdate = () => { },
      onArchived = () => { }
    } = this.props;
    const tag = Obj(event.tag || this.state.tag);
    // ON ARCHIVE/UNARCHIVE TAG
    if (event.tag) {
      const { archived } = event.tag;
      this.setState({ tag: event.tag }, () => {
        archived
          ? onArchived(event.tag)
          : onUpdate(event.tag);
      });
    }
    // ON SAVE
    else if (this.state.changes) {
      const { archived } = this.state.tag;
      this.setState({
        changes: false,
        // RESET LOCAL STATE ON SAVING NEW
        tag: this.props.isNew ? {} : tag
      }, () => {
        archived
          ? onArchived(tag)
          : onUpdate(tag);
      });
    }
  };
  focus = (em) => {
    this.textField && this.textField.focus();
  };
  render() {
    const {
      isNew,
      divider = true,
    } = this.props;
    if (/categories/i.test(this.state.tag.key)) {
      return <div />;
    }

    const _onBlur = (event) => {
      if (
        (event.type === 'blur') ||
        !!Str(event.key).match(/enter|tab/i)
      ) {
        this.save(event);
      }
    }

    return (
      <>
        {!isNew && divider && <Divider className="m-0" />}
        <div className="hover-bg p-1 w-100">
          <div className="tag-box inline-blocks padding-left">
            {!this.props.isNew && (
              <small className="c-black-medium mr-1">id: {this.state.tag.id}</small>
            )}
            <div>
              <small className="c-black-medium mr-1">Label:</small>
              <TextField
                ref={(tf) => (this.textField = tf)}
                name={this.props.name}
                placeholder={this.props.placeholder}
                value={this.state.tag.label}
                onChange={(ev, change) => {
                  this.setState((state) => {
                    state.tag.label = change;
                    state.changes = true;
                    return state;
                  });
                }}
                onBlur={_onBlur}
                onKeyPress={_onBlur}
                disabled={this.state.tag.archived}
              />
            </div>
            {this.state.tag.archived
              ? (
                <IconButton
                  onClick={(event) => {
                    const tag = { ...this.state.tag };
                    tag.archived = false;
                    this.save({ tag });
                  }}
                >
                  <i className="material-icons">unarchive</i>
                </IconButton>
              )
              : this.state.changes
                ? (
                  <IconButton onClick={this.save}>
                    <i className="material-icons c-orange">save</i>
                  </IconButton>
                )
                : (
                  <IconButton
                    className=""
                    onClick={(ev) =>
                      this.setState({ disabled: false }, (em) => this.textField.focus())
                    }
                  >
                    <i className="material-icons">mode_edit</i>
                  </IconButton>
                )}
            {!this.props.isNew && !this.state.tag.archived && (
              <IconButton
                onClick={(ev) => {
                  const tag = { ...this.state.tag };
                  tag.archived = true;
                  this.save({ tag });
                }}
              >
                <i className="material-icons">archive</i>
              </IconButton>
            )}
          </div>
          <blockquote>
            <TagAttributeList
              category={this.props.category}
              tag={this.state.tag}
              disabled
              onChange={
                tag => {
                  this.setState((state) => {
                    state.tag = tag;
                    state.changes = true;
                    return state;
                  });
                }
              }
            />
          </blockquote>
        </div>
      </>
    );
  }
}
export default TagBox;
