// CAVE

export const TEMPLATE__CANDIDATE_REPRESENTATION_EMAIL__SUBJECT = `Thanks for your interest in {{COMPANIES}}`

/**
 * @param CANDIDATE__FIRSTNAME
 * @param RECRUITER__NAME
 * @param JOB_LIST
 * @param CANDIDATE__EMAIL
 * @param CANDIDATE__PHONE
 * @param BEST_TIME_FOR_CALL
 * @param TEN_X_TEN__EMAIL_ADDRESS
 * @param SIGNATURE
 */
export const TEMPLATE__CANDIDATE_REPRESENTATION_EMAIL__BODY = `
<div>
  <p>
    Hi {{CANDIDATE__FIRSTNAME}},
  </p>
  <p>
    <b>{{RECRUITER__NAME}}</b>, our partner, shared that you're interested in new opportunities. We've presented you to the hiring team to expedite the process.
  </p>
  <p>
    Please review the information below to ensure that we have presented you to the correct role(s):
  </p>
  <ul>
    {{{JOB_LIST}}}
  </ul>
  <p>
    Here's the contact information we have:
  </p>
  <ul>
    <li>
      Email: {{{CANDIDATE__EMAIL}}}
    </li>
    <li>
      Phone: {{{CANDIDATE__PHONE}}}
    </li>
  </ul>
  <p>
    <span style="color:#ff0000;">
      Important: Employers expect you to be excited about the role and can explain why you want this interview.
    </span>
  </p>
  <p>
    What's next?
  </p>
  <ul>
    <li>
      You are represented by <a href="https://www.10by10.io/candidate">10 BY 10</a>.
    </li>
    <li>
      <b>Employers</b> communicate <b>solely</b> with {{{TEN_X_TEN__EMAIL_ADDRESS}}} (we will relay info to {{RECRUITER__NAME}}).
    </li>
    <li>
      <b>Employer will email you</b> if interested in interviewing you.
    </li>
    <li>
      <b>{{RECRUITER__NAME}}</b>, our partner in finding top candidates, can help with interview prep.
    </li>
    <li>
      For questions, email both {{{TEN_X_TEN__EMAIL_ADDRESS}}} and {{RECRUITER__NAME}} for a faster response.
    </li>
  </ul>
  <p>
    Best of luck!
  </p>
  {{{SIGNATURE}}}
</div>
`;
