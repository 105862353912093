import Definition, {
  LOC_TYPE__ALIAS_ID
} from "../../lib/Definition";
import MultipleSelect from "../Layout/Wrappers/MultipleSelect";
import SingleSelectDropdown from "../Layout/Wrappers/SingleSelect";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function TagAttributeItem(props) {
  const {
    category,
    tag,
    attributeName,
    value,
    onChange = () => { },
  } = props;
  function _getLocationTags({ ignoreAlias } = {}) {
    return category.values.filter(n => (
      /* story-3220 M1 - "not allow aliases to be selected as parents" */
      (ignoreAlias ? n.type !== LOC_TYPE__ALIAS_ID : true) &&
      (n.id !== tag.id) &&
      !n.archived
    ));
  }
  function _onChange(attributeName) {
    return value => {
      onChange({
        attributeName,
        value,
      });
    }
  }
  return (
    <div className="d-flex align-center justify-start">
      <small className="mr-1 c-black-medium col1 t-align-right">{attributeName}:</small>
      {(
        attributeName.match(/type/i)
          ? (
            <SingleSelectDropdown
              name="type"
              placeholder="Select a type of location"
              data={[
                ...Definition.get('locationType')
              ]}
              value={value}
              defaultValue={LOC_TYPE__ALIAS_ID}
              onChange={_onChange('type')}
            />
          )
          : attributeName.match(/parentId/i)
            ? (
              <SingleSelectDropdown
                name="locationId"
                placeholder="Select a location"
                data={_getLocationTags({ ignoreAlias: true })}
                value={value}
                onChange={_onChange('parentId')}
              />
            )
            : attributeName.match(/aliasLocationIds/i)
              ? (
                <MultipleSelect
                  name="aliasLocationIds"
                  placeholder="Select multiple locations"
                  data={_getLocationTags()}
                  value={value}
                  onChange={_onChange('aliasLocationIds')}
                />
              )
              : ''
      )}
    </div>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export {
  TagAttributeItem as default,
  TagAttributeItem
};

/* ============================================ */
