import {
  Input
} from 'antd';
import Core from '../../lib/Core';
import Engagement from '../../lib/Engagement';
import {
  NOT
} from '../../lib/GenericTools.lib';
import {
  ENGAGEMENT__FEEDBACK_LOG
} from '../../dictionaries/Engagement.dic';
import {
  trim
} from '../../lib/String.lib';
import Fieldset from '../Layout/Wrappers/Fieldset';
import {
  Html
} from '../Layout/Wrappers/Html';
import useState from '../../lib/hooks/useState.hook';
import EngagementUI from '../../dictionaries/EngagementUI.dic';

/**
 * 
 * @param {object} props
 * @param {object} props.engagement  [{id,[ENGAGEMENT__FEEDBACK_LOG]}]
 * @param {string} props.title  [ReactJSXElement]
 * @param {boolean} props.autoSave  [default:true]
 * @param {boolean} props.disabled  [default:false]
 * @param {boolean} props.readOnly  [default:false]
 * 
 * @returns 
 */
export function FeedbackLog({
  engagement,
  title = EngagementUI[ENGAGEMENT__FEEDBACK_LOG].title,
  onChange = async (update) => null,
  autoSave = true,
  disabled = false,
  readOnly = false,
  ...props
}) {
  const [_state, _updateState] = useState();
  const _value = (
    _state[ENGAGEMENT__FEEDBACK_LOG] !== undefined
      ? _state[ENGAGEMENT__FEEDBACK_LOG]
      : engagement[ENGAGEMENT__FEEDBACK_LOG]
  );
  const _trimValue = trim(_value);
  const _onDone = async (event) => {
    try {
      if (_trimValue === engagement[ENGAGEMENT__FEEDBACK_LOG]) { return; }
      const _update = { [ENGAGEMENT__FEEDBACK_LOG]: _trimValue };
      Object.assign(engagement, _update);
      await onChange(_update);
      if (NOT(autoSave)) { return; }
      await Engagement.update(engagement, _update);
    }
    catch (error) {
      Core.showError(error);
    }
  }
  return (NOT(readOnly) || (readOnly && _trimValue)) && (
    <Fieldset
      title={title}
      wrapperProps={{ className: 'p-0' }}
      fullWidth
      {...props}
    >
      {readOnly
        ? (
          <div
            className='scroll rounded border-1 p-1'
            style={{ maxHeight: 128 }}
          >
            <Html value={engagement[ENGAGEMENT__FEEDBACK_LOG]} />
          </div>
        ) : (
          <Input.TextArea
            placeholder={EngagementUI[ENGAGEMENT__FEEDBACK_LOG].placeholder}
            value={_value}
            onChange={(event) => _updateState({
              [ENGAGEMENT__FEEDBACK_LOG]: event.target.value
            })}
            onBlur={_onDone}
            onPressEnter={_onDone}
            autoSize={{ minRows: 3, maxRows: 12 }}
            disabled={disabled}
          />
        )}

    </Fieldset>
  );
}
