import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  Component
} from "react";
import formatMoney from "../../lib/tools/formatMoney";
import { getLocation } from '../../lib/URL.lib';
import Button from '../Layout/Wrappers/Button';
import Checkbox from '../Layout/Wrappers/Checkbox';
import Col from "../Layout/Wrappers/Col";
import Dialog from '../Layout/Wrappers/Dialog';
import Row from "../Layout/Wrappers/Row";
import Slider from "../Layout/Wrappers/Slider";
import TextField from '../Layout/Wrappers/TextField';

class MoreFiltersDialog extends Component {
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = {
      more: [],
      open: false,
      search: ""
    };
  }
  open = (more, selected) => {
    this.setState({ more, open: true });
  };
  close = em => this.setState({ open: false, search: "" });
  render() {
    return (
      <Dialog
        title={"Filter by"}
        actionBar={
          <>
            {!!this.state.more.length &&
              !!this.state.more[0].key && (
                <TextField
                  name="searchFilter"
                  className="input"
                  floatingLabelText="Search keywords"
                  required={true}
                  style={{
                    width: "calc(100% - 16px)",
                    fontSize: 14,
                    margin: "0 8px"
                  }}
                  type="text"
                  value={this.state.search}
                  onChange={(event, search) => this.setState({ search })}
                  autoFocus
                />
              )}
          </>
        }
        content={
          <>
            {!!getLocation().match(/jobs|candidate\/match/i) &&
              <Row className='mb-3'>
                <Col style={{ height: 80 }} fullWidth>
                  <label>
                    Jobs offering up to:&nbsp; ${formatMoney(
                      this.parent.state.minimumSalary,
                      0
                    )}
                  </label>
                  <Slider moneyFormat
                    name="minimumSalary"
                    min={this.parent.minSalary}
                    max={this.parent.maxSalary}
                    step={this.parent.sliderStep}
                    value={this.parent.state.minimumSalary}
                    onChange={(event, minimumSalary) => {
                      this.parent.setState({ minimumSalary });
                    }}
                  />
                </Col>
              </Row>
            }

            {!!getLocation().match(/candidates|job\/match/i) &&
              <Row className='mb-3'>
                <Col style={{ height: 80 }} fullWidth>
                  <label>
                    Can Work for Salary less than:&nbsp; ${formatMoney(
                      this.parent.state.minimumSalary,
                      0
                    )}
                  </label>
                  <Slider moneyFormat
                    name="minimumSalary"
                    min={this.parent.minSalary}
                    max={this.parent.maxSalary}
                    step={this.parent.sliderStep}
                    value={this.parent.state.minimumSalary}
                    onChange={(event, minimumSalary) => {
                      this.parent.setState({ minimumSalary });
                    }}
                  />
                </Col>
              </Row>
            }

            {!!getLocation().match(/candidates|jobs|(job|candidate)\/match/i) &&
              <Row className='mb-3'>
                <Col style={{ height: 80 }} fullWidth>
                  <label>
                    {getLocation().match(/jobs|candidate\/match/i) ? `Jobs requiring <=` : 'Candidate has >='}&nbsp;{this.parent.state.minimumXp || 0}&nbsp;years
                  </label>
                  <Slider
                    name="minimumXp"
                    min={this.parent.minXp}
                    max={this.parent.maxXp}
                    step={this.parent.sliderStepXp}
                    value={this.parent.state.minimumXp}
                    onChange={(event, minimumXp) => {
                      this.parent.setState({ minimumXp });
                    }}
                  />
                </Col>
              </Row>
            }

            {!!getLocation().match(/jobs/i) && (
              <Row className='mb-3'>
                <Col style={{ height: 80 }} fullWidth>
                  <label>
                    Company size up to:&nbsp;{this.parent.state.minimumCompanySize || 0}
                  </label>
                  <Slider
                    name="companySize"
                    min={this.parent.minCompanySize}
                    max={this.parent.maxCompanySize}
                    step={this.parent.sliderStepCS}
                    value={this.parent.state.minimumCompanySize}
                    onChange={(event, minimumCompanySize) => {
                      this.parent.setState({ minimumCompanySize });
                    }}
                  />
                </Col>
              </Row>
            )}

            {
              (this.state.more

                // epic-3038(new locations)-story-3689-m4 | 2021-08-05 Thu µ
                // related to story-3083-M1-4 
                // - candidate.candidateLocation is needed for filtering, but not it was no requested to be displayed. 
                .filter(menu => !!menu.key && (menu.visible !== false))

                .map(menu => {
                  return (
                    <Row key={`menu${menu.key}`} className='mb-3'>
                      <Col fullWidth>
                        <label>{menu.label}</label>
                        <List key={"list"} style={{ maxHeight: "60vh" }}>
                          {Object.keys(menu.items || {})
                            .sort()
                            .filter(label =>
                              new RegExp(this.state.search, "i").test(label)
                            )
                            .map((label, index) => (
                              <ListItem
                                key={`all_filters_dialog__list_item__${label}__${index}`}
                                dense
                                button
                                onClick={ev => {
                                  this.setState(state => {
                                    menu.items[label] = !menu.items[label];
                                    return state;
                                  });
                                }}
                              >
                                <ListItemIcon style={{ minWidth: 'inherit' }}>
                                  <Checkbox
                                    edge="start"
                                    checked={Object(menu.items)[label]}
                                    tabIndex={-1}
                                    color="primary"
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': label }}
                                  />
                                </ListItemIcon>
                                <ListItemText id={label} primary={label === "State: Lead" ? "Lead" : label} />
                              </ListItem>
                            ))}
                        </List>
                      </Col>
                    </Row>
                  );
                })
              )
            }
          </>
        }
        actions={[
          <Button outlined minW120
            label="Cancel"
            onClick={this.close}
          />,
          <Button primary minW120
            label="Apply"
            onClick={ev => {
              this.parent.onChange();
              this.close();
            }}
          />
        ]}
        open={this.state.open}
      />
    );
  }
}
export default MoreFiltersDialog;
