import React from 'react';
import {
  Arr
} from '../../../lib/Array.lib';
import Candidate, {
  deleteResumeFile
} from '../../../lib/Candidate';
import Core from '../../../lib/Core';
import {
  ACCOUNT_ACTION__EDIT_CANDIDATE,
  ACCOUNT_ACTION__LIST_CANDIDATES,
  ACCOUNT_ACTION__LIST_ENGAGEMENTS,
  ACCOUNT_ACTION__MATCH_CANDIDATE
} from '../../../lib/Definition';
import {
  Obj
} from '../../../lib/Object.lib';
import SovrenData from '../../../lib/SovrenData';
import {
  trim
} from '../../../lib/String.lib';
import {
  CANDIDATE_UI
} from '../../Candidates/CandidateUI.dic';
import {
  openWithdrawCandidate
} from '../../Engagements/WithdrawCandidate';
import ResumeMatch from '../../Shared/ResumeMatch';
import { reloadLocation } from '../../../lib/URL.lib';

let count = 0;

export const moreMenuOptionsCandidates = [
  {
    label: 'Edit Candidate',
    to: candidate => (`/candidate/edit/${candidate.id}`),
    target: candidate => (candidate.isDuplicate ? '_blank' : ''),
    acl: (model) => Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE }),
  },
  {
    to: candidate => (`/candidate/match/${candidate.id}`),
    label: 'Match',
    acl: candidate => Core.isAdmin({ action: ACCOUNT_ACTION__MATCH_CANDIDATE }),
  },
  {
    acl: candidate => Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ENGAGEMENTS }),
    label: candidate => `Engagements Details${candidate.engagements && candidate.engagements.length ? `(${candidate.engagements.length})` : ''}`,
    action: candidate => candidate.openEngagementsDetails(),
  },
  {
    label: 'View Dups',
    action: candidate => Core.openExact(`/candidates?viewDup=t&cId=${candidate.id}`),
    acl: candidate => Core.isAdmin({ action: ACCOUNT_ACTION__LIST_CANDIDATES }),
  },
  {
    label: 'LinkedIn',
    action: candidate => Core.openPopUp(candidate.linkedInURL),
    acl: candidate => (!!candidate.linkedInURL && Core.isAdmin()),
  },
  {
    label: 'Github',
    action: candidate => Core.openPopUp(candidate.gitHubURL),
    acl: candidate => (!!candidate.gitHubURL && Core.isAdmin()),
  },
  {
    render: candidate => (
      <ResumeMatch
      key={`more-options-menu-option-${candidate.id}-${count++}`}
      candidateResume={candidate.resumeTxtUrl}
      />
    ),
    acl: candidate => (candidate.resumeTxtUrl),
  },
  {
    acl: candidate => (!!candidate.resumes?.length),
    label: 'View Resume',
    action: candidate => Core.openPopUp(Candidate.getMyPdfUrl(candidate))
  },
  {
    acl: candidate => !!candidate.sovrenDataId,
    label: CANDIDATE_UI.resumeScrubbedUrl.moreMenuLabel,
    action: candidate => openScrubbedResume({ candidate })
  },
  {
    acl: candidate => Core.isAdmin(),
    label: 'Copy Confirmation Job Description',
    action: candidate => Candidate.bulkCopy(candidate)
  },
  {
    acl: candidate => Core.isAdmin(),
    label: 'Message',
    action: candidate => candidate.openMessage()
  },
  {
    acl: candidate => Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE }),
    label: 'WithDraw Candidate',
    action: candidate => openWithdrawCandidate({ candidateId: candidate.id }),
  },
  {
    acl: candidate => Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE }),
    label: <span className='c-red'>Delete</span>,
    action: candidate => deleteCandidate(candidate)
  }
];

function deleteCandidate(candidate) {
  Candidate.fetchEngagements({ candidateId: candidate.id }).then(
    engagements =>
      Core.showConfirm({
        title: <>Confirm</>,
        message: (
          `Delete "${candidate._name}"${!!engagements.length
            ? ` and ${engagements.length} engagement${engagements.length === 1 ? "" : "s"
            }?`
            : ""
          }`
        ),
        onAccept() {
          Candidate.delete(candidate.id,
            async response => {
              if (Array.isArray(candidate.resumes)) {
                await Promise.all(candidate.resumes.map(async (resume) => {
                  await deleteResumeFile({ resume });
                  return SovrenData.destroyData({ resume, candidate });
                }));
              }
              Core.showSuccess('Candidate was deleted successfully', () => reloadLocation());
            },
            error => {
              if (!!error) {
                Core.showError('Can not delete candidate. Please contact support.');
              }
            }
          );
        },
        onAcceptLabel: 'Delete Candidate'
      })
  )
}

export async function openScrubbedResume({ candidate }) {
  let { resumeScrubbedUrl } = candidate;
  if (!trim(resumeScrubbedUrl) && !!candidate.sovrenDataId) {
    const sovrenDataRecord = Obj(Arr(
      await SovrenData.get({ where: { id: candidate.sovrenDataId }, limit: 1, fields: ['scrubbedParsedDocument'] })
    )[0]);
    resumeScrubbedUrl = sovrenDataRecord.scrubbedParsedDocument;
  }
  if (!!trim(resumeScrubbedUrl)) { return Core.openPopUp(resumeScrubbedUrl, 1600); }
  Core.showError(`Scrubbed resume no found`);
}
