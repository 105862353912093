export const TEN_BY_TEN_ADMIN_CONTACT = {
  accountType: 'Admin',
  name: '10x10',
  email: 'recruiter@10by10.io',
};

export const DEFAULT_EXPIRATION = '5 days';

export const US_DATE_FORMAT = `YYYY/MM/DD`;
export const TIMEZONE__LA = 'America/Los_Angeles';

export const SCHEDULE_DAY__MONDAY = 'monday';
export const SCHEDULE_DAY__TUESDAY = 'tuesday';
export const SCHEDULE_DAY__WEDNESDAY = 'wednesday';
export const SCHEDULE_DAY__THURSDAY = 'thursday';
export const SCHEDULE_DAY__FRIDAY = 'friday';
export const SCHEDULE_DAY__SATURDAY = 'saturday';
export const SCHEDULE_DAY__SUNDAY = 'sunday';
export const SCHEDULE_DAY__FULLWEEK = 'fullweek';
export const SCHEDULE_DAY__WEEKEND = 'weekend';
export const SCHEDULE_DAY__WEEKDAYS = 'weekdays';

export const CONTENT_TYPE__DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const CONTENT_TYPE__DOC = 'application/msword';
export const CONTENT_TYPE__PDF = 'application/pdf';

export const OPTION_UNKNOWN = 0;
export const OPTION_YES = 1;
export const OPTION_NO = 2;

export const MONTHS = [
  '',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const PLAINTEXT__LOG__SEPARATOR = '\n-----------\n';

export const topUniversities = [
  {
    key: 21,
    label: 'Elite university',
  },
  {
    key: 23,
    label: 'Top ranked university',
  },
  {
    key: 22,
    label: 'Ranked universities',
  },
];

export const jobLevels = [
  {
    key: 4,
    label: 'Architect',
  },
  {
    key: 5,
    label: 'Lead',
  },
  {
    key: 10,
    label: 'Staff',
  },
  {
    key: 11,
    label: 'Principal',
  },
];

export const allowAttrOnMustHaveOrNot = ['education', 'experience', 'others', 'technicalSkills'];

export const jobTypes = {
  jobsPermitted: 'jobsPermitted',
  jobsPitched: 'jobsPitched',
  jobsDeclined: 'jobsDeclined',
};

/* FILTERING FIELD FROM DB!! */
export const fieldsForCandidatePage = [
  'id',
  'email',
  'emailsList',
  'phone',
  'firstName',
  'lastName',
  'roles',
  'visa',
  'introduced',
  'updatedAt',
  'technicalSkills',
  'workLocationIds',
  'yearsOfExperience',
  'resumeTxtUrl',
  'resumePdfUrl',
  'resumes',
  'positiveSignals',
  'negativeSignals',
  'minimumSalary',
  'platformRating',
  'diversity',
  'diversityCategories',
  'state',
  'withEngagements',
  'locationCandidate',
  'level',
  'accountId',
  'isDuplicate',
  'isOwnedRightNow',
  'linkedInURL',
  'gitHubURL',
  'officeLocations',
  'desiredEmploymentTypes',

  /* epic-3038-story-3459 - 2021-07-14 µ */
  'candidateLocations',

  /* epic-3038-story3330-m2 - 2021-07-01 µ */
  'inOfficeRemoteFlags',

  // story-3869 | 2021-08-30 Mon µ
  'matchExclusions',

  'calendarBookingLink',

  'jobsPermitted',
  'jobsPermittedAnswers',

  // [ 2023-02-20 ][ MS: story_8276 ]
  'employmentHistories',
  'educationHistories',
  'positiveSignalsManual',
  'negativeSignalsManual',

  // [ 2023-04-27 ][ MS: story_8909 ]
  'salaryConfidence',

  // [ 2023-07-12 ][ MS: story_9314 ]
  'sovrenDataId'

];

export const accountIncludeForCandidate = [
  {
    relation: 'account',
    scope: {
      fields: ['id', 'firstName', 'lastName', 'email', 'companyName'],
    },
  },
];

// URL Constants - AlphaNumeric ordering
export const CRAFT_CO_BASE_URL = 'https://craft.co';
export const SOVREN_PARSER_URL = 'https://eu-rest.resumeparsing.com/v9';

// Data Constants - AlphaNumeric ordering
export const FULLDAY_SECOND_REFERENCE = 1;
export const FULLDAY_MILL_SECOND_REFERENCE = 300;
export const SOVREN_FILENAME_PREFIX = 'id_';

// story-3901-m1 | 2021-08-30 Mon µ
// add a comment in the code that says consult10by10 account
export const GITHUB_TOKEN = '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';

export const GITHUB_API_URL = 'https://api.github.com/graphql';

export const MDASH = '—';

export const CSS_BLACK_RGB = 'rgb(0, 0, 0)';
export const CSS_CYAN_DARKER_RGB = 'rgb(79, 128, 120)';
export const CSS_GRAY_RGB = 'rgb(118, 119, 121)';
export const CSS_RED_RGB = 'rgb(230, 0, 0)';
export const CSS_PURPLE_RGB = 'rgb(83, 109, 254)';
export const CSS_YELLOW_RGB = 'rgb(255, 255, 0)';
export const CSS_TRANSPARENT = 'transparent';
