import {
  TreeSelect as AntDTreeSelect
} from 'antd';
import React, {
  useEffect
} from 'react';
import AppUI from '../../../dictionaries/AppUI.dic';
import LocationLib from '../../../lib/DefinitionLocation.lib';
import {
  joinClassName
} from '../Libraries/Theme.lib';
import Definition, { DEFINITION_CATEGORY__LOCATION } from '../../../lib/Definition';
import { isArray } from 'lodash';

const { SHOW_PARENT } = AntDTreeSelect;

export default function TreeSelect({
  fullWidth = undefined,
  data = [],
  value,
  onChange = () => null,
  onSearch = () => null,
  onClose = () => null,
  onFocus,
  layers = [],
  multiple = true,
  placeholder = null,
  treeDefaultExpandedIds = [],
  popupMatchSelectWidth = true,
  listHeight = 'calc(50vh - 64px)',
  onDropdownVisibleChange,
  size = 'middle',
  maxTagCount = 'tags',
  autoFocus,
  disabled,
  className,
  style,
  ...props
}) {

  const ref = React.useRef();
  const [_value, setValue] = React.useState(multiple ? [] : null);

  const _mapOption = (value) => (
    {
      value,
      label: Definition.getLabel(DEFINITION_CATEGORY__LOCATION, value)
    }
  )

  value = value || _value;

  const values = (
    (
      multiple && isArray(value)
    ) ? (
      value.map(_mapOption)
    ) : (
      _mapOption(value || _value)
    )
  );

  let treeData = [];

  treeData = LocationLib.getTreeData(data, LocationLib.getRootIds({ data }));

  if (!!layers.length) {
    treeData = LocationLib.filterTreeDataLayers({ treeData, layers });
  }

  const autoFocusRef = React.useRef(autoFocus);
  autoFocusRef.current = autoFocus;
  React.useEffect(() => {
    autoFocusRef.current && ref.current.focus();
  }, []);

  function _onChange(value) {
    if (multiple) {
      value = value.map((v) => v.value);
    }
    setValue(value);
    onChange(value);
  }

  function useDropdownVisibleChange(open) {
    return {
      _onDropdownVisibleChange: (open) => {
        onDropdownVisibleChange
          ? onDropdownVisibleChange(open)
          : open ? onSearch('') : onClose(value);
      },
    };
  }
  const { _onDropdownVisibleChange } = useDropdownVisibleChange();

  useEffect(() => {
    if (!!value?.length && !data?.length) {
      _onDropdownVisibleChange(true);
    }
  }, [_onDropdownVisibleChange, data, value]);


  treeData = treeData.reverse();

  // console.debug({ values, treeData });

  const _props = {
    ...props,

    allowClear: true,
    value: values,
    onChange: _onChange,
    treeData,
    onSearch,
    onFocus,
    treeCheckable: multiple,
    showSearch: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: placeholder || AppUI.placeholder.tree,
    treeNodeFilterProp: 'label',
    treeNodeLabelProp: 'label',
    style: { width: '100%', ...style },
    className: joinClassName([
      disabled && 'disabled',
      className
    ]),
    listHeight,
    virtual: false,
    treeDefaultExpandedKeys: treeDefaultExpandedIds,

    popupMatchSelectWidth,

    size,
    disabled,
    maxTagCount,

    /* epic-3038(new locations)-story-3675-m1 | 2021-08-04 Wed µ */
    treeCheckStrictly: multiple,

    onDropdownVisibleChange: _onDropdownVisibleChange,

  };
  return <AntDTreeSelect ref={ref} {..._props} />;
}
