import {
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import {
  Component
} from "react";
import {
  THEME__VARIANT__OUTLINED
} from '../../Layout/Libraries/Theme.lib';
import Button from '../../Layout/Wrappers/Button';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import Dialog from '../../Layout/Wrappers/Dialog';
import TextField from '../../Layout/Wrappers/TextField';

class SelectListDialog extends Component {
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = {
      title: "Select an item",
      open: false,
      search: "",
      list: [],
      onSubmit: ev => true
    };
  }
  open = (title, list, selected, onSubmit) => {
    const selectedStr = JSON.stringify(selected);
    this.setState({
      title,
      list: list.map(item => {
        delete item._selected;
        if (!!~selectedStr.indexOf(item.id)) {
          item._selected = true;
        }
        return item;
      }),
      open: true,
      onSubmit
    });
  };
  close = () => {
    this.setState({ open: false });
  };
  submit = () => {
    this.close();
    this.state.onSubmit(
      this.state.list.filter(item => item._selected).map(item => {
        delete item._selected;
        return item;
      })
    );
  };
  toogleHandler = itemId => {
    this.setState({
      list: this.state.list.map(item => {
        if (item.id === itemId) {
          item._selected = !item._selected;
        }
        return item;
      })
    });
  };
  render() {
    return (
      <Dialog
        title={this.state.title}
        onClose={this.close}
        actionBar={
          <TextField
            className="input"
            floatingLabelText="Search keywords"
            required={true}
            style={{
              width: "calc(100% - 16px)",
              fontSize: 14,
              margin: "0 8px"
            }}
            type="text"
            value={this.state.search}
            onChange={(event, search) => this.setState({ search })}
            autoFocus
          />
        }
        actions={
          [
            <Button
              color="secondary"
              variant={THEME__VARIANT__OUTLINED}
              onClick={this.close}>
              Cancel
            </Button>,
            <Button
              color="primary"
              onClick={this.submit}>
              Apply
            </Button>
          ]
        }
        open={this.state.open}
      >
        {this.state.list
          .filter(
            item =>
              !!this.state.search
                ? !!~item._name
                  .toLowerCase()
                  .indexOf(this.state.search.toLowerCase())
                : true
          )
          .map(item => (
            <ListItem key={item.id} role={undefined} dense button
              onClick={ev => this.toogleHandler(item.id)}

            >
              <ListItemIcon style={{ minWidth: 'inherit' }}>
                <Checkbox
                  edge="start"
                  checked={item._selected}
                  tabIndex={-1}
                  color="primary"
                  disableRipple
                  inputProps={{ 'aria-labelledby': item._name }}
                />
              </ListItemIcon>
              <ListItemText id={item._name} primary={item._name || <i>&mdash;</i>} />

            </ListItem>
          ))}
      </Dialog>
    );
  }
}
export default SelectListDialog;
