import Definition from '../../../lib/Definition';
import {
  Str
} from '../../../lib/String.lib';
import Chip from '../../Layout/Wrappers/Chip';
import CompanyPreference from '../Candidate/CompanyPreference';
import LocationDetails from '../Candidate/LocationDetails';
import MatchLocationChips, {
  MATCH_LOCATION_CHIPS__JD_REQUESTER
} from '../List/MatchLocationChips';
import Stage from '../Candidate/Stage';

export default function JobHeaderChips({
  candidate = {},
  job = {}
}) {
  const {
    employer = {}
  } = job;
  return (
    <>
      {!!job.visaTransfer &&
        <Chip
          className="slim-chip"
          variant="outlined"
          size="small"
          label={
            Definition.getLabel('visa', job.visaTransfer)
          }
        />
      }
      {
        Definition.getLabels('roles', job.roles).map(
          (role, index) => (
            <Chip
              key={index}
              className="slim-chip"
              variant="outlined"
              size="small"
              label={role}
            />
          )
        )
      }
      <Chip
        className="slim-chip"
        variant="outlined"
        size="small"
        label={`${Str(job.minYearsOfExperience)} yrs`}
      />
      <MatchLocationChips
        requester={MATCH_LOCATION_CHIPS__JD_REQUESTER}
        candidate={candidate}
        job={job}
      />
      <Chip
        className="slim-chip"
        variant="outlined"
        size="small"
        label={`>$${Str(job.salaryMax)}`}
      />
      <CompanyPreference
        candidateCompanyPreferenceMin={candidate.minCompanySize}
        candidateCompanyPreferenceMax={candidate.maxCompanySize}
        jobCompanyPreference={employer.employeeCount}
        source="job"
      />
      <Stage
        candidateStage={candidate.desiredStage}
        jobStage={employer.stage}
        source="job"
      />
      <LocationDetails
        candidateText={candidate.locationDetails}
        jobText={
          job.remoteExtraInfo
            ? job.remoteExtraInfo
            : employer.remoteExtraInfo
        }
        source="job"
      />
    </>
  );
}