import React, {
  Component,
  Fragment
} from "react";
import {
  matchSkillWithText
} from '../../Candidates/SubmissionNotes.lib';
import Chip from '../../Layout/Wrappers/Chip';

class JobTags extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      labels: this.props.labels.map(label => label.split(",")).flat() || [],
      resumeText: null
    };
  }

  componentDidMount() {
    if (!!this.props.resume) {
      this.fetchResume(this.props.resume);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resume !== this.props.resume) {
      this.fetchResume(this.props.resume);
    }
    if (prevProps.labels !== this.props.labels) {
      const newLabels = this.props.labels.map(label => label.split(",")).flat();
      this.setState({ labels: newLabels });
    }
  }

  fetchResume = (resume) => {
    fetch(resume).then(responseText => {
      responseText.text().then(text => {
        this.setState({ resumeText: text });
      });
    });
  }

  findMatchingTagsWithResume = (chip) => {
    const { resumeText } = this.state;
    if (!!resumeText) {
      return !!matchSkillWithText(chip, resumeText);
    }
    return false;
  }

  render() {
    const { labels, resumeText } = this.state;
    const { source } = this.props;

    return <>
      {labels.map((label, index) => (
        <Chip
          key={`job_tags__chip__${index}`}
          className="chip slim-chip"
          variant="outlined"
          size="small"
          label={label}
          style={{
            border: (
              !resumeText
                ? "1px solid #888888"
                : !!this.findMatchingTagsWithResume(label)
                  ? "1px solid green"
                  : source === "jobMatch"
                    ? "1px solid #888888"
                    : "2px solid red"
            )
          }}
        />
      ))}
    </>;
  }
}

export default JobTags;