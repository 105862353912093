import Core from '../../../../lib/Core';
import {
  NOT,
  YES
} from '../../../../lib/GenericTools.lib';
import {
  removeHtmlBlankLines
} from '../../../../lib/HTML.lib';
import {
  convertHtmlToPlainText,
  trim
} from '../../../../lib/String.lib';
import { joinClassName } from '../../../Layout/Libraries/Theme.lib';
import Box from '../../../Layout/Wrappers/Box';
import Fieldset from '../../../Layout/Wrappers/Fieldset';
import NavigationTabs from '../../../Layout/Wrappers/NavTabs';
import RichTextBox from '../../../Layout/Wrappers/RichTextBox';
import { PLACEMENT__TOP_START } from '../../../Layout/Wrappers/StyledTooltip';
import TextField from '../../../Layout/Wrappers/TextField';
import {
  JobUI
} from '../../JobUI.dic';

export const JOB__RESUME_FEEDBACK__TAB = 0;
export const JOB__INTERVIEW_FEEDBACK__TAB = 1;

function ResumeFeedback(props) {
  let { job = {}, onChange = async (update) => null, disabled = false, readOnly = false } = props;
  let {
    feedbackLogResume = '',
    feedbackSummaryResume = '',
  } = job;
  feedbackLogResume = convertHtmlToPlainText(feedbackLogResume);
  const _onChange = (key) => async (event) => {
    let update = { [key]: typeof event === 'string' ? event : event.target.value };
    await onChange(update);
  }
  const _isAdminOrRecruiter = (Core.isAdmin() || Core.isRecruiter());
  const _isAdminOrEmployer = (Core.isAdmin() || Core.isEmployer());
  return (
    <Box column w100>

      {/** SUMMARY LOG */}
      <Fieldset
        acl={_isAdminOrRecruiter}
        title={Core.isAdmin() ? JobUI.feedbackSummaryResume.title : null}
        info={JobUI.feedbackSummaryResume.tooltip}
        infoProps={{
          placement: PLACEMENT__TOP_START,
          style: { maxWidth: 460 }
        }}
      >
        {readOnly ? (
          YES(removeHtmlBlankLines(feedbackSummaryResume))
            ? <div dangerouslySetInnerHTML={{ __html: feedbackSummaryResume }} className='rounded border-1 p-1' />
            : <i className='c-black-medium f-md'>{JobUI.feedbackSummaryResume.empty}</i>

        ) : (
          <RichTextBox
            placeholder={JobUI.feedbackSummaryResume.tooltip}
            value={feedbackSummaryResume}
            onChange={_onChange('feedbackSummaryResume')}
            disabled={disabled}
          />
        )}
      </Fieldset>

      {/** FEEDBACK LOG */}
      <Fieldset
        acl={_isAdminOrEmployer}
        title={Core.isAdmin() ? JobUI.feedbackLogResume.title : null}
        info={JobUI.feedbackLogResume.tooltip}
        infoProps={{
          placement: PLACEMENT__TOP_START,
          style: { maxWidth: 460 }
        }}
      >
        {readOnly ? (
          !!trim(feedbackLogResume)
            ? <pre className='rounded border-1 p-1'>{feedbackLogResume}</pre>
            : <i className='c-black-medium f-md'>{JobUI.feedbackLogResume.empty}</i>

        ) : (
          <TextField
            placeholder={JobUI.feedbackLogResume.tooltip}
            value={feedbackLogResume}
            onChange={_onChange('feedbackLogResume')}
            minRows={3}
            maxRows={12}
            disabled={disabled}
          />
        )}
      </Fieldset>

    </Box>
  );
}

function InterviewFeedback(props) {
  let { job = {}, onChange = async (update) => null, disabled = false, readOnly = false } = props;
  let {
    feedbackLogInterview = '',
    feedbackSummaryInterview = '',
  } = job;
  feedbackLogInterview = convertHtmlToPlainText(feedbackLogInterview);
  const _onChange = (key) => async (event) => {
    let update = { [key]: typeof event === 'string' ? event : event.target.value };
    console.debug('E3', key, update);
    await onChange(update);
  }
  const _isAdminOrRecruiter = (Core.isAdmin() || Core.isRecruiter());
  const _isAdminOrEmployer = (Core.isAdmin() || Core.isEmployer());
  return (
    <Box column w100>

      {/** SUMMARY LOG */}
      <Fieldset
        acl={_isAdminOrRecruiter}
        title={Core.isAdmin() ? JobUI.feedbackSummaryInterview.title : null}
        info={JobUI.feedbackSummaryInterview.tooltip}
        infoProps={{
          placement: PLACEMENT__TOP_START,
          style: { maxWidth: 460 }
        }}
        classNameWrapper={'p-0'}
      >
        {readOnly ? (
          YES(removeHtmlBlankLines(feedbackSummaryInterview))
            ? <div dangerouslySetInnerHTML={{ __html: feedbackSummaryInterview }} className='rounded border-1 p-1' />
            : <i className='c-black-medium f-md'>{JobUI.feedbackSummaryInterview.empty}</i>
        ) : (
          <RichTextBox
            placeholder={JobUI.feedbackSummaryInterview.tooltip}
            value={feedbackSummaryInterview}
            onChange={_onChange('feedbackSummaryInterview')}
            disabled={disabled}
          />
        )}
      </Fieldset>

      {/** FEEDBACK LOG */}
      <Fieldset
        acl={_isAdminOrEmployer}
        title={Core.isAdmin() ? JobUI.feedbackLogInterview.title : null}
        info={JobUI.feedbackLogInterview.tooltip}
        infoProps={{
          placement: PLACEMENT__TOP_START,
          style: { maxWidth: 460 }
        }}
        classNameWrapper={'p-0'}
      >
        {readOnly ? (
          !!trim(feedbackLogInterview)
            ? <pre className='rounded border-1 p-1'>{(feedbackLogInterview)}</pre>
            : <i className='c-black-medium f-md'>{JobUI.feedbackLogInterview.empty}</i>
        ) : (
          <TextField
            placeholder={JobUI.feedbackLogInterview.tooltip}
            value={feedbackLogInterview}
            onChange={_onChange('feedbackLogInterview')}
            minRows={3}
            maxRows={12}
            disabled={disabled}
          />
        )}
      </Fieldset>

    </Box>
  );
}

/**
 * 
 * job 
 * - {feedbackLogResume,feedbackSummaryResume,feedbackLogInterview,feedbackSummaryInterview}
 * 
 * disabled & readOnly
 * - default false
 * 
 * @param {object} props_ 
 * @param {object} props_.job
 * @param {function} props_.onChange
 * @param {boolean} props_.disabled
 * @param {boolean} props_.readOnly
 * @returns 
 */
export default function JobFeedbacks(props) {
  let { className = '', defaultTab = JOB__RESUME_FEEDBACK__TAB, job, readOnly } = props;
  const _isNotReadOnly = NOT(readOnly);
  return (
    // IF readOnly set as True, then will render content.
    _isNotReadOnly ||
    // ELSE will evaluate if content has values.
    evaluateEmployerFeedback({ job, readOnly })
  ) ? (
    <Box column w100
      className={
        joinClassName([
          'rounded border-1',
          className
        ])
      }
    >
      <NavigationTabs
        name={`job_feedbacks`}
        defaultTab={defaultTab}
        data={[
          (
            _isNotReadOnly ||
            evaluateResumeFeedback({ job, readOnly })
          ) && {
            title: JobUI.feedbackResume.title,
            content: (
              <>
                <span className='c-red f-sm fw-500 uppercase'>
                  {JobUI.feedbackSectionInsiderSubtitle}
                </span>
                <ResumeFeedback {...props} />
              </>
            )
          },
          (
            _isNotReadOnly ||
            evaluateInterviewFeedback({ job, readOnly })
          ) && {
            title: JobUI.feedbackInterview.title,
            content:
              <>
                <span className='c-red f-sm fw-500 uppercase'>
                  {JobUI.feedbackSectionInsiderSubtitle}
                </span>
                <InterviewFeedback {...props} />
              </>
          },
        ]}
      />
    </Box>
  ) : (
    null
  );
}

/**
 * 
 * @param {object} options 
 * @param {object} options.job
 * @returns {boolean}
 */
export function evaluateEmployerFeedback({ job = {} }) {
  if (Core.isAdmin()) {
    return YES(
      removeHtmlBlankLines(
        job.feedbackSummaryResume +
        job.feedbackSummaryInterview +
        job.feedbackLogResume +
        job.feedbackLogInterview
      )
    );
  }
  if (Core.isEmployer()) {
    return YES(
      removeHtmlBlankLines(
        job.feedbackLogResume +
        job.feedbackLogInterview
      )
    );
  }
  // Other user (recruiter)
  return YES(
    removeHtmlBlankLines(
      job.feedbackSummaryResume +
      job.feedbackSummaryInterview
    )
  );
}

/**
 * 
 * @param {object} options 
 * @param {object} options.job
 * @returns {boolean}
 */
export function evaluateResumeFeedback({ job = {} }) {
  if (Core.isAdmin()) {
    return YES(
      removeHtmlBlankLines(
        job.feedbackSummaryResume +
        job.feedbackLogResume
      )
    );
  }
  if (Core.isEmployer()) {
    return YES(
      removeHtmlBlankLines(
        job.feedbackLogResume
      )
    );
  }
  // Other user (recruiter)
  return YES(
    removeHtmlBlankLines(
      job.feedbackSummaryResume
    )
  );
}

/**
 * 
 * @param {object} options 
 * @param {object} options.job
 * @returns {boolean}
 */
export function evaluateInterviewFeedback({ job = {} }) {
  if (Core.isAdmin()) {
    return YES(
      removeHtmlBlankLines(
        job.feedbackSummaryInterview +
        job.feedbackLogInterview
      )
    );
  }
  if (Core.isEmployer()) {
    return YES(
      removeHtmlBlankLines(
        job.feedbackLogInterview
      )
    );
  }
  // Other user (recruiter)
  return YES(
    removeHtmlBlankLines(
      job.feedbackSummaryInterview
    )
  );
}
