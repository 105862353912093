import {
  isString
} from 'lodash';
import {
  useRef
} from 'react';
import ReactQuill, {
  Quill
} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AppUI from '../../../dictionaries/AppUI.dic';
import {
  Fun
} from '../../../lib/Function.lib';
import useStateObserved from '../../../lib/hooks/useStateObserved.hook';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  Str,
  trim
} from '../../../lib/String.lib';
import {
  joinClassName,
  mapWrapper
} from '../Libraries/Theme.lib';
import Box from './Box';

// Register a new Block (optional if using a different block element)
const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

/**
 * RichTextBox component using react-quill.
 * 
 * @see https://www.npmjs.com/package/react-quill
 * @see https://github.com/zenoamaro/react-quill
 * @see https://github.com/zenoamaro/react-quill/issues/989
 * @see https://www.npmjs.com/package/react-quill-new
 * @see https://github.com/VaguelySerious/react-quill
 * 
 * @param {object} props - The properties for the component.
 * @param {boolean} [props.acl=true] - Access control logic to render the component.
 * @param {boolean} [props.debug=false] - Debug mode.
 * @param {string} [props.placeholder=''] - Placeholder text for the editor.
 * @param {string} [props.value=''] - Initial value of the editor.
 * @param {string} [props.autoComplete=''] - AutoComplete attribute for the editor.
 * @param {boolean} [props.autoFocus=false] - AutoFocus attribute for the editor.
 * @param {function} [props.onChange=Fun] - Function to call on change event.
 * @param {function|null} [props.onBlur=null] - Function to call on blur event.
 * @param {function|null} [props.onKeyDown=null] - Function to call on keydown event.
 * @param {function|null} [props.onKeyPress=null] - Function to call on keypress event.
 * @param {string} [props.className=''] - Additional class names for the wrapper.
 * @param {object} [props.style={}] - Inline styles for the wrapper.
 * @param {boolean} [props.disabled=false] - Disable the editor.
 * @param {boolean} [props.taller=false] - Apply taller styling.
 * @param {boolean} [props.xtaller=false] - Apply extra taller styling.
 * @param {boolean} [props.minH128=false] - Apply minimum height styling.
 * @param {object} [props.wrapperProps={}] - Additional properties for the wrapper.
 * @param {object} [props.rtbProps={}] - Additional properties for the RichTextBox.
 * @param {...object} [props] - Other properties to pass down to the RichTextBox.
 * 
 * @returns {React.Element} The rendered RichTextBox component.
 */
export default function RichTextBox({
  acl = true,
  debug = false,
  placeholder = '',
  value: propsValue = '',
  autoComplete = '',
  autoFocus = false,
  onChange = Fun,
  onBlur = null,
  onFocus = null,
  onKeyDown = null,
  onKeyPress = null,
  className = '',
  style = {},
  disabled = false,
  taller = false,
  xtaller = false,
  minH128 = false,
  wrapperProps = {},
  rtbProps = {},
  ...props
}) {
  const defaultState = {
    value: propsValue
  };
  const focused = useRef({});
  const [{
    value = ''
  }, updateState] = useStateObserved({
    state: defaultState,
    observed: defaultState
  });

  if (!acl) { return null; }

  const _exportValue = async (event) => {
    const _value = (
      (
        isString(event)
      ) ? (
        event
      ) : (
        Str(Obj(Obj(event).target).innerHTML) ||
        value
      )
    );
    console.debug(
      'RichTextBox__exportValue...',
      '\n', event,
      '\n', _value,
    );
    await updateState({ value: _value });
    if (_value !== propsValue) {
      onChange(_value);
    }
  };

  const _onChange = async (value) => {
    if (
      trim(autoComplete) ||
      autoFocus ||
      onBlur ||
      onKeyDown ||
      onKeyPress
    ) {
      return _exportValue({ target: { innerHTML: value } });
    }
    else {
      await updateState({ value });
    }
  };

  const _onBlur = async (event) => {
    if (event.target.classList.contains('ql-editor')) {
      focused.current = false;
      setTimeout(() => {
        console.debug('BLUR', event, focused);
        if (focused.current === false) {
          _exportValue(event);
          Fun(onBlur)(event);
        }
      }, 100);
    }
  };

  const _onFocus = (event) => {
    console.debug('FOCUS', event, focused);
    focused.current = true;
    Fun(onFocus)(event);
  };

  const _onKeyDown = async (event) => {
    if (!!Str(event.key).match(/enter|tab/i)) {
      await _exportValue(event);
    }
    Fun(onKeyDown)(event);
    Fun(onKeyPress)(event);
  };

  placeholder = placeholder || (disabled ? AppUI.disabled : AppUI.placeholder.multiline);
  const flags = { debug, disabled };
  const colors = [
    '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc',
    '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc',
    '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66',
    '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00',
    '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000',
    '#663d00', '#666600', '#003700', '#002966', '#3d1466', '#536dfe',
    '#009688', '#4f8078'
  ];
  return (
    <Box
      {...mapWrapper({
        role: 'RichTextBox__Wrapper',
        props: wrapperProps,
        assign: {
          onBlur: _onBlur,
          onFocus: _onFocus,
          className: joinClassName([
            'flex-column flex-1',
            className
          ]),
          style,
        }
      })}
    >
      <ReactQuill
        {...mapWrapper(
          {
            role: 'RichTextBox',
            props: rtbProps,
            assign: {
              ...props,
              value,
              onChange: _onChange,
              onKeyDown: _onKeyDown,
              onKeyPress: _onKeyDown,
              theme: 'snow',
              placeholder,
              modules: {
                toolbar: [
                  [{ header: '1' }, { header: '2' }],
                  [{ color: colors }, { background: colors }],
                  ['bold', 'italic', 'underline', 'strike'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  ['link'],
                  ['clean']
                ],
                clipboard: {
                  matchVisual: false
                }
              },
              formats: [
                'header',
                'font',
                'size',
                'color',
                'background',
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                'list',
                'indent',
                'link',
                'image',
                'video'
              ],
              readOnly: disabled,
              className: joinClassName([
                'flex-column flex-1',
                disabled && 'ql-rtb-disabled',
                xtaller ? 'ql-xtaller' : taller ? 'ql-taller' : 'ql-normal',
                minH128 && 'ql-min-h128',
              ])
            },
            flags
          }
        )}
      />
    </Box>
  );
}
