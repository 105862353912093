import {
  Component,
  Fragment
} from 'react';
import Account, {
  getAccountEmployer
} from '../../../lib/Account';
import {
  Arr,
  join
} from '../../../lib/Array.lib';
import Core from '../../../lib/Core';
import {
  formatDateTime24hPT,
  isValidDate
} from '../../../lib/Date.lib';
import Definition, {
  ACCOUNT_ACTION__CREATE_SYSADMIN_ACCOUNT,
  ACCOUNT_ACTION__EDIT_ACCOUNT,
  ACCOUNT_ACTION__LIST_ACCOUNTS,
  DEFINITION_CATEGORY__ACCOUNT_ANNOUNCEMENTS,
  DEFINITION_CATEGORY__ACCOUNT_EXPERTISE,
  DEFINITION_CATEGORY__ACCOUNT_INTERACTIONS,
  DEFINITION_CATEGORY__STATE
} from '../../../lib/Definition';
import Employer, {
  getEmployersList
} from '../../../lib/Employer';
import {
  YES
} from '../../../lib/GenericTools.lib';
import HistoryLog from '../../../lib/HistoryLog';
import Job from '../../../lib/Job';
import {
  getPersonName
} from '../../../lib/Object.lib';
import Store from '../../../lib/Store';
import {
  Str
} from '../../../lib/String.lib';
import {
  getLocation,
  getParams
} from '../../../lib/URL.lib';
import {
  getAccountModel
} from '../../../lib/models/account';
import {
  ROLE__EMPLOYER_RECRUITER,
  ROLE__SYS_ADMIN
} from '../../../lib/services/Accounts/Session.lib';
import {
  openMessageEmailPreview
} from '../../../lib/services/Email/Email.lib';
import formatURL from '../../../lib/tools/formatURL';
import getEmployerSourceListApis from '../../../lib/tools/getEmployerSourceListApis';
import getJobSourceListApis from '../../../lib/tools/getJobSourceListApis';
import getStateModel from '../../../lib/tools/getStateModel';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';
import SuccessDialog from '../../Dialogs/Success';
import {
  EmailsListClass
} from '../../Forms/EmailsList';
import HistoryMenu from '../../HistoryMenu/HistoryMenu';
import Page from '../../Layout/Page';
import Button from '../../Layout/Wrappers/Button';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import Col from '../../Layout/Wrappers/Col';
import DatePicker from '../../Layout/Wrappers/DatePicker';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import IconButton from '../../Layout/Wrappers/IconButton';
import Menu from '../../Layout/Wrappers/Menu';
import {
  ErrorChipMessage
} from '../../Layout/Wrappers/Message';
import MultipleSelect from '../../Layout/Wrappers/MultipleSelect';
import Navigate from '../../Layout/Wrappers/Navigate';
import RadioGroup from '../../Layout/Wrappers/RadioGroup';
import RichTextBox from '../../Layout/Wrappers/RichTextBox';
import Row from '../../Layout/Wrappers/Row';
import Snackbar from '../../Layout/Wrappers/Snackbar';
import StyledTooltip, {
  PLACEMENT__RIGHT_START
} from '../../Layout/Wrappers/StyledTooltip';
import TextField from '../../Layout/Wrappers/TextField';
import AccessDenied from '../../Shared/AccessDenied';
import {
  TaggedValue
} from '../../Shared/TaggedValue';
import SelectListDialog from './SelectListDialog';
import {
  SelectSubrole
} from './SelectSubrole';
import { stepperScrollToTop } from '../../Layout/Wrappers/Stepper';

const CONFIG = {
  labels: {
    interactions: 'Interaction style',
    expertise: 'Expertise',
    announcements: 'Announcements'
  },
  placeholder: {
    multipleOptions: 'Select options'
  }
};

/* MAIN CLASS ================================= */

class AccountEdit extends Component {
  selectListDialog;
  continue = 0;
  confirm = false;
  constructor() {
    super(...arguments);
    this.state = {
      ...getAccountModel({ extended: true }),
      /** controller states */
      accounts: [],
      recruiters: [],
      snackbar: null,
      errorFirstName: '',
      errorLastName: '',
      errorRole: '',
      errorEmail: '',
      errorPhone: '',
      errorPassword: '',
      errorRetype: '',
      state: Definition.getId('state', 'Draft'),
      existingAccountJobSource: [],
      accountJobSourceListIdsFromServer: [],
      accountJobSourceList: [],
      recruitersRemovedJobs: [],
      recruitersAddedJobs: [],
      newRecruitersRemovedJobs: [],
      recruiterAllJobs: [],
      existingAccountEmployerSource: [],
      accountEmployerSourceListIdsFromServer: [],
      accountEmployerSourceList: [],
      recruitersRemovedEmployers: [],
      recruitersAddedEmployers: [],
      newRecruitersRemovedEmployers: [],
      recruiterAllEmployers: [],
      _roles: []
    };
    this._getRemovedRecruiterJobIdsFlag = true;
    this._getRemovedRecruiterEmployerIdsFlag = true;
    Store.set('path', getLocation());
  }
  componentDidMount = () => {
    const _accountId = getParams({ pattern: '/account/edit/:id' }).id;
    setTimeout(() => {
      Account.getRoles((roles) => {
        this.setState({
          _roles: roles
            .map((role) => {
              switch (role.name) {
                case ROLE__SYS_ADMIN:
                  role.order = 1;
                  break;
                case 'TrustedRecruiter':
                  role.order = 2;
                  break;
                case 'LimitedRecruiter':
                  role.order = 3;
                  break;
                default:
              }
              if (
                !Core.isAdmin({
                  action: ACCOUNT_ACTION__CREATE_SYSADMIN_ACCOUNT
                }) &&
                role.name === ROLE__SYS_ADMIN
              ) {
                role.disabled = true;
              }
              return role;
            })
            .sort((a, b) => {
              a = a.order;
              b = b.order;
              return a > b ? 1 : a < b ? -1 : 0;
            })
        });
      });
      /** LOAD EMPLOYER DATA to the current state */
      if (_accountId) {
        Account.get(_accountId, (model) => {
          this.setState({ ...model }, (then) =>
            HistoryLog.set({ group: 'accounts', label: this.state._name })
          );
          this.getRoleMapping(_accountId);
          this.getAccountRecruiterWhiteList(_accountId);
          this.getAccountEmployerBlackList(_accountId);
          this.getAccountJobBlackList(_accountId);
          this.getAccountEmployerSourceList(_accountId);
          this.getAccountJobSourceList(_accountId);
        });
      }
      else {
        this.setState({ leftCompanyFlag: true });
      }
    }, 300);
  };
  fetchRecruiterAllJobs = () => {
    getJobSourceListApis.getAllJobsOfAdmin(this.state.id, (response) => {
      let endedJobs = [];
      let addedJobs = [];

      response.forEach((resp) => {
        if (resp.endDate === null) {
          addedJobs.push(resp.jobId);
        } else {
          endedJobs.push(resp.jobId);
        }
      });
      this.setState({
        recruitersRemovedJobs: endedJobs,
        recruitersAddedJobs: addedJobs,
        recruiterAllJobs: response
      });
    });
  };
  fetchRecruiterAllEmployers = () => {
    getEmployerSourceListApis.getAllEmployersOfAdmin(
      this.state.id,
      (response) => {
        let endedEmployers = [];
        let addedEmployers = [];

        response.forEach((resp) => {
          if (resp.endDate === null) {
            addedEmployers.push(resp.employerId);
          } else {
            endedEmployers.push(resp.employerId);
          }
        });
        this.setState({
          recruitersRemovedEmployers: endedEmployers,
          recruitersAddedEmployers: addedEmployers,
          recruiterAllEmployers: response
        });
      }
    );
  };
  componentDidUpdate() {
    if (
      Core.isAdmin() &&
      this.state.id &&
      this._getRemovedRecruiterJobIdsFlag
    ) {
      this._getRemovedRecruiterJobIdsFlag = false;
      this.fetchRecruiterAllJobs();
    }
    if (
      Core.isAdmin() &&
      this.state.id &&
      this._getRemovedRecruiterEmployerIdsFlag
    ) {
      this._getRemovedRecruiterEmployerIdsFlag = false;
      this.fetchRecruiterAllEmployers();
    }
  }

  getRoleMapping(accountId) {
    Account.getRoleMapping(accountId, (roleMap) => {
      if (roleMap) {
        this.setState({
          roleMapId: roleMap.id,
          roleMap,
          realRoleId: roleMap.roleId,
          realRole: roleMap.role
        });
      }
    });
  }

  getAccountRecruiterWhiteList(accountId) {
    Account.getRecruiterWhiteList(accountId, (accountRecruiterWhiteList) =>
      this.setState({ accountRecruiterWhiteList })
    );
  }
  getAccountEmployerBlackList(accountId) {
    Account.getEmployerBlackList(accountId, (accountEmployerBlackList) =>
      this.setState({ accountEmployerBlackList })
    );
  }
  getAccountJobBlackList(accountId) {
    Account.getJobBlackList(accountId, (accountJobBlackList) =>
      this.setState({ accountJobBlackList })
    );
  }
  getAccountEmployerSourceList(accountId) {
    Account.getEmployerSourceList(accountId, (accountEmployerSourceList) =>
      this.setState({
        accountEmployerSourceList,
        existingAccountEmployerSource: accountEmployerSourceList.map(
          (job) => job.id
        ),
        accountEmployerSourceListIdsFromServer: accountEmployerSourceList.map(
          (job) => job.id
        )
      })
    );
  }
  getAccountJobSourceList(accountId) {
    Account.getJobSourceList(accountId, (accountJobSourceList) =>
      this.setState({
        accountJobSourceList,
        accountJobSourceListIdsFromServer: accountJobSourceList.map(
          (job) => job.id
        ),
        existingAccountJobSource: accountJobSourceList.map((job) => job.id)
      })
    );
  }

  getFullname = (em) => {
    return (this.state.firstName + ' ' + this.state.lastName).trim();
  };

  sortSourceLists = (accountSourceList, addedJob, endedJobs, newJobs) => {
    let sortedByEndDate = [];

    accountSourceList.forEach((item) => {
      if (!!addedJob.includes(item.id)) {
        sortedByEndDate.unshift(item);
      } else if (!!endedJobs.includes(item.id)) {
        sortedByEndDate.push(item);
      }
    });

    accountSourceList.forEach((item) => {
      if (!!newJobs.includes(item.id)) {
        sortedByEndDate.push(item);
      }
    });

    return sortedByEndDate;
  };

  endJob = (id, key, accountSourceList) => () => {
    let popupName = accountSourceList
      .filter((job) => job.id === id)
      .map((job) => job._name)
      .pop();
    this.ConfirmDialog.open({
      message: `Do you want to pause the ${key} - ${popupName} for the recruiter ?`,
      onConfirm: (ev) => {
        const otherFields = {
          who: Core.getSessionEmail()
        };

        if (key === 'Job') {
          const {
            recruitersRemovedJobs,
            recruitersAddedJobs,
            recruiterAllJobs
          } = this.state;

          getJobSourceListApis.deleteRecruiterJobByAdmin(
            id,
            this.state.id,
            otherFields,
            (response) => {
              const witoutThisJob = recruiterAllJobs.filter(
                (job) => job.jobId !== response.jobId
              );
              const newRecruiterALlJobs = [...witoutThisJob, response];
              this.setState({
                recruitersRemovedJobs: [...recruitersRemovedJobs, id],
                recruitersAddedJobs: recruitersAddedJobs.filter(
                  (job) => job !== id
                ),
                recruiterAllJobs: newRecruiterALlJobs
              });
            }
          );
        } else if (key === 'Employer') {
          const {
            recruitersRemovedEmployers,
            recruitersAddedEmployers,
            recruiterAllEmployers
          } = this.state;
          getEmployerSourceListApis.deleteRecruiterEmployerByAdmin(
            id,
            this.state.id,
            otherFields,
            (response) => {
              const witoutThisEmployer = recruiterAllEmployers.filter(
                (emp) => emp.employerId !== response.employerId
              );
              const newRecruiterAllEmployers = [
                ...witoutThisEmployer,
                response
              ];
              this.setState({
                recruitersRemovedEmployers: [...recruitersRemovedEmployers, id],
                recruitersAddedEmployers: recruitersAddedEmployers.filter(
                  (job) => job !== id
                ),
                recruiterAllEmployers: newRecruiterAllEmployers
              });
            }
          );
        }
      }
    });
  };

  resumeJob = (id, key, accountSourceList) => () => {
    let popupName = accountSourceList
      .filter((job) => job.id === id)
      .map((job) => job._name)
      .pop();
    this.ConfirmDialog.open({
      message: `Do you want to resume the ${key} - ${popupName} for the recruiter`,
      onConfirm: (ev) => {
        const otherFields = {
          who: Core.getSessionEmail()
        };

        if (key === 'Job') {
          const {
            recruitersRemovedJobs,
            recruitersAddedJobs,
            recruiterAllJobs
          } = this.state;
          getJobSourceListApis.resumeRecruiterJobByAdmin(
            id,
            this.state.id,
            otherFields,
            (response) => {
              const witoutThisJob = recruiterAllJobs.filter(
                (job) => job.jobId !== response.jobId
              );
              const newRecruiterALlJobs = [...witoutThisJob, response];
              this.setState({
                recruitersAddedJobs: [...recruitersAddedJobs, id],
                recruitersRemovedJobs: recruitersRemovedJobs.filter(
                  (job) => job !== id
                ),
                recruiterAllJobs: newRecruiterALlJobs
              });
            }
          );
        }
        if (key === 'Employer') {
          const {
            recruitersRemovedEmployers,
            recruitersAddedEmployers,
            recruiterAllEmployers
          } = this.state;
          getEmployerSourceListApis.resumeRecruiterEmployerByAdmin(
            id,
            this.state.id,
            otherFields,
            (response) => {
              const witoutThisEmployer = recruiterAllEmployers.filter(
                (emp) => emp.employerId !== response.employerId
              );
              const newRecruiterAllEmployers = [
                ...witoutThisEmployer,
                response
              ];
              this.setState({
                recruitersAddedEmployers: [...recruitersAddedEmployers, id],
                recruitersRemovedEmployers: recruitersRemovedEmployers.filter(
                  (job) => job !== id
                ),
                recruiterAllEmployers: newRecruiterAllEmployers
              });
            }
          );
        }
      }
    });
  };

  getLabelsOfSourceList = (
    id,
    removedJobs,
    addedJobs,
    accountSourceList,
    key
  ) => {
    let label = null;

    if (addedJobs.includes(id)) {
      label = (
        <span
          title="Pause Job"
          className="rec-actions"
          style={{ backgroundColor: 'red' }}
          onClick={this.endJob(id, key, accountSourceList)}
        >
          Pause {key}
        </span>
      );
    }
    if (removedJobs.includes(id)) {
      label = (
        <span
          title="Resume Job"
          className="rec-actions"
          style={{ backgroundColor: 'green' }}
          onClick={this.resumeJob(id, key, accountSourceList)}
        >
          Resume {key}
        </span>
      );
    }

    return label;
  };

  showDates = (id, recruiterObject, sourceKey) => {
    let date = [];
    let label = '',
      formatDate = '';

    recruiterObject.forEach((obj) => {
      if (obj[sourceKey] === id) {
        if (!!obj.startDate) {
          formatDate = new Date(obj.startDate);
          date.push(
            `${formatDate.getMonth() + 1
            }/${formatDate.getDate()}/${formatDate.getFullYear()}`
          );
        }
        if (!!obj.endDate) {
          formatDate = new Date(obj.endDate);
          date.push(
            `${formatDate.getMonth() + 1
            }/${formatDate.getDate()}/${formatDate.getFullYear()}`
          );
        }
        label = <span>({date.join(' - ')})</span>;
      }
    });

    return label;
  };

  updateState = async (update, delay) => {
    update = Object(update) === update ? update : {};
    let state = { ...this.state, ...update };
    delay
      ? setTimeout(() => this.setState(state), delay)
      : this.setState(state);
    return await new Promise((resolve) =>
      setTimeout(() => resolve(state), delay)
    );
  };

  render() {
    console.debug(this);
    const self = this;
    const account = this.state;
    const isEmployerRecruiter = account.role === ROLE__EMPLOYER_RECRUITER;
    let {
      accountJobSourceList,
      recruitersRemovedJobs,
      recruitersAddedJobs,
      newRecruitersRemovedJobs,
      accountEmployerSourceList,
      recruitersRemovedEmployers,
      recruitersAddedEmployers,
      newRecruitersRemovedEmployers,
      __employers = {},
      interactions = [],
      expertise = [],
      announcements = []
    } = this.state;

    if (Core.isLoggedOut()) {
      return <Navigate to="/login" />;
    }
    if (Core.isRecruiter()) {
      return <Navigate to="/" />;
    }
    if (!Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_ACCOUNT })) {
      return <AccessDenied />;
    }
    const required = 'This field is required';

    /** BACK BUTTON */
    const goBack = (event) => {
      cancel();
    };
    /** CANCEL BUTTON */
    const cancel = (event) => {
      Core.goBack(this.props);
    };
    /** DONE BUTTON */
    const save = (event) => {
      if (!Core.isAdmin()) {
        Core.showFailure('This action is restricted to Administrators');
        return false;
      }
      this.setState({ state: Definition.getId('state', 'Draft') });
      setTimeout(submit);
    };
    /**
     * SUBMIT
     */
    const submit = async (event) => {
      if (!Core.isAdmin()) {
        Core.showFailure('This action is restricted to Administrators');
        return false;
      }
      if (isValid()) {
        const {
          recruitersRemovedJobs,
          accountJobSourceListIdsFromServer,
          existingAccountJobSource,
          existingAccountEmployerSource,
          accountEmployerSourceListIdsFromServer
        } = this.state;
        /** IF ALL FIELDS ARE VALID */
        if (this.state.id) {
          /** DELETE JOB**/
          const differenceInJobs = accountJobSourceListIdsFromServer.filter(
            (sJob) => !existingAccountJobSource.includes(sJob)
          );
          differenceInJobs.forEach((difIds) => {
            getJobSourceListApis.removeJobByAdmin(
              difIds,
              this.state.id,
              {},
              (response) => { }
            );
          });
          /** UPDATE */
          await Account.update(
            this.state.id,
            getStateModel(this.state, getAccountModel()),
            this.state.roleMapId,
            this.state.realRoleId,
            {
              accountRecruiterWhiteList: this.state.accountRecruiterWhiteList,
              accountEmployerBlackList: this.state.accountEmployerBlackList,
              accountJobBlackList: this.state.accountJobBlackList,
              accountEmployerSourceList: this.state.accountEmployerSourceList,
              accountJobSourceList: this.state.accountJobSourceList
            },
            (response) => {
              /* DELETE EMPLOYER **/
              const differenceInEmployers = accountEmployerSourceListIdsFromServer.filter(
                (sEmp) => !existingAccountEmployerSource.includes(sEmp)
              );
              differenceInEmployers.forEach((difIds) => {
                getEmployerSourceListApis.removeEmployerByAdmin(
                  difIds,
                  this.state.id,
                  {},
                  (response) => { }
                );
              });
              this.setState(
                {
                  id: response.id,
                  recruitersAddedJobs: accountJobSourceList
                    .filter((job) => !recruitersRemovedJobs.includes(job.id))
                    .map((job) => job.id),
                  newRecruitersRemovedJobs: [],

                  accountJobSourceListIdsFromServer: accountJobSourceList.map(
                    (job) => job.id
                  ),
                  existingAccountJobSource: accountJobSourceList.map(
                    (job) => job.id
                  ),

                  accountEmployerSourceListIdsFromServer: accountEmployerSourceList.map((job) => job.id),
                  existingAccountEmployerSource: accountEmployerSourceList.map(
                    (job) => job.id
                  ),

                  recruitersAddedEmployers: accountEmployerSourceList
                    .filter(
                      (emp) => !recruitersRemovedEmployers.includes(emp.id)
                    )
                    .map((emp) => emp.id),
                  newRecruitersRemovedEmployers: []
                },
                () => {
                  setTimeout(() => {
                    this.fetchRecruiterAllJobs();
                    this.fetchRecruiterAllEmployers();
                  }, 3000);
                }
              );
              this.getRoleMapping(response.id);
              this.successDialog.open(
                'User has been successfully updated'
              );
            }
          ).catch(Core.showError);
          if (__employers.selected?.id) {
            __employers.selected = await getAccountEmployer({
              accountId: account.id,
              employerId: __employers.selected.id
            });
            await self.updateState({ __employers });
          }
        }
        else {
          /** CREATE */
          Account.post(
            getStateModel(this.state, getAccountModel()),
            this.state.realRoleId,
            {
              accountRecruiterWhiteList: this.state.accountRecruiterWhiteList,
              accountEmployerBlackList: this.state.accountEmployerBlackList,
              accountJobBlackList: this.state.accountJobBlackList,
              accountEmployerSourceList: this.state.accountEmployerSourceList,
              accountJobSourceList: this.state.accountJobSourceList
            },
            async (response) => {
              if (response.id && __employers.selected?.id) {
                __employers.selected = await getAccountEmployer({
                  accountId: response.id,
                  employerId: __employers.selected.id
                });
                await self.updateState({ __employers });
              }

              /* DELETE EMPLOYER **/
              const differenceInEmployers =
                accountEmployerSourceListIdsFromServer.filter(
                  (sEmp) => !existingAccountEmployerSource.includes(sEmp)
                );
              differenceInEmployers.forEach((difIds) => {
                getEmployerSourceListApis.removeEmployerByAdmin(
                  difIds,
                  this.state.id,
                  {},
                  (response) => { }
                );
              });
              this.setState(
                {
                  id: response.id,
                  recruitersAddedJobs: accountJobSourceList
                    .filter((job) => !recruitersRemovedJobs.includes(job.id))
                    .map((job) => job.id),
                  newRecruitersRemovedJobs: [],

                  accountJobSourceListIdsFromServer: accountJobSourceList.map(
                    (job) => job.id
                  ),
                  existingAccountJobSource: accountJobSourceList.map(
                    (job) => job.id
                  ),

                  accountEmployerSourceListIdsFromServer: accountEmployerSourceList.map((job) => job.id),
                  existingAccountEmployerSource: accountEmployerSourceList.map(
                    (job) => job.id
                  ),

                  recruitersAddedEmployers: accountEmployerSourceList
                    .filter(
                      (emp) => !recruitersRemovedEmployers.includes(emp.id)
                    )
                    .map((emp) => emp.id),
                  newRecruitersRemovedEmployers: []
                },
                () => {
                  setTimeout(() => {
                    this.fetchRecruiterAllJobs();
                    this.fetchRecruiterAllEmployers();
                  }, 3000);
                }
              );
              this.getRoleMapping(response.id);
              this.successDialog.open(
                'User has been successfully created'
              );
            }
          ).catch(Core.showError);
        }
      }
    };
    /**
     * VALIDATIONS
     */
    const isValid = (event) => {
      /** FIRST NAME: required */
      if (!String(this.state.firstName).trim().length) {
        stepperScrollToTop();
        this.setState({
          snackbar: 'First Name is required',
          errorFirstName: required
        });
        return false;
      }
      /** LAST NAME: required */
      if (!String(this.state.lastName).trim().length) {
        stepperScrollToTop();
        this.setState({
          snackbar: 'Last Name is required',
          errorLastName: required
        });
        return false;
      }
      /** ROLE: required */
      if (!String(this.state.realRoleId).trim().length) {
        stepperScrollToTop();
        this.setState({
          snackbar: 'Role is required',
          errorRole: required
        });
        return false;
      }
      /** EMAIL: required */
      if (!String(this.state.email).trim().length) {
        stepperScrollToTop();
        this.setState({
          snackbar: 'Email is required',
          errorEmail: required
        });
        return false;
      }
      /** EMAIL: validation */
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.state.email
        )
      ) {
        stepperScrollToTop();
        this.setState({
          snackbar: 'Email is invalid',
          errorEmail: 'Please enter a valid email'
        });
        return false;
      }
      /** PASSWORD */
      if (
        !_accountId &&
        !String(this.state.password).trim().length
      ) {
        stepperScrollToTop();
        this.setState({
          snackbar: 'Password is required',
          errorPassword: required
        });
        return false;
      }
      /** PASSWORD: retype */
      if (
        (!!String(this.state.password).trim().length ||
          !!String(this.state.retype).trim().length) &&
        this.state.password !== this.state.retype
      ) {
        stepperScrollToTop();
        this.setState({
          snackbar: 'Password retype does not match',
          errorRetype: 'No match with password'
        });
        return false;
      }
      /** ALL TESTS PASSED */
      return true;
    };

    async function fetchEmployers() {
      __employers.error = false;
      __employers.fetching = true;
      __employers.fetched = false;
      await self.updateState({ __employers });
      __employers.list = await getEmployersList().catch(Core.showError);
      if (Array.isArray(__employers.list)) {
        __employers.error = false;
        __employers.fetching = false;
        __employers.fetched = true;
      }
      else {
        __employers.error = true;
        __employers.fetching = false;
        __employers.fetched = false;
      }
      __employers.selected = await getAccountEmployer({
        accountId: account.id
      }).catch(Core.showError);
      await self.updateState({ __employers });
    }

    const employersList = Arr(__employers.list);
    let selectedEmployerId = __employers.selected?.id;

    console.debug({ isEmployerRecruiter, __employers });
    if (
      isEmployerRecruiter &&
      !__employers.error &&
      !__employers.fetching &&
      !__employers.fetched
    ) {
      fetchEmployers();
    }

    const _accountId = getParams({ pattern: '/account/edit/:id' }).id;
    return (
      <Page paper
        title={
          join([
            (_accountId ? 'Edit' : 'New'),
            'Account',
            getPersonName(account)
          ], ' ')
        }
        contentRight={
          <>
            <HistoryMenu />
            <IconButton
              title={`Open a message box`}
              onClick={(ev) =>
                openMessageEmailPreview({ recruiterId: this.state.id })
              }
              icon='email'
            />
          </>
        }
        paperClassName='p-0'
      >

        <div className="d-flex bg-white sticky-top p-1 px-2">
          <Button outlined minW120
            className="ml-auto px-40"
            onClick={submit}
          >
            Save
          </Button>
        </div>
        <>
          <Row style={{ paddingTop: '32px' }}>
            <Col>
              <label>
                First Name <b className="cred">*</b>
              </label>
              <TextField
                name="firstName"
                type="text"
                                value={this.state.firstName}
                onChange={(event, firstName) => {
                  this.setState({ firstName, errorFirstName: '' });
                }}
                required
                errorText={this.state.errorFirstName}
              />
            </Col>
            <Col>
              <label>
                Last Name <b className="cred">*</b>
              </label>
              <TextField
                name="lastName"
                type="text"
                                value={this.state.lastName}
                onChange={(event, lastName) => {
                  this.setState({ lastName, errorLastName: '' });
                }}
                required
                errorText={this.state.errorLastName}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <label>Nickname</label>
              <TextField
                name="preferredName"
                type="text"
                                value={this.state.preferredName}
                onChange={(event, preferredName) =>
                  this.setState({ preferredName })
                }
              />
            </Col>
            <Col>
              <label>Talent Advisor</label>
              <TextField
                name="talentAdvisor"
                type="text"
                                value={this.state.talentAdvisor}
                onChange={(event, talentAdvisor) =>
                  this.setState({ talentAdvisor })
                }
              />
            </Col>
          </Row>

          <Fieldset
            title="Role"
            dot="red"
          >
            <Menu dropdown
              name={`accounts__edit__select_role__dropdown`}
              value={account.realRoleId}
              onChange={(realRoleId, realRole) => {
                this.setState({
                  realRoleId: realRoleId,
                  realRole,
                  role: Str(realRole.name),
                  errorRole: ''
                });
              }}
              options={Arr(this.state._roles)}
              optionLabel='description'
            />
            <ErrorChipMessage show={!!this.state.errorRole}>
              {this.state.errorRole}
            </ErrorChipMessage>
          </Fieldset>
          <SelectSubrole
            account={account}
            onChange={(subroleId) => {
              this.setState({ subroleId: Str(subroleId) });
            }}
          />

          <Row>
            <Fieldset
              title="Title"
            >
              <TextField
                name="title"
                value={this.state.title}
                onChange={(event, title) => {
                  this.setState({ title });
                }}
              />
            </Fieldset>
            <Fieldset
              title="Company name"
              fullWidth={false}
              actionBar={
                <Checkbox
                  checked={this.state.leftCompanyFlag}
                  onChange={event => {
                    let checked = event.target.checked;
                    this.setState({ leftCompanyFlag: checked });
                  }}
                  labelProps={{ className: 'f-sm fw-400 c-black-medium' }}
                  checkboxProps={{ size: 'small', style: { maxHeight: '1rem' } }}
                >
                  Left the company?
                </Checkbox>
              }
            >
              {(account.id && isEmployerRecruiter) ? (
                <StyledTooltip
                  title={
                    <>
                      <TaggedValue
                        tag='AccountEmployer.employer.name'
                        value={(
                          __employers?.selected?.name
                            ? `${__employers.selected.name} (${__employers.selected.id})`
                            : '[missing]'
                        )}
                      />
                      <TaggedValue
                        tag='Account.companyName'
                        value={account.companyName || '[unset]'}
                      />
                    </>
                  }
                  placement={PLACEMENT__RIGHT_START}
                >
                  <Menu dropdown
                    name='account_edit__account_employer'
                    className='mt-2'
                    value={selectedEmployerId}
                    onChange={async (employerId, employer) => {
                      __employers.selected = employer;
                      await self.updateState({
                        __employers,
                        companyName: employer.name
                      });
                    }}
                    options={employersList}
                    optionLabel='name'
                    renderOption={({ option }) => (
                      <StyledTooltip
                        title={(
                          <TaggedValue
                            tag='Updated at'
                            value={isValidDate(option.updatedAt) && formatDateTime24hPT(option.updatedAt)}
                          />
                        )}
                        placement={PLACEMENT__RIGHT_START}
                      >
                        {option.name}
                        <div className='w-100 f-sm t-align-right'>
                          {Definition.getLabel(DEFINITION_CATEGORY__STATE, option.state)}
                        </div>
                      </StyledTooltip>
                    )}
                  />
                </StyledTooltip>
              ) : (
                <TextField
                  name="companyName"
                  type="text"
                                    value={this.state.companyName}
                  onChange={(event, companyName) => {
                    this.setState({ companyName });
                  }}
                  fullWidth
                />
              )}
              {YES(this.state.leftCompanyFlag) && (
                <div className='d-flex flex-align-left-center'>
                  <span className='f-md fw-500 mr-1 nowrap'>New company</span>
                  <TextField
                    value={this.state.newCompanyName}
                    onChange={(event) => this.setState({ newCompanyName: event.target.value })}
                  />
                </div>
              )}
            </Fieldset>
          </Row>

          <Row className="mt-3">
            <Col>
              <label>
                Email <b className="cred">*</b>
              </label>
              <TextField
                name="email"
                type="email"
                                value={this.state.email}
                onChange={(event, email) => {
                  email = String(email).toLowerCase();
                  this.setState({ email, errorEmail: '' });
                }}
                required
                fullWidth
                errorText={this.state.errorEmail}
              />
            </Col>
            <Col>
              <label>Phone</label>
              <TextField
                name="phone"
                type="phone"
                                value={this.state.phone}
                onChange={(event, phone) => {
                  this.setState({ phone, errorPhone: '' });
                }}
                required
                fullWidth
                errorText={this.state.errorPhone}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <label>Additional Email</label>
              <TextField
                name="additionalEmail"
                type="email"
                                value={this.state.additionalEmail}
                onChange={(event, additionalEmail) => {
                  this.setState({ additionalEmail });
                }}
                fullWidth
              />
            </Col>
            <Col>
              <label>Additional Phone</label>
              <TextField
                name="additionalPhone"
                type="phone"
                                value={this.state.additionalPhone}
                onChange={(event, additionalPhone) => {
                  this.setState({ additionalPhone });
                }}
                fullWidth
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <div className="d-flex flex-align-left-center">
                <label className="mr-1">Calendar booking URL</label>
                {!!this.state.calendarBookingLink && (
                  <i
                    className="material-icons pointer"
                    style={{ fontSize: 18 }}
                    onClick={(ev) =>
                      window.open(this.state.calendarBookingLink)
                    }
                  >
                    open_in_new
                  </i>
                )}
              </div>
              <TextField
                name="calendarBookingLink"
                                value={this.state.calendarBookingLink}
                onChange={(ev, calendarBookingLink) => {
                  calendarBookingLink = calendarBookingLink
                    .split('\n')
                    .map((url) => formatURL(url))
                    .join('\n');
                  this.setState({ calendarBookingLink });
                }}
                fullWidth
              />
            </Col>
          </Row>

          <EmailsListClass
            name={'accounts_edit__admin_contacts'}
            model={this.state}
            onSave={(update) => this.setState(update)}
            messageTypeTags={Definition.get('recruiterMessageType')}
          />

          <Row className="mt-3">
            <Col>
              <label>LinkedIn</label>
              <TextField
                name="linkedin"
                type="text"
                                value={this.state.linkedInUrl}
                onChange={(event, linkedInUrl) =>
                  this.setState({ linkedInUrl: formatURL(linkedInUrl) })
                }
                fullWidth
              />
            </Col>
            <Col>
              <label>Calendar</label>
              <TextField
                name="calendar"
                type="text"
                                value={this.state.calendarUrl}
                onChange={(event, calendarUrl) => {
                  this.setState({ calendarUrl });
                }}
                fullWidth
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <label>
                {_accountId ? (
                  'Enter Password'
                ) : (
                  <Fragment>
                    Password <b className="cred">*</b>
                  </Fragment>
                )}
              </label>
              <form>
                <TextField
                  name="password"
                  type="password"
                  autoComplete="password"
                                    value={this.state.password}
                  onChange={(event, password) => {
                    this.setState({ password, errorPassword: '' });
                  }}
                  required={!_accountId}
                  fullWidth
                  errorText={this.state.errorPassword}
                />
              </form>
            </Col>
            <Col>
              <label>Confirm Password</label>
              <form>
                <TextField
                  name="retype"
                  type="password"
                  autoComplete="retype"
                                    value={this.state.retype}
                  onChange={(event, retype) => {
                    this.setState({ retype, errorRetype: '' });
                  }}
                  fullWidth
                  errorText={this.state.errorRetype}
                />
              </form>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <label>LinkedIn Extension Id</label>
              <form>
                <TextField
                  name="Linkedin Extension key"
                  type="text"
                  autoComplete="extensionId"
                                    value={this.state.extensionId}
                  onChange={(event, extensionId) => {
                    this.setState({ extensionId, errorRetype: '' });
                  }}
                  fullWidth
                />
              </form>
            </Col>
            <Fieldset
              title={CONFIG.labels.expertise}
            >
              <MultipleSelect
                name={CONFIG.labels.expertise}
                placeholder={CONFIG.placeholder.multipleOptions}
                data={Definition.get(
                  DEFINITION_CATEGORY__ACCOUNT_EXPERTISE
                )}
                value={Arr(expertise)}
                onChange={(expertise) => this.setState({ expertise })}
                className="mt-1"
              />
            </Fieldset>
          </Row>

          <Row>
            <Fieldset
              title={CONFIG.labels.interactions}
            >
              <MultipleSelect
                name={CONFIG.labels.interactions}
                placeholder={CONFIG.placeholder.multipleOptions}
                data={Definition.get(
                  DEFINITION_CATEGORY__ACCOUNT_INTERACTIONS
                )}
                value={Arr(interactions)}
                onChange={(interactions) =>
                  this.setState({ interactions })
                }
                className="mt-1"
              />
            </Fieldset>
            <Fieldset
              title={CONFIG.labels.announcements}
            >
              <MultipleSelect
                name={CONFIG.labels.announcements}
                placeholder={CONFIG.placeholder.multipleOptions}
                data={Definition.get(
                  DEFINITION_CATEGORY__ACCOUNT_ANNOUNCEMENTS
                )}
                value={Arr(announcements)}
                onChange={(announcements) =>
                  this.setState({ announcements })
                }
                className="mt-1"
              />
            </Fieldset>
          </Row>

          <Row className="mt-3">
            <Col fullWidth>
              <label>Notes</label>
              <RichTextBox
                name="publicNotes"
                value={this.state.publicNotes}
                onChange={(publicNotes) => {
                  this.setState({
                    publicNotes
                  });
                }}
              />
            </Col>
          </Row>

          <div className="green-block py-1 rounded mt-3">
            <Row>
              <Col fullWidth>
                <h4>For 10 by 10 use only</h4>
              </Col>
            </Row>
            <Row className="edit-account-list-row">
              <Col className="edit-account-list-col">
                <label>Black List Employers</label>
                <IconButton
                  className="icon-button"
                  children={<i className="material-icons">create</i>}
                  onClick={(ev) => {
                    Employer.getAll((employers) => {
                      this.selectListDialog.open(
                        'Black List Employers',
                        employers,
                        this.state.accountEmployerBlackList.map(
                          (item) => item.id
                        ),
                        (accountEmployerBlackList) =>
                          this.setState({ accountEmployerBlackList })
                      );
                    });
                  }}
                />
                {this.state.accountEmployerBlackList.map((item) => (
                  <li key={item.id}>{item._name || <i>&mdash;</i>}</li>
                ))}
              </Col>
              <Col className="edit-account-list-col">
                <label>Black List Job</label>
                <IconButton
                  className="icon-button"
                  children={<i className="material-icons">create</i>}
                  onClick={(ev) => {
                    Job.getActives((jobs) => {
                      this.selectListDialog.open(
                        'Black List Jobs',
                        jobs,
                        this.state.accountJobBlackList.map(
                          (item) => item.id
                        ),
                        (accountJobBlackList) =>
                          this.setState({ accountJobBlackList })
                      );
                    });
                  }}
                />
                {this.state.accountJobBlackList.map((item) => (
                  <li key={item.id}>{item._name || <i>&mdash;</i>}</li>
                ))}
              </Col>
            </Row>
            <Row className="edit-account-list-row mt-3">
              <Col className="edit-account-list-col">
                <label>Source List Employers</label>
                <IconButton
                  className="icon-button"
                  children={<i className="material-icons">create</i>}
                  onClick={(ev) => {
                    Employer.getAll((employers) => {
                      this.selectListDialog.open(
                        'Source List Employers',
                        employers,
                        this.state.accountEmployerSourceList.map(
                          (item) => item.id
                        ),
                        (accountEmployerSourceList) => {
                          const concatServerEmployers = [
                            ...recruitersRemovedEmployers,
                            ...recruitersAddedEmployers
                          ];
                          let onlyEmpFromPopup = [];
                          if (!!concatServerEmployers.length) {
                            onlyEmpFromPopup = accountEmployerSourceList
                              .filter(
                                (emp) =>
                                  !concatServerEmployers.includes(
                                    emp.id
                                  )
                              )
                              .map((emp) => emp.id);
                          } else {
                            onlyEmpFromPopup = accountEmployerSourceList.map(
                              (emp) => emp.id
                            );
                          }

                          this.setState({
                            accountEmployerSourceList,
                            newRecruitersRemovedEmployers: onlyEmpFromPopup,
                            existingAccountEmployerSource: accountEmployerSourceList.map(
                              (job) => job.id
                            )
                          });
                        }
                      );
                    });
                  }}
                />
                {this.sortSourceLists(
                  this.state.accountEmployerSourceList,
                  recruitersAddedEmployers,
                  recruitersRemovedEmployers,
                  newRecruitersRemovedEmployers
                ).map((item) => (
                  <li key={item.id}>
                    {item._name || <i>&mdash;</i>}&nbsp;&nbsp;
                    {this.showDates(
                      item.id,
                      this.state.recruiterAllEmployers,
                      'employerId'
                    )}
                    &nbsp;&nbsp;
                    {this.getLabelsOfSourceList(
                      item.id,
                      recruitersRemovedEmployers,
                      recruitersAddedEmployers,
                      this.state.accountEmployerSourceList,
                      'Employer'
                    )}
                  </li>
                ))}
              </Col>
              <Col className="edit-account-list-col">
                <label>Source List Job</label>
                <IconButton
                  className="icon-button"
                  children={<i className="material-icons">create</i>}
                  onClick={(ev) => {
                    Job.getActives((jobs) => {
                      let notInQuery = this.state.accountJobSourceList.filter(
                        (j) => !jobs.map((el) => el.id).includes(j.id)
                      );
                      let combined = [...jobs, ...notInQuery];

                      this.selectListDialog.open(
                        'Source List Jobs',
                        combined,
                        this.state.accountJobSourceList.map(
                          (item) => item.id
                        ),
                        (accountJobSourceList) => {
                          let oldIds = this.state.accountJobSourceList.map(
                            (el) => el.id
                          );
                          const concatServerJobs = [
                            ...recruitersRemovedJobs,
                            ...recruitersAddedJobs
                          ];
                          let onlyJobFromPopup = [];
                          if (!!concatServerJobs.length) {
                            onlyJobFromPopup = accountJobSourceList
                              .filter(
                                (job) => !concatServerJobs.includes(job.id)
                              )
                              .map((job) => job.id);
                          } else {
                            onlyJobFromPopup = accountJobSourceList.map(
                              (job) => job.id
                            );
                          }

                          if (
                            oldIds.filter(
                              (el) =>
                                !accountJobSourceList
                                  .map((item) => item.id)
                                  .includes(el)
                            ).length
                          ) {
                            if (
                              window.confirm(
                                "you want to delete the the job for the recruiter, can't be undo.  If you want to pause, please use pause, if you don't see pause then save record first"
                              )
                            ) {
                              this.setState({
                                accountJobSourceList,
                                newRecruitersRemovedJobs: onlyJobFromPopup,
                                existingAccountJobSource: accountJobSourceList.map(
                                  (job) => job.id
                                )
                              });
                            }
                          } else {
                            this.setState({
                              accountJobSourceList,
                              newRecruitersRemovedJobs: onlyJobFromPopup,
                              existingAccountJobSource: accountJobSourceList.map(
                                (job) => job.id
                              )
                            });
                          }
                        }
                      );
                    });
                  }}
                />
                {this.sortSourceLists(
                  this.state.accountJobSourceList,
                  recruitersAddedJobs,
                  recruitersRemovedJobs,
                  newRecruitersRemovedJobs
                ).map((item) => (
                  <Fragment>
                    <li key={item.id}>
                      {item._name || <i>&mdash;</i>}&nbsp;&nbsp;
                      {this.showDates(
                        item.id,
                        this.state.recruiterAllJobs,
                        'jobId'
                      )}
                      &nbsp;&nbsp;
                      {this.getLabelsOfSourceList(
                        item.id,
                        recruitersRemovedJobs,
                        recruitersAddedJobs,
                        this.state.accountJobSourceList,
                        'Job'
                      )}
                    </li>
                  </Fragment>
                ))}
              </Col>
            </Row>
            <Row className="edit-account-list-row mt-3">
              <Col className="edit-account-list-col">
                <label>White List Employers</label>
                <IconButton
                  className="icon-button"
                  children={<i className="material-icons">create</i>}
                  onClick={(ev) => {
                    Employer.getAll((employers) => {
                      this.selectListDialog.open(
                        'White List Recruiters',
                        employers,
                        this.state.accountRecruiterWhiteList.map(
                          (item) => item.id
                        ),
                        (accountRecruiterWhiteList) =>
                          this.setState({ accountRecruiterWhiteList })
                      );
                    });
                  }}
                />
                {this.state.accountRecruiterWhiteList.map((item) => (
                  <li key={item.id}>{item._name || <i>&mdash;</i>}</li>
                ))}
              </Col>
              <Col />
            </Row>

            <SelectListDialog
              ref={(selectListDialog) =>
                (this.selectListDialog = selectListDialog)
              }
              parent={this}
            />

            <Row className="mt-3">
              <Col fullWidth>
                <label>Test Bucket</label>
                <RadioGroup
                  name="testBucket"
                  options={
                    Definition.get('testBucket').filter((tag) =>
                      Core.isAdminOrCoordinator()
                        ? true
                        : Definition.test('testBucket', tag.id, /Beta1/)
                    )
                  }
                  value={this.state.testBucket}
                  onChange={
                    (event, testBucket) => this.setState({ testBucket })
                  }
                  fullWidth
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col fullWidth>
                <label>Current State</label>
                <RadioGroup
                  name="state"
                  options={
                    Definition.get('state')
                      .filter((tag) =>
                        Core.isAdminOrCoordinator()
                          ? true
                          : Definition.test('state', tag.id, /draft/)
                      )
                  }
                  value={this.state.state}
                  onChange={
                    (event, state) => this.setState({ state })
                  }
                  fullWidth
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col fullWidth>
                <label>Private Notes</label>
                <RichTextBox
                  name="privateNotes"
                  value={this.state.privateNotes}
                  onChange={(privateNotes) => {
                    this.setState({
                      privateNotes
                    });
                  }}
                />
              </Col>
            </Row>
          </div>

          <Row className="mt-3">
            <Col>
              <label>Creation Date</label>
              <DatePicker
                name="createdAt"
                value={
                  this.state.createdAt
                    ? new Date(this.state.createdAt)
                    : null
                }
                onChange={(createdAt) => {
                  this.setState({ createdAt: createdAt.toISOString() });
                }}
                fullWidth
              />
            </Col>
            <Col>
              <label>Expiration Date</label>
              <DatePicker
                name="expirationDate"
                                value={
                  this.state.expirationDate
                    ? new Date(this.state.expirationDate)
                    : null
                }
                onChange={(expirationDate) => {
                  this.setState({
                    expirationDate: expirationDate.toISOString()
                  });
                }}
                fullWidth
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <label>Closed Date</label>
              <DatePicker
                name="closeDate"
                                value={
                  this.state.closeDate
                    ? new Date(this.state.closeDate)
                    : null
                }
                onChange={(closeDate) => {
                  this.setState({ closeDate: closeDate.toISOString() });
                }}
                fullWidth
              />
            </Col>
            <Col>
              <label>Last Update</label>
              <DatePicker
                name="updatedAt"
                                value={
                  this.state.updatedAt
                    ? new Date(this.state.updatedAt)
                    : null
                }
                onChange={(updatedAt) => {
                  this.setState({ updatedAt: updatedAt.toISOString() });
                }}
                fullWidth
              />
            </Col>
          </Row>
        </>
        <Row className='sticky-bottom m-0 p-1 bg-white'>
          <Col>
            <Button outlined minW120 error
              onClick={goBack}
            >
              Cancel
            </Button>
            {(!this.state.state ||
              Definition.test('state', this.state.state, /draft/)) && (
                <Button outlined minW120
                  label="SAVE DRAFT"
                  onClick={save}
                />
              )}
          </Col>
          <Col style={{ textAlign: 'right' }}>
            <Button primary minW120
              label="DONE"
              onClick={submit}
            />
          </Col>
        </Row>
        <SuccessDialog Controller={this}
          actions={[
            <Button outlined minW120
              label="STAY"
            />,
            <Button primary autoFocus minW120
              label="DONE"
              onClick={(ev) =>
                Core.go({
                  ...this.props,
                  to: Core.isAdmin({
                    action: ACCOUNT_ACTION__LIST_ACCOUNTS
                  })
                    ? '/accounts/'
                    : '/'
                })
              }
            />
          ]}
        />
        <Snackbar
          open={!!this.state.snackbar}
          message={this.state.snackbar || ''}
          className="snack-bar"
          autoHideDuration={6000}
          onClose={(ev) => this.setState({ snackbar: null })}
        />
        <ConfirmDialog
          ref={(self) => (this.ConfirmDialog = self)}
          title="Confirmation"
          message=""
          actionLabel="Confirm"
        />
      </Page>
    );
  }
}

/* EXPORTS ==================================== */

export default AccountEdit;

/* ============================================ */
